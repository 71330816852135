import Cookies from "js-cookie";

import type { LoginServerModel } from "types";

export type UserCB = (user: LoginServerModel | null, error: any) => void;

export class Auth {
  private static instance: Auth | null = null;

  static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private cookieKey = "COCOTRUCK_ADMIN";
  private user: LoginServerModel | null = null;
  private cb: UserCB | null = null;

  private constructor() {}

  get isAuth() {
    return !!this.user?.refreshToken;
  }

  get accessToken() {
    return this.user?.accessToken;
  }

  get refreshToken() {
    return this.user?.refreshToken!;
  }

  get adminGrade() {
    return this.user?.adminGrade;
  }

  changeAccessToken(token: string) {
    if (!this.user) return;

    const newUser = { ...this.user, accessToken: token };
    Cookies.set(this.cookieKey, JSON.stringify(newUser));
    this.setUser(newUser);
  }

  onAuthStateChanged(cb: UserCB) {
    this.cb = cb;

    this.onUserChange(this.user);

    return () => {
      this.cb = null;
    };
  }

  private onUserChange(
    user: LoginServerModel | null,
    error?: { message: string },
  ) {
    this.cb && this.cb(user, error);
  }

  private setUser(user: LoginServerModel) {
    this.user = user;
  }

  save(data: LoginServerModel) {
    Cookies.set(this.cookieKey, JSON.stringify(data), {
      expires: 14,
      secure: true,
    });
    this.onUserChange(data);
    this.resolveUser();
  }

  clear() {
    Cookies.remove(this.cookieKey);
    this.user = null;
  }

  resolveUser() {
    if (window) {
      const signedInUser = Cookies.get(this.cookieKey);

      if (signedInUser) {
        this.setUser(JSON.parse(Cookies.get(this.cookieKey)!));
      }
    }

    return this;
  }
}
