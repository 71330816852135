import { css, Theme } from "@emotion/react";

export const listPageLayout = css`
  position: relative;
`;

export const link = (theme: Theme) => css`
  ${theme.font.regular_14};
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${theme.color.primary_40};
`;

export const icon = (theme: Theme) => css`
  width: 12px;
  height: 12px;

  & > path {
    fill: ${theme.color.primary_40};
  }
`;
