import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable, StatusLabel } from "components";
import { useDownloadFile } from "services";
import {
  calculateVatAmount,
  calculateTotalAmount,
  findLookupTableLabel,
  formatPrice,
  makeOrderFileName,
} from "utils";
import { PAYMENT_METHOD_RADIOS } from "assets";
import type { GetSettlementDetailServerModel, SettlementStatus } from "types";
import * as S from "./SettlementRequestPayment.styled";

interface SettlementRequestPaymentProps {
  requestPayment?: GetSettlementDetailServerModel["requestPayment"];
  memo?: string;
  status: SettlementStatus;
}

const SettlementRequestPayment = ({
  requestPayment,
  memo,
  status,
}: SettlementRequestPaymentProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  if (!requestPayment) return null;

  const paymentMethodLabel = findLookupTableLabel(
    PAYMENT_METHOD_RADIOS,
    requestPayment.paymentMethod,
  );

  const vatPrice = formatPrice(
    calculateVatAmount(`${requestPayment.price}`, `${requestPayment.vat}`),
  );
  const vat = `(${requestPayment.vat}%) ${vatPrice}`;
  const totalPrice = formatPrice(
    calculateTotalAmount(`${requestPayment.price}`, `${requestPayment.vat}`),
  );
  const hasUploadFile = Object.values(requestPayment.attachment ?? {}).some(
    Boolean,
  );

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row css={S.statusLabel}>
        <LabelContentTable.Content label="Status" labelWidth={200}>
          {status && <StatusLabel status={status} />}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Payment method" labelWidth={200}>
          {paymentMethodLabel ? t(paymentMethodLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Price" labelWidth={200}>
          <S.PriceWrapper>
            {status && <S.Total status={status}>{totalPrice}</S.Total>}
            <S.SubtotalWrapper>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                <S.SubtotalContent>
                  {formatPrice(requestPayment.price)}
                </S.SubtotalContent>
              </S.SubtotalItem>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                <S.SubtotalContent>{vat}</S.SubtotalContent>
              </S.SubtotalItem>
            </S.SubtotalWrapper>
          </S.PriceWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Attached file" labelWidth={200}>
          {hasUploadFile && requestPayment.attachment ? (
            <S.FileWrapper>
              {[
                requestPayment.attachment.attachment1,
                requestPayment.attachment.attachment2,
              ].map(
                (fileKey, idx) =>
                  fileKey && (
                    <DownloadBtn
                      key={fileKey}
                      downloadFileNamePrefix="SettlementAttachedFile"
                      fileName={makeOrderFileName(fileKey, idx)}
                      fileKey={fileKey}
                      downloadFileMutate={downloadFileMutate}
                    />
                  ),
              )}
            </S.FileWrapper>
          ) : (
            "-"
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row css={S.memo}>
        <LabelContentTable.Content label="Memo" labelWidth={200}>
          {memo ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default SettlementRequestPayment;
