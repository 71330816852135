import React from "react";
import { useTranslation } from "react-i18next";

import { NoResultIcon } from "assets";
import * as S from "./NoResult.styled";

interface NoResultProps {
  className?: string;
  as?: any;
}

const NoResult = ({ className, as }: NoResultProps) => {
  const { t } = useTranslation();

  return (
    <S.Root className={className} as={as}>
      <S.IconWrapper>
        <NoResultIcon />
      </S.IconWrapper>
      <p>{t("No results found")}</p>
    </S.Root>
  );
};

export default NoResult;
