import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { SCHEMA } from "utils";
import { GRADE_TYPE_RADIOS } from "assets";
import { useGetMemberDetailRegisteredMemberDetail } from "services";
import type {
  TruckingCompanyType,
  EditMemberDetailRegisteredMemberForm,
} from "types";

const initForm = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  grade: GRADE_TYPE_RADIOS[0],
};

const schema = yup.object({
  lastName: SCHEMA.REQUIRED_STRING,
  middleName: SCHEMA.OPTIONAL_STRING,
  firstName: SCHEMA.REQUIRED_STRING,
  phone: SCHEMA.REQUIRED_STRING,
  grade: SCHEMA.DROPDOWN_GRADE,
});

const useMemberDetailRegisteredMembeEdit = () => {
  const { memberId, staffId } = useParams();
  const [searchParams] = useSearchParams();

  const formMethod = useForm<EditMemberDetailRegisteredMemberForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const memberType = searchParams.get("memberType");

  const req = {
    staffId: staffId!,
    query: {
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data } = useGetMemberDetailRegisteredMemberDetail(req);

  useEffect(() => {
    if (!data) return;

    const selectedGrade = GRADE_TYPE_RADIOS.findIndex(
      ({ key }) => key === data.grade,
    );

    formMethod.reset({
      lastName: data.lastName,
      middleName: data.middleName ?? "",
      firstName: data.firstName,
      phone: data.phone,
      grade: GRADE_TYPE_RADIOS[selectedGrade],
    });
  }, [data]);
  return { formMethod };
};

export default useMemberDetailRegisteredMembeEdit;
