import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { useAuth } from "contexts";
import { PrivateLayout } from "components";
import { PATH } from "constants/index";

const AuthRoute = () => {
  const { pathname, search } = useLocation();

  const { initializing, auth } = useAuth();
  if (initializing) return null;

  return auth.isAuth ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={PATH.ROOT} state={{ from: pathname + search }} />
  );
};

export default AuthRoute;
