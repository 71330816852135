import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { InvisibleIcon } from "assets";
import type { Languages } from "types";
import * as S from "./LoginInput.styled";

export interface PasswordInputProps {
  className?: string;
  label: Languages;
  register: UseFormRegisterReturn;
  type?: "password";
  id: string;
  value: string;
  disabled?: boolean;
}

const PasswordInput = ({
  className,
  label,
  register,
  id,
  value,
}: PasswordInputProps) => {
  const { t } = useTranslation();

  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const handlePasswordShow = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <S.AccountInput className={className} hasValue={!!value}>
      <S.Label htmlFor={id}>{t(label)}</S.Label>
      <S.Input
        id={id}
        type={isPasswordShow ? "text" : "password"}
        {...register}
      />
      <S.IconWrapper>
        <S.PasswordShowButton
          type="button"
          onClick={handlePasswordShow}
          tabIndex={-1}
        >
          <InvisibleIcon css={S.passwordShowIcon} />
        </S.PasswordShowButton>
      </S.IconWrapper>
    </S.AccountInput>
  );
};

export default PasswordInput;
