import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import { useGetDrivers } from "services";
import type {
  GetMemberDetailDriversQueryModel,
  TruckingCompanyType,
} from "types";
import {
  RegisteredDriverFilter,
  RegisteredDriverPagination,
  RegisteredDriverTable,
} from "./container";

const MemberDetailRegisteredDriver = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<
    GetMemberDetailDriversQueryModel["query"]
  >(["page", "linked", "name", "status"]);

  const memberType = searchParams.get("memberType");

  const { data, dataUpdatedAt, refetch } = useGetDrivers({
    query: {
      ...filters,
      status: filters.status || "ALL",
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  });

  return (
    <>
      <RegisteredDriverFilter />
      <RegisteredDriverTable
        drivers={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <RegisteredDriverPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default MemberDetailRegisteredDriver;
