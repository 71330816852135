import { useEffect, useState } from "react";
import { isNil } from "lodash-es";

import { useGoogleMap } from "contexts";
import { createMarker, rotateSVGIcon } from "utils";
import { TRUCK_ICON } from "assets";
import { zIndex } from "style";
import type {
  GetMonitoringTrucksClientModel,
  GetShipperMonitoringOrderDetailClientModel,
  LatLngWithNull,
} from "types";

type TruckMarkersDataType = ({
  direction: number;
} & LatLngWithNull)[];

interface TruckMarkers {
  truckMarker?: google.maps.Marker;
}

const useMonitoringTruckMarker = (
  data:
    | GetMonitoringTrucksClientModel["trucks"]
    | GetShipperMonitoringOrderDetailClientModel["allocationList"],
) => {
  const [truckMarkers, setTruckMarkers] = useState<TruckMarkers[]>([]);
  const { googleMap } = useGoogleMap();

  const location = data.map(({ lat, lng, direction }) => {
    return { lat, lng, direction };
  });

  const makeTruckMarker = async ({
    lat,
    lng,
    rotation,
  }: {
    lat: number;
    lng: number;
    rotation: number;
  }): Promise<google.maps.Marker> => {
    const icon = await rotateSVGIcon({
      svgUrl: TRUCK_ICON["default"],
      rotation,
    });

    return createMarker(
      googleMap!,
      lat,
      lng,
      icon,
      zIndex.GOOGLE_MAP_DEFAULT_TRUCK_MARKER,
    );
  };

  const initTruckMarkers = async (
    data: TruckMarkersDataType,
  ): Promise<TruckMarkers[]> => {
    const newTrucks: TruckMarkers[] = [];

    for (const truck of data) {
      if (
        isNil(truck.lat) ||
        isNil(truck.lng) ||
        isNaN(+truck.lng) ||
        isNaN(+truck.lat)
      ) {
        newTrucks.push({});
        continue;
      }

      const truckMarker = await makeTruckMarker({
        lng: +truck.lng,
        lat: +truck.lat,
        rotation: truck.direction,
      });

      newTrucks.push({ truckMarker });
    }
    setTruckMarkers(newTrucks);

    return newTrucks;
  };

  useEffect(() => {
    if (!location || !data || !googleMap) return;

    let truckMarkers: TruckMarkers[] = [];

    (async () => {
      truckMarkers = await initTruckMarkers(location);
    })();

    return () => {
      truckMarkers.forEach((marker) => {
        if (!marker.truckMarker) return;
        marker.truckMarker.setMap(null);
      });
    };
  }, [data, googleMap]);

  return { truckMarkers };
};

export default useMonitoringTruckMarker;
