import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { Outlet, useLocation } from "react-router-dom";

import { useAuth } from "contexts";
import { Modal, Toast } from "components";
import { beforePagePathState, truckTypeOptions } from "stores";
import { useGetTruckTypeOptions } from "services";

const Root = () => {
  const { auth } = useAuth();
  const { pathname, search } = useLocation();

  const { data: truckOptions } = useGetTruckTypeOptions(auth.isAuth);

  const setTruckTypeOptions = useSetRecoilState(truckTypeOptions);
  const setBeforePagePath = useSetRecoilState(beforePagePathState); // NOTE: 이전 페이지 주소

  useEffect(() => {
    if (!truckOptions) return;

    setTruckTypeOptions(truckOptions);
  }, [truckOptions]);

  useEffect(() => {
    return () => {
      setBeforePagePath(`${pathname}${search ?? ""}`);
    };
  }, [pathname, search]);

  return (
    <>
      <Toast />
      <Modal />
      <Outlet />
    </>
  );
};

export default Root;
