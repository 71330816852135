import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, PreviousPageLink } from "components";
import { PATH } from "constants/index";
import type { GetInquiryDetailServerModel, InquiryAnswerForm } from "types";
import * as S from "./InquiryBtns.styled";

interface InquiryBtnsProps {
  data?: GetInquiryDetailServerModel;
  isCreateReply: boolean;
  handleCheckCreateReply: () => void;
  handleCreateReply: () => void;
}

const InquiryBtns = ({
  data,
  isCreateReply,
  handleCheckCreateReply,
  handleCreateReply,
}: InquiryBtnsProps) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext<InquiryAnswerForm>();

  return (
    <S.BtnsWrapper>
      {isCreateReply ? (
        <Button
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleCheckCreateReply}
        />
      ) : (
        <PreviousPageLink variant="outlineMedium" to={PATH.SUPPORT_INQUIRY_TAB}>
          {t("Back to list")}
        </PreviousPageLink>
      )}
      {data?.status === "WAITING" && (
        <Button
          isDisabled={!!errors.content?.message}
          variant="primaryMedium"
          label={isCreateReply ? "Save" : "Reply"}
          handleClickBtn={
            isCreateReply ? handleCreateReply : handleCheckCreateReply
          }
        />
      )}
    </S.BtnsWrapper>
  );
};

export default InquiryBtns;
