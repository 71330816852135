import React, { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { LabelContent, Profile, StatusLabel } from "components";
import { clientLanguage } from "stores";
import { formatICTDateTime, formatPhoneNum } from "utils";
import { SuccessIcon, PhoneIcon, TruckIcon, DELIVERY_LOG_LABELS } from "assets";
import type { GetShipperMonitoringOrderDetailClientModel } from "types";
import { useOrderAllocationInfo } from "./hooks";
import * as S from "./OrderAllocationInfo.styled";

interface AllocationInfoProps {
  orderDetail: GetShipperMonitoringOrderDetailClientModel;
}

const OrderAllocationInfo = ({ orderDetail }: AllocationInfoProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const truckRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const languages = useRecoilValue(clientLanguage);

  const orderId = searchParams.get("orderId");

  const { selectedOrderCard, handleAllocationCardClick } =
    useOrderAllocationInfo(orderDetail, truckRef, orderId!);

  return (
    <S.AllocationInfoWrapper>
      <h3>{t("Allocation list")}</h3>
      <S.OrderCardWrapper>
        {orderDetail.allocationList.length &&
          orderDetail.allocationList.map((allocation, i: number) => {
            const allocationEntries = Object.entries({
              shipmentsStart: allocation.shipmentsStart,
              puAta: allocation.puAta,
              puAtd: allocation.puAtd,
              doAta: allocation.doAta,
              doAtd: allocation.doAtd,
            });

            const ongoingIndex = allocationEntries.findIndex(
              ([key, time]) => time === null,
            );

            return (
              <S.OrderCard
                isSelected={i === selectedOrderCard && selectedOrderCard !== -1}
                key={i}
                onClick={handleAllocationCardClick(i)}
              >
                <S.OrderCardButton disabled={!!allocation.doAtd}>
                  <S.TitleWrapper
                    ref={(el) =>
                      (truckRef.current[allocation.plateNumber] = el)
                    }
                  >
                    <Profile src={allocation.profileS3Url} />
                    <span>{allocation.driverName} </span>
                    <S.GpsBoxWrapper>
                      <SuccessIcon
                        css={S.checkIcon(!!allocation.gpsAvailable)}
                      />
                      <span>GPS</span>
                    </S.GpsBoxWrapper>
                  </S.TitleWrapper>
                  <S.DriverInfo>
                    <S.DriverInfoRow>
                      <TruckIcon css={S.icon} />
                      <span>
                        {languages === "en"
                          ? allocation.truckTypeEn
                          : allocation.truckTypeVi}{" "}
                        ({allocation.plateNumber})
                      </span>
                    </S.DriverInfoRow>
                    <S.DriverInfoRow css={S.driverInfo}>
                      <PhoneIcon css={S.icon} />
                      <span>{formatPhoneNum(allocation.driverPhone)}</span>
                    </S.DriverInfoRow>
                  </S.DriverInfo>
                  <S.DeliveryLogWrapper>
                    {allocationEntries.map(([key, time], index) => (
                      <LabelContent
                        key={key}
                        css={S.logLabelContent}
                        direction="horizontal"
                        label={
                          DELIVERY_LOG_LABELS[
                            key as keyof typeof DELIVERY_LOG_LABELS
                          ]
                        }
                      >
                        {index === ongoingIndex ? (
                          <StatusLabel status={"ONGOING"} />
                        ) : (
                          <S.Gray70Text>
                            {time
                              ? formatICTDateTime(time, "DD/MM/YY, HH:mm")
                              : "-"}
                          </S.Gray70Text>
                        )}
                      </LabelContent>
                    ))}
                  </S.DeliveryLogWrapper>
                </S.OrderCardButton>
              </S.OrderCard>
            );
          })}
      </S.OrderCardWrapper>
    </S.AllocationInfoWrapper>
  );
};

export default OrderAllocationInfo;
