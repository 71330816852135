import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useMemberType, useToast } from "hooks";
import { useGetLinkDriverTrucks, usePostDriverCreate } from "services";
import { makeS3PresignedUrlFileUuids } from "api";
import { makeCryptoFunction, SCHEMA } from "utils";
import { PATH, TAB, TOAST_MSG, VALID_MSG } from "constants/index";
import type {
  AutoCompleteDropdown,
  CreateDriverForm,
  S3FileUploadType,
} from "types";

const initForm = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  linked: { name: "", id: "" },
  license: null,
  profile: null,
  id: "",
  password: "",
  confirmPassword: "",
  bankName: "",
  bankBranch: "",
  bankAccountNo: "",
  isProvideInfo: false,
  isAgreeCollection: false,
};

const schema = yup.object({
  lastName: SCHEMA.REQUIRED_STRING,
  middleName: SCHEMA.OPTIONAL_STRING,
  firstName: SCHEMA.REQUIRED_STRING,
  phone: SCHEMA.REQUIRED_STRING,
  linked: SCHEMA.SINGLE_AUTOCOMPLETE.test(
    "required",
    VALID_MSG.REQUIRED,
    (value) => !!value?.id,
  ),
  license: SCHEMA.CREATE_FILELIST,
  profile: SCHEMA.CREATE_FILELIST,
  id: SCHEMA.REQUIRED_STRING,
  password: SCHEMA.PASSWORD,
  confirmPassword: SCHEMA.CONFIRM_PASSWORD,
  bankName: SCHEMA.OPTIONAL_STRING,
  bankBranch: SCHEMA.OPTIONAL_STRING,
  bankAccountNo: SCHEMA.OPTIONAL_STRING,
  isProvideInfo: SCHEMA.CHECK_REQURIED_BOOLEAN,
  isAgreeCollection: SCHEMA.CHECK_REQURIED_BOOLEAN,
});

const useMemberDetailRegisteredDriverCreate = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();

  const { memberType } = useMemberType();

  const query = { companyId: memberId!, companyType: memberType };

  const { data: linkDriverTrucks } = useGetLinkDriverTrucks({ query });
  const { mutate: driverCreateMutate } = usePostDriverCreate();

  const { addToast } = useToast();
  const formMethod = useForm<CreateDriverForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${
    memberType === "forwardercarrier" ? "forwarder" : "carrier"
  }&tab=${TAB.MEMBER.REGISTERED_DRIVER}`;

  const formatLinkTrucks = linkDriverTrucks?.map((truck) => ({
    name: truck.registNumber,
    id: truck.truckId,
  }));

  const handleSelectLink = (linkDriver: AutoCompleteDropdown): void => {
    formMethod.setValue("linked", linkDriver);

    if (!formMethod.watch("linked").id && linkDriver.name) {
      formMethod.setError(`linked`, {
        message: VALID_MSG.PLATE_NUMBER,
      });
      return;
    }

    if (formMethod.watch("linked").id || !linkDriver.name) {
      formMethod.clearErrors(`linked`);
      return;
    }
  };

  const handleDeleteFile = (name: keyof CreateDriverForm) => (): void =>
    formMethod.resetField(name);

  const handleCheckAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    formMethod.setValue(name as keyof CreateDriverForm, checked);
  };

  const handleDriverLicenseEmptyError = (err: any): void => {
    if (!formMethod.watch("license")) {
      formMethod.setError("license", { message: VALID_MSG.REQUIRED });
    }
  };

  const handleAddClick = formMethod.handleSubmit(async (data) => {
    if (data.license === null) return;

    const files: S3FileUploadType = {
      license: {
        file: data.license[0],
        path: "license",
      },
      ...(data.profile && {
        profile: { file: data.profile[0], path: "profile" },
      }),
    };

    const fileUuids = await makeS3PresignedUrlFileUuids(files);

    const body = {
      lastName: data.lastName.trim(),
      middleName: data.middleName ?? null,
      firstName: data.firstName.trim(),
      license: fileUuids.license,
      profile: fileUuids.profile ?? null,
      id: data.id,
      password: makeCryptoFunction(data.password),
      truckId: data.linked.id,
      phone: data.phone,
      bankName: data.bankName ?? null,
      bankBranch: data.bankBranch ?? null,
      bankAccountNo: data.bankAccountNo ?? null,
    };

    driverCreateMutate(
      { query, body },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.TRUCK_CREATE_DONE);
          navigate(beforeUrl);
        },
        onError: (error: any) => {
          //TODO: 서버 에러코드 정리하여 수정필요
          addToast({
            type: "warning",
            content: error.response?.data.response,
          });
        },
      },
    );
  }, handleDriverLicenseEmptyError);

  return {
    formMethod,
    beforeUrl,
    formatLinkTrucks,
    handleSelectLink,
    handleDeleteFile,
    handleCheckAgree,
    handleAddClick,
  };
};

export default useMemberDetailRegisteredDriverCreate;
