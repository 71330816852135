import { numericOnly, deleteComma } from "./formatter/number";

export const calculateTaxAmount = (
  totalAmount: string,
  taxPercentage: string,
): { principalAmount: number; taxAmount: number } => {
  const taxAmount = Math.round((+totalAmount * +taxPercentage) / 100);
  const principalAmount = +totalAmount - taxAmount;

  return { principalAmount, taxAmount };
};

export const calculateVatAmount = (subtotal = "0", taxRate: string): number => {
  const subtotalNum = deleteComma(subtotal);

  return Math.round((+numericOnly(subtotalNum) * +taxRate) / 100);
};

export const calculateTotalAmount = (
  subtotal: string,
  taxRate: string,
): number => {
  const subtotalNum = deleteComma(subtotal);
  const vatAmount = calculateVatAmount(subtotalNum, taxRate);

  return +subtotalNum + vatAmount;
};

export const calculateDropDecimal = (number: number): number => {
  const rounded = Math.round(number * 10) / 10;

  return Math.round(rounded);
};
