import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  postNoticeDeleteAPI,
  getInquiriesAPI,
  getInquiryDetailAPI,
  getNoticeDetailAPI,
  getNoticesAPI,
  patchNoticePinAPI,
  patchNoticeUnpinAPI,
  postInquiryReplyAPI,
  postNoticeAPI,
  putNoticeAPI,
} from "api";
import type {
  GetInquiriesQueryModel,
  GetNoticesQueryModel,
  PatchNoticePinnedQueryModel,
  PostInquiryReplyQueryModel,
  PostNoticeQueryModel,
  PostNoticeDeleteQueryModel,
  PutNoticeQueryModel,
} from "types";

const supportKeys = {
  all: ["support"],
  inquiries: () => [...supportKeys.all, "inquiry"],
  inquiry: (req: GetInquiriesQueryModel) => [...supportKeys.inquiries(), req],
  inquiryDetails: () => [...supportKeys.all, "inquiryDetail"],
  inquiryDetail: (inquiryId: string) => [
    ...supportKeys.inquiryDetails(),
    inquiryId,
  ],
  notices: () => [...supportKeys.all, "notice"],
  notice: (req: GetNoticesQueryModel) => [...supportKeys.notices(), req],
  noticeDetails: () => [...supportKeys.all, "noticeDetail"],
  noticeDetail: (noticeId: string) => [
    ...supportKeys.noticeDetails(),
    noticeId,
  ],
};

export const useGetInquries = (req: GetInquiriesQueryModel) => {
  return useQuery({
    queryKey: supportKeys.inquiry(req),
    queryFn: () => getInquiriesAPI(req),
  });
};

export const useGetInquiryDetail = (inquiryId: string) => {
  return useQuery({
    queryKey: supportKeys.inquiryDetail(inquiryId),
    queryFn: () => getInquiryDetailAPI(inquiryId),
  });
};

export const usePostInquiryReply = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PostInquiryReplyQueryModel) => postInquiryReplyAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supportKeys.inquiryDetails() });
    },
  });
};

export const useGetNotices = (req: GetNoticesQueryModel) => {
  return useQuery({
    queryKey: supportKeys.notice(req),
    queryFn: () => getNoticesAPI(req),
  });
};

export const useGetNoticeDetail = (noticeId: string) => {
  return useQuery({
    queryKey: supportKeys.noticeDetail(noticeId),
    queryFn: () => getNoticeDetailAPI(noticeId),
  });
};

export const usePostNotice = () => {
  return useMutation({
    mutationFn: (req: PostNoticeQueryModel) => postNoticeAPI(req),
  });
};

export const usePutNotice = () => {
  return useMutation({
    mutationFn: (req: PutNoticeQueryModel) => putNoticeAPI(req),
  });
};

export const useDeleteNotice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PostNoticeDeleteQueryModel) => postNoticeDeleteAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supportKeys.notices() });
    },
  });
};

export const usePatchPin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PatchNoticePinnedQueryModel) => patchNoticePinAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supportKeys.notices() });
    },
  });
};

export const usePatchUnpin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PatchNoticePinnedQueryModel) => patchNoticeUnpinAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supportKeys.notices() });
    },
  });
};
