import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 13px;
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 2px;
  padding: 0 18px;
  border: 1px solid ${theme.color.gray_20};
  color: ${theme.color.gray_70};
  background-color: ${theme.color.gray_10};

  :disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: ${theme.color.gray_20};
    border: 1px solid ${theme.color.gray_30};
  }

  &:disabled {
    opacity: 0.4;
    background-color: ${theme.color.gray_10};
    border: 1px solid ${theme.color.gray_20};
  }
`;
