import React from "react";

import {
  CarrierLinking,
  ForwarderLinking,
  ListPageLayout,
  ShipperLinking,
  Tab,
} from "components";
import { TAB } from "constants/index";

const Linking = () => {
  return (
    <ListPageLayout>
      <Tab
        tabs={[
          { key: TAB.SHIPPER, label: "Shipper" },
          { key: TAB.FORWARDER, label: "Forwarder" },
          { key: TAB.CARRIER, label: "Carrier" },
        ]}
      >
        <Tab.TabList />
        <Tab.TabPanel
          render={{
            shipper: <ShipperLinking />,
            forwarder: <ForwarderLinking />,
            carrier: <CarrierLinking />,
          }}
        />
      </Tab>
    </ListPageLayout>
  );
};

export default Linking;
