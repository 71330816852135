import React from "react";

import { ShipperPagination, ShipperTable } from "./containers";
import { MemberFilter } from "../container";

const Shipper = () => {
  return (
    <>
      <MemberFilter />
      <ShipperTable />
      <ShipperPagination />
    </>
  );
};

export default Shipper;
