import dayjs from "dayjs";

const makeFileNamePrefix = (fileExtension: "jpg" | "jpeg" | "png" | "pdf") => {
  if (fileExtension === "pdf") {
    return "PDF";
  } else {
    return "image";
  }
};

const getFileExtension = (filename: string): string => {
  const splitFilename = filename.split(".");

  if (splitFilename.length <= 1) return "";

  return splitFilename[splitFilename.length - 1];
};

export const makeFileNameLabel = (fileName: string, idx: number) => {
  const extension = getFileExtension(fileName);
  const fileNamePrefix = makeFileNamePrefix(
    extension as "jpg" | "jpeg" | "png" | "pdf",
  );

  return `${fileNamePrefix}${idx + 1}.${extension}`;
};

export const makeDownloadFileName = (filePrefix: string) => {
  const now = dayjs().format("DDMMYYYY_HHmmss");

  return `${filePrefix}_${now}`;
};

export const makeOrderFileName = (fileName: string, idx: number) => {
  const extension = getFileExtension(fileName);
  const fileNamePrefix = makeFileNamePrefix(
    extension as "jpg" | "jpeg" | "png" | "pdf",
  );

  return `${fileNamePrefix}${idx + 1}.${extension}`;
};

export const makeUploadedFileName = (fileName: string) => {
  const extension = getFileExtension(fileName);

  return `File.${extension}`;
};
