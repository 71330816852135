import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { button } from "style";

const mixinContents = css`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

interface ButtonProps {
  variant: keyof typeof button;
  isLoading: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, variant, isLoading }) => css`
    ${variant === "primarySmall" && theme.button.primarySmall};
    ${variant === "primaryMedium" && theme.button.primaryMedium};
    ${variant === "primaryLarge" && theme.button.primaryLarge};
    ${variant === "outlineXSmall" && theme.button.outlineXSmall};
    ${variant === "outlineMedium" && theme.button.outlineMedium};
    ${variant === "greySmall" && theme.button.greySmall};
    ${variant === "errorMedium" && theme.button.errorMedium};
    ${variant === "ghostPrimary" && theme.button.ghostPrimary};
    ${variant === "ghostGray" && theme.button.ghostGray};
    ${variant === "ghostRed" && theme.button.ghostRed};
    ${variant === "ghostNoUnderlineGray" && theme.button.ghostNoUnderlineGray};
    ${variant === "ghostNoUnderlinePrimary" &&
    theme.button.ghostNoUnderlinePrimary};
    ${variant === "icon" && theme.button.icon};
    pointer-events: ${isLoading && "none"};
  `}
`;

export const LoadingContents = styled.div`
  ${mixinContents};
  visibility: hidden;
`;

export const spinner = css`
  position: absolute;
`;
