import React from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Button, InternalLink } from "components";
import { PATH } from "constants/index";
import * as S from "./MemberFormBtns.styled";

interface MemberFormBtnsProps {
  isLoading: boolean;
}

const MemberFormBtns = ({ isLoading }: MemberFormBtnsProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const {
    formState: { errors },
  } = useFormContext();

  const memberType = searchParams.get("memberType");

  return (
    <S.BtnWrapper>
      <InternalLink
        variant="outlineMedium"
        to={
          pathname.split("/").at(-1) === "create"
            ? `${PATH.MEMBER}?tab=${memberType}`
            : `${PATH.MEMBER}/${memberId}?memberType=${memberType}`
        }
      >
        {t("Cancel")}
      </InternalLink>
      <Button
        type="submit"
        variant="primaryMedium"
        label={pathname.includes("create") ? "Add" : "Update"}
        isDisabled={isLoading || Object.keys(errors).length > 0}
      />
    </S.BtnWrapper>
  );
};

export default MemberFormBtns;
