import type { AxiosResponse } from "axios";

import type { S3PresignedServerModel } from "types";

export const getS3FileFormData = (
  s3Info: S3PresignedServerModel,
  file: File,
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(s3Info.data.fields)) {
    formData.append(key, value);
  }
  formData.append("Content-type", file.type);
  formData.append("file", file);

  return formData;
};

export const downloadFileEditor = (url: string, name: string) => {
  const a = document.createElement("a");

  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};

export const downloadExcelFile = (data: AxiosResponse<any, any>) => {
  const url = window.URL.createObjectURL(new Blob([data?.data]));
  const filename = data.headers["content-disposition"].split("filename=")[1];

  downloadFileEditor(url, filename);
};

export const findUrls = (text: string, reg: RegExp) => {
  const srcMatches = [];
  let match;

  while ((match = reg.exec(text)) !== null) {
    srcMatches.push(match[1]);
  }

  return srcMatches;
};

export const findUrl = (html: string) => {
  const srcRegex = /<img[^>]*\bsrc=["']([^"']+)["']/gi;
  const srcMatches = [];
  let match;
  while ((match = srcRegex.exec(html)) !== null) {
    srcMatches.push(match[1]);
  }

  const temp = srcMatches
    .filter((src) => src.includes(process.env.REACT_APP_AWS_URL as string))
    .map((src) => src.split("/").at(-1));

  return temp;
};

export const findNonCommonStrings = (arr1: string[], arr2: string[]) => {
  // 문자열 배열을 Set으로 변환하여 중복을 제거
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  // 두 집합을 합칩니다.
  const combinedSet = new Set([...set1, ...set2]);

  // 중복되지 않는 요소를 찾아 배열로 변환
  const nonCommonArray = [...combinedSet].filter((item) => {
    return !(set1.has(item) && set2.has(item));
  });

  return nonCommonArray;
};
