import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const multiLineContent = css`
  & > div {
    display: flex;
    flex-flow: column;
    row-gap: 12px;
    width: 500px;
  }
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin: 40px 0;
    border: 0;
    border-top: 1px solid ${theme.color.gray_20};
  `}
`;

export const radio = css`
  column-gap: 40px;
`;

export const formInput = css`
  width: 500px;
`;
