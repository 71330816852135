import React from "react";

import * as S from "./PublicLayout.styled";

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => {
  return (
    <S.PublicLayout>
      <S.Wrapper>{children}</S.Wrapper>
    </S.PublicLayout>
  );
};

export default PublicLayout;
