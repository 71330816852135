import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const LinkWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin-left: auto;
`;

export const link = css`
  width: fit-content;
`;
