import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  FormRadioBtn,
  LabelContentTable,
  InternalLink,
  DownloadBtn,
  StatusLabel,
  Button,
} from "components";
import { useCurrentQueryParams, useModal } from "hooks";
import { useDownloadFile, useGetMemberDetail } from "services";
import { addrFormatter, findLookupTableLabel } from "utils";
import {
  COMPANY_LOOKUP_TABLE,
  MEMBER_REGISTERED_COMPANY_RADIOS,
  MEMBER_COMPANY_TYPE_RADIOS,
  MEMBER_MEMBERSHIP_PLANS_RADIOS,
} from "assets";
import { PATH } from "constants/index";
import { ChangeStatusModal } from "./container";
import * as S from "./BusinessInfo.styled";

const BusinessInfo = () => {
  const { t } = useTranslation();
  const { memberId } = useParams();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const { mutate: downloadFileMutate } = useDownloadFile();
  const { data } = useGetMemberDetail({
    companyId: memberId!,
    query: {
      companyType:
        COMPANY_LOOKUP_TABLE[memberType! as keyof typeof COMPANY_LOOKUP_TABLE],
    },
  });

  const { handleModalOpen, modalRef } = useModal();

  if (!data) return null;

  const companyTypeLabel = findLookupTableLabel(
    MEMBER_COMPANY_TYPE_RADIOS,
    data.type,
  );

  return (
    <LabelContentTable
      css={S.labelContentTable}
      variant="bg"
      subject="Business information"
    >
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Company name">
          {data.name || "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Status">
          <S.StatusWrapper>
            <StatusLabel status={data.status} />
            <Button
              variant="ghostPrimary"
              label="Change status"
              isDisabled={data.status !== "PENDING" || data.registered === "NO"}
              handleClickBtn={handleModalOpen(
                <ChangeStatusModal
                  ref={modalRef}
                  imgs={data.newLicenseUrls.filter((item) => item !== "")}
                />,
              )}
            />
          </S.StatusWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Company type">
          {companyTypeLabel ? t(companyTypeLabel) : "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Registered company">
          <FormRadioBtn
            css={S.radioBtn}
            isDisabled
            radioList={MEMBER_REGISTERED_COMPANY_RADIOS}
            currentValue={data.registered}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Membership plans">
          {data.type !== "SHIPPER" && data.registered === "YES" ? (
            <S.MembershipPlans>
              <FormRadioBtn
                css={S.radioPlanBtn}
                isDisabled
                radioList={MEMBER_MEMBERSHIP_PLANS_RADIOS}
                currentValue={data.plan}
              />
              <InternalLink
                variant="ghostPrimary"
                to={`${PATH.MEMBER}/${memberId}/membershipPlan?memberType=${memberType}`}
              >
                {t("Details")}
              </InternalLink>
            </S.MembershipPlans>
          ) : (
            "-"
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address">
          {addrFormatter(data.addr, data.addrDetail)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Business email">
          {data.email || "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Business tel.">
          {data.phone || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="ERC">
          <S.DownloadBtnWrapper>
            {!data.license1 && !data.license2 && "-"}
            {data.license1 && (
              <DownloadBtn
                downloadFileNamePrefix="Front of ERC"
                fileName="Front of ERC"
                fileKey={data.license1}
                downloadFileMutate={downloadFileMutate}
              />
            )}
            {data.license2 && (
              <DownloadBtn
                downloadFileNamePrefix="Back of ERC"
                fileName="Back of ERC"
                fileKey={data.license2}
                downloadFileMutate={downloadFileMutate}
              />
            )}
          </S.DownloadBtnWrapper>
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Tax code">
          {data.taxcode || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      {(data.type === "CARRIER" || data.type === "FORWARDERCARRIER") && (
        <LabelContentTable.Row partition={1}>
          <LabelContentTable.Content label="Business license">
            <S.DownloadBtnWrapper>
              {!data.transportLicense1 && !data.transportLicense2 && "-"}
              {data.transportLicense1 && (
                <DownloadBtn
                  downloadFileNamePrefix="Front of transportation business license"
                  fileName="Front of transportation business license"
                  fileKey={data.transportLicense1}
                  downloadFileMutate={downloadFileMutate}
                />
              )}
              {data.transportLicense2 && (
                <DownloadBtn
                  downloadFileNamePrefix="Back of transportation business license"
                  fileName="Back of transportation business license"
                  fileKey={data.transportLicense2}
                  downloadFileMutate={downloadFileMutate}
                />
              )}
            </S.DownloadBtnWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      )}
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Representative">
          {data.representative || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default BusinessInfo;
