import React from "react";
import { useTranslation } from "react-i18next";

import {
  InternalLink,
  RegisteredMemberFilter,
  RegisteredMemberPagination,
  RegisteredMemberTable,
  SettingLayout,
} from "components";
import { useFilterParams } from "hooks";
import { useGetRegisteredMembers } from "services";
import { PlusCircleIcon, SETTING_MENU } from "assets";
import type { GetRegisteredMemberQueryModel } from "types";
import * as S from "./RegisteredMember.styled";

const RegisteredMember = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<GetRegisteredMemberQueryModel["query"]>([
    "name",
    "page",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetRegisteredMembers({
    query: filters,
  });

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <S.Wrapper>
        <S.TitleWrapper>
          <S.Title>{t("Registered member")}</S.Title>
          <InternalLink variant="ghostNoUnderlinePrimary" to="create">
            <PlusCircleIcon css={S.icon} />
            {t("Add member")}
          </InternalLink>
        </S.TitleWrapper>
        <RegisteredMemberFilter />
        <RegisteredMemberTable
          admins={data}
          dataUpdatedAt={dataUpdatedAt}
          refetch={refetch}
        />
        <RegisteredMemberPagination pageInfo={data?.pageInfo} />
      </S.Wrapper>
    </SettingLayout>
  );
};

export default RegisteredMember;
