import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Button = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    border-radius: 2px;
    padding: 14px 0;
    color: ${theme.color.white};
    background-color: ${theme.color.primary_10};

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  `}
`;
