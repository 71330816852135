import React from "react";

import { ConfirmModal } from "components";

interface DeleteNoticeModalProps {
  activeFn: () => void;
}

const DeleteNoticeModal = React.forwardRef(
  (
    { activeFn }: DeleteNoticeModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="Delete notice"
        desc={`Are you sure you want to delete?`}
        activeBtnName="Delete"
        btnType="alert"
        activeFn={activeFn}
      />
    );
  },
);

DeleteNoticeModal.displayName = "DeleteNoticeModal";

export default DeleteNoticeModal;
