import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { CheckboxBtn } from "components";
import type * as type from "types";
import * as S from "./CheckTable.styled";

interface CheckTableSkeletonProps {
  className?: string;
  columnTable: type.ColumnTable;
  gridTemplateColumns: string;
  rowCount?: number;
}

const CheckTableSkeleton = ({
  className,
  columnTable,
  gridTemplateColumns,
  rowCount = 10,
}: CheckTableSkeletonProps) => {
  return (
    <S.CheckTableSkeleton
      className={className}
      gridTemplateColumns={gridTemplateColumns}
    >
      <thead>
        <S.HeadRow>
          <S.CheckTh>
            <CheckboxBtn shape="rectangle" />
          </S.CheckTh>
          {Object.values(columnTable).map((value, i) => (
            <S.Th key={i} title={value}>
              {value}
            </S.Th>
          ))}
        </S.HeadRow>
      </thead>
      <tbody>
        {[...Array(rowCount)].map((_, i) => (
          <S.Row key={i}>
            <S.Td>
              <Skeleton />
            </S.Td>
            {Object.keys(columnTable).map((item) => (
              <S.Td key={item}>
                <Skeleton />
              </S.Td>
            ))}
          </S.Row>
        ))}
      </tbody>
    </S.CheckTableSkeleton>
  );
};

export default CheckTableSkeleton;
