import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useToast } from "hooks";
import { SuccessIcon, WarningIcon } from "assets";
import type { Toast } from "types";
import * as S from "./ToastItem.styled";

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

const ToastItem = ({ type, id, content, prefix }: Toast) => {
  const { t } = useTranslation();

  const [isClosing, setIsClosing] = useState(false);

  const { removeToast } = useToast();

  useEffect(() => {
    const existTimeout = setTimeout(() => {
      setIsClosing(true);
    }, TOAST_DURATION);

    const expireToastTimeout = setTimeout(() => {
      removeToast(id);
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(existTimeout);
      clearTimeout(expireToastTimeout);
    };
  }, []);

  const message = prefix ? `${prefix} ${t(content)}` : t(content);

  return (
    <S.ToastItem isClosing={isClosing}>
      <S.Item role="status">
        {type === "success" ? (
          <SuccessIcon css={S.successIcon} />
        ) : (
          <WarningIcon css={S.warningIcon} />
        )}
        <pre>{message}</pre>
      </S.Item>
    </S.ToastItem>
  );
};

export default ToastItem;
