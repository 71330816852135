import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Filter, CompanySearchModal } from "components";
import { useModal } from "hooks";
import { useGetCommonPlayer } from "services";
import { selectCompanyInfo, getAllQuery } from "utils";
import { COMPANY_COLUMNS, ORDER_MANAGEMENT_FILTER_STATUS_RADIOS } from "assets";
import type { CompanyInfo, Languages } from "types";

const OrderManagementFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo[]>([]);

  const { data: companies } = useGetCommonPlayer();

  const { modalRef } = useModal();

  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: ORDER_MANAGEMENT_FILTER_STATUS_RADIOS,
    },
    { queryKey: "orderNumber", label: "Order number" },
    { queryKey: "date", label: "Date" },
    {
      queryKey: "companyId",
      label: "Company name",
      options: companies?.map((company) => ({
        key: company.id,
        label: company.name as Languages, //NOTE: 회사 고유 이름으로 Languages 단언
      })),
    },
  ] as const;

  const handleCompanySelect = (companyInfo: CompanyInfo[]): void => {
    setSearchParams({
      ...getAllQuery(searchParams),
      ...(companyInfo[0] && { companyId: companyInfo[0].id }),
      page: "1",
    });
  };

  useEffect(() => {
    if (!companies) return;

    setSelectedCompany(
      selectCompanyInfo(companies, searchParams.get("companyId") ?? ""),
    );
  }, [companies, searchParams.get("companyId")]);

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status" isRequired>
          <Filter.Radio
            queryKey="status"
            radios={ORDER_MANAGEMENT_FILTER_STATUS_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Order number">
          <Filter.Input
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Date" isRequired>
          <Filter.Calendar queryKey="date" />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Company name">
          <Filter.Modal
            selectModalData={selectedCompany[0]?.name}
            placeholder="Search by company name"
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedCompany}
                tableDatas={companies ?? []}
                handleCompanySelect={handleCompanySelect}
              />
            }
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default OrderManagementFilter;
