import React from "react";
import { FormProvider } from "react-hook-form";

import {
  SettingMemberInfoButtons,
  MemberInfoForm,
  PageLayout,
} from "components";
import { SETTING_REGISTERED_MEMBER_EDIT_LAYOUT } from "assets";
import { useEditRegisteredMember } from "./hooks";
import * as S from "./RegisteredMemberEdit.styled";

const RegisteredMemberEdit = () => {
  const { formMethod, handleSubmit } = useEditRegisteredMember();

  return (
    <PageLayout
      css={S.layout}
      layoutStaticData={SETTING_REGISTERED_MEMBER_EDIT_LAYOUT}
    >
      <FormProvider {...formMethod}>
        <form onSubmit={handleSubmit}>
          <MemberInfoForm type="edit" />
          <SettingMemberInfoButtons isLoading={false} />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default RegisteredMemberEdit;
