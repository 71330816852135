import axios from "axios";

import { ERROR_CODE } from "assets";
import type { RenewAccessTokenAPI } from "types";
import { Auth } from "./auth";

interface Fn {
  (accessToken: string): void;
}

export class TokenService {
  private static instance: TokenService | null = null;

  static getInstance(): TokenService {
    const auth = Auth.getInstance();

    if (!TokenService.instance) {
      TokenService.instance = new TokenService(auth);
    }
    return TokenService.instance;
  }

  private isAlreadyFetchingAccessToken = false;
  private subscribers: Fn[] = [];

  private constructor(private auth: Auth) {}

  async resetTokenAndReattemptRequest(
    error: any,
    renewAccessTokenAPI: RenewAccessTokenAPI, // NOTE: 매개변수로 안 하면 import 에러 발생
  ) {
    try {
      const { response: errorResponse } = error;

      const refreshToken = this.auth.refreshToken;
      if (!refreshToken) {
        this.expireSession();
        return Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        try {
          this.isAlreadyFetchingAccessToken = true;
          const { accessToken: newAccessToken } = await renewAccessTokenAPI(
            refreshToken,
          );
          if (!newAccessToken) {
            return Promise.reject(error);
          }

          this.auth.changeAccessToken(newAccessToken);

          this.onAccessTokenFetched(newAccessToken);
        } catch (err: any) {
          const { response: errorResponse } = err;

          const authErrorCode = [
            ERROR_CODE.DUPLICATE_SIGNIN_DETECTED,
            ERROR_CODE.REFRESHTOKEN_EXPIRED,
          ];

          if (authErrorCode.includes(errorResponse?.data.response)) {
            this.expireSession();
            return Promise.reject(err);
          }
        } finally {
          this.isAlreadyFetchingAccessToken = false;
        }
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getAccessToken() {
    return this.auth.accessToken;
  }

  onAccessTokenFetched(accessToken: string) {
    this.subscribers.forEach((callback) => callback(accessToken));
    this.subscribers = [];
  }

  addSubscriber(callback: Fn) {
    this.subscribers.push(callback);
  }

  expireSession() {
    this.auth.refreshToken && alert("Your session has expired.");
    this.auth.clear();
    window.location.reload();
  }
}
