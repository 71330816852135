import { useState, useEffect, useRef } from "react";

import { DEFAULT_MAP_COORD } from "constants/index";

const useAddrSearchGoogleMap = (
  lng: number,
  lat: number,
  handleMapClick: (e: google.maps.MapMouseEvent) => void,
) => {
  const ref = useRef(null);
  const defaultMapRef = useRef<google.maps.Map>();
  const [googleMap, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    defaultMapRef.current = new google.maps.Map(ref.current!, {
      zoom: 13,
      streetViewControl: false,
      center: {
        lng: lng || DEFAULT_MAP_COORD.lng,
        lat: lat || DEFAULT_MAP_COORD.lat,
      },
      fullscreenControl: false,
    });
    setMap(defaultMapRef.current);
    defaultMapRef.current.addListener("click", handleMapClick);
  }, []);

  return { ref, googleMap };
};

export default useAddrSearchGoogleMap;
