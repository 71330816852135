import React from "react";
import { useTranslation } from "react-i18next";

import { CargoIcon, PriceIcon } from "assets";
import type { Languages } from "types";
import * as S from "./SummaryStatistics.styled";

interface SummaryStatisticsProps {
  className?: string;
  totalOrderCount: number | null;
  totalPrice: string | null;
}

const SummaryStatistics = ({
  className,
  totalOrderCount,
  totalPrice,
}: SummaryStatisticsProps) => {
  const { t } = useTranslation();

  return (
    <S.SummaryStatistics className={className}>
      <S.InfoWrapper>
        <S.IconWrapper>
          <CargoIcon css={S.icon} />
        </S.IconWrapper>
        <S.ContentWrapper>
          <S.Label>{t("Number of order")}</S.Label>
          <S.Content>{totalOrderCount ?? "-"}</S.Content>
        </S.ContentWrapper>
      </S.InfoWrapper>
      <S.InfoWrapper>
        <S.IconWrapper>
          <PriceIcon css={S.icon} />
        </S.IconWrapper>
        <S.ContentWrapper>
          <S.Label>{t("Total")}</S.Label>
          <S.Content>{totalPrice ?? "-"}</S.Content>
        </S.ContentWrapper>
      </S.InfoWrapper>
    </S.SummaryStatistics>
  );
};

export default SummaryStatistics;
