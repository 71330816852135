import { css, Theme } from "@emotion/react";

export const modal = (theme: Theme) => css`
  gap: 18px;

  & > div:last-of-type {
    column-gap: 12px;

    & > button {
      border: 1px solid ${theme.color.gray_20};
      border-radius: 2px;
      padding: 10px 20px;
      color: ${theme.color.gray_70};
      background-color: ${theme.color.gray_10};

      &:last-of-type {
        border: 0;
        color: ${theme.color.white};
        background-color: ${theme.color.red_20};
      }
    }
  }
`;
