import React from "react";

import { DataSection, NoResult, Profile, StatusLabel } from "components";
import { addrFormatter, formatICTDateTime, formatOrderNum } from "utils";
import type {
  ColumnTable,
  GetDriverAllocationServerModel,
  PageInfo,
} from "types";
import * as S from "./AllocationSection.styled";

interface AllocationListSectionProps {
  data?: GetDriverAllocationServerModel["order"];
  columns: ColumnTable;
  dataUpdatedAt: number;
  pageInfo?: PageInfo;
  refetch: () => void;
}

const AllocationListSection = ({
  data,
  columns,
  dataUpdatedAt,
  pageInfo,
  refetch,
}: AllocationListSectionProps) => {
  const formatTableData = <T extends Record<string, any>>( //TODO: 제네릭 -> 유니온 타입으로 변경 필요
    data: T,
    key: keyof T,
  ) => {
    switch (key) {
      case "status": {
        if (data[key] === "CANCELLED") return <StatusLabel status="CANCELED" />;
        //TODO: 서버 DB에서 CANCELLED 데이터 삭제 후 코드 삭제 예정

        return <StatusLabel status={data[key]} />;
      }

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddress":
        return addrFormatter(data.puAddress, data.puAddressDetail);
      case "doAddress":
        return addrFormatter(data.doAddress, data.doAddressDetail);

      case "orderNumber":
        return <span>{formatOrderNum(data[key])}</span>;

      case "driverName":
        return (
          <S.Driver>
            {data[key] ? (
              <>
                <Profile src={data.driverProfileUrl ?? ""} />
                <span>{data[key]}</span>
              </>
            ) : (
              <span>-</span>
            )}
          </S.Driver>
        );

      default:
        return <span>{data[key] ?? "-"}</span>;
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={pageInfo?.totalData ?? 0}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="driver list"
        columnTable={columns}
      >
        {data?.length ? (
          data.map((data, i) => (
            <DataSection.Table.Row key={i}>
              {Object.keys(columns).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(data, key as keyof typeof data)}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.Row>
          ))
        ) : (
          <NoResult />
        )}
      </DataSection.Table>
    </DataSection>
  );
};

export default AllocationListSection;
