import React from "react";

import { Filter } from "components";

const RegisteredMemberFilter = () => {
  const searchLabels = [
    {
      queryKey: "name",
      label: "Member name",
    },
  ] as const;

  const excludeResetQueryKeys = ["memberType"];

  return (
    <Filter
      searchLabels={searchLabels}
      excludeResetQueryKeys={excludeResetQueryKeys}
    >
      <Filter.Row>
        <Filter.Condtion conditionName="Member name">
          <Filter.Input queryKey="name" placeholder="Search by member name" />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default RegisteredMemberFilter;
