import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg path {
      fill: ${theme.color.black};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.black};
  `}
`;

export const card = css`
  width: 564px;
  margin-bottom: 12px;
`;

export const cardContent = css`
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const labelContent = css`
  margin-bottom: 28px;
`;

export const UnitSuffixWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 4px;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;

export const yearDropdown = css`
  width: 250px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin-left: auto;
  width: fit-content;
`;
