import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import type { RadioType } from "types";
import * as S from "./FormRadioBtn.styled";

interface CommonRadioBtn {
  className?: string;
  currentValue: string;
  radioList: Readonly<RadioType[]>;
  register?: UseFormRegisterReturn<string>;
}

interface isDiabledRadioBtn extends CommonRadioBtn {
  isDisabled?: boolean;
  handleDisabled?: never;
}

interface handleDisabledRadioBtn extends CommonRadioBtn {
  isDisabled?: never;
  handleDisabled?: (key: string) => boolean;
}

type RadioBtnProps = isDiabledRadioBtn | handleDisabledRadioBtn;

const FormRadioBtn = ({
  className,
  currentValue,
  radioList,
  register,
  isDisabled,
  handleDisabled,
}: RadioBtnProps) => {
  const uuid = useId();
  const { t } = useTranslation();

  return (
    <S.RadioWrapper className={className}>
      {radioList.map((item) => (
        <S.Label
          key={item.key}
          disabled={handleDisabled ? handleDisabled(item.key) : isDisabled}
        >
          {register ? (
            <input
              type="radio"
              id={item.key + uuid}
              disabled={handleDisabled ? handleDisabled(item.key) : isDisabled}
              value={item.key}
              {...register}
            />
          ) : (
            <input
              type="radio"
              id={item.key + uuid}
              disabled={handleDisabled ? handleDisabled(item.key) : isDisabled}
              value={item.key}
              name={uuid}
              checked={currentValue === item.key}
            />
          )}
          <S.RadioBtn htmlFor={item.key + uuid} />
          <span>{t(item.label)}</span>
        </S.Label>
      ))}
    </S.RadioWrapper>
  );
};

export default FormRadioBtn;
