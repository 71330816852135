import { theme } from "style";
import type { LatLng } from "types";
import { convertToNumberTypeLatLng } from "../formatter";

export const displayPolylines = (
  type: "outline" | "inline",
  coords: LatLng[],
  googleMap: google.maps.Map | null,
): google.maps.Polyline => {
  const convertedLatLngs = coords.map(convertToNumberTypeLatLng);

  const polyline = new google.maps.Polyline({
    geodesic: true,
    strokeColor:
      type === "outline" ? theme.color.white : theme.color.primary_40,
    strokeWeight: type === "outline" ? 8 : 4,
    path: convertedLatLngs,
    zIndex: theme.zIndex.POLYLINE,
  });

  polyline.setMap(googleMap);

  return polyline;
};
