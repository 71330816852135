import React from "react";

import { LabelContentTable } from "components";
import { formatICTDateTime, formatPhoneNum } from "utils";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationPickUpInfo.styled";

interface QuotationPickUpInfoProps {
  pickupInfo?: GetQuotationDetailServerModel["pickUpInfo"];
}

const QuotationPickUpInfo = ({ pickupInfo }: QuotationPickUpInfoProps) => {
  if (!pickupInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address" labelWidth={200}>
          <S.AddrWrapper>
            <span>{pickupInfo.puAddr}</span>
            {pickupInfo.puAddrDetail && (
              <S.AddrDetail>{pickupInfo.puAddrDetail}</S.AddrDetail>
            )}
          </S.AddrWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge" labelWidth={200}>
          {pickupInfo.puPic} {`(${formatPhoneNum(pickupInfo.puPhone)})`}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA" labelWidth={200}>
          {formatICTDateTime(pickupInfo.puEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD" labelWidth={200}>
          {pickupInfo.puEtd ? formatICTDateTime(pickupInfo.puEtd) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default QuotationPickUpInfo;
