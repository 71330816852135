import React from "react";
import { useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, InternalLink } from "components";
import { PATH } from "constants/index";
import type { RegisteredMemberForm } from "types";
import * as S from "./SettingMemberInfoButtons.styled";

interface SettingMemberInfoButtonsProps {
  isLoading: boolean;
}

const SettingMemberInfoButtons = ({
  isLoading,
}: SettingMemberInfoButtonsProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const {
    formState: { errors },
  } = useFormContext<RegisteredMemberForm>();

  return (
    <S.BtnWrapper>
      <InternalLink variant="outlineMedium" to={PATH.SETTING_REGISTERED_MEMBER}>
        {t("Cancel")}
      </InternalLink>
      <Button
        type="submit"
        variant="primaryMedium"
        label={pathname.includes("create") ? "Add" : "Update"}
        isDisabled={isLoading || Object.keys(errors).length > 0}
      />
    </S.BtnWrapper>
  );
};

export default SettingMemberInfoButtons;
