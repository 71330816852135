import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useTimer } from "hooks";
import { onlyNumber } from "utils";
import { EMAIL_REG, HOOKFORM_ERR } from "assets";
import type { VerificationNumType } from "types";

const schema = yup.object({
  authCode: yup
    .string()
    .matches(/^\d{6}$/, HOOKFORM_ERR.VALID_AUTHCODE)
    .defined()
    .nullable(),
  email: yup
    .string()
    .required(HOOKFORM_ERR.EMAIL)
    .matches(EMAIL_REG, HOOKFORM_ERR.VALID_EMAIL), //TODO: 임의로 설정한 값이므로 api 및 디자인 확인 후 수정 필요
  isEmailVerifiDone: yup.boolean().defined(),
});

const useAccountId = () => {
  const [isEmailVerifiDone, setIsEmailVerifiDone] = useState(false);

  const formMethod = useForm<VerificationNumType>({
    mode: "onTouched",
    defaultValues: { authCode: null, email: "", isEmailVerifiDone: false },
    resolver: yupResolver(schema),
  });

  const {
    resetTimer,
    startTimer,
    isTimeReset,
    min,
    sec,
    limitTime,
    isTimeout,
  } = useTimer();

  const handleChangeVerifi = (e: React.ChangeEvent<HTMLInputElement>) =>
    formMethod.setValue("authCode", onlyNumber(e.target.value));

  const handleSendVerifiEmail = () => {
    setIsEmailVerifiDone(true);

    resetTimer();
    startTimer();
  };

  useEffect(() => {
    formMethod.setValue("authCode", "");
    formMethod.setFocus("authCode");
  }, [isTimeReset, formMethod.setValue, formMethod.setFocus]);

  return {
    isEmailVerifiDone,
    min,
    sec,
    limitTime,
    isTimeout,
    formMethod,
    handleSendVerifiEmail,
    handleChangeVerifi,
  };
};

export default useAccountId;
