import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PrivateMain = styled.main`
  ${({ theme }) => css`
    min-width: max-content;
    width: 100%;
    min-height: 100vh;
    padding: 52px 0 0 64px;
    background-color: ${theme.color.gray_00};
  `}
`;
