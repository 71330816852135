import { useState } from "react";

const useImgBtn = () => {
  const [imgIdx, setImgIdx] = useState(0);

  const handlePrevImg = () => {
    setImgIdx(imgIdx - 1);
  };

  const handleNextImg = () => {
    setImgIdx(imgIdx + 1);
  };

  return {
    imgIdx,
    handlePrevImg,
    handleNextImg,
  };
};

export default useImgBtn;
