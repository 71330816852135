import React from "react";

import { LabelContentTable } from "components";
import { addrFormatter, formatICTDateTime, formatPhoneNum } from "utils";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementDropoffInfo.styled";

interface OrderManagementDropoffInfoProps {
  dropOffInfo?: GetOrderManagementDetailServerModel["dropOffInfo"];
}

const OrderManagementDropoffInfo = ({
  dropOffInfo,
}: OrderManagementDropoffInfoProps) => {
  if (!dropOffInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address" labelWidth={200}>
          <S.AddressWrapper>
            <div>{dropOffInfo.doName}</div>
            <S.AddressContent>
              {addrFormatter(dropOffInfo.doAddr, dropOffInfo.doAddrDetail)}
            </S.AddressContent>
          </S.AddressWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge" labelWidth={200}>
          {dropOffInfo.doPic} ({formatPhoneNum(dropOffInfo.doPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA" labelWidth={200}>
          {formatICTDateTime(dropOffInfo.doEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD" labelWidth={200}>
          {dropOffInfo.doEtd ? formatICTDateTime(dropOffInfo.doEtd) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementDropoffInfo;
