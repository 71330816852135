import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components";
import { comma, formatICTDateTime } from "utils";
import { ResetIcon, DownloadIcon } from "assets";
import type { Languages } from "types";
import Table from "./table/Table";
import CheckTable from "./checkTable/CheckTable";
import * as S from "./DataSection.styled";

interface DataSectionProps {
  className?: string;
  children: React.ReactNode;
  totalData: number | null;
  activeBtns?: React.ReactNode;
  dataUpdatedAt?: number | null;
  refetch: () => void;
  title?: Languages;
}

interface ActiveWrapperProps {
  className?: string;
  activeBtn: React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  handleConfirm?: (e: React.MouseEvent) => void;
  handleUnActiveCheckTable?: () => void;
}

interface ExcelDownloadBtnProps {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  handleDownload: () => void;
}

export interface ActiveBtnProps {
  className?: string;
  label: Languages;
  variant?: "primaryMedium" | "outlineMedium";
  isDisabled?: boolean;
  handleClickActiveBtn: () => void;
}

const DataSection = ({
  children,
  className,
  totalData,
  activeBtns,
  dataUpdatedAt,
  refetch,
  title = "List",
}: DataSectionProps) => {
  const { t } = useTranslation();

  const handleRefetch = useCallback(() => {
    refetch && refetch();
  }, [refetch]);

  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>
            {t(title)}&#32;({totalData ? comma(totalData) : "-"})
          </h2>
          <S.Refetch>
            <span>{t("Latest updated")}:</span>
            <time>
              {dataUpdatedAt && dataUpdatedAt !== 0
                ? formatICTDateTime(dataUpdatedAt, "DD/MM/YYYY, HH:mm")
                : "-"}
            </time>
            {dataUpdatedAt && (
              <S.RefetchButton type="button" onClick={handleRefetch}>
                <ResetIcon css={S.icon} />
              </S.RefetchButton>
            )}
          </S.Refetch>
        </S.LeftContent>
        {activeBtns}
      </S.Header>
      {children}
    </section>
  );
};

DataSection.ActiveWrapper = function ActiveWrapper({
  className,
  activeBtn,
  isActive,
  isDisabled,
  handleConfirm,
  handleUnActiveCheckTable,
}: ActiveWrapperProps) {
  return (
    <S.ActiveWrapper className={className}>
      {isActive ? (
        <>
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleUnActiveCheckTable}
          />
          <Button
            isDisabled={!isDisabled}
            variant="primaryMedium"
            label="Confirm"
            handleClickBtn={handleConfirm}
          />
        </>
      ) : (
        <>{activeBtn}</>
      )}
    </S.ActiveWrapper>
  );
};

DataSection.ExcelDownloadBtn = function ExcelDownloadBtn({
  handleDownload,
  isLoading,
  isDisabled,
}: ExcelDownloadBtnProps) {
  return (
    <Button
      icon={<DownloadIcon />}
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="outlineMedium"
      label="Excel download"
      handleClickBtn={handleDownload}
    />
  );
};

DataSection.ActiveBtn = function CheckTableActiveBtn({
  className,
  label,
  variant = "outlineMedium",
  isDisabled = false,
  handleClickActiveBtn,
}: ActiveBtnProps) {
  return (
    <Button
      className={className}
      variant={variant}
      label={label}
      isDisabled={isDisabled}
      handleClickBtn={handleClickActiveBtn}
    />
  );
};

DataSection.Table = Table;

DataSection.CheckTable = CheckTable;

export default DataSection;
