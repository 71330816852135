import * as yup from "yup";

import { checkEmailValidation, checkPasswordValidation } from "utils";
import { GRADE_TYPE_RADIOS, HOOKFORM_ERR } from "assets";
import type { AutoCompleteDropdown, PropertyStatus } from "types";

export const SCHEMA = {
  ADDRESS: yup.object().shape({
    address: yup.string().required(HOOKFORM_ERR.NOT_EMPTY),
    addressDetail: yup.string().defined().optional(),
    buildingName: yup.string().defined().optional(),
    city: yup.string().defined().optional(),
    coord: yup.object().shape({
      lng: yup.number().required(HOOKFORM_ERR.NOT_EMPTY),
      lat: yup.number().required(HOOKFORM_ERR.NOT_EMPTY),
    }),
  }),
  CHECK_BOOLEAN: yup.boolean().defined(),
  CHECK_REQURIED_BOOLEAN: yup
    .boolean()
    .required(HOOKFORM_ERR.REQUIRE)
    .test((value) => value),
  CONFIRM_PASSWORD: yup
    .string()
    .required(HOOKFORM_ERR.NOT_EMPTY)
    .min(8, HOOKFORM_ERR.PASSWORD_STEP_ONE)
    .max(16, HOOKFORM_ERR.PASSWORD_STEP_ONE)
    .test(
      "confirmPasswordValidation",
      HOOKFORM_ERR.PASSWORD_STEP_TWO,
      (value) => checkPasswordValidation(value),
    )
    .test("confirmPassword", HOOKFORM_ERR.PASSWORD_CONFIRM, function (value) {
      return value === this.resolve(yup.ref("password")); // NOTE: this를 사용하려면 화살표 함수를 사용할 수 없음
    }),
  DROPDOWN_GRADE: yup
    .mixed<(typeof GRADE_TYPE_RADIOS)[number]>()
    .required(HOOKFORM_ERR.NOT_EMPTY),
  EMAIL: yup
    .string()
    .required(HOOKFORM_ERR.NOT_EMPTY)
    .test("emailValidation", HOOKFORM_ERR.VALID_EMAIL, (email) =>
      checkEmailValidation(email),
    ),
  FILELIST: yup.mixed<FileList | string>().defined().nullable(),
  CREATE_FILELIST: yup.mixed<FileList>().defined().nullable(),
  OPTIONAL_STRING: yup.string().defined(), //TODO: 옵셔널한 값이 아니기때문에 수정 필요 -> optional()로 작성 시 에러 발생하여 일단 현상 유지함
  PASSWORD: yup
    .string()
    .required(HOOKFORM_ERR.NOT_EMPTY)
    .min(8, HOOKFORM_ERR.PASSWORD_STEP_ONE)
    .max(16, HOOKFORM_ERR.PASSWORD_STEP_ONE)
    .test("passwordValidation", HOOKFORM_ERR.PASSWORD_STEP_TWO, (value) =>
      checkPasswordValidation(value),
    ),
  REQUIRED_STRING: yup
    .string()
    .required(HOOKFORM_ERR.NOT_EMPTY)
    .test("trimValidation", HOOKFORM_ERR.NOT_EMPTY, (value) => !!value.trim()),
  STATUS_AVAILABLE: yup
    .mixed<PropertyStatus>()
    .defined()
    .required(HOOKFORM_ERR.NOT_EMPTY),
  SINGLE_AUTOCOMPLETE: yup.mixed<AutoCompleteDropdown>().defined(),
};
