import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useLogin } from "services";
import { checkEmailValidation, makeCryptoFunction } from "utils";
import { HOOKFORM_ERR } from "assets";
import { PATH } from "constants/index";

interface LoginForm {
  id: string;
  password: string;
}
const schema = yup.object({
  id: yup
    .string()
    .required(HOOKFORM_ERR.EMAIL)
    .test("id", HOOKFORM_ERR.VALID_EMAIL, (value) =>
      checkEmailValidation(value),
    ),
  password: yup.string().required(HOOKFORM_ERR.PASSWORD),
});

const useLoginForm = () => {
  const navigate = useNavigate();

  const { mutate: loginMutate } = useLogin();

  const formMethod = useForm<LoginForm>({
    mode: "onTouched",
    defaultValues: { id: "", password: "" },
    resolver: yupResolver(schema),
  });

  const [loginError, setLoginError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const handleClearLoginError = () => setLoginError(null);

  const handleLoginSuccess = (data: LoginForm) => {
    loginMutate(
      {
        body: {
          id: data.id,
          password: makeCryptoFunction(data.password),
          agentType: "web",
        },
      },
      {
        onSuccess: () => navigate(PATH.MEMBER),
        onError: (err) => {
          if (!err.response) return;

          setLoginError({
            type: "loginError",
            message: HOOKFORM_ERR.INCORRECT_LOGIN,
          });
        },
      },
    );
  };

  return {
    loginError,
    formMethod,
    handleSubmit: formMethod.handleSubmit(handleLoginSuccess),
    handleClearLoginError,
  };
};

export default useLoginForm;
