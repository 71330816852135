import { v4 as uuidv4 } from "uuid";

/* eslint-disable no-useless-escape */
const imgSrcReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]/g;
const fileNameReg = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}.\w*/g;

export const changeImgSrc = (
  data: string,
  bucket: string,
  imgTitles: string[],
) => {
  let changeData = data;

  data.match(imgSrcReg)?.forEach((item, i) => {
    changeData = changeData.replace(
      item,
      `<img src="${process.env.REACT_APP_EDITOR_BUCKET_ADDRESS}/${bucket}/${imgTitles[i]}" `,
    );
  });

  return changeData;
};

export const changeImgSrcOnlyNewImg = (
  data: string,
  bucket: string,
  imgTitles: string[],
) => {
  let changeData = data;

  data
    .match(imgSrcReg)
    ?.filter(
      (src) => !src.includes(process.env.REACT_APP_EDITOR_BUCKET_ADDRESS!),
    )
    .forEach((item, i) => {
      changeData = changeData.replace(
        item,
        `<img src="${process.env.REACT_APP_EDITOR_BUCKET_ADDRESS}/${bucket}/${imgTitles[i]}" `,
      );
    });

  return changeData;
};

export const findUploadedImg = (data: string) => data.match(fileNameReg) ?? [];

export const makeBase64ImageToFile = (base64Image: string) => {
  const mimeType = base64Image.split(";")[0].split(":")[1];
  const extension = mimeType.split("/")[1];
  const byteString = atob(base64Image.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: mimeType });
  const fileName = `${uuidv4()}.${extension}`;
  const file = new File([blob], fileName, { type: mimeType });

  return {
    file,
    fileName,
  };
};
