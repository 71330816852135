import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

const mixinButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:disabled {
    opacity: 0.2;
  }

  & > svg {
    margin-right: 8px;
  }
`;

const buttonSize = {
  xSmall: css`
    ${font.semibold_12};
    height: 20px;
    padding: 2px 4px;
  `,
  small: css`
    ${font.semibold_12};
    height: 24px;
    padding: 4px 12px;
  `,
  medium: css`
    ${font.semibold_14};
    height: 36px;
    padding: 8px 16px;
  `,
  large: css`
    ${font.medium_15};
    height: 48px;
    padding: 13px 20px;
  `,
};

const buttonStyle = {
  primary: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.primary_40};

    &:not(:disabled):hover {
      background-color: ${color.primary_50};
    }

    &:disabled {
      background-color: ${color.primary_40};
    }

    & svg {
      fill: ${color.white};
    }
  `,
  red: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.red_20};

    &:not(:disabled):hover {
      background-color: ${color.red_30};
    }

    & svg {
      fill: ${color.white};
    }
  `,
  outlineXS: css`
    /* TODO: 수정 확인 필요 */
    ${mixinButton};
    border: 1px solid ${color.gray_35};
    color: ${color.gray_60};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }
  `,
  grey: css`
    ${mixinButton};
    border: 0;
    color: ${color.black};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_35};
    }
  `,
  outline: css`
    ${mixinButton};
    border: 1px solid ${color.gray_35};
    color: ${color.gray_80};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }

    & svg {
      fill: ${color.black};
    }
  `,
  error: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.red_20};

    &:not(:disabled):hover {
      background-color: ${color.gray_30};
    }
  `,
  ghostPrimary: css`
    ${mixinButton};
    ${font.regular_14_underline};
    color: ${color.primary_40};

    & svg path {
      fill: ${color.primary_40};
    }

    &:not(:disabled):hover {
      color: ${color.primary_50};

      & svg path {
        fill: ${color.primary_50};
      }
    }
  `,
  ghostGray: css`
    ${mixinButton};
    color: ${color.gray_70};
    ${font.regular_14_underline};

    &:not(:disabled):hover {
      color: ${color.gray_80};
    }

    & svg {
      fill: ${color.gray_40};
      /* NOTE: 사용시 확인 필요 */
    }
  `,
  ghostRed: css`
    ${mixinButton};
    color: ${color.red_20};
    ${font.regular_14_underline};

    &:not(:disabled):hover {
      color: ${color.red_30};
    }
  `,

  // TODO: v4에서 NoUnderline 버튼 사용 여부 확인 후 삭제 필요
  ghostNoUnderlineGray: css`
    ${mixinButton};
    ${font.regular_14};
    color: ${color.gray_60};

    &:not(:disabled):hover {
      color: ${color.gray_80};
    }

    & svg {
      fill: ${color.gray_40};
    }
  `,

  ghostNoUnderlinePrimary: css`
    ${mixinButton};
    ${font.regular_14};
    color: ${color.primary_40};

    & svg path {
      fill: ${color.primary_40};
    }

    &:not(:disabled):hover {
      color: ${color.primary_50};

      & svg path {
        fill: ${color.primary_50};
      }
    }
  `,

  // TODO: 스크린 v4로 변경 후 디자인 시스템 재확인 필요
  icon: css`
    ${mixinButton};

    & svg path {
      fill: ${color.gray_50};
    }

    &:not(:disabled):hover {
      & svg path {
        fill: ${color.gray_80};
      }
    }
  `,

  // TODO: 스크린 v4로 변경 후 디자인 시스템 재확인 필요
  text: css`
    ${font.medium_15};
    position: relative;
    padding: 0;
    color: ${color.gray_50};

    &:not(:disabled):hover {
      svg > path {
        fill: ${color.gray_70};
      }
      color: ${color.gray_70};

      &::before {
        background-color: ${color.gray_70};
      }
    }

    &:disabled {
      opacity: 0.6;
    }
  `,
};

export const button = {
  primarySmall: css`
    ${buttonStyle.primary};
    ${buttonSize.small};
  `,
  primaryMedium: css`
    ${buttonStyle.primary};
    ${buttonSize.medium};
  `,
  primaryLarge: css`
    ${buttonStyle.primary};
    ${buttonSize.large};
  `,
  outlineXSmall: css`
    ${buttonStyle.outlineXS};
    ${buttonSize.xSmall};
  `,
  greySmall: css`
    ${buttonStyle.grey};
    ${buttonSize.small};
  `,
  outlineMedium: css`
    ${buttonStyle.outline};
    ${buttonSize.medium};
  `,
  errorMedium: css`
    ${buttonSize.medium};
    ${buttonStyle.red};
  `,
  ghostPrimary: css`
    ${buttonStyle.ghostPrimary};
  `,
  ghostGray: css`
    ${buttonStyle.ghostGray};
  `,
  ghostRed: css`
    ${buttonStyle.ghostRed};
  `,
  ghostNoUnderlineGray: css`
    ${buttonStyle.ghostNoUnderlineGray};
  `,
  ghostNoUnderlinePrimary: css`
    ${buttonStyle.ghostNoUnderlinePrimary};
  `,
  icon: css`
    ${buttonStyle.icon};
  `,
  text: css`
    ${buttonStyle.text};
  `,
} as const;

export type BtnTheme = typeof button;
