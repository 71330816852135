import axios from "axios";

import { ax } from "api/axios";
import type { LoginQueryModel, RenewAccessTokenServerModel } from "types";

export const loginAPI = async (req: LoginQueryModel) => {
  const { data } = await ax.post("/auth/login?clientType=admin", req.body);

  return data;
};

export const logoutAPI = (refreshToken: string) =>
  ax.patch("/auth/logout", { refreshToken });

export const renewAccessTokenAPI = async (refreshToken: string) => {
  const { data } = await axios.get<RenewAccessTokenServerModel>(
    `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
    { headers: { Authorization: `Bearer ${refreshToken}` } },
  );
  return data;
};
