import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const input = css`
  width: 500px;
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin: 40px 0;
    border: 0;
    border-top: 1px solid ${theme.color.gray_20};
  `}
`;

export const dropdown = css`
  width: 500px;
`;
