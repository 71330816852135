import React from "react";

import { ForwarderPagination, ForwarderTable } from "./containers";
import { MemberFilter } from "../container";

const Forwarder = () => {
  return (
    <>
      <MemberFilter />
      <ForwarderTable />
      <ForwarderPagination />
    </>
  );
};

export default Forwarder;
