import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 528px;
    padding-top: 100px;
  `}
`;

export const Form = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 528px;
    border: 1px solid ${theme.color.gray_30};
    padding: 64px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_24};
    margin-bottom: 12px;
  `}
`;

export const P = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 32px;
    color: ${theme.color.gray_60};
  `}
`;

export const PasswordTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_24};
    margin-bottom: 32px;
  `}
`;

export const InputWrapper = styled.div<{ isError: boolean }>`
  ${({ isError }) => `
  position: relative;
  margin-bottom: ${isError ? "40px" : "16px"};
  `}
`;

export const submitBtn = css`
  height: 52px;
  width: 400px;
  padding: 15px 0;
`;
