import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { MEMBER_DETAIL_REGISTERED_GARAGE } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${MEMBER_DETAIL_REGISTERED_GARAGE};

    & > :last-of-type {
      padding: 0;
    }
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  border-radius: 2px;
  padding: 10px 20px;
  color: ${theme.color.white};
  text-decoration: none;
  background-color: ${theme.color.primary_10};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const deleteButton = css`
  width: 32px;
  height: 30px;

  svg {
    margin: 0;
  }
`;

export const editLink = css`
  width: 32px;
  height: 30px;

  svg {
    margin: 0;
  }
`;

export const icon = css`
  width: 12px;
  height: 12px;
`;

export const alertModal = css`
  width: 392px;
`;
