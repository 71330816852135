import React, { useState, useEffect, useId } from "react";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";

import { CheckboxBtn, Portal } from "components";
import { useDomReady } from "hooks";
import { NoResultIcon } from "assets";
import type { ColumnTable, Languages } from "types";
import useTableScrollTop from "../hooks/useTableScrollTop";
import * as S from "./Table.styled";

interface TableProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  columnTable: ColumnTable;
  hasCheckbox?: boolean;
  isAllChecked?: boolean;
  handleAllCheck?: () => void;
}

interface RowProps {
  className?: string;
  children: React.ReactNode;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface SelectRowMovePageProps extends RowProps {
  id: string;
  isSelected?: boolean;
  path: string;
  isOpenNewTab?: boolean;
}

interface TdProps {
  className?: string;
  children: React.ReactNode;
}

const Table = ({
  className,
  children,
  title = "",
  columnTable,
  hasCheckbox,
  isAllChecked,
  handleAllCheck,
}: TableProps) => {
  const { t } = useTranslation();
  const { tableRef } = useTableScrollTop();

  return (
    <S.Table className={className} ref={tableRef}>
      <caption className="a11y">{title}</caption>
      <thead>
        <S.HeadRow>
          {Object.values(columnTable).map((value, i) => (
            <th key={i} title={value}>
              {t(value as Languages)}
            </th>
          ))}
        </S.HeadRow>
      </thead>
      <S.Tbody>{isEmpty(children) ? <Table.NoData /> : children}</S.Tbody>
    </S.Table>
  );
};

Table.Row = function Row({
  className,
  children,
  handleMouseOver,
  handleMouseLeave,
}: RowProps) {
  return (
    <S.Row
      className={className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </S.Row>
  );
};

interface SelectRowProps extends RowProps {
  isSelected?: boolean;
  handleRowClick: () => void;
}

Table.SelectRow = function SelectRow({
  className,
  children,
  isSelected = false,
  handleRowClick,
}: SelectRowProps) {
  const domReady = useDomReady();

  const rowId = useId().replace(/[^a-zA-Z0-9-_]/g, "");

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <S.RowBtn type="button" onClick={handleRowClick}>
            <span className="a11y">select row</span>
          </S.RowBtn>
        </td>
      </Portal>
    </>
  );
};
Table.SelectRowMovePage = function SelectRowMovePage({
  className,
  children,
  id,
  isSelected = false,
  path,
  isOpenNewTab,
}: SelectRowMovePageProps) {
  const [domReady, setDomReady] = useState(false);

  const rowId = `table-row-${id}`;

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId} > td`} mounted={domReady}>
        <S.RowLink to={path} target={isOpenNewTab ? "_blank" : "_self"}>
          <span className="a11y">select row</span>
        </S.RowLink>
      </Portal>
    </>
  );
};

Table.Td = function Td({ className, children }: TdProps) {
  return <S.Td className={className}>{children}</S.Td>;
};

Table.NoData = function NoData() {
  const { t } = useTranslation();
  return (
    <S.NoData>
      <td>
        <NoResultIcon />
        {t("No results found")}
      </td>
    </S.NoData>
  );
};

export default Table;
