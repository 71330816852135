import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ChangePasswordModal, LabelContentTable } from "components";
import { useModal, useToast } from "hooks";
import { useGetMyAccount, usePutChangePassword } from "services";
import { makeCryptoFunction } from "utils";
import { TOAST_MSG } from "constants/index";
import type { ChangePasswordForm } from "types";
import * as S from "./MyAccountLoginID.styled";

const MyAccountLoginID = () => {
  const { t } = useTranslation();

  const { data: myAccount } = useGetMyAccount();
  const { mutate: changePasswordMutate, isLoading: isLoadingChangePassword } =
    usePutChangePassword();

  const { handleModalOpen, modalRef, handleModalClose } = useModal();
  const { addToast } = useToast();

  const changePassword =
    (data: ChangePasswordForm, onError: () => void) => (): void => {
      changePasswordMutate(
        {
          body: {
            newPassword: makeCryptoFunction(data.password),
            currentPassword: makeCryptoFunction(data.currentPassword),
          },
        },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.PASSWORD_CHANGE_SUCCESS);
            handleModalClose();
          },
          onError: (err) => {
            if (err.response?.data.response === "WRONG_PASSWORD") {
              onError();
            }
          },
        },
      );
    };

  return (
    <div>
      <S.Title>{t("Login ID / Password")}</S.Title>
      <LabelContentTable variant="bg">
        {/* TODO: whiteBg로 변경 예정 */}
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Login ID">
            {myAccount?.email}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Password" css={S.content}>
            ••••••••••••
            <Button
              variant="outlineXSmall"
              label="Change"
              type="button"
              handleClickBtn={handleModalOpen(
                <ChangePasswordModal
                  ref={modalRef}
                  isLoading={isLoadingChangePassword}
                  changePassword={changePassword}
                />,
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </div>
  );
};

export default MyAccountLoginID;
