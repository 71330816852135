const PickUpDropOffInfoWindow = (
  infoWindowContent: {
    label: string;
    icon: string;
  },
  index?: number,
) => {
  return `<div class="location-info-window">
      <img src=${infoWindowContent.icon} alt="location-icon"/>
      <span>${infoWindowContent.label}</span>
      ${index ? `<span>#${index}</span>` : ``}
      </div>`;
};

export default PickUpDropOffInfoWindow;
