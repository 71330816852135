import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable, ModalPagination } from "components";
import { findLookupTableLabel, formatQuantity, formatWeight } from "utils";
import { CARGO_TYPE, PACKAGING_TYPE } from "assets";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementCargoDetail.styled";

interface OrderManagementCargoDetailProps {
  cargoes?: GetOrderManagementDetailServerModel["cargoes"];
}

const OrderManagementCargoDetail = ({
  cargoes,
}: OrderManagementCargoDetailProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  if (!cargoes) return null;

  const {
    cargoType,
    packagingType,
    width,
    height,
    length,
    weight,
    cargoCnt,
    hazardGoods,
    doubleLoading,
  } = cargoes[currentPage - 1];

  const cargoTypeLabel = findLookupTableLabel(CARGO_TYPE, cargoType);
  const packagingTypeLabel = findLookupTableLabel(
    PACKAGING_TYPE,
    packagingType,
  );
  const hazardGoodsLabel = hazardGoods ? t("Yes") : t("No");
  const doubleLoadingLabel = doubleLoading ? t("Yes") : t("No");

  const handleChangePage = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Type">
            {cargoTypeLabel ? t(cargoTypeLabel) : cargoType}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Packaging type">
            {packagingTypeLabel ? t(packagingTypeLabel) : packagingType}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Cargo">{`${width}m x ${height}m x ${length}m / ${formatWeight(
            weight,
          )} / ${formatQuantity(cargoCnt)}`}</LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Hazard goods">
            {hazardGoodsLabel}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Stackable">
            {doubleLoadingLabel}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <ModalPagination
        css={S.pagination}
        currentPage={currentPage}
        pageInfo={{
          currentPage,
          dataPerPage: 10,
          startRow: 1,
          totalData: cargoes.length,
          totalPages: cargoes.length,
        }}
        movePage={handleChangePage}
      />
    </>
  );
};

export default OrderManagementCargoDetail;
