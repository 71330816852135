import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 72px;
    border: 1px solid ${theme.color.gray_20};
    background-color: ${theme.color.white};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
`;

export const ArrowButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 2px;

    svg > path {
      fill: ${theme.color.gray_50};
    }

    :hover:not([disabled]) {
      background-color: ${theme.color.gray_30};

      svg > path {
        fill: ${theme.color.gray_60};
      }
    }

    &[disabled] {
      display: none;
    }
  `}
`;

export const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :last-child {
    border-right: 0;
  }
`;

interface NumberButtonProps {
  isCurrentPage: boolean;
}

export const NumberButton = styled.button<NumberButtonProps>`
  ${({ theme, isCurrentPage }) => css`
    ${theme.font.regular_14};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    color: ${isCurrentPage ? theme.color.primary_40 : theme.color.gray_80};

    :disabled {
      :hover {
        background-color: ${theme.color.gray_10};
      }
    }

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const icon = css`
  width: 20px;
  height: 20px;
`;
