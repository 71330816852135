import React from "react";
import { pick } from "lodash-es";

import {
  ListPageLayout,
  SettlementFilter,
  SettlementPagination,
  SettlementTable,
  SummaryStatistics,
} from "components";
import { useFilterParams } from "hooks";
import { useGetSettlements } from "services";
import { calculateDropDecimal, comma } from "utils";
import type { GetSettlementQueryModel } from "types";
import * as S from "./Settlement.styled";

interface Filter
  extends Omit<GetSettlementQueryModel["query"], "startDate" | "endDate"> {
  date?: string;
}

const Settlement = () => {
  const requiredFilterKeys = ["startDate", "endDate"];

  const { filters } = useFilterParams<Filter>([
    "page",
    "status",
    "orderNumber",
    "date",
    "sender",
    "recipient",
  ]);

  const hasRequiredFilter =
    Object.keys(pick(filters, requiredFilterKeys)).length ===
    requiredFilterKeys.length;

  const { data, refetch, dataUpdatedAt } = useGetSettlements(
    { query: { ...filters, status: filters.status ?? "ALL" } },
    { enabled: hasRequiredFilter },
  );

  return (
    <ListPageLayout css={S.layout} heading="Settlement">
      <SettlementFilter />
      <SummaryStatistics
        css={S.simpleStatistics}
        totalOrderCount={hasRequiredFilter ? data?.pageInfo.totalData! : null}
        totalPrice={
          hasRequiredFilter
            ? `${comma(data ? calculateDropDecimal(data.totalPrice) : 0)}  ₫`
            : null
        }
      />
      <SettlementTable
        hasRequiredFilter={hasRequiredFilter}
        offers={data}
        refetch={refetch}
        dataUpdatedAt={dataUpdatedAt}
      />
      <SettlementPagination pageInfo={data?.pageInfo} />
    </ListPageLayout>
  );
};

export default Settlement;
