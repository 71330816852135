import React from "react";
import { useParams } from "react-router-dom";

import { LabelContentTable } from "components";
import { useCurrentQueryParams } from "hooks";
import { useGetMemberDetail } from "services";
import { COMPANY_LOOKUP_TABLE } from "assets";
import * as S from "./GpsApi.styled";

const GpsApi = () => {
  const { memberId } = useParams();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const { data } = useGetMemberDetail({
    companyId: memberId!,
    query: {
      companyType:
        COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE],
    },
  });

  if (!data) return null;

  return (
    <>
      <LabelContentTable variant="bg" subject="GPS API">
        <LabelContentTable.Row partition={2}>
          <LabelContentTable.Content label="Company name">
            {data.gpsType || "-"}
          </LabelContentTable.Content>
          <LabelContentTable.Content label="GPS API ID">
            {data.gpsCustomerCode || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="GPS API KEY">
            {data.gpsApiKey || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.Division />
    </>
  );
};

export default GpsApi;
