import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
`;

export const content = css`
  & > div:first-of-type {
    display: flex;
    align-items: center;

    & > span {
      width: fit-content;
      padding: 0;
    }
  }
`;

export const icon = css`
  width: 16px;
  height: 16px;
`;
