import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { ax } from "api/axios";
import type { S3FileUploadType, S3PresignedServerModel } from "types";

export const postS3PresignedUrlAPI = async (
  key: string,
  file: File,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/${key}`);

  const formData = makeS3FileFormData(res, file);

  return await axios.post(res.data.url, formData);
};

const makeNewFileName = (file: File) => {
  const uuid = uuidv4();
  const extension = file.name.split(".").at(-1);

  return `${uuid}.${extension}`;
};

const makeS3Promise = async (
  file: S3FileUploadType,
  postS3PresignedUrlAPI: (key: string, file: File) => Promise<any>,
) => {
  const S3Promises: Promise<any>[] = [];

  const fileUuids = Object.entries(file).reduce(
    (acc, [fileKey, { file, path }]) => {
      const fileUid = makeNewFileName(file!);

      S3Promises.push(postS3PresignedUrlAPI(`${path}/${fileUid}`, file!));

      return { ...acc, [fileKey]: fileUid };
    },
    {} as { [fileKey: string]: string },
  );

  return { fileUuids, S3Promises };
};

export const makeS3PresignedUrlFileUuids = async (file: S3FileUploadType) => {
  const { fileUuids, S3Promises } = await makeS3Promise(
    file,
    postS3PresignedUrlAPI,
  );
  const s3Responses = await Promise.all(S3Promises);

  if (s3Responses.every((response) => response.status === 204)) {
    return fileUuids;
  }

  return {};
};

export const getS3PresignedUrlAPI = async (
  s3FileKey: string,
): Promise<string> => {
  if (!s3FileKey) return "";

  const { data } = await ax.get(`/presigned/${s3FileKey}`);

  return data;
};

const makeS3FileFormData = (s3Info: S3PresignedServerModel, file: File) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(s3Info.data.fields)) {
    formData.append(key, value);
  }
  formData.append("Content-type", file.type);
  formData.append("file", file);

  return formData;
};
