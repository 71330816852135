import React from "react";

import { Filter } from "components";

const RegisteredDriverFilter = () => {
  const searchLabels = [
    { queryKey: "name", label: "Driver name" },
    {
      queryKey: "status",
      label: "Status",
      options: [
        { key: "ALL", label: "All" },
        { key: "AVAILABLE", label: "Available" },
        { key: "UNAVAILABLE", label: "Unavailable" },
      ],
    },
    { queryKey: "linked", label: "Linking" },
  ] as const;

  const excludeResetQueryKeys = ["memberType"];

  return (
    <Filter
      searchLabels={searchLabels}
      excludeResetQueryKeys={excludeResetQueryKeys}
    >
      <Filter.Row>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio
            queryKey="status"
            radios={[
              { key: "ALL", label: "All" },
              { key: "AVAILABLE", label: "Available" },
              { key: "UNAVAILABLE", label: "Unavailable" },
            ]}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Driver name">
          <Filter.Input queryKey="name" placeholder="Search by driver name" />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input
            queryKey="linked"
            placeholder="Search by plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default RegisteredDriverFilter;
