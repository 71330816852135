import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getMyAccountAPI,
  getRegisteredMemberDetailAPI,
  getRegisteredMembersAPI,
  putChangePasswordAPI,
  putMyAccountAPI,
  postRegisteredMemberAPI,
  putRegisteredMemberAPI,
} from "api";
import type {
  GetRegisteredMemberQueryModel,
  PutAccountQueryModel,
  PutChangePasswordQueryModel,
  APIError,
  PostRegisteredMemberQueryModel,
  PutRegisteredMemberQueryModel,
} from "types";

const settingKeys = {
  all: ["setting"],
  myAccount: () => [...settingKeys.all, "myAccount"],
  lists: () => [...settingKeys.all, "list"],
  list: (req: GetRegisteredMemberQueryModel) => [...settingKeys.lists(), req],
  details: () => [...settingKeys.all, "detail"],
  detail: (adminId: string) => [...settingKeys.details(), { adminId }],
};

export const useGetMyAccount = () => {
  return useQuery({
    queryKey: settingKeys.myAccount(),
    queryFn: () => getMyAccountAPI(),
  });
};

export const usePutMyAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PutAccountQueryModel>({
    mutationFn: (req: PutAccountQueryModel) => putMyAccountAPI(req),
    onSuccess: () => queryClient.invalidateQueries(settingKeys.myAccount()),
  });
};

export const useGetRegisteredMembers = (req: GetRegisteredMemberQueryModel) => {
  return useQuery({
    queryKey: settingKeys.list(req),
    queryFn: () => getRegisteredMembersAPI(req),
  });
};

export const useGetRegisteredMemberDetail = (adminId: string) => {
  return useQuery({
    queryKey: settingKeys.detail(adminId),
    queryFn: () => getRegisteredMemberDetailAPI(adminId),
  });
};

export const usePutChangePassword = () => {
  return useMutation<unknown, APIError, PutChangePasswordQueryModel>({
    mutationFn: (req: PutChangePasswordQueryModel) => putChangePasswordAPI(req),
  });
};

export const usePostRegisteredMember = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostRegisteredMemberQueryModel>({
    mutationFn: (req) => postRegisteredMemberAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: settingKeys.lists(),
      });
    },
  });
};

export const usePutRegisteredMember = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PutRegisteredMemberQueryModel>({
    mutationFn: (req) => putRegisteredMemberAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: settingKeys.lists(),
      });
    },
  });
};
