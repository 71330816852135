import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import { useGetGarages } from "services";
import type {
  GetMemberDetailGaragesQueryModel,
  TruckingCompanyType,
} from "types";
import {
  RegisteredGarageFilter,
  RegisteredGaragePagination,
  RegisteredGarageTable,
} from "./container";

const MemberDetailRegisteredGarage = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<
    GetMemberDetailGaragesQueryModel["query"]
  >(["page", "name"]);

  const memberType = searchParams.get("memberType");
  const req = {
    query: {
      ...filters,
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data, dataUpdatedAt, refetch } = useGetGarages(req);

  return (
    <>
      <RegisteredGarageFilter />
      <RegisteredGarageTable
        garages={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <RegisteredGaragePagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default MemberDetailRegisteredGarage;
