import styled from "@emotion/styled";
export const PublicLayout = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
