import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const History = styled.ul`
  display: flex;
  column-gap: 16px;
  margin-bottom: 8px;
`;

export const HistoryItem = styled.li`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;

    :last-of-type > button {
      color: ${theme.color.gray_70};
      cursor: default;
    }

    :not(:last-of-type)::after {
      content: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.65 5.478L5.551 3.437L0.65 1.11V0.0959995L6.773 3.151V3.801L0.65 6.492V5.478Z' fill='%23A2ABB0'/%3E%3C/svg%3E%0A");
      position: absolute;
      right: -12px;
      width: 8px;
      height: 7px;
    }
  `}
`;

export const HistoryBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;
