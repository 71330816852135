import { ax } from "api/axios";
import { addPrefixToUrlIfNeeded } from "utils";
import type {
  DeleteOrderManagementQueryModel,
  GetOrderManagementDetailServerModel,
  GetOrderManagementMapServerModel,
  GetOrderManagementQueryModel,
  GetOrderManagementServerModel,
} from "types";
import { getS3PresignedUrlAPI } from "./presignedUrl";

export const getOrderManagementAPI = async (
  req: GetOrderManagementQueryModel,
) => {
  const { data } = await ax.get<GetOrderManagementServerModel>("/order", {
    params: req.query,
  });

  return data;
};

export const getOrderDetailAPI = async (orderId: string) => {
  const { data } = await ax.get<GetOrderManagementDetailServerModel>(
    `/order/detail/${orderId}`,
  );

  return data;
};

export const cancelOrderAPI = async (req: DeleteOrderManagementQueryModel) => {
  const { data } = await ax.delete(`/order/${req.orderId}`, { data: req.body });

  return data;
};

export const getOrderManagementHistoryTrackingAPI = async (orderId: string) => {
  const { data } = await ax.get<GetOrderManagementMapServerModel>(
    `/order/tracking/${orderId}`,
  );

  const profileUrls = await Promise.all(
    data.delivery.map(({ driverProfile }) => {
      if (driverProfile) {
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(driverProfile, "profile"),
        );
      } else {
        return "";
      }
    }),
  );

  data.delivery.forEach((delivery, i) => {
    delivery.driverProfile = profileUrls[i];
  });

  return data;
};
