export const VALID_MSG = {
  CARGO_SIZE: "Please enter a valid cargo size.",
  CONTAINER_SIZE: "Please enter a valid container size.",
  PHONE: "Please enter a vaild phone number.",
  NUMBER_OF_TRUCK: "Please write at least 1+",
  ADDRESS: "Please select an address in the list.",
  LINKEDTRUCK: "Please select a vaild truck.",
  DUPLICATE_EMAIL: "This ID is already taken.",
  PLATE_NUMBER_EXISTS: "Plate number already exists",
  VIN: "Please enter a valid VIN.",
  PHONE_EXISTS: "Phone number already exists.",
  ID_EXISTS: "This ID is already taken.",
  PLATE_NUMBER: "Please enter a valid plate number.",
  DRIVER_NAME: "Please enter a valid driver name",
  ORDER_NUMBER: "Please enter a valid order number",
  EXIST_ORDER_NUMBER: "Order number is not checked.",
  ACCOUNT_NUMBER: "Account number length is 6-15 characters.",
  INVALID_ORDERNUMBER: "Order number is not checked.",
  REQUIRED: "You can’t leave this empty.",
  EMAIL: "Please enter a valid e-mail address.",
  VERIFICATION_CODE_INCORRECT:
    "Verification code is incorrect. Please try again.",
  PASSWORD_CONFIRM: "Password does not match.",
  ID_STEP_ONE: "Login ID has to start with a letter.",
  ID_STEP_TWO: "You can only use letters, numbers in your login ID.",
  ID_STEP_THREE: "Login ID must be between 6-16 characters.",
  PASSWORD_STEP_ONE: "Password length is 8-16 characters.",
  PASSWORD_STEP_TWO: "At least 2 types (A-Z, a-z, number and symbol)",
  TIME: "That’s an invalid time.",
  MAX_VALUE_LIMIT: "Please enter a valid value", // TODO: 각각 문구 산정 시 수정 필요 (임시로 통합하여 사용)
  NOT_ZERO: "Please enter a value more than 0", // TODO: 각각 문구 산정 시 수정 필요 (임시로 통합하여 사용)
  MISMATCH_CURRENT_PASSWORD: "Wrong password.",
  INVALID_PRICE: "The amount exceeds the maximum allowed.",
  INVALID_MEMO_200: "Memo cannot be longer than 200 characters.",
  INVALID_MEMO_150: "Memo cannot be longer than 150 characters.", // TODO: 번역 추가 필요
  INVALID_DESCRIPTION_1000:
    "Description cannot be longer than 1,000 characters.",
  INVALID_SUBJECT_1000: "Subject cannot be longer than 50 characters.",
  TIME_VALID: "Please check the time format.",
  END_TIME_BEFORE_THAN_START_TIME: "Start date must be prior to the end date.",
} as const;
