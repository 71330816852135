import { ax } from "./axios";
import { addPrefixToUrlIfNeeded, filterValidCoords } from "utils";
import type {
  GetMonitoringCompanysQueryModel,
  GetMonitoringCompanysServerModel,
  GetMonitoringTrucksQueryModel,
  GetMonitoringTrucksServerModel,
  GetMonitoringOrdersQueryModel,
  GetMonitoringOrdersServerModel,
  GetShipperMonitoringOrderDetailServerModel,
  GetMonitoringOrderDetailQueryModel,
  GetMonitoringTruckDetailServerModel,
  GetMonitoringTruckDetailQueryModel,
} from "types";
import { getS3PresignedUrlAPI } from "./presignedUrl";

export const getMonitoringCompanysAPI = async (
  req: GetMonitoringCompanysQueryModel,
) => {
  const { data } = await ax.get<GetMonitoringCompanysServerModel>(
    "/monitoring/company",
    { params: req.query },
  );

  return data;
};

export const getMonitoringTrucksAPI = async (
  req: GetMonitoringTrucksQueryModel,
) => {
  const { data } = await ax.get<GetMonitoringTrucksServerModel>(
    "/monitoring/truck",
    { params: req.query },
  );

  const coords = filterValidCoords(data.trucks);

  return { ...data, coords };
};

export const getMonitoringOrdersAPI = async (
  req: GetMonitoringOrdersQueryModel,
) => {
  const { data } = await ax.get<GetMonitoringOrdersServerModel>(
    "/order/shipping",
    { params: req.query },
  );

  return data;
};

export const getMonitoringOrderDetailAPI = async (
  req: GetMonitoringOrderDetailQueryModel,
) => {
  const { data } = await ax.get<GetShipperMonitoringOrderDetailServerModel>(
    `/monitoring/order/${req.orderId}`,
    { params: req.query },
  );

  const profileUrls: (string | undefined)[] = await Promise.all(
    data.allocationList.map((allocation) => {
      allocation.profileS3Url = "";
      if (allocation.driverProfile)
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(allocation.driverProfile, "profile"),
        );
    }),
  );

  profileUrls.forEach((profileUrl, idx) => {
    if (profileUrl) {
      data.allocationList[idx].profileS3Url = profileUrl;
    }
  });

  return {
    ...data,
    allocationList: data.allocationList.map((allocation, idx) => ({
      ...allocation,
      profileUrl: profileUrls[idx] ?? null,
    })),
  };
};

export const getMonitoringTruckDetailAPI = async (
  req: GetMonitoringTruckDetailQueryModel,
) => {
  const { data } = await ax.get<GetMonitoringTruckDetailServerModel>(
    `/monitoring/truck/${req.truckId}`,
    { params: req.query },
  );

  return data;
};
