import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useModal, useToast } from "hooks";
import { useChangeDriverPassword, useGetDriverDetail } from "services";
import { makeCryptoFunction } from "utils";
import { TOAST_MSG } from "constants/index";
import type { ChangePasswordForm, TruckingCompanyType } from "types";

const useMemberDetailRegisteredDriverIDPassword = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();

  const memberType = searchParams.get("memberType");
  const { data } = useGetDriverDetail(
    {
      driverId: driverId!,
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
    },
    !!driverId,
  );
  const { mutate: changePasswordMutate, isLoading: isLoadingChangePassword } =
    useChangeDriverPassword();

  const { handleModalClose } = useModal();
  const { addToast } = useToast();

  const changePassword =
    (data: ChangePasswordForm, onError: () => void) => (): void => {
      const req = {
        driverId: driverId!,
        query: {
          companyId: memberId!,
          companyType: (memberType === "forwarder"
            ? "forwardercarrier"
            : memberType) as TruckingCompanyType,
        },
        body: {
          newPassword: makeCryptoFunction(data.password),
          currentPassword: makeCryptoFunction(data.currentPassword),
        },
      };

      changePasswordMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.PASSWORD_CHANGE_SUCCESS);
          handleModalClose();
        },
        onError: (err) => {
          if (err.response?.data.response === "WRONG_PASSWORD") {
            onError();
          }
        },
      });
    };

  return { data, isLoadingChangePassword, changePassword };
};

export default useMemberDetailRegisteredDriverIDPassword;
