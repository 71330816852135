import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface SettlementPaginationProps {
  pageInfo?: PageInfo;
}

const SettlementPagination = ({ pageInfo }: SettlementPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default SettlementPagination;
