import React from "react";

import { Button, ChangePasswordModal, LabelContentTable } from "components";
import { useModal } from "hooks";
import { useMemberDetailRegisteredDriverIDPassword } from "./hooks";
import * as S from "./MemberDetailRegisteredDriverIDPassword.styled";

const MemberDetailRegisteredDriverIDPassword = () => {
  const { data, isLoadingChangePassword, changePassword } =
    useMemberDetailRegisteredDriverIDPassword();
  const { handleModalOpen, modalRef } = useModal();

  if (!data) return null;

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Login ID">
            {data.id}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.labelContent} label="Password">
            **************
            <Button
              variant="outlineXSmall"
              label="Change"
              handleClickBtn={handleModalOpen(
                <ChangePasswordModal
                  isLoading={isLoadingChangePassword}
                  changePassword={changePassword}
                  ref={modalRef}
                />,
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </>
  );
};

export default MemberDetailRegisteredDriverIDPassword;
