import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArrowBackIcon } from "assets";
import type { Languages } from "types";
import * as S from "./PageBackHeader.styled";

interface PageBackHeaderProps {
  className?: string;
  children?: React.ReactNode;
  title: Languages;
  path: string;
}

const PageBackHeader = ({
  className,
  children,
  title,
  path,
}: PageBackHeaderProps) => {
  const { t } = useTranslation();

  return (
    <S.Root className={className}>
      <S.FrontWrapper>
        <Link css={S.customLink} to={path} replace>
          <ArrowBackIcon />
        </Link>
        <span>{t(title)}</span>
      </S.FrontWrapper>
      {children}
    </S.Root>
  );
};

export default PageBackHeader;
