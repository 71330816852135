import type { Languages } from "types";

export const getNumPeriods = (
  from: number,
  to: number,
): {
  key: string;
  label: Languages;
}[] => {
  return Array(to - from + 1)
    .fill(0)
    .map((_, i) => {
      return { key: `${to - i}`, label: `${to - i}` as Languages };
    });
};

export const getYears = getNumPeriods(1990, new Date().getFullYear());
