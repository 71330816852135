import { css, Theme } from "@emotion/react";

import { ORDER_MANAGEMENT_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS } from "assets";

export const table = (theme: Theme) => css`
  height: fit-content;

  & > thead {
    position: relative;
    top: 0;

    & > tr {
      & > th:not(:last-of-type) {
        border-right: 1px solid ${theme.color.gray_20};
      }
    }
  }

  tr {
    grid-template-columns: ${ORDER_MANAGEMENT_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS};
    padding: 0;

    & > td:not(:last-of-type) {
      border-right: 1px solid ${theme.color.gray_20};
    }
  }
`;
