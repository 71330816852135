import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = (theme: Theme) => css`
  margin-bottom: 32px;

  & > span {
    border-bottom: 1px solid ${theme.color.gray_60};
    padding-bottom: 12px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const input = css`
  width: 500px;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_50};
  `}
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin: 40px 0;
    border: 0;
    border-top: 1px solid ${theme.color.gray_20};
  `}
`;
