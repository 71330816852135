import React from "react";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "assets";
import usePagination from "../hooks/usePagination";
import * as S from "./Pagination.styled";

interface PaginationProps {
  className?: string;
  hasDoubleBtn?: boolean;
  currentPage: number;
  totalPages: number;
  maxPageCount: number;
  handlePrevPage: () => void;
  handleFirstPage: () => void;
  handleNextPage: () => void;
  handleLastPage: () => void;
  handleClickNum: (idx: number) => () => void;
}

const Pagination = ({
  className,
  hasDoubleBtn = true,
  currentPage,
  totalPages,
  maxPageCount,
  handlePrevPage,
  handleFirstPage,
  handleNextPage,
  handleLastPage,
  handleClickNum,
}: PaginationProps) => {
  const { isDisabledPrev, isDisabledNext, pageNumList } = usePagination(
    currentPage,
    maxPageCount,
    totalPages,
  );

  return (
    <S.Root className={className}>
      <S.Wrapper>
        {hasDoubleBtn && (
          <S.ArrowButton
            type="button"
            disabled={isDisabledPrev}
            onClick={handleFirstPage}
          >
            <ChevronDoubleLeftIcon css={S.icon} />
          </S.ArrowButton>
        )}

        <S.ArrowButton
          type="button"
          disabled={isDisabledPrev}
          onClick={handlePrevPage}
        >
          <ChevronLeftIcon css={S.icon} />
        </S.ArrowButton>
        <S.NumberWrapper>
          {pageNumList.length ? (
            pageNumList.map((num) => (
              <S.NumberButton
                key={num}
                type="button"
                isCurrentPage={currentPage === num}
                onClick={handleClickNum(num)}
              >
                {num}
              </S.NumberButton>
            ))
          ) : (
            <S.NumberButton type="button" isCurrentPage={true} disabled>
              1
            </S.NumberButton>
          )}
        </S.NumberWrapper>
        <S.ArrowButton
          type="button"
          disabled={isDisabledNext}
          onClick={handleNextPage}
        >
          <ChevronRightIcon css={S.icon} />
        </S.ArrowButton>
        {hasDoubleBtn && (
          <S.ArrowButton
            type="button"
            disabled={isDisabledNext}
            onClick={handleLastPage}
          >
            <ChevronDoubleRightIcon css={S.icon} />
          </S.ArrowButton>
        )}
      </S.Wrapper>
    </S.Root>
  );
};

export default Pagination;
