import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useToast } from "hooks";
import { useGetNoticeDetail, usePutNotice } from "services";
import { HOOKFORM_ERR } from "assets";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { CreateNoticeForm } from "types";

const useEditNotice = () => {
  const { supportId } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { mutate: editNoticeMutate } = usePutNotice();
  const { data } = useGetNoticeDetail(supportId!);

  const formMethod = useForm<CreateNoticeForm>({
    mode: "onTouched",
    defaultValues: {
      shipper: false,
      forwarder: false,
      carrier: false,
      driver: false,
      subject: "",
      description: "",
    },
  });

  const handleIsEmpty = (count: number, description?: string) => {
    if (count === 0 && description === "<p><br></p>") {
      formMethod.setError("description", {
        type: "required",
        message: HOOKFORM_ERR.REQUIRE,
      });
    }
  };

  const handleClearError = (data: string) => {
    const regex = /(<([^>]+)>)/gi;
    const newData = data;
    const result = newData.replace(regex, (match, tag) => {
      if (tag.startsWith("<img")) {
        return tag;
      }
      return "";
    });

    if (result.length !== 0) {
      formMethod.clearErrors("description");
    }
  };

  const handleEditNotice = () => {
    const editNotice = (formData: CreateNoticeForm) => {
      if (!data) return;

      if (
        !formData.carrier &&
        !formData.driver &&
        !formData.forwarder &&
        !formData.shipper
      )
        return;

      editNoticeMutate(
        {
          noticeId: supportId!,
          body: {
            shipper: !!formData.shipper ? 1 : 0,
            forwarder: !!formData.forwarder ? 1 : 0,
            carrier: !!formData.carrier ? 1 : 0,
            driver: !!formData.driver ? 1 : 0,
            subject: formData.subject,
            description: formData.description,
            originalDescription: data?.description,
          },
        },
        {
          onSuccess: () => {
            navigate(`${PATH.SUPPORT}/${supportId}?tab=${TAB.SUPPORT.NOTICE}`);
            addToast(TOAST_MSG.SUCCESS.NOTICE_ADD_SUCCESS);
          },
        },
      );
    };

    formMethod.handleSubmit(editNotice)();
  };

  useEffect(() => {
    if (!data) return;

    formMethod.reset({
      shipper: !!data.isShipper,
      forwarder: !!data.isForwarder,
      carrier: !!data.isCarrier,
      driver: !!data.isDriver,
      subject: data.subject,
      description: data.description,
    });
  }, [data]);

  return {
    formMethod,
    handleIsEmpty,
    handleClearError,
    handleEditNotice,
  };
};

export default useEditNotice;
