import React from "react";
import { useFormContext } from "react-hook-form";

import { ErrorMsg, LabelContentTable, Textarea } from "components";
import { formatICTDateTime } from "utils";
import { HOOKFORM_ERR } from "assets";
import type { GetInquiryDetailServerModel, InquiryAnswerForm } from "types";
import * as S from "./InquiryAnswerHistory.styled";

interface InquiryAnswerHistoryProps {
  data?: GetInquiryDetailServerModel;
  isCreateReply: boolean;
}

const InquiryAnswerHistory = ({
  data,
  isCreateReply,
}: InquiryAnswerHistoryProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<InquiryAnswerForm>();

  return (
    <>
      <LabelContentTable
        css={S.labelContentTable}
        variant="bg"
        subject="Answer history"
      >
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Answered">
            {data?.answered ? formatICTDateTime(data.answered) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row
          css={S.row(!errors.content?.message)}
          hasError={!!errors.content?.message}
        >
          <LabelContentTable.Content
            css={S.contentFormWrapper(isCreateReply)}
            label="Content"
            hasError={!!errors.content?.message}
          >
            {isCreateReply ? (
              <Textarea
                css={S.contentForm}
                id="inquiry textarea"
                placeholder="Reply to this inquiry"
                maxLength={400}
                value={watch("content")}
                register={register("content", {
                  required: HOOKFORM_ERR.REQUIRE,
                })}
              />
            ) : (
              data?.content ?? "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {errors.content?.message && (
          <ErrorMsg css={S.errorMsg} errorMsg={errors.content?.message} />
        )}
      </LabelContentTable>
    </>
  );
};

export default InquiryAnswerHistory;
