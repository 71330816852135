import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
  padding-top: 100px;
`;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.white};
  `}
`;

export const P = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 32px;
    color: ${theme.color.gray_60};
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

export const HintBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 24px;
`;

export const submitBtn = css`
  height: 52px;
  width: 400px;
  padding: 15px 0;
`;

export const accountInput = css`
  & > input {
    margin-bottom: 12px;
  }
`;
