import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useToast } from "hooks";
import { useGetInquiryDetail, usePostInquiryReply } from "services";
import { TOAST_MSG } from "constants/index";
import type { InquiryAnswerForm } from "types";

const useInquiryDetail = (handleIsCreateReply: () => void) => {
  const { supportId } = useParams();

  const formMethod = useForm<InquiryAnswerForm>({
    mode: "onTouched",
    defaultValues: { content: "" },
  });

  const { addToast } = useToast();

  const { mutate: postInquiryAnswerMutate } = usePostInquiryReply();
  const { data } = useGetInquiryDetail(supportId!);

  const handleCheckCreateReply = () => {
    handleIsCreateReply();
    formMethod.clearErrors("content");
    formMethod.reset();
  };

  const handleCreateReply = () => {
    const answerInquiry = (data: InquiryAnswerForm) => {
      postInquiryAnswerMutate(
        { inquiryId: supportId!, body: { content: data.content.trim() } },
        {
          onSuccess: () => {
            handleCheckCreateReply();
            addToast(TOAST_MSG.SUCCESS.INQUIRY_ANSWER_SUCCESS);
          },
          onError: () => addToast(TOAST_MSG.WARNING.INQUIRY_ANSWER_ALREADY), // TODO: 에러 코드 확인 후 해당 토스트 띄우도록 수정 필요
        },
      );
    };

    formMethod.handleSubmit(answerInquiry)();
  };

  return {
    data,
    formMethod,
    handleCheckCreateReply,
    handleCreateReply,
  };
};

export default useInquiryDetail;
