import React from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon } from "assets";
import type { Languages } from "types";
import * as S from "./PasswordHint.styled";

interface PasswordHintProps {
  passwordHint: Languages;
  isValidCheck: boolean;
}

const PasswordHint = ({ passwordHint, isValidCheck }: PasswordHintProps) => {
  const { t } = useTranslation();

  return (
    <S.PasswordHint isValidCheck={isValidCheck}>
      <CheckIcon />
      {t(passwordHint)}
    </S.PasswordHint>
  );
};

export default PasswordHint;
