import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import type { TruckStatus } from "types";

export const badge = css`
  margin-bottom: 4px;
`;

export const ButtonFoldTruck = styled.button<{
  isFold: boolean;
}>`
  ${({ theme, isFold }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 294px 1fr;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_20};
    padding: 12px 20px;
    background-color: ${isFold ? theme.color.white : theme.color.gray_00};

    &:hover {
      background-color: ${theme.color.gray_00};
    }
  `}
`;

export const TruckIconWrapper = styled.div<{ truckStatus: TruckStatus }>`
  ${({ theme, truckStatus }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    width: fit-content;
    margin-bottom: 4px;
    border-radius: 2px;
    padding: 2px 8px;
    background-color: ${truckStatus === "SHIPPING"
      ? theme.color.primary_20
      : theme.color.gray_20};

    & > svg {
      width: 12px;
      height: 12px;

      & > path {
        fill: ${truckStatus === "SHIPPING"
          ? theme.color.primary_40
          : theme.color.gray_60};
      }
    }

    & > span {
      ${theme.font.semibold_12};
      color: ${truckStatus === "SHIPPING"
        ? theme.color.primary_40
        : theme.color.gray_60};
    }
  `}
`;

export const ButtonFoldTruckContentWrapper = styled.div`
  ${({ theme }) => css`
    & > h3 {
      ${theme.font.semibold_16};
      width: fit-content;

      & > span {
        ${theme.font.regular_14};
        margin-left: 4px;
        color: ${theme.color.gray_70};
      }
    }
  `}
`;

export const foldBtn = (isFold: boolean) => (theme: Theme) =>
  css`
    justify-self: center;
    transform: rotate(${isFold ? 0 : 180}deg);

    & > path {
      fill: ${theme.color.black};
    }
  `;
