import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { TrashIcon, UploadIcon } from "assets";
import { makeUploadedFileName } from "utils";
import type { Languages } from "types";
import * as S from "./FormFileInput.styled";

interface FormFileInputProps {
  className?: string;
  placeholder: Languages;
  file: FileList | string | null;
  hasErr: boolean;
  register: UseFormRegisterReturn<string>;
  handleDeleteFile: () => void;
}

const FormFileInput = ({
  className,
  placeholder,
  file,
  hasErr,
  register,
  handleDeleteFile,
}: FormFileInputProps) => {
  const uuid = useId();
  const { t } = useTranslation();

  // NOTE: 1. 새 파일 업로드 (FileList) / 2. 기존 파일 (string) / 3. 빈 파일 (null)
  const fileName = file
    ? file instanceof FileList
      ? file[0].name
      : makeUploadedFileName(file)
    : file;

  return (
    <S.Wrapper className={className} hasFile={!!fileName} hasErr={hasErr}>
      {fileName || t(placeholder)}
      <S.FileBtnWrapper>
        {file !== null ? (
          <S.DeleteBtn type="button" onClick={handleDeleteFile}>
            <TrashIcon css={S.icon} />
          </S.DeleteBtn>
        ) : (
          <S.FileUpload htmlFor={uuid}>
            <UploadIcon css={S.icon} />
            <input
              id={uuid}
              type="file"
              accept="image/jpg, image/jpeg, image/png, .pdf"
              {...register}
            />
          </S.FileUpload>
        )}
      </S.FileBtnWrapper>
    </S.Wrapper>
  );
};

export default FormFileInput;
