import { useSearchParams } from "react-router-dom";

// TODO: 인수, 반환값 타입 좁히기에 대해서 생각해보기
const useCurrentQueryParams = (queryKey: string | string[]) => {
  const [searchParams] = useSearchParams();

  const queryParams = {} as { [key: string]: string[] };

  typeof queryKey === "string"
    ? (queryParams[queryKey] = searchParams.getAll(queryKey))
    : queryKey.forEach(
        (query) => (queryParams[query] = searchParams.getAll(query)),
      );

  Object.entries({ ...queryParams }).forEach(([query, value]) => {
    if (value.length === 0) delete queryParams[query];
  });

  return Object.keys(queryParams).length === 0 ? null : queryParams;
};

export default useCurrentQueryParams;
