import React from "react";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { DataSection, LinkCompanySearchModal } from "components";
import { useModal } from "hooks";
import { findLookupTableLabel } from "utils";
import {
  COMPANY_COLUMNS,
  LINKING_TABLE_HEADER,
  LINKING_FILTER_STATUS_RADIOS,
} from "assets";
import type { GetLinkingsServerModel } from "types";
import * as S from "./CarrierTable.styled";

interface CarrierTableProps {
  linkings?: GetLinkingsServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const CarrierTable = ({
  linkings,
  dataUpdatedAt,
  refetch,
}: CarrierTableProps) => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();

  const formatTableData = (
    data: GetLinkingsServerModel["list"][number],
    key: keyof GetLinkingsServerModel["list"][number],
  ) => {
    switch (key) {
      case "type":
        const typeLabel = findLookupTableLabel(
          LINKING_FILTER_STATUS_RADIOS,
          data[key],
        );

        return typeLabel ? t(typeLabel) : "-";

      case "linked":
        const linking = data[key] !== "0" ? `+${data[key]}` : "-";

        return <S.LinkBox>{linking}</S.LinkBox>;

      default:
        return data[key] ?? "-";
    }
  };

  if (!linkings) return null;

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={linkings.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Linking"
        columnTable={LINKING_TABLE_HEADER}
      >
        {linkings.list.length &&
          linkings.list.map((link) => (
            <DataSection.Table.SelectRow
              css={S.row}
              key={link.companyId}
              handleRowClick={handleModalOpen(
                <LinkCompanySearchModal
                  ref={modalRef}
                  id={link.companyId}
                  title={link.name}
                  columns={COMPANY_COLUMNS}
                />,
              )}
            >
              {keys(LINKING_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key} css={S.td}>
                  {formatTableData(link, key as keyof typeof link)}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRow>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default CarrierTable;
