import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface OrderManagementPaginationProps {
  pageInfo?: PageInfo;
}

const OrderManagementPagination = ({
  pageInfo,
}: OrderManagementPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default OrderManagementPagination;
