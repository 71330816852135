import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { LINKING_TABLE_HEADER_COLUMNS } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${LINKING_TABLE_HEADER_COLUMNS};
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  border-radius: 2px;
  padding: 10px 20px;
  color: ${theme.color.white};
  text-decoration: none;
  background-color: ${theme.color.primary_10};
`;

export const LinkBox = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    width: 35px;
    border-radius: 2px;
    padding: 2px 4px;
    text-align: center;
    color: ${theme.color.gray_70};
    background-color: ${theme.color.gray_10};
  `}
`;

export const td = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const row = (theme: Theme) => css`
  background-color: ${theme.color.white};
`;
