import React from "react";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";

import { formatICTDateTime } from "utils";
import TableSkeleton from "./table/Table.skeleton";
import CheckTableSkeleton from "./checkTable/CheckTable.skeleton";
import * as S from "./DataSection.styled";

interface DataSectionSkeletonProps {
  className?: string;
  children: React.ReactNode;
  btnCount?: number;
  title?: string;
}

const DataSectionSkeleton = ({
  className,
  children,
  btnCount = 0,
  title = "목록",
}: DataSectionSkeletonProps) => {
  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>{title}</h2>
          <S.Refetch>
            <span>마지막 업데이트:</span>
            <time>{formatICTDateTime(dayjs())}</time>
          </S.Refetch>
        </S.LeftContent>
        <S.ActiveWrapper>
          {[...Array(btnCount)].map((_, i) => (
            <Skeleton key={i} width={130} height={40} />
          ))}
        </S.ActiveWrapper>
      </S.Header>
      {children}
    </section>
  );
};

DataSectionSkeleton.Table = TableSkeleton;
DataSectionSkeleton.CheckTable = CheckTableSkeleton;

export default DataSectionSkeleton;
