import React from "react";
import { useTranslation } from "react-i18next";

import { LabelContent, LabelContentTable } from "components";
import { calculateTaxAmount, findLookupTableLabel, formatPrice } from "utils";
import { PAYMENT_METHOD_RADIOS } from "assets";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementPayment.styled";

interface OrderManagementPaymentProps {
  payments?: GetOrderManagementDetailServerModel["payments"];
}

const OrderManagementPayment = ({ payments }: OrderManagementPaymentProps) => {
  const { t } = useTranslation();

  if (!payments) return null;

  const { principalAmount, taxAmount } = calculateTaxAmount(
    `${payments.price}`,
    `${payments.vat}`,
  );
  const vat = `(${payments.vat}%) ${formatPrice(taxAmount)}`;
  const payMethodLabel = findLookupTableLabel(
    PAYMENT_METHOD_RADIOS,
    payments.pay,
  );

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Payment method">
          {payMethodLabel ? t(payMethodLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total">
          <S.PriceWrapper>
            {formatPrice(payments.price)}
            <S.LabelContentWrapper>
              <LabelContent
                css={S.labelContent}
                label="Subtotal"
                direction="horizontal"
              >
                {formatPrice(principalAmount)}
              </LabelContent>
              <LabelContent
                css={S.labelContent}
                label="Tax"
                direction="horizontal"
              >
                {vat}
              </LabelContent>
            </S.LabelContentWrapper>
          </S.PriceWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementPayment;
