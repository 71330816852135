import { useForm } from "react-hook-form";

import { useModal, useToast } from "hooks";
import { usePostSendtoShipper } from "services";
import { deleteComma } from "utils";
import { TAX_RADIOS } from "assets";
import { TOAST_MSG } from "constants/index";

interface Form {
  subtotal: string;
  tax: (typeof TAX_RADIOS)[number]["key"];
  memo: string;
}

const SEND_TOSHIPPER_QUOTATION_FORM = {
  subtotal: "",
  tax: "8",
  memo: "",
} as const;

const useSendtoShipperForm = (shipperId: string) => {
  const { mutate: postSendtoShipperMutate, isLoading } = usePostSendtoShipper();

  const formMethod = useForm<Form>({
    mode: "onTouched",
    defaultValues: SEND_TOSHIPPER_QUOTATION_FORM,
  });

  const { addToast } = useToast();
  const { handleModalClose } = useModal();

  const handleSend = formMethod.handleSubmit(() => {
    const req = {
      offerId: shipperId,
      body: {
        price: +deleteComma(formMethod.watch("subtotal")) ?? 0,
        vat: +formMethod.watch("tax"),
        ...(formMethod.watch("memo") && {
          remarks: formMethod.watch("memo").trim(),
        }),
      },
    };

    postSendtoShipperMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.QUOTATION_SEND_TO_SHIPPER_SUCCESS);
        handleModalClose();
      },
    });
  });

  return { isLoading, formMethod, handleSubmit: handleSend };
};

export default useSendtoShipperForm;
