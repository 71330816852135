export const ERROR_CODE = {
  BAD_REQUEST: "BAD_REQUEST",
  UNAUTHORIZED: "UNAUTHORIZED", //NOTE: tokenService 만들면서, cocotruck 운송사와 에러코드 동일하게 진행 -> 확인필요
  EMAIL_NOT_FOUND: "EMAIL_NOT_FOUND",
  ACCESSTOKEN_EXPIRED: "ACCESSTOKEN_EXPIRED",
  REFRESHTOKEN_EXPIRED: "REFRESHTOKEN_EXPIRED",
  DUPLICATE_SIGNIN_DETECTED: "DUPLICATE_SIGNIN_DETECTED",
  EMAIL_EXISTS: "EMAIL_EXISTS",
  PHONE_EXISTS: "PHONE_EXISTS",
  WRONG_PASSWORD: "WRONG_PASSWORD",
  TAXCODE_EXISTS: "TAXCODE_EXISTS",
  STAFF_EMAIL_EXISTS: "STAFF_EMAIL_EXISTS",
  COMPANY_EMAIL_EXISTS: "COMPANY_EMAIL_EXISTS",
  COMPANY_PHONE_EXISTS: "COMPANY_PHONE_EXISTS",
  STAFF_PHONE_EXISTS: "STAFF_PHONE_EXISTS",
  GARAGE_NAME_EXISTS: "GARAGE_NAME_EXISTS",
  PLATE_NUMBER_EXISTS: "PLATE_NUMBER_EXISTS",
  VIN_EXISTS: "VIN_EXISTS",
  DRIVER_ALREADY_LINKED: "DRIVER_ALREADY_LINKED",
  GARAGE_FULL: "GARAGE_FULL",
  CONNECTED_TRUCK_EXISTS: "CONNECTED_TRUCK_EXISTS",
};
