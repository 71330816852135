import { useState } from "react";

const useCheckTable = <T extends Record<K, string>, K extends keyof T>(
  tableDatas: T[] = [],
  uniqueId: K,
) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const isChecked = (id: string): boolean => checkedIds.includes(id);
  const isAllChecked =
    !!tableDatas.length && tableDatas.length === checkedIds.length;

  const handleCheck = (id: string) => (): void => {
    checkedIds.includes(id)
      ? setCheckedIds(checkedIds.filter((checkId) => checkId !== id))
      : setCheckedIds([...checkedIds, id]);
  };

  const handleAllCheck = (): void => {
    setCheckedIds([
      ...new Set([
        ...checkedIds,
        ...tableDatas.map((tableData) => tableData[uniqueId]),
      ]),
    ]);
  };

  const handleAllUnCheck = (): void => setCheckedIds([]);

  return {
    isChecked,
    isAllChecked,
    checkedIds,
    setCheckedIds,
    handleCheck,
    handleAllCheck,
    handleAllUnCheck,
  };
};

export default useCheckTable;
