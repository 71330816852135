import { useEffect, useState } from "react";

import type { CompanyInfo, SelectCompanyInfo } from "types";

const useSelectCompany = (selectedCompany: CompanyInfo[]) => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo[]>([]);

  const clickTableRow =
    ({ id, name }: SelectCompanyInfo) =>
    (): void => {
      setCompanyInfo([{ id, name }]);
    };

  useEffect(() => {
    setCompanyInfo(selectedCompany);
  }, []);

  return {
    companyInfo,
    clickTableRow,
  };
};

export default useSelectCompany;
