export const size = {
  HEADER_HEIGHT: "52px",
  FOOTER_HEIGHT: "76px",
  SECTION_MIN_WIDTH: "1296px",
  SECTION_MAX_WIDTH: "1660px",
  NAV_WIDTH: "64px",
  LAYOUT_PADDING: "40px",
  LIST_LAYOUT_HEADER_HEIGHT: "48px",
  LIST_LAYOUT_MAIN_TAB_HEIGHT: "50px",
  FOLDABLE_SHORT_LAYOUT_WIDTH: "390px",
  FOLDABLE_WIDE_LAYOUT_WIDTH: "780px",
  TABLE_HEADER_HEIGHT: "46px",
  TABLE_SECTION_MIN_WIDTH: "1376px", // NOTE: 1296px (padding 40+40)
  TABLE_SECTION_MAX_WIDTH: "1740px", // NOTE: 1660px (padding 40+40)
  TABLE_INQUIRY_WIDTH: "698px",
  TABLE_BODY_HEIGHT: "821px",
  TABLE_HALF_BODY_HEIGHT: "410.5px", // NOTE:  TABLE_BODY_HEIGHT / 2
  GOOGLE_MAP_LEFT_PADDING: 390,
} as const;

export type SizeType = typeof size;
