import React from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable } from "components";
import { formatICTDateTime } from "utils";
import type { GetOrderManagementDetailServerModel, Languages } from "types";
import AllocationDriver from "./allocationDriver.tsx/AllocationDriver";
import * as S from "./OrderManagementAllocationInfo.styled";

interface OrderManagementAllocationInfoProps {
  allocationInfo?: GetOrderManagementDetailServerModel["allocationInfo"];
}

const OrderManagementAllocationInfo = ({
  allocationInfo,
}: OrderManagementAllocationInfoProps) => {
  const { t } = useTranslation();
  if (!allocationInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Request date" labelWidth={200}>
          {allocationInfo.requestedDate
            ? formatICTDateTime(allocationInfo.requestedDate)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        {Array.from({ length: allocationInfo.drivers.length }).map(
          (
            _,
            i, //TODO: 개수 기준 확인 필요
          ) => (
            <LabelContentTable.Content
              css={S.content}
              key={i}
              label={`${t("Drive")} (${i + 1})` as Languages}
              labelWidth={200}
            >
              {allocationInfo.drivers?.length > i ? (
                <AllocationDriver
                  profileUrl={allocationInfo.drivers[i].profile}
                  name={allocationInfo.drivers[i].driverName}
                  phone={allocationInfo.drivers[i].driverPhone}
                  status={allocationInfo.drivers[i].driverStatus}
                  plateNumber={allocationInfo.drivers[i].plateNumber}
                  deliveryLog={{
                    doAtd: allocationInfo.drivers[i].driverDoAtd,
                    doAta: allocationInfo.drivers[i].driverDoAta,
                    puAtd: allocationInfo.drivers[i].driverPuAtd,
                    puAta: allocationInfo.drivers[i].driverPuAta,
                    shipmentsStart: allocationInfo.drivers[i].shipmentsStart,
                  }}
                />
              ) : (
                <span>-</span>
              )}
            </LabelContentTable.Content>
          ),
        )}
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementAllocationInfo;
