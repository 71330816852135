import React from "react";

import { useFilterParams } from "hooks";
import { useGetLinkings } from "services";
import { GetLinkingsQueryModel } from "types";
import { ForwarderPagination, ForwarderTable } from "./containers";
import { LinkingFilter } from "../container";

const ForwarderLinking = () => {
  const { filters } = useFilterParams<GetLinkingsQueryModel["query"]>([
    "page",
    "type",
    "company",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetLinkings({
    query: { ...filters, type: "forwarder" },
  });

  return (
    <>
      <LinkingFilter />
      <ForwarderTable
        linkings={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <ForwarderPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default ForwarderLinking;
