import { isEqual } from "lodash-es";

/** NOTE: 서버 데이터 수정 때 사용하는 함수
 * originObj: 원본 객체, changeObj: 서버에 수정 요청하는 최종 객체
 * for in문 내에 changeObj의 키를 기준으로 값을 비교하고 returnObj를 반환한다.
 * 값이 같다면 returnObj의 키를 삭제
 * 값이 다르면 changeObj의 값을 returnObj에 추가
 * originObj에서 값이 있었으나 changeObj에서 빈 문자열이 되었을 때는 returnObj에 null을 추가한다.  */
export const detectValueChange = <
  T extends Record<string, unknown>,
  K extends Record<string, unknown>,
>(
  originObj: T,
  changeObj: K,
) => {
  const returnObj: any = { ...changeObj }; // TODO: type 에러때문에 any로 설정했습니다. 타입에러 수정되면 함수 반환 값도 추가해주어야됩니다.

  for (const key in changeObj) {
    const isSameValue =
      isEqual(originObj[key], changeObj[key]) ||
      (originObj[key] === null && changeObj[key] === ""); // TODO: hook form 에서 type 이 string 이면 "" -> null 로 변경하는 건 어떤지에 대한 김재경 선임님의 의견

    if (isSameValue) {
      delete returnObj[key];
    }

    const isEmptyAfterUpdate =
      typeof changeObj[key] === "string" &&
      originObj[key] &&
      changeObj[key] === "";

    if (isEmptyAfterUpdate) {
      returnObj[key] = null;
    }
  }

  return returnObj;
};
