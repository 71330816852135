import React from "react";

import { useFilterParams } from "hooks";
import { useGetInquries } from "services";
import type { GetInquiriesQueryModel } from "types";
import { InquiryFilter, InquiryPagination, InquiryTable } from "./containers";

const Inquiry = () => {
  const { filters } = useFilterParams<GetInquiriesQueryModel["query"]>([
    "category",
    "companyType",
    "page",
  ]);
  const { data, refetch, dataUpdatedAt } = useGetInquries({
    query: {
      ...filters,
      category: filters.category ?? "ALL",
      companyType: filters.companyType ?? "ALL",
    },
  });

  return (
    <>
      <InquiryFilter />
      <InquiryTable
        inquiries={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <InquiryPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default Inquiry;
