import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  DeliveryCard,
  Foldable1DepthLayout,
  PageBackHeader,
  TrackingHistoryInfo,
} from "components";
import { useOrderDetailTrackingHistory } from "services";
import { filterValidCoords } from "utils";
import { MONITORING_QUERY_PARAMS } from "assets";
import { PATH } from "constants/index";
import * as S from "./OrderManagementMap.styled";

const OrderManagementMap = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data } = useOrderDetailTrackingHistory(searchParams.get("orderId")!);

  const deliveries = data?.delivery.map((item) => ({
    ...item,
    coord: filterValidCoords(item.coord.map(([lat, lng]) => ({ lng, lat }))),
  }));

  if (!data || !deliveries) return null;

  return (
    <Foldable1DepthLayout heading="Tracking history">
      <PageBackHeader
        path={`${PATH.ORDER_MANAGEMENT}/${searchParams.get(
          MONITORING_QUERY_PARAMS.ORDER_ID,
        )}`}
        title="Tracking history"
      />
      <TrackingHistoryInfo orderInfo={data} />
      <S.Division />
      <S.AllocationListWrapper>
        <span>{t("Allocation list")}</span>
        <DeliveryCard
          deliveries={deliveries}
          googleMapCenterLatLng={{
            pickUp: {
              lat: +deliveries[0].puLat,
              lng: +deliveries[0].puLng,
              type: "PICK",
            },
            dropOff: {
              lat: +deliveries[0].doLat,
              lng: +deliveries[0].doLng,
              type: "DROP",
            },
          }}
        />
      </S.AllocationListWrapper>
    </Foldable1DepthLayout>
  );
};

export default OrderManagementMap;
