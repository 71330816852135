import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import type { Languages } from "types";
import * as S from "./CheckboxBtn.styled";

interface CheckboxBtnProps {
  className?: string;
  disabled?: boolean;
  isChecked?: boolean;
  label?: Languages;
  shape: "circle" | "rectangle";
  register?: UseFormRegisterReturn<string>;
  handleCheck?: () => void;
}

const CheckboxBtn = ({
  className,
  disabled,
  isChecked = false,
  label,
  shape,
  register,
  handleCheck,
}: CheckboxBtnProps) => {
  const { t } = useTranslation();

  const uuid = uuidv4();

  return (
    <S.Wrapper className={className}>
      <S.Input
        disabled={disabled}
        checked={isChecked}
        type="checkbox"
        readOnly
        id={uuid}
        onChange={handleCheck}
        {...register}
      />
      <S.CheckboxClickAbleArea
        className="label"
        htmlFor={uuid}
        disabled={disabled}
      >
        <S.CheckBox shape={shape} className="box" tabIndex={0} />
      </S.CheckboxClickAbleArea>
      {label && <S.Label htmlFor={uuid}>{t(label)}</S.Label>}
    </S.Wrapper>
  );
};

export default CheckboxBtn;
