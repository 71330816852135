import { Theme, css } from "@emotion/react";

import { COMPANY_SELECT_TABLE_CELL_SIZES } from "assets";
import { mixin_scrollbar } from "style";

export const detailModal = css`
  width: 689px;
  height: 654px;
  padding: 32px;
`;

export const searchInput = css`
  width: 300px;
  margin-bottom: 12px;
`;

export const table = (isLast: boolean) => (theme: Theme) =>
  css`
    height: 420px;
    border-bottom: 1px solid ${theme.color.gray_30};

    th {
      border-right: 1px solid ${theme.color.gray_30};
      color: ${theme.color.gray_60};
    }

    tbody {
      ${mixin_scrollbar};
      display: block;
      height: 380px;
      overflow-x: hidden;

      & > tr:last-of-type {
        border-bottom: ${isLast && "0"};
      }
    }

    tr {
      grid-template-columns: ${COMPANY_SELECT_TABLE_CELL_SIZES};
    }

    td:not(:nth-last-of-type(2)) {
      border-right: 1px solid ${theme.color.gray_30};
    }
  `;
