import type { Languages } from "types";

export const TOAST_MSG = {
  SUCCESS: {
    DRIVER_LINK_UPDATE_DONE: {
      type: "success",
      content: "Driver information has been updated.",
    },
    ACCOUNT_CHANGE_SUCCESS: {
      type: "success",
      content: "The account information has been changed.",
    },
    COPY_PLATE_DONE: {
      type: "success",
      content: "Plate number was copied successfully.",
    },
    DEFAULT_INFORMATION_EDIT_DONE: {
      type: "success",
      content: "Default information has been updated.",
    },
    DRIVER_DELETE_DONE: {
      type: "success",
      content: "Driver has been deleted.",
    },
    GARAGE_CREATE: {
      type: "success",
      content: "Garage has been added.",
    },
    GARAGE_DELETE: {
      type: "success",
      content: "Garage has been deleted.",
    },
    GARAGE_UPDATE: {
      type: "success",
      content: "Garage information has been updated.",
    },
    INQUIRY_ANSWER_SUCCESS: {
      type: "success",
      content: "Reply has been added.",
    },
    LINK_COMPANYS_DONE: {
      type: "success",
      content: "Link has been set.",
    },
    LINK_CONNECT_DONE: { type: "success", content: "Link has been set." },
    LINK_DISCONNECT_DONE: {
      type: "success",
      content: "Link has been disabled.",
    },
    MEMBER_CREATE_DONE: {
      type: "success",
      content: "The member has been added.",
    },
    MEMBER_UPDATE_DONE: {
      type: "success",
      content: "Member information has been updated.",
    },
    MEMBER_DELETE_DONE: {
      type: "success",
      content: "Member has been deleted.",
    },
    MEMBER_STATUS_CHANGE_APPROVE_SUCCESS: {
      type: "success",
      content: "Request has been approved.",
    },
    MEMBER_STATUS_CHANGE_REJECT_SUCCESS: {
      type: "success",
      content: "Request has been rejected.",
    },
    NOTICE_ADD_SUCCESS: {
      type: "success",
      content: "The notice has been added.",
    },
    ORDER_CANCELD_SUCCESS: {
      type: "success",
      content: "The order has been canceled successfully.",
    },
    PASSWORD_CHANGE_SUCCESS: {
      type: "success",
      content: "The password has been changed.",
    },
    PLAN_ADD_SUCCESS: {
      type: "success",
      content: "The membership plan has been added.",
    },
    PLAN_EDIT_SUCCESS: {
      type: "success",
      content: "The membership plan has been changed.",
    },
    QUOTATION_REJECT_SUCCESS: {
      type: "success",
      content: "The quotation has been rejected.",
    },
    QUOTATION_SEND_TO_SHIPPER_SUCCESS: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    REGISTERED_MEMBER_ADD_SUCCESS: {
      type: "success",
      content: "The member account has been added.",
    },
    REGISTERED_MEMBER_EDIT_SUCCESS: {
      type: "success",
      content: "The member account has been changed.",
    },
    SETTLEMENT_STATUS_CONFIRM_SUCCESS: {
      type: "success",
      content: "This requested payment has been confirmed.",
    },
    SETTLEMENT_STATUS_REJECT_SUCCESS: {
      type: "success",
      content: "This requested payment has been rejected.",
    },
    SUPPORT_NOTICE_DELETE_SUCCESS: {
      type: "success",
      content: "The notice has been deleted.",
    },
    SUPPORT_NOTICE_PINNED_SUCCESS: {
      type: "success",
      content: "The notice has been pinned.",
    },
    SUPPORT_NOTICE_UNPINNED_SUCCESS: {
      type: "success",
      content: "The notice has been unpinned.",
    },
    TRUCK_CREATE_DONE: {
      type: "success",
      content: "Truck has been added.",
    },
    TRUCK_DELETE_DONE: {
      type: "success",
      content: "Truck has been deleted.",
    },
    TRUCK_UPDATE_DONE: {
      type: "success",
      content: "Truck information has been updated.",
    },
  },
  WARNING: {
    ACCEPTED_OFFER_EXISTS: {
      type: "warning",
      content: "ACCEPTED_OFFER_EXISTS",
    },
    ACCEPTED_ORDER_EXISTS: {
      type: "warning",
      content: "ACCEPTED_ORDER_EXISTS",
    },
    BAD_REQUEST: {
      type: "warning",
      content: "Request failed. Please try again.",
    },
    COMPANY_EMAIL_EXISTS: {
      type: "warning",
      content: "This business email is already registered.",
    },
    COMPANY_NUMBER_EXCEEDED: {
      type: "warning",
      content: "COMPANY_NUMBER_EXCEEDED",
    },
    COMPANY_PHONE_EXISTS: {
      type: "warning",
      content: "This business tel. is already registered.",
    },
    DISPATCH_ALREADY_EXISTS: {
      type: "warning",
      content: "DISPATCH_ALREADY_EXISTS",
    },
    DRIVER_ALREADY_LINKED: {
      type: "warning",
      content: "DRIVER_ALREADY_LINKED",
    },
    DRIVING_DRIVER: {
      type: "warning",
      content: "DRIVING_DRIVER",
    },
    EMAIL_EXISTS: {
      type: "warning",
      content: "EMAIL_EXISTS",
    },
    GARAGE_NAME_EXISTS: {
      type: "warning",
      content: "This garage name is already taken.",
    },
    INVALID_ADDRESS: {
      type: "warning",
      content: "INVALID_ADDRESS",
    },
    INVALID_ALLOCATION: {
      type: "warning",
      content: "INVALID_ALLOCATION",
    },
    INVALID_ATTACHMENT: {
      type: "warning",
      content: "INVALID_ATTACHMENT",
    },
    INVALID_BLNUMBER: {
      type: "warning",
      content: "INVALID_BLNUMBER",
    },
    INVALID_BRAND: {
      type: "warning",
      content: "INVALID_BRAND",
    },
    INVALID_CAPACITY: {
      type: "warning",
      content: "INVALID_CAPACITY",
    },
    INVALID_CARGO_TYPE: {
      type: "warning",
      content: "INVALID_CARGO_TYPE",
    },
    INVALID_CATEGORY: {
      type: "warning",
      content: "INVALID_CATEGORY",
    },
    INVALID_COMPANY: {
      type: "warning",
      content: "INVALID_COMPANY",
    },
    INVALID_COMPANY_TYPE: {
      type: "warning",
      content: "INVALID_COMPANY_TYPE",
    },
    INVALID_CONTENT: {
      type: "warning",
      content: "INVALID_CONTENT",
    },
    INVALID_DATE: {
      type: "warning",
      content: "INVALID_DATE",
    },
    INVALID_DRIVER_ID: {
      type: "warning",
      content: "INVALID_DRIVER_ID",
    },
    INVALID_DRIVER_NAME: {
      type: "warning",
      content: "INVALID_DRIVER_NAME",
    },
    INVALID_EMAIL: {
      type: "warning",
      content: "INVALID_EMAIL",
    },
    INVALID_ETA: {
      type: "warning",
      content: "INVALID_ETA",
    },
    INVALID_ETD: {
      type: "warning",
      content: "INVALID_ETD",
    },
    INVALID_FAVORITE_NAME: {
      type: "warning",
      content: "INVALID_FAVORITE_NAME",
    },
    INVALID_HAZARD: {
      type: "warning",
      content: "INVALID_HAZARD",
    },
    INVALID_INVOICENUMBER: {
      type: "warning",
      content: "INVALID_INVOICENUMBER",
    },
    INVALID_LATITUDE: {
      type: "warning",
      content: "INVALID_LATITUDE",
    },
    INVALID_LOADING_METHOD: {
      type: "warning",
      content: "INVALID_LOADING_METHOD",
    },
    INVALID_LOADINGSTATUS: {
      type: "warning",
      content: "INVALID_LOADINGSTATUS",
    },
    INVALID_LONGITUDE: {
      type: "warning",
      content: "INVALID_LONGITUDE",
    },
    INVALID_MEMO: {
      type: "warning",
      content: "INVALID_MEMO",
    },
    INVALID_MODEL: {
      type: "warning",
      content: "INVALID_MODEL",
    },
    INVALID_NAME: {
      type: "warning",
      content: "INVALID_NAME",
    },
    INVALID_OPTION: {
      type: "warning",
      content: "INVALID_OPTION",
    },
    INVALID_ORDERNUMBER: {
      type: "warning",
      content: "INVALID_ORDERNUMBER",
    },
    INVALID_PACKAGING_TYPE: {
      type: "warning",
      content: "INVALID_PACKAGING_TYPE",
    },
    INVALID_PAGE: {
      type: "warning",
      content: "INVALID_PAGE",
    },
    INVALID_PAGE_SIZE: {
      type: "warning",
      content: "INVALID_PAGE_SIZE",
    },
    INVALID_PASSWORD: {
      type: "warning",
      content: "INVALID_PASSWORD",
    },
    INVALID_PAYMENT_TYPE: {
      type: "warning",
      content: "INVALID_PAYMENT_TYPE",
    },
    INVALID_PHONE: {
      type: "warning",
      content: "INVALID_PHONE",
    },
    INVALID_PIC: {
      type: "warning",
      content: "INVALID_PIC",
    },
    INVALID_PRICE: {
      type: "warning",
      content: "INVALID_PRICE",
    },
    INVALID_QUANTITY: {
      type: "warning",
      content: "INVALID_QUANTITY",
    },
    INVALID_SIZE: {
      type: "warning",
      content: "INVALID_SIZE",
    },
    INVALID_STACKABLE: {
      type: "warning",
      content: "INVALID_STACKABLE",
    },
    INVALID_STATUS: {
      type: "warning",
      content: "INVALID_STATUS",
    },
    INVALID_TITLE: {
      type: "warning",
      content: "INVALID_TITLE",
    },
    INVALID_TRUCK_ID: {
      type: "warning",
      content: "INVALID_TRUCK_ID",
    },
    INVALID_TRUCK_NUMBER: {
      type: "warning",
      content: "INVALID_TRUCK_NUMBER",
    },
    INVALID_TRUCK_TYPE: {
      type: "warning",
      content: "INVALID_TRUCK_TYPE",
    },
    INVALID_VAT: {
      type: "warning",
      content: "INVALID_VAT",
    },
    INVALID_VIN: {
      type: "warning",
      content: "INVALID_VIN",
    },
    INVALID_WEIGHT: {
      type: "warning",
      content: "INVALID_WEIGHT",
    },
    INVALID_YEAR: {
      type: "warning",
      content: "INVALID_YEAR",
    },
    lNVALID_PLAYER: {
      type: "warning",
      content: "lNVALID_PLAYER",
    },
    LOGIN_FAILED: {
      type: "warning",
      content: "LOGIN_FAILED",
    },
    OFFER_EXISTS: {
      type: "warning",
      content: "OFFER_EXISTS",
    },
    REQUESTED_ORDER_EXISTS: {
      type: "warning",
      content: "REQUESTED_ORDER_EXISTS",
    },
    STAFF_EMAIL_EXISTS: {
      type: "warning",
      content: "This Login ID is already taken.",
    },
    TAXCODE_EXISTS: {
      type: "warning",
      content: "This tax code is already registered.",
    },
    TRUCK_NOT_LINKED: {
      type: "warning",
      content: "TRUCK_NOT_LINKED",
    },
    WRONG_TRUCK_COUNT: {
      type: "warning",
      content: "WRONG_TRUCK_COUNT",
    },
    STAFF_PHONE_EXISTS: {
      type: "warning",
      content: "This phone number is already registered.",
    },
    FORBIDDEN: {
      type: "warning",
      content: "FORBIDDEN",
    },
    UNAUTHORIZED: {
      type: "warning",
      content: "UNAUTHORIZED",
    },
    NOT_FOUND: {
      type: "warning",
      content: "NOT_FOUND",
    },
    INQUIRY_ANSWER_ALREADY: {
      type: "warning",
      content: "The inquiry has already been answered.",
    },
    SETTING_REGISTERED_MEMBER_EMAIL_EXISTS: {
      type: "warning",
      content: "This Login ID is already taken.",
    },
    SETTING_REGISTERED_MEMBER_PHONE_EXISTS: {
      type: "warning",
      content: "This phone number is already registered.",
    },
    CANNOT_FIND_GPS: {
      type: "warning",
      content: "GPS information doesn't exist.",
    },
    GPS_NOT_CONNECTED: {
      type: "warning",
      content: "GPS is not registerd, Please contact your administrator.",
    },
    DRIVER_ALREADY_LINK: {
      type: "warning",
      content:
        "Unable to process your request due to linked truck already exists.",
    },
    GARAGE_FULL: {
      type: "warning",
      content: "Trucks exceeds the maximum allowed.",
    },
    DELETE_GARAGE_EXISTING_TRUCK: {
      type: "warning",
      content: "Unable to process your request due to a parked truck exists.",
    },
  },
} as const;
