import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  getQuotationDetailAPI,
  getQuotationsAPI,
  patchQuotationRejectAPI,
  postSendtoShipperAPI,
} from "api";
import {
  APIError,
  GetQuotationQueryModel,
  GetQuotationServerModel,
  PostSendtoShipperQueryModel,
} from "types";

const quotationKeys = {
  all: ["quotation"],
  lists: () => [...quotationKeys.all, "list"],
  list: (filters: GetQuotationQueryModel) => [
    ...quotationKeys.lists(),
    { filters },
  ],
  details: () => [...quotationKeys.all, "detail"] as const,
  detail: (id: string) => [...quotationKeys.details(), id] as const,
};

export const useGetQuotations = (
  req: GetQuotationQueryModel,
  options?: UseQueryOptions<
    GetQuotationServerModel,
    APIError,
    GetQuotationServerModel,
    ReturnType<typeof quotationKeys.list>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: quotationKeys.list(req),
    queryFn: () => getQuotationsAPI(req),
  });
};

export const useGetQuotationDetail = (orderId: string) => {
  return useQuery({
    queryKey: quotationKeys.detail(orderId),
    queryFn: () => getQuotationDetailAPI(orderId),
  });
};

export const usePostSendtoShipper = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostSendtoShipperQueryModel>({
    mutationFn: (req) => postSendtoShipperAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};

export const usePatchQuotationReject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (offerId: string) => patchQuotationRejectAPI(offerId),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};
