import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${theme.size.HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 0 40px 0 20px;
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.HEADER};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 22px;
`;

export const LogoutBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${theme.color.gray_80};
  `}
`;

export const logoutIcon = (theme: Theme) => css`
  width: 16px;
  height: 16px;

  & > path {
    fill: ${theme.color.gray_50};
  }
`;
