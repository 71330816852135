import { ax } from "api";
import { addPrefixToUrlIfNeeded } from "utils";
import {
  GetMemberDetailDriverDetailServerModel,
  GetMemberDetailDriversQueryModel,
  GetMemberDetailDriversServerModel,
  GetMemberDetailGaragesQueryModel,
  GetMemberDetailGaragesServerModel,
  GetMemberDetailTruckDetailServerModel,
  GetMemberDetailTrucksQueryModel,
  GetMemberDetailTrucksServerModel,
  GetMemberCompanyPlanQueryModel,
  GetMemberCompanyPlanServerModel,
  GetMemberDetailQueryModel,
  GetMembersQueryModel,
  GetMembersServerModel,
  PostMemberCompanyPlanQueryModel,
  PostMemberDummyQueryModel,
  PostMemberQueryModel,
  PutLicenseApproveQueryModel,
  PutMemberCompanyLicenseQueryModel,
  PutMemberCompanyPlanQueryModel,
  PutMemberCompanyPlanServerModel,
  PutMemberDummyQueryModel,
  PutMemberQueryModel,
  GetTruckGPSsQueryModel,
  GetTruckGPSsServerModel,
  GetTruckGarageServerModel,
  GetTruckGarageQueryModel,
  PostTruckCreateQueryModel,
  GetTruckLinkDriverQueryModel,
  GetTruckLinkDriverServerModel,
  GetMemberDetailRegisteredMemberQueryModel,
  GetMemberDetailRegisteredMemberServerModel,
  DeleteMemberDetailRegisteredMemberQueryModel,
  CreateMemberDetailRegisteredMemberQueryModel,
  GetMemberDetailRegisteredMemberDetailQueryModel,
  GetMemberDetailRegisteredMemberDetailServerModel,
  EditMemberDetailRegisteredMemberDetailQueryModel,
  GetMemberDetailTruckDetailQueryModel,
  PutTruckSpecificationServerModel,
  CreateMemberDetailGarageQueryModel,
  PutTruckDefaultInformationQueryModel,
  DeleteTruckQueryModel,
  GetMemberDetailDriverDetailQueryModel,
  GetLinkDriverTrucksQueryModel,
  GetLinkDriverTrucksServerModel,
  PostLinkDriverTruckQueryModel,
  DeleteLinkDriverTruckQueryModel,
  GetMemberLinksQueryModel,
  GetMemberLinksServerModel,
  GetMemberDetailStaffsQueryModel,
  GetMemberDetailStaffsServerModel,
  GetMemberDetailGarageQueryModel,
  EditMemeberDetailGarageQueryModel,
  DeleteMemeberDetailGarageQueryModel,
  PutDriverDefaultInfoQueryModel,
  PutDriverAccountInfoQueryModel,
  PostDriverCreateQueryModel,
  ChangeDriverPasswordQueryModel,
  GetDriverAllocationQueryModel,
  GetDriverAllocationServerModel,
  DeleteDriverQueryModel,
  PostMemberDetailConnectLinkQueryModel,
  GetMemberDetailLinkedDriverSearchQueryModel,
  GetMemberDetailLinkedDriverSearchServerModel,
  GetMemberDetailTruckDetailAllocationQueryModel,
  GetMemberDetailTruckDetailAlloctionServerModel,
  GetMemberDetailServerModel,
} from "types";
import { getS3PresignedUrlAPI } from "./presignedUrl";

export const getMembersAPI = async (req: GetMembersQueryModel) => {
  const { data } = await ax.get<GetMembersServerModel>("/member/company", {
    params: req.query,
  });

  return data;
};

export const getMemberDetailAPI = async (req: GetMemberDetailQueryModel) => {
  const { data } = await ax.get<GetMemberDetailServerModel>(
    `/member/company/${req.companyId}`,
    { params: req.query },
  );

  const makeLicensePrefix = (license: (string | null)[]): Promise<string>[] => {
    return license
      .filter(Boolean)
      .map((license) =>
        getS3PresignedUrlAPI(addPrefixToUrlIfNeeded(license!, "license")),
      );
  };

  const licenseUrlsMakePrefix = Promise.all(
    makeLicensePrefix([
      data.license1,
      data.license2,
      data.transportLicense1,
      data.transportLicense2,
    ]),
  );

  const newLicenseUrlsMakePrefix = Promise.all(
    makeLicensePrefix([
      data.newLicense1,
      data.newLicense2,
      data.newTransportLicense1,
      data.newTransportLicense2,
    ]),
  );

  const [licenseUrls, newLicenseUrls] = await Promise.all([
    licenseUrlsMakePrefix,
    newLicenseUrlsMakePrefix,
  ]);

  return { ...data, licenseUrls, newLicenseUrls };
};

export const putMemberAPI = async (req: PutMemberQueryModel) => {
  const { data } = await ax.put(`/member/company/${req.companyId}`, req.body, {
    params: req.query,
  });

  return data;
};

export const postMemberAPI = async (req: PostMemberQueryModel) => {
  const { data } = await ax.post("/member/company", req.body, {
    params: req.query,
  });

  return data;
};

export const getMemberDetailDriversAPI = async (
  req: GetMemberDetailDriversQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailDriversServerModel>(
    "/property/driver",
    { params: req.query },
  );

  const drivers = await Promise.all(
    data.drivers.map(async (driver) => ({
      ...driver,
      profileUrl: driver.profile
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(driver.profile, "profile"),
          )
        : null,
    })),
  );

  return { ...data, drivers };
};

export const getMemberDetailDriverDetailAPI = async (
  req: GetMemberDetailDriverDetailQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailDriverDetailServerModel>(
    `/property/driver/detail/${req.driverId}`,
    { params: req.query },
  );

  const profileUrl: string = await getS3PresignedUrlAPI(
    addPrefixToUrlIfNeeded(data.profile, "profile"),
  );

  return { ...data, profileUrl: profileUrl || null };
};

export const getDriverAllocationAPI = async (
  req: GetDriverAllocationQueryModel,
) => {
  const { data } = await ax.get<GetDriverAllocationServerModel>(
    `/property/driver/${req.driverId}/allocation`,
    { params: req.query },
  );

  return data;
};

export const deleteDriverAPI = async (req: DeleteDriverQueryModel) => {
  const { data } = await ax.delete(`/property/driver/${req.driverId}`, {
    params: req.query,
  });

  return data;
};

export const postDriverCreateAPI = async (req: PostDriverCreateQueryModel) => {
  const { data } = await ax.post("/property/driver", req.body, {
    params: req.query,
  });

  return data;
};

export const getLinkDriverTrucksAPI = async (
  req: GetLinkDriverTrucksQueryModel,
) => {
  const { data } = await ax.get<GetLinkDriverTrucksServerModel>(
    "/property/driver/truck",
    { params: req.query },
  );

  return data;
};

export const postLinkDriverTruckAPI = async (
  req: PostLinkDriverTruckQueryModel,
) => {
  const { data } = await ax.post(
    `/property/driver/${req.driverId}/truck`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const deleteLinkDriverTruckAPI = async (
  req: DeleteLinkDriverTruckQueryModel,
) => {
  const { data } = await ax.delete(`/property/driver/${req.driverId}/truck`, {
    params: req.query,
  });

  return data;
};

export const getMemberDetailGaragesAPI = async (
  req: GetMemberDetailGaragesQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailGaragesServerModel>(
    `/property/garage`,
    { params: req.query },
  );

  return data;
};

export const getMemberDetailGarageDetailAPI = async (
  req: GetMemberDetailGarageQueryModel,
) => {
  const { data } = await ax.get(`/property/garage/${req.garageId}`, {
    params: req.query,
  });

  return data;
};

export const createMemberDetailGaragesAPI = (
  req: CreateMemberDetailGarageQueryModel,
) => ax.post("/property/garage", req.body, { params: req.query });

export const editMemeberDetailGarageAPI = (
  req: EditMemeberDetailGarageQueryModel,
) =>
  ax.put(`/property/garage/${req.garageId}`, req.body, { params: req.query });

export const deleteMemberDetailGarageAPI = (
  req: DeleteMemeberDetailGarageQueryModel,
) => ax.delete(`/property/garage/${req.garageId}`, { params: req.query });

export const getMemberDetailStaffsAPI = async (
  req: GetMemberDetailStaffsQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailStaffsServerModel>(
    `/member/company/${req.carrierId}/staff`,
    { params: req.query },
  );

  return data;
};

export const getMemberDetailTrucksAPI = async (
  req: GetMemberDetailTrucksQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailTrucksServerModel>(
    "property/truck",
    { params: req.query },
  );

  const trucks = await Promise.all(
    data.trucks.map(async (truck) => ({
      ...truck,
      profileUrl: truck.profileS3Key
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(truck.profileS3Key, "profile"),
          )
        : null,
    })),
  );

  return { ...data, trucks };
};

export const getMemberDetailTruckDetailAPI = async (
  req: GetMemberDetailTruckDetailQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailTruckDetailServerModel>(
    `/property/truck/${req.truckId}`,
    { params: req.query },
  );

  const linked = await Promise.all(
    data.linked.map(async (driver) => ({
      ...driver,
      profileUrl: driver.profileS3Key
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(driver.profileS3Key, "profile"),
          )
        : null,
    })),
  );

  return { ...data, linked };
};

export const putMemberCompanyLicense = async (
  req: PutMemberCompanyLicenseQueryModel,
) => {
  const { data } = await ax.put(
    `/member/company/${req.companyId}/license`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const getMemberCompanyPlanAPI = async (
  req: GetMemberCompanyPlanQueryModel,
) => {
  const { data } = await ax.get<GetMemberCompanyPlanServerModel>(
    `/member/company/${req.companyId}/plan`,
    { params: req.query },
  );

  return data;
};

export const postMemberCompanyPlanAPI = async (
  req: PostMemberCompanyPlanQueryModel,
) => {
  const { data } = await ax.post(
    `/member/company/${req.companyId}/plan`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const putMemberCompanyPlanAPI = async (
  req: PutMemberCompanyPlanQueryModel,
) => {
  const { data } = await ax.put<PutMemberCompanyPlanServerModel>(
    `/member/plan/${req.planId}`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const postMemberDummyAPI = async (req: PostMemberDummyQueryModel) => {
  const { data } = await ax.post("/member/company/dummy", req.body, {
    params: req.query,
  });

  return data;
};

export const putMemberDummyAPI = async (req: PutMemberDummyQueryModel) => {
  const { data } = await ax.put(
    `/member/company/${req.companyId}/dummy`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const putLicenseApproveAPI = async (
  req: PutLicenseApproveQueryModel,
) => {
  const { data } = await ax.put(
    `/member/company/${req.companyId}/license`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const getTruckGPSAPI = async (req: GetTruckGPSsQueryModel) => {
  const { data } = await ax.get<GetTruckGPSsServerModel>(
    "/property/truck/gps",
    { params: req.query },
  );

  return data.plateList;
};

export const getTruckGarageAPI = async (req: GetTruckGarageQueryModel) => {
  const { data } = await ax.get<GetTruckGarageServerModel[]>(
    "/property/truck/garage",
    { params: req.query },
  );

  return data;
};

export const postTruckCreateAPI = async (req: PostTruckCreateQueryModel) => {
  const { data } = await ax.post("/property/truck", req.body, {
    params: req.query,
  });

  return data;
};

export const getTruckLinkDriverAPI = async (
  req: GetTruckLinkDriverQueryModel,
) => {
  const { data } = await ax.get<GetTruckLinkDriverServerModel>(
    "/property/truck/driver",
    { params: req.query },
  );

  return data;
};

export const putTruckSpecificationAPI = async (
  req: PutTruckSpecificationServerModel,
) => {
  const { data } = await ax.put(
    `/property/truck/${req.truckId}/spec`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const getMemberDetailRegisteredMemberAPI = async (
  req: GetMemberDetailRegisteredMemberQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailRegisteredMemberServerModel>(
    "/setting/member",
    { params: req.query },
  );

  return data;
};

export const putDriverDefaultInfoAPI = async (
  req: PutDriverDefaultInfoQueryModel,
) => {
  const { data } = await ax.put(
    `/property/driver/${req.driverId}/default`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const changeDriverPasswordAPI = async (
  req: ChangeDriverPasswordQueryModel,
) => {
  const { data } = await ax.patch(
    `/property/driver/${req.driverId}/password`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const putDriveAccountInfoAPI = async (
  req: PutDriverAccountInfoQueryModel,
) => {
  const { data } = await ax.put(
    `/property/driver/${req.driverId}/account`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const putTruckDefaultInformationAPI = async (
  req: PutTruckDefaultInformationQueryModel,
) => {
  const { data } = await ax.put(
    `/property/truck/${req.truckId}/default`,
    req.body,
    { params: req.query },
  );

  return data;
};

export const createMemberDetailRegisteredMemberAPI = (
  req: CreateMemberDetailRegisteredMemberQueryModel,
) => ax.post("/setting/member", req.body, { params: req.query });

export const deleteMemberDetailRegisteredMemberAPI = (
  req: DeleteMemberDetailRegisteredMemberQueryModel,
) => ax.delete(`/setting/member/${req.staffId}`, { params: req.query });

export const getMemberDetailRegisteredMemberDetailAPI = async (
  req: GetMemberDetailRegisteredMemberDetailQueryModel,
) => {
  const { data } =
    await ax.get<GetMemberDetailRegisteredMemberDetailServerModel>(
      `/setting/member/${req.staffId}`,
      { params: req.query },
    );

  return data;
};

export const editMemberDetailRegisteredMemberDetailAPI = (
  req: EditMemberDetailRegisteredMemberDetailQueryModel,
) => ax.put(`/setting/member/${req.staffId}`, req.body, { params: req.query });

export const deleteTruckAPI = async (req: DeleteTruckQueryModel) => {
  const { data } = await ax.delete(`/property/truck/${req.truckId}`, {
    params: req.query,
  });

  return data;
};

export const getMemeberDetailLinksAPI = async (
  req: GetMemberLinksQueryModel,
) => {
  const { data } = await ax.get<GetMemberLinksServerModel>("/property/link", {
    params: req.query,
  });

  const linkManageTrucks = await Promise.all(
    data.links.map(async (link) => ({
      ...link,
      drivers: await Promise.all(
        link.drivers.map(async (driver, i) => ({
          ...driver,
          profileUrl:
            i === 0 && driver.profile
              ? await getS3PresignedUrlAPI(
                  addPrefixToUrlIfNeeded(driver.profile, "profile"),
                )
              : driver.profile,
        })),
      ),
    })),
  );

  return { ...data, links: linkManageTrucks };
};

export const postMemberDetailConnectLinkAPI = (
  req: PostMemberDetailConnectLinkQueryModel,
) => ax.post(`/property/link/${req.truckId}`, req.body, { params: req.query });

export const getMemberDetailLinkedDriverSearchAPI = async (
  req: GetMemberDetailLinkedDriverSearchQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailLinkedDriverSearchServerModel>(
    `/property/truck/driver`,
    { params: req.query },
  );

  const drivers = await Promise.all(
    data.map(async (driver) => ({
      ...driver,
      profileUrl: driver.profile
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(driver.profile, "profile"),
          )
        : null,
    })),
  );

  return drivers;
};

export const getMemberDetailTruckDetailAllocationAPI = async (
  req: GetMemberDetailTruckDetailAllocationQueryModel,
) => {
  const { data } = await ax.get<GetMemberDetailTruckDetailAlloctionServerModel>(
    `/property/truck/${req.truckId}/allocation`,
    { params: req.query },
  );

  const allocationList = await Promise.all(
    data.allocationList.map(async (truck) => ({
      ...truck,
      profileUrl: truck.driverProfile
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(truck.driverProfile, "profile"),
          )
        : null,
    })),
  );

  return { ...data, allocationList };
};
