import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { map, upperCase } from "lodash-es";
import dayjs from "dayjs";

import { usePostPlan, usePutPlan } from "services";
import { useModal, useToast } from "hooks";
import { calcHourDiffICTWithUTC0, deleteComma, formatDate } from "utils";
import { HOOKFORM_ERR, MEMBER_COMPANY_TYPE_RADIOS } from "assets";
import { TOAST_MSG } from "constants/index";
import type {
  GetMemberCompanyPlanServerModel,
  PostMemberCompanyPlanForm,
} from "types";

const useMembershipPlanForm = (
  plan?: GetMemberCompanyPlanServerModel["plans"][number],
) => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const memberTypeParams = searchParams.get("memberType")!;

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const { mutate: postPlanMutate, isLoading: isLoadingPostPlan } =
    usePostPlan();
  const { mutate: putPlanMutate, isLoading: isLoadingPutPlan } = usePutPlan();

  const { handleModalClose } = useModal();
  const { addToast } = useToast();

  const formMethod = useForm<PostMemberCompanyPlanForm>({
    mode: "onTouched",
    defaultValues: {
      planType: plan?.planName ?? "STANDARD",
      startDate: plan?.startDate ?? "",
      total: plan?.total ?? "",
      pay: plan?.pay ?? "ACCOUNT_TRANSFER",
      status: plan?.status ?? "COMPLETED",
    },
  });

  const handleChangeDate = (date: dayjs.Dayjs[] | []) => {
    if (!date) {
      setSelectedDates([]);
      formMethod.setError("startDate", {
        type: "required",
        message: HOOKFORM_ERR.SELECT_DATE,
      });
    } else {
      const [startDate, endDate] = map(date, (d) =>
        formatDate(d, "DD/MM/YYYY"),
      );
      const selStartDate = dayjs
        .utc(startDate, "DD/MM/YYYY")
        .subtract(calcHourDiffICTWithUTC0(), "h")
        .toISOString();

      formMethod.setValue("startDate", selStartDate);
      formMethod.clearErrors("startDate");
      setSelectedDates([startDate, endDate ?? startDate]);
    }
  };

  const handleSuccess = (data: PostMemberCompanyPlanForm) => {
    if (!plan) {
      const req = {
        companyId: memberId!,
        query: {
          companyType: upperCase(
            memberTypeParams,
          ) as (typeof MEMBER_COMPANY_TYPE_RADIOS)[number]["key"],
        },
        body: {
          planType: data.planType ?? "STANDARD",
          startDate: data.startDate,
          total: +deleteComma(data.total) ?? 0,
          pay: data.pay,
          status: data.status,
        },
      };

      postPlanMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.PLAN_ADD_SUCCESS);
          handleModalClose();
        },
      });
    } else {
      const req = {
        planId: plan.companyPlanId,
        query: {
          companyType: upperCase(
            memberTypeParams,
          ) as (typeof MEMBER_COMPANY_TYPE_RADIOS)[number]["key"],
        },
        body: {
          planType: data.planType ?? "STANDARD",
          startDate: data.startDate,
          total: +deleteComma(data.total) ?? 0,
          pay: data.pay,
          status: data.status,
        },
      };

      putPlanMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.PLAN_EDIT_SUCCESS);
          handleModalClose();
        },
      });
    }
  };

  const handleError = () => {
    if (!formMethod.watch("startDate")) {
      formMethod.setError("startDate", {
        type: "required",
        message: HOOKFORM_ERR.SELECT_DATE,
      });
    }
  };

  return {
    formMethod,
    isLoadingPostPlan,
    isLoadingPutPlan,
    selectedDates,
    handleChangeDate,
    handleConfirm: formMethod.handleSubmit(handleSuccess, handleError),
  };
};

export default useMembershipPlanForm;
