import { css, Theme } from "@emotion/react";

export const tableOfContent = css`
  margin-top: 40px;

  & > div > section {
    width: 764px;

    &:nth-of-type(5) {
      padding-bottom: 0;
    }
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  display: flex;
  width: fit-content;
  margin: 32px 0 0 auto;
  padding: 9px 19px;
  text-decoration: none;
  background-color: ${theme.color.white};
`;

export const layout = css`
  position: relative;
  width: fit-content;
  min-width: fit-content;
  margin: 0 auto;

  & > h1 {
    margin-bottom: 40px;
  }
`;
