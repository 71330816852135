import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  OrderManagementCargoDetail,
  OrderManagementDropoffInfo,
  OrderManagementAllocationInfo,
  OrderManagementDefaultInfo,
  OrderManagementPayment,
  OrderManagementTruckList,
  OrderManagementTruckingMethods,
  OrderManagementPickupInfo,
  TableOfContent,
  PageLayout,
  PreviousPageLink,
  Button,
  CancelOrderModal,
  InternalLink,
} from "components";
import { useModal } from "hooks";
import { useGetOrderDetail } from "services";
import { MapIcon, OREDER_MANAGEMENT_DETAIL_LAYOUT } from "assets";
import { PATH } from "constants/index";
import * as S from "./OrderManagementDetail.styled";

const OrderManagementDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: orderDetails } = useGetOrderDetail(id!);

  const { handleModalOpen, modalRef } = useModal();

  const hideCancelOrderBtn =
    orderDetails?.status === "CANCELED" ||
    orderDetails?.allocationInfo?.drivers.some(
      (driver) => driver.driverStatus === "COMPLETED",
    );

  const contents = [
    {
      text: "Default information",
      content: (
        <OrderManagementDefaultInfo
          defaultInformation={orderDetails?.defaultInformation}
        />
      ),
    },
    {
      text: "Truck list",
      content: <OrderManagementTruckList trucks={orderDetails?.trucks} />,
    },
    {
      text: "Allocation information",
      content: (
        <OrderManagementAllocationInfo
          allocationInfo={orderDetails?.allocationInfo}
        />
      ),
    },
    {
      text: "Pick-up information",
      content: (
        <OrderManagementPickupInfo pickUpInfo={orderDetails?.pickUpInfo} />
      ),
    },
    {
      text: "Drop-off information",
      content: (
        <OrderManagementDropoffInfo dropOffInfo={orderDetails?.dropOffInfo} />
      ),
    },
    {
      text: `Cargo details`, //TODO: 개수 추가 -> 번역으로 인해 잠시 삭제
      content: <OrderManagementCargoDetail cargoes={orderDetails?.cargoes} />,
    },
    {
      text: "Trucking methods",
      content: (
        <OrderManagementTruckingMethods
          truckingMethods={orderDetails?.truckingMethods}
        />
      ),
    },
    {
      text: "Payment",
      content: <OrderManagementPayment payments={orderDetails?.payments} />,
    },
  ];

  return (
    <PageLayout
      css={S.layout}
      layoutStaticData={OREDER_MANAGEMENT_DETAIL_LAYOUT}
    >
      <TableOfContent
        css={S.tableOfContent}
        contents={contents.map(({ text, content }) => ({
          heading: { headingLevel: "h2", text },
          content,
        }))}
        stickyBottom={
          orderDetails?.status === "COMPLETED" && (
            <InternalLink
              css={S.cargoTracking}
              variant="ghostNoUnderlineGray"
              to={`${PATH.ORDER_MANAGEMENT_MAP}?orderId=${id}`}
            >
              <MapIcon />
              {t("Tracking history")}
            </InternalLink>
          )
        }
      />
      <S.BottomBtns>
        <PreviousPageLink
          css={S.link}
          variant="outlineMedium"
          to={PATH.ORDER_MANAGEMENT}
        >
          {t("Back to list")}
        </PreviousPageLink>
        {!hideCancelOrderBtn && (
          <Button
            variant="errorMedium"
            label="Cancel order"
            handleClickBtn={handleModalOpen(
              <CancelOrderModal ref={modalRef} />,
            )}
          />
        )}
      </S.BottomBtns>
    </PageLayout>
  );
};

export default OrderManagementDetail;
