import React from "react";
import { useTranslation } from "react-i18next";

import { History } from "components";
import type { Histories, Languages } from "types";
import * as S from "./PageLayout.styled";

interface PageLayoutProps {
  className?: string;
  children: React.ReactNode;
  layoutStaticData: {
    heading: Languages;
    subHeading?: Languages;
    histories?: Histories;
  };
}

const PageLayout = ({
  className,
  children,
  layoutStaticData,
}: PageLayoutProps) => {
  const { t } = useTranslation();
  return (
    <S.PageLayout className={className}>
      {layoutStaticData.histories && (
        <History histories={layoutStaticData.histories} />
      )}
      <S.Heading>{t(layoutStaticData.heading)}</S.Heading>
      {layoutStaticData.subHeading && (
        <S.SubHeading>{t(layoutStaticData.subHeading)}</S.SubHeading>
      )}
      {children}
    </S.PageLayout>
  );
};

export default PageLayout;
