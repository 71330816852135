import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import { useMemeberDetailLinks } from "services";
import type { GetMemberLinksQueryModel, TruckingCompanyType } from "types";
import {
  RegisteredManageLinkDriverLink,
  RegisteredManageLinkFilter,
  RegisteredManageLinkPagination,
  RegisteredManageLinkTable,
} from "./container";
import * as S from "./MemberDetailRegisteredManageLink.styled";

const MemberDetailRegisteredManageLink = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const [selectedTruckId, setSelectedTruckId] = useState<string | null>(null);

  const { filters } = useFilterParams<GetMemberLinksQueryModel["query"]>([
    "page",
    "registNumber",
    "driverName",
    "truckTypeId",
  ]);

  const memberType = searchParams.get("memberType");
  const req = {
    query: {
      ...filters,
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data } = useMemeberDetailLinks(req);

  const initSelectedTruckId = (): void => {
    setSelectedTruckId(null);
  };

  const handleSelectTruckId = (id: string) => (): void => {
    setSelectedTruckId((prev) => (prev === id ? "" : id));
  };

  useEffect(() => {
    setSelectedTruckId(null);
  }, [data]);

  return (
    <>
      <RegisteredManageLinkFilter />
      <S.LinkWrapper>
        <div>
          <RegisteredManageLinkTable
            links={data?.links}
            selectedTruckId={selectedTruckId}
            handleRowClick={handleSelectTruckId}
          />
          <RegisteredManageLinkPagination pageInfo={data?.pageInfo} />
        </div>
        <RegisteredManageLinkDriverLink
          selectedTruckId={selectedTruckId}
          initSelectedTruckId={initSelectedTruckId}
        />
      </S.LinkWrapper>
    </>
  );
};

export default MemberDetailRegisteredManageLink;
