import React from "react";
import { FormProvider } from "react-hook-form";

import {
  PageLayout,
  InquiryInfo,
  InquiryAnswerHistory,
  InquiryBtns,
} from "components";
import { useToggle } from "hooks";
import { INQUIRY_DETAIL_LAYOUT } from "assets";
import { useInquiryDetail } from "./hooks";
import * as S from "./InquiryDetail.styled";

const InquiryDetail = () => {
  const [isCreateReply, handleIsCreateReply] = useToggle();
  const { data, formMethod, handleCheckCreateReply, handleCreateReply } =
    useInquiryDetail(handleIsCreateReply);

  return (
    <PageLayout css={S.heading} layoutStaticData={INQUIRY_DETAIL_LAYOUT}>
      <FormProvider {...formMethod}>
        <InquiryInfo data={data} />
        <InquiryAnswerHistory data={data} isCreateReply={isCreateReply} />
        <InquiryBtns
          data={data}
          isCreateReply={isCreateReply}
          handleCheckCreateReply={handleCheckCreateReply}
          handleCreateReply={handleCreateReply}
        />
      </FormProvider>
    </PageLayout>
  );
};

export default InquiryDetail;
