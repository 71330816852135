import React from "react";

import { ConfirmModal } from "components";

interface UnPinNoticeModalProps {
  activeFn: () => void;
}

const UnPinNoticeModal = React.forwardRef(
  (
    { activeFn }: UnPinNoticeModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="Unpin"
        desc={`Are you sure you want to unpin the notice?`}
        activeBtnName="Confirm"
        closeBtnName="Close"
        activeFn={activeFn}
      />
    );
  },
);

UnPinNoticeModal.displayName = "UnPinNoticeModal";

export default UnPinNoticeModal;
