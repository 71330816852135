import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 8px;
  `}
`;

export const content = css`
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;
