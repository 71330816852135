import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import {
  DetailLayout,
  MemberDetailRegisteredGarageForm,
  useMemeberDetailRegisteredGarageForm,
} from "components";
import { useToast } from "hooks";
import { useCreateGarage } from "services";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { TruckingCompanyType } from "types";

const MemberDetailRegisteredGarageCreate = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { formMethod } = useMemeberDetailRegisteredGarageForm({});
  const { addToast } = useToast();
  const { mutate: createGarageMutate } = useCreateGarage();

  const memberType = searchParams.get("memberType");
  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_GARAGE}`;

  const createGarage = formMethod.handleSubmit((data): void => {
    const req = {
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
      body: {
        name: data.name.trim(),
        address: data.garageAddress.address,
        ...(data.garageAddress.addressDetail && {
          addressDetail: data.garageAddress.addressDetail,
        }),
        lng: +data.garageAddress.coord.lng,
        lat: +data.garageAddress.coord.lat,
        capacity: +data.capacity,
      },
    };

    createGarageMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.GARAGE_CREATE);
        navigate(beforeUrl);
      },
      onError: (err) => {
        if (err.response?.data.response === "GARAGE_NAME_EXISTS")
          return addToast(TOAST_MSG.WARNING.GARAGE_NAME_EXISTS);
      },
    });
  });

  return (
    <DetailLayout>
      <FormProvider {...formMethod}>
        <MemberDetailRegisteredGarageForm
          formType="create"
          handleSubmit={createGarage}
        />
      </FormProvider>
    </DetailLayout>
  );
};

export default MemberDetailRegisteredGarageCreate;
