import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "contexts";
import {
  InternalLink,
  PageLayout,
  Tab,
  MemberDetailDefaultInfo,
  MemberDetailRegisteredDriver,
  MemberDetailRegisteredGarage,
  MemberDetailRegisteredManageLink,
  MemberDetailRegisteredMember,
  MemberDetailRegisteredTruck,
} from "components";
import { useCurrentQueryParams } from "hooks";
import { useGetMemberDetail } from "services";
import {
  COMPANY_LOOKUP_TABLE,
  MEMBER_DETAIL_LAYOUT,
  PlusCircleIcon12,
} from "assets";
import { TAB } from "constants/index";
import * as S from "./MemberDetail.styled";

const MemberDetail = () => {
  const { t } = useTranslation();
  const { memberId } = useParams();

  const { auth } = useAuth();
  const memberType = useCurrentQueryParams("memberType")?.memberType[0];
  const tab = useCurrentQueryParams("tab")?.tab[0];

  const { data } = useGetMemberDetail({
    companyId: memberId!,
    query: {
      companyType:
        COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE],
    },
  });

  const tabs =
    auth.adminGrade === "MANAGER"
      ? ([
          {
            key: TAB.MEMBER.DEFAULT_INFO,
            label: "Default information",
            addButtonLabel: "",
          },
          {
            key: TAB.MEMBER.REGISTERED_TRUCK,
            label: "Truck list",
            addButtonLabel: t("New truck"),
          },
          {
            key: TAB.MEMBER.REGISTERED_GARAGE,
            label: "Garage list",
            addButtonLabel: t("New garage"),
          },
          {
            key: TAB.MEMBER.REGISTERED_DRIVER,
            label: "Driver list",
            addButtonLabel: t("New driver"),
          },
          {
            key: TAB.MEMBER.REGISTERED_MANAGELINK,
            label: "Manage link",
            addButtonLabel: "",
          },
          {
            key: TAB.MEMBER.REGISTERED_MEMBER,
            label: "Member",
            addButtonLabel: t("Add member"),
          },
        ] as const)
      : ([
          {
            key: TAB.MEMBER.DEFAULT_INFO,
            label: "Default information",
            addButtonLabel: "",
          },
          {
            key: TAB.MEMBER.REGISTERED_TRUCK,
            label: "Truck list",
            addButtonLabel: t("New truck"),
          },
          {
            key: TAB.MEMBER.REGISTERED_GARAGE,
            label: "Garage list",
            addButtonLabel: t("New garage"),
          },
          {
            key: TAB.MEMBER.REGISTERED_DRIVER,
            label: "Driver list",
            addButtonLabel: t("New driver"),
          },
          {
            key: TAB.MEMBER.REGISTERED_MANAGELINK,
            label: "Manage link",
            addButtonLabel: "",
          },
        ] as const);

  const addButtonLabel = tabs.find(({ key }) => key === tab)?.addButtonLabel;
  const tabKey = tabs.find(({ key }) => key === tab)?.key;

  return (
    <PageLayout css={S.heading} layoutStaticData={MEMBER_DETAIL_LAYOUT}>
      {memberType === "carrier" || data?.type === "FORWARDERCARRIER" ? (
        <Tab tabs={tabs} keepQueries={["memberType"]}>
          <S.Wrapper>
            <Tab.TabList />
            {!!addButtonLabel && (
              <InternalLink
                variant="ghostNoUnderlinePrimary"
                to={`${tabKey}/create?memberType=${memberType}`}
              >
                <PlusCircleIcon12 />
                {addButtonLabel}
              </InternalLink>
            )}
          </S.Wrapper>
          <Tab.TabPanel
            render={{
              defaultInfomation: (
                <MemberDetailDefaultInfo memeberDetailType={data?.type} />
              ),
              registeredTruck: <MemberDetailRegisteredTruck />,
              registeredGarage: <MemberDetailRegisteredGarage />,
              registeredDriver: <MemberDetailRegisteredDriver />,
              registeredManageLink: <MemberDetailRegisteredManageLink />,
              registeredMember: <MemberDetailRegisteredMember />,
            }}
          />
        </Tab>
      ) : (
        <MemberDetailDefaultInfo />
      )}
    </PageLayout>
  );
};

export default MemberDetail;
