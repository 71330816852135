import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PATH } from "constants/index";
import { MyAccount } from "./myAccount";

const Settings = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== PATH.SETTING) return;

    navigate(PATH.SETTING_MYACCOUNT, { replace: true });
  }, [pathname]);

  return <MyAccount />;
};

export default Settings;
