import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div<{ direction: "horizontal" | "vertical" }>`
  ${({ direction }) => css`
    display: grid;
    align-items: ${direction === "horizontal" && "center"};
    row-gap: ${direction === "vertical" && "4px"};
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const Label = styled.label<{ isRequired?: boolean }>`
  ${({ theme, isRequired }) => css`
    ${theme.font.medium_13};
    position: relative;
    color: ${theme.color.gray_70};

    &::after {
      content: ${isRequired ? "'*'" : null};
      margin-left: 4px;
      color: ${theme.color.red_20};
    }
  `}
`;
