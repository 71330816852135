import React from "react";
import { useParams } from "react-router-dom";

import { LabelContentTable } from "components";
import { useGetMemberDetail } from "services";
import { useCurrentQueryParams } from "hooks";
import { COMPANY_LOOKUP_TABLE } from "assets";
import { formatICTDateTime, formatPhoneNum, makeNameString } from "utils";
import * as S from "./UserInfo.styled";

const UserInfo = () => {
  const { memberId } = useParams();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const { data } = useGetMemberDetail({
    companyId: memberId!,
    query: {
      companyType:
        COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE],
    },
  });

  if (!data) return null;

  return (
    <LabelContentTable
      css={S.labelContentTable}
      variant="bg"
      subject="User information"
    >
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Name">
          {makeNameString(
            data.managerLastName,
            data.managerMiddleName,
            data.managerFirstName,
          ) || "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Phone number">
          {formatPhoneNum(data.managerPhone) || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Login ID">
          {data.managerEmail || "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Created date">
          {formatICTDateTime(data.managerCreated) || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default UserInfo;
