import React from "react";

import { useFilterParams } from "hooks";
import { useGetNotices } from "services";
import type { GetNoticesQueryModel } from "types";
import { NoticeFilter, NoticePagination, NoticeTable } from "./containers";

const Notice = () => {
  const { filters } = useFilterParams<GetNoticesQueryModel["query"]>([
    "subject",
    "companyType",
    "page",
  ]);
  const { data, dataUpdatedAt, refetch } = useGetNotices({
    query: {
      ...filters,
      companyType: filters.companyType ? filters.companyType : "ALL",
    },
  });

  if (!data) return null;

  return (
    <>
      <NoticeFilter />
      <NoticeTable
        data={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <NoticePagination pageInfo={data.pageInfo} />
    </>
  );
};

export default Notice;
