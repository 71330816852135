import React, { Fragment } from "react";

import { ExternalLink } from "components";
import { formatOrderNum } from "utils";
import { ArrowIcon } from "assets";
import { PATH } from "constants/index";
import * as S from "./MoreDialog.styled";

interface MoreDialogProps {
  orders: { orderNumber: string; orderId: string }[];
}

const MoreDialog = ({ orders }: MoreDialogProps) => {
  return (
    <S.MoreDialog>
      <ul>
        {orders.map(({ orderId, orderNumber }, idx) => (
          <Fragment key={orderId}>
            {idx > 0 && (
              <li>
                <ExternalLink
                  css={S.goToOrderDetail}
                  variant="ghostNoUnderlineGray"
                  href={`${PATH.ORDER_MANAGEMENT}/${orderId}`}
                >
                  {formatOrderNum(orderNumber)}
                  <ArrowIcon />
                </ExternalLink>
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </S.MoreDialog>
  );
};

MoreDialog.displayName = "MoreDialog";

export default MoreDialog;
