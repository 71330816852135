import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const Layout = styled.section`
  width: fit-content;
  margin-left: 170px;
  padding: 32px 0 30px 40px;
`;

export const button = (theme: Theme) => css`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${theme.color.gray_70};

  & > svg {
    width: 16px;

    path {
      fill: ${theme.color.gray_40};
    }
  }
`;

export const previousPageLink = css`
  width: fit-content;
  margin: 32px 0 0 auto;
`;
