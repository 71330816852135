import React, { useState } from "react";

import { PasswordInput, PasswordHint, SubmitBtn, ErrorMsg } from "components";
import { checkPasswordLengthValidation, checkPasswordValidation } from "utils";
import { HOOKFORM_ERR } from "assets";
import useCreateNewPassword from "./hooks/useCreateNewPassword";
import * as S from "./CreateNewPassword.styled";

const CreateNewPassword = () => {
  const [isLength, setIsLength] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  const { register, watch, errors, clearErrors, handleSubmit } =
    useCreateNewPassword();

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.InputWrapper>
        <PasswordInput
          css={S.accountInput}
          value={watch("newPassword")}
          label="New password"
          id="newPassword"
          type="password"
          register={register("newPassword", {
            onChange: (e) => {
              const { value } = e.target;
              const isCheckLength = checkPasswordLengthValidation(value);
              const isCheckPassword = checkPasswordValidation(value);

              if (watch("confirmPassword") === watch("newPassword")) {
                clearErrors("confirmPassword");
              }

              setIsLength(isCheckLength);
              setIsCheck(isCheckPassword);
            },
          })}
        />
        <S.HintBox>
          <PasswordHint
            isValidCheck={isLength}
            passwordHint="8-16 characters."
          />
          <PasswordHint
            isValidCheck={isCheck}
            passwordHint="At least 2 types (A-Z, a-z, numbers, symbols)."
          />
        </S.HintBox>
        <PasswordInput
          css={S.accountInput}
          value={watch("confirmPassword")}
          label="Confirm password"
          id="confirmPassword"
          type="password"
          register={register("confirmPassword")}
        />
        {(errors?.newPassword?.message || errors?.confirmPassword?.message) && (
          <ErrorMsg errorMsg={HOOKFORM_ERR.MATCH_PASSWORD} /> //TODO: 이전 패스워드와 동일한 경우 에러 메세지 추가
        )}
      </S.InputWrapper>
      <SubmitBtn
        css={S.submitBtn}
        hasError={
          !!Object.keys(errors).length ||
          watch("confirmPassword") !== watch("newPassword")
        }
        label="Done"
      />
    </S.Form>
  );
};

export default CreateNewPassword;
