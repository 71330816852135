import { useRecoilValue } from "recoil";
import { upperFirst } from "lodash-es";

import { clientLanguage } from "stores";
import type { DropdownOptionType, Languages } from "types";

import { truckTypeOptions } from "stores";
import type { TableTruckTypeOption, TrucksTypeOptionServerModel } from "types";

export const formatTrucksTableData = (
  key: "truckType" | "truckOption" | "truckNum",
  data?: TableTruckTypeOption,
): string => {
  if (!data) return "";

  switch (key) {
    case "truckOption":
    case "truckType":
      return data[key] ? data[key].label : "-";
    default:
      return data[key] ? `${data[key]}` : "-";
  }
};

type TruckTypeOptionLanguagesKey =
  | "optionEn"
  | "optionVi"
  | "typeEn"
  | "typeVi";

export const convertLanguagesType = (
  languages: "en" | "vi",
  types: "option" | "type",
): TruckTypeOptionLanguagesKey =>
  `${types}${upperFirst(languages)}` as TruckTypeOptionLanguagesKey;

export const formatTruckNumTypeOption = (
  trucks: Record<string, number> | Record<string, number>[] | undefined,
): TableTruckTypeOption[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const languages = useRecoilValue(clientLanguage);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const recoilTruckTypeOptions = useRecoilValue(truckTypeOptions);

  if (recoilTruckTypeOptions === null) return [];

  return Object.entries(trucks || {})
    .map(([key, truckNum]: [string, number]) => {
      const truckOptionId = +key;
      const matchedType = recoilTruckTypeOptions.filter(
        (type) => type.truckOptionId === truckOptionId,
      );

      return {
        truckType: {
          key: `${matchedType[0].truckTypeId}`,
          label: matchedType[0][
            convertLanguagesType(languages, "type")
          ] as Languages,
        },
        truckOption: {
          key: `${matchedType[0].truckOptionId}`,
          label: matchedType[0][
            convertLanguagesType(languages, "option")
          ] as Languages,
        },
        truckNum,
      };
    })
    .filter(Boolean);
};

export const formatTruckTypeOption = (
  truckOptionId: string,
  languages: "en" | "vi",
  truckTypeOptions: TrucksTypeOptionServerModel[],
) => {
  const matchedType = truckTypeOptions.filter(
    (type) => type.truckOptionId === +truckOptionId!,
  )[0];

  return {
    truckType: {
      key: `${matchedType.truckTypeId}`,
      label: matchedType[convertLanguagesType(languages, "type")] as Languages,
    },
    truckOption: {
      key: `${matchedType.truckOptionId}`,
      label: matchedType[
        convertLanguagesType(languages, "option")
      ] as Languages,
    },
  };
};

export const formatTruckOptions = (
  languages: "en" | "vi",
  truckTypeOptions: TrucksTypeOptionServerModel[],
  truckTypeId: string,
): DropdownOptionType[] => {
  const filteredOptions = truckTypeOptions.filter(
    (option) => option.truckTypeId === +truckTypeId,
  );

  const truckOptions = filteredOptions.map((option) => ({
    key: `${option.truckOptionId}`,
    label: option[convertLanguagesType(languages, "option")] as Languages,
  }));

  return truckOptions;
};

export const formatTruckTypes = (
  languages: "en" | "vi",
  truckTypeOptions: TrucksTypeOptionServerModel[],
): DropdownOptionType[] => {
  const truckTypes = truckTypeOptions.reduce<
    Record<number, { key: string; label: Languages }>
  >((acc, type) => {
    if (!acc[type.truckTypeId]) {
      acc[type.truckTypeId] = {
        key: `${type.truckTypeId}`,
        label: type[convertLanguagesType(languages, "type")] as Languages,
      };
    }

    return acc;
  }, {});

  return Object.values(truckTypes);
};
