import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CheckboxBtn,
  ErrorMsg,
  FormInput,
  InternalLink,
  LabelContentTable,
  PageLayout,
  SunEditor,
} from "components";
import { HOOKFORM_ERR, SUPPORT_NOTICE_CREAET_LAYOUT } from "assets";
import { PATH } from "constants/index";
import { useCreateNotice } from "./hooks";
import * as S from "./SupportNoticeCreate.styled";

const SupportNoticeCreate = () => {
  const { t } = useTranslation();

  const { formMethod, handleIsEmpty, handleClearError, handleCreateNotice } =
    useCreateNotice();

  return (
    <PageLayout css={S.layout} layoutStaticData={SUPPORT_NOTICE_CREAET_LAYOUT}>
      <LabelContentTable variant="empty">
        <LabelContentTable.Row>
          <LabelContentTable.Content isRequired label="Company type">
            <div>
              <S.CheckBoxWrapper>
                <CheckboxBtn
                  css={S.checkbox}
                  shape="rectangle"
                  label="Shipper"
                  isChecked={formMethod.watch("shipper")}
                  register={formMethod.register("shipper")}
                />
                <CheckboxBtn
                  css={S.checkbox}
                  shape="rectangle"
                  label="Forwarder"
                  isChecked={formMethod.watch("forwarder")}
                  register={formMethod.register("forwarder")}
                />
                <CheckboxBtn
                  css={S.checkbox}
                  shape="rectangle"
                  label="Carrier"
                  isChecked={formMethod.watch("carrier")}
                  register={formMethod.register("carrier")}
                />
                <CheckboxBtn
                  css={S.checkbox}
                  shape="rectangle"
                  label="Driver"
                  isChecked={formMethod.watch("driver")}
                  register={formMethod.register("driver")}
                />
              </S.CheckBoxWrapper>
              {formMethod.formState.isSubmitted &&
                !formMethod.watch("shipper") &&
                !formMethod.watch("forwarder") &&
                !formMethod.watch("carrier") &&
                !formMethod.watch("driver") && (
                  <ErrorMsg errorMsg={HOOKFORM_ERR.REQUIRE} />
                )}
              <S.CompanyTypeDesc>
                {t("Please select the service to expose the notice.")}
              </S.CompanyTypeDesc>
            </div>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.content} isRequired label="Subject">
            <FormInput
              css={S.subject}
              placeholder="Enter the subject"
              maxLength={50}
              hasError={!!formMethod.formState.errors.subject?.message}
              errorMsg={formMethod.formState.errors.subject?.message}
              register={formMethod.register("subject", {
                required: HOOKFORM_ERR.REQUIRE,
              })}
            />
            <S.SubjectLength>
              ({formMethod.watch("subject").length}/50)
            </S.SubjectLength>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.contentSunEditor}
            isRequired
            label="Description"
          >
            <SunEditor
              name="description"
              placeholder="Enter the description"
              defaultValue=""
              control={formMethod.control}
              hasError={!!formMethod.formState.errors.description?.message}
              handleIsEmpty={handleIsEmpty}
              handleClearError={handleClearError}
            />
            {!!formMethod.formState.errors.description?.message && (
              <ErrorMsg errorMsg={HOOKFORM_ERR.REQUIRE} />
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.Division />
      <S.BtnsWrapper>
        <InternalLink variant="outlineMedium" to={PATH.SUPPORT_NOTICE_TAB}>
          {t("Cancel")}
        </InternalLink>
        <Button
          variant="primaryMedium"
          isDisabled={Object.keys(formMethod.formState.errors).length > 0}
          label="Done"
          handleClickBtn={handleCreateNotice}
        />
      </S.BtnsWrapper>
    </PageLayout>
  );
};

export default SupportNoticeCreate;
