import React from "react";
import { Controller } from "react-hook-form";

import {
  DetailModal,
  Dropdown,
  ErrorMsg,
  FormRadioBtn,
  ImgZoom,
  LabelContent,
} from "components";
import {
  APPROVE_STATUS_RADIOS,
  HOOKFORM_ERR,
  REASON_FOR_REJECT_DROPDOWN_OPTIONS,
} from "assets";
import useChangeStatusForm from "./hooks/useChangeStatusForm";
import * as S from "./ChangeStatusModal.styled";

interface ChangeStatusModalProps {
  imgs: string[];
}

const ChangeStatusModal = React.forwardRef(
  (
    { imgs }: ChangeStatusModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { formMethod, isLoading, handleConfirm } = useChangeStatusForm();

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        title="Sign-up approval"
        posBtnLabel="Confirm"
        isLoading={isLoading}
        callbackFn={handleConfirm}
      >
        <S.ImgWrapper>
          <ImgZoom imgArr={imgs} />
        </S.ImgWrapper>
        <S.Form>
          <LabelContent
            css={S.labelContent}
            label="Approval status"
            direction="vertical"
            isRequired
          >
            <FormRadioBtn
              css={S.radio}
              radioList={APPROVE_STATUS_RADIOS}
              currentValue={formMethod.watch("status")}
              register={formMethod.register("status", {
                onChange: () => formMethod.resetField("reason"),
              })}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Reason for reject"
            direction="vertical"
            isRequired
          >
            <Controller
              name="reason"
              control={formMethod.control}
              rules={{ required: HOOKFORM_ERR.REQUIRE }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropdown
                  options={REASON_FOR_REJECT_DROPDOWN_OPTIONS}
                  selectedOption={value}
                  placeholder="-Select-"
                  hasError={!!error}
                  disabled={formMethod.watch("status") === "APPROVE"}
                  handleSelect={(status) => {
                    const selectIndex =
                      REASON_FOR_REJECT_DROPDOWN_OPTIONS.findIndex(
                        (item) => item.key === status,
                      );

                    onChange(REASON_FOR_REJECT_DROPDOWN_OPTIONS[selectIndex]);
                  }}
                />
              )}
            />

            {formMethod.formState.errors.reason?.message && (
              <ErrorMsg
                errorMsg={formMethod.formState.errors.reason?.message}
              />
            )}
          </LabelContent>
          <S.Division />
        </S.Form>
      </DetailModal>
    );
  },
);

ChangeStatusModal.displayName = "ChangeStatusModal";

export default ChangeStatusModal;
