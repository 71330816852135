import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useGoogleMap } from "contexts";
import { useToast } from "hooks";
import { rotateSVGIcon } from "utils";
import { TRUCK_ICON } from "assets";
import { TOAST_MSG } from "constants/index";
import { zIndex } from "style";
import type { GetMonitoringTrucksClientModel } from "types";

const updateTruckMarkerIcon = async (
  iconType: keyof typeof TRUCK_ICON,
  truckMarker?: GetMonitoringTrucksClientModel["trucks"][number],
) => {
  if (!truckMarker) return;

  const icon = await rotateSVGIcon({
    svgUrl: TRUCK_ICON[iconType],
    rotation: truckMarker.direction,
  });

  truckMarker.truckMarker?.setOptions({
    icon,
    zIndex:
      iconType === "selected"
        ? zIndex.GOOGLE_MAP_SELECTED_TRUCK_MARKER
        : zIndex.GOOGLE_MAP_DEFAULT_TRUCK_MARKER,
  });
};

const useTruckSelect = (
  truckMarkers: GetMonitoringTrucksClientModel["trucks"],
  truckRef: React.MutableRefObject<{
    [key: string]: HTMLLIElement | null;
  }>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { googleMap } = useGoogleMap();
  const { addToast } = useToast();

  const checkTruckMarkerExist = (
    isSameTruckId: boolean,
    selectedTruckId: string,
  ): void => {
    // NOTE: 리스트에서 클릭한 트럭 카드가, 이전에 선택한 트럭과 다름
    if (!isSameTruckId) {
      const selectedTruck = truckMarkers.find(
        (truck) => truck.truckId === selectedTruckId,
      );
      // NOTE: 리스트에서 클릭한 트럭 카드의 마커가, 현재 지도에 표시되지 않는 경우
      if (!selectedTruck?.truckMarker) {
        addToast(TOAST_MSG.WARNING.CANNOT_FIND_GPS);
      }
    }
  };

  const focusTruckCard = (selectedTruckId: string): void => {
    truckRef.current?.[selectedTruckId]?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const focusTruckMarker = (selectedTruckId: string): void => {
    const selectedTruck = truckMarkers.find(
      (truck) => truck.truckId === selectedTruckId,
    );
    if (!selectedTruck) return;

    const truckPosition = selectedTruck.truckMarker?.getPosition();
    if (truckPosition) {
      googleMap?.panTo(truckPosition);
    }
  };

  const updateTruckMarkerState = async (
    previousTruckId: string | null,
    isSameTruckId: boolean,
    selectedTruckId: string,
  ) => {
    const updateQueryParams = (): void => {
      if (isSameTruckId) {
        searchParams.delete("type");
        searchParams.delete("truckId");
      } else {
        searchParams.set("type", "card");
        searchParams.set("truckId", selectedTruckId);
      }

      setSearchParams(searchParams);
    };

    const updateTruckMarkerIcons = async (): Promise<void> => {
      const previoustTruckMarker = truckMarkers.find(
        (truck) => truck.truckId === previousTruckId,
      );
      const currentTruckMarker = truckMarkers.find(
        (truck) => truck.truckId === selectedTruckId,
      );
      if (isSameTruckId) {
        await updateTruckMarkerIcon("default", currentTruckMarker);
      } else {
        await updateTruckMarkerIcon("selected", currentTruckMarker);
        await updateTruckMarkerIcon("default", previoustTruckMarker);
      }
    };

    updateQueryParams();
    await updateTruckMarkerIcons();
  };

  const handleTruckCardClick =
    (selectedTruckId: string) => async (): Promise<void> => {
      const previousTruckId = searchParams.get("truckId");
      const isSameTruckId = previousTruckId === selectedTruckId;

      updateTruckMarkerState(previousTruckId, isSameTruckId, selectedTruckId);
      checkTruckMarkerExist(isSameTruckId, selectedTruckId);
      focusTruckMarker(selectedTruckId);
    };

  const handleTruckMarkerClick =
    (selectedTruckId: string) => async (): Promise<void> => {
      const previousTruckId = searchParams.get("truckId");
      const isSameTruckId = previousTruckId === selectedTruckId;

      updateTruckMarkerState(previousTruckId, isSameTruckId, selectedTruckId);
      focusTruckCard(selectedTruckId);
    };

  useEffect(() => {
    if (truckMarkers.length === 0) return;

    const selectedTruckId = searchParams.get("truckId");

    if (!selectedTruckId) {
      truckMarkers.forEach(async (truckMarker) => {
        updateTruckMarkerIcon("default", truckMarker);
      });
      return;
    }

    const selectedTruckMarker = truckMarkers.find(
      (truck) => truck.truckId === selectedTruckId,
    );
    if (!selectedTruckMarker) {
      checkTruckMarkerExist(false, selectedTruckId);
      return;
    }

    updateTruckMarkerIcon("selected", selectedTruckMarker);
    focusTruckMarker(selectedTruckId);
    focusTruckCard(selectedTruckId);
  }, [truckMarkers, searchParams]);

  return { handleTruckCardClick, handleTruckMarkerClick };
};

export default useTruckSelect;
