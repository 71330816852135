import { css, Theme } from "@emotion/react";

import { SUPPORT_INQUIRY_TABLE_HEADER_COLUMNS } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${SUPPORT_INQUIRY_TABLE_HEADER_COLUMNS};
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  border-radius: 2px;
  padding: 10px 20px;
  color: ${theme.color.white};
  text-decoration: none;
  background-color: ${theme.color.primary_10};
`;
