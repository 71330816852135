import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { NoResult, QueryPagination } from "components";
import { useGetMonitoringOrderDetail } from "services";
import {
  addrFormatter,
  filterValidCoords,
  foldCardAnimate,
  formatICTDateTime,
  formatPhoneNum,
} from "utils";
import type { GetMonitoringOrdersServerModel } from "types";
import {
  ButtonFoldOrderNumber,
  DriverInfo,
  OrderAllocationInfo,
  OrderInfo,
} from "../card";
import { useOrderSelect } from "../hooks";
import * as S from "./Monitorings.styled";

interface ShippingMonitoringOrdersProps {
  data: GetMonitoringOrdersServerModel;
}

const ShippingMonitoringOrders = ({ data }: ShippingMonitoringOrdersProps) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId")!;

  const { data: orderDetail } = useGetMonitoringOrderDetail(
    {
      orderId,
      query: {
        companyId: id!,
        companyType: searchParams.get("companyType")!,
      },
    },
    !!orderId,
  );

  const { handleOrderCardClick } = useOrderSelect();

  return (
    <>
      <S.MonitoringListWrapper>
        {data.order.length ? (
          data.order.map((order) => {
            const isFold = order.orderId !== orderId;

            return (
              <li key={order.orderId}>
                <ButtonFoldOrderNumber
                  orderNumber={order.orderNumber}
                  isFold={isFold}
                  handleFoldBtnClick={handleOrderCardClick(order.orderId)}
                />
                {!isFold && orderDetail && (
                  <S.TruckDetailInfoWrapper
                    layout
                    initial="closed"
                    animate={isFold ? "closed" : "open"}
                    exit="closed"
                    variants={foldCardAnimate(
                      searchParams.get("type") === "card",
                    )}
                    transition={{ duration: 0.3 }}
                  >
                    <OrderInfo
                      orderInfo={{
                        cargoes: orderDetail.cargoes,
                        puAddr: addrFormatter(
                          orderDetail.puAddr,
                          orderDetail.puAddrDetail,
                        ),
                        doAddr: addrFormatter(
                          orderDetail.doAddr,
                          orderDetail.doAddrDetail,
                        ),
                        loadingStatus: orderDetail.loadingStatus,
                      }}
                    />
                    <DriverInfo
                      driverInfo={{
                        title: "Trucking company",
                        name: orderDetail.truckingCompany ?? "-",
                        phone: formatPhoneNum(orderDetail.truckingCompanyPhone),
                        addr: orderDetail.puEta
                          ? `(ETA) ${formatICTDateTime(orderDetail.puEta)}`
                          : "-",
                      }}
                    />
                    <OrderAllocationInfo
                      orderDetail={{
                        ...orderDetail,
                        locations: [
                          {
                            lat: +orderDetail.puLat,
                            lng: +orderDetail.puLng,
                            type: "PICK",
                          },
                          {
                            lat: +orderDetail.doLat,
                            lng: +orderDetail.doLng,
                            type: "DROP",
                          },
                        ],
                        coords: filterValidCoords(orderDetail.allocationList),
                      }}
                    />
                  </S.TruckDetailInfoWrapper>
                )}
              </li>
            );
          })
        ) : (
          <NoResult />
        )}
      </S.MonitoringListWrapper>
      <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
    </>
  );
};

export default ShippingMonitoringOrders;
