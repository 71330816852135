/* eslint-disable no-useless-escape */

export const deleteHyphen = (value: string): string | undefined => {
  if (!value) return;

  return value.replaceAll("-", "");
};

export const parseFileExtension = (fileType: string): string =>
  `.${fileType.split("/")[1]}`;

export const parseFileExtensionByPoint = (fileType: string): string =>
  `.${fileType.split(".")[1]}`;

export const devideFileExtension = (file: string | File): "PDF" | "Photo" => {
  const fileName = file && (typeof file === "string" ? file : file.name);
  switch (fileName?.split(".")[1]) {
    case "pdf":
      return "PDF";
    default:
      return "Photo";
  }
};

export const parsePresignedUrl = (fileType: string): string => {
  return `.${fileType?.split("/")[1]}`;
};

export const formatVin = (vin: string): string => {
  return vin.replace(/[^a-zA-Z0-9]/, "").toUpperCase();
};

export const formatSearchData = (value: string): string => {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, "");
};

export const formatPlateNumber = (value: string): string => {
  if (!value) return value;

  const regex = /[^a-zA-Z0-9]/g;
  const plateNumber = value.toUpperCase()?.replace(regex, "");

  return plateNumber;
};
