export const checkFirstNum = (v: string | number): string => {
  if (typeof v === "number") v = `${v}`;

  if (v === "0") {
    return v;
  }

  if (typeof v === "string" && v[0] === "0") {
    v = v.slice(1);
  }
  return v;
};

export const numericOnly = (v: string | number): string => {
  const regex = /[^0-9]/g;
  v = checkFirstNum(v);

  return `${v}`.replaceAll(regex, "");
};

export const numericOnlyWithComma = (v: string | number): string => {
  v = numericOnly(v + "");
  const parts = v.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const numericOnlyWithColons = (v: string): string => {
  // eslint-disable-next-line
  const regex = /[^0-9\:]/g;

  return v.replaceAll(regex, "");
};

export const isNumber = (string: string): boolean => {
  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식

  return regExp.test(string);
};

export const onlyNumber = (string: string): string => {
  if (!string) return "";

  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  return string.replace(regExp, "");
};

export const formatPhoneNum = (phoneNum: string | null): string => {
  if (!phoneNum) return "-";

  return phoneNum.replace(/^([0-9]{3})([0-9]*)$/, "$1 $2");
};

export const formatOrderNum = (num: string): string => {
  if (!num) return "";

  return num.replace(/(\S{3})(\S{6})(\S{4})/g, "# $1-$2-$3");
};

// NOTE: toLocaleString을 사용하면 hook-form의 setValueAs에서 적용되지 않음
export const comma = (input: string | number): string =>
  numericOnly(input).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const deleteComma = (value = ""): string => value.replaceAll(",", "");

export const formatWeight = (weight: number): string => `${comma(weight)}kg`;

export const formatQuantity = (quantity: number): String =>
  `${comma(quantity)}Qty`;

export const formatPrice = (price: number): string => `${comma(price)} ₫`;

export const formatterDecimal = (value: string): string => {
  return value.replace(/\.$/, "");
};

export const formatTrimDecimalZero = (number: number): string => {
  const numberStr = number.toString();
  const decimalIndex = numberStr.indexOf(".");

  if (decimalIndex !== -1) {
    const decimalPart = numberStr.slice(decimalIndex + 1);
    const isZero = decimalPart.split("").every((digit) => digit === "0");

    if (isZero) {
      return numberStr.slice(0, decimalIndex);
    }
  }

  return numberStr;
};
