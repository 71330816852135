import React from "react";
import { useTranslation } from "react-i18next";

import { Badge, LabelContent, Tooltip } from "components";
import { findLookupTableLabel } from "utils";
import {
  CARGO_TYPE,
  DropOffPointIcon,
  LOADING_STATUS_RADIOS,
  PACKAGING_TYPE,
  PickUpPointIcon,
} from "assets";
import type { Languages } from "types";
import * as S from "./OrderInfo.styled";

interface OrderInfoProps {
  orderInfo: {
    cargoes: {
      weight: number;
      width: number;
      height: number;
      length: number;
      cargoCnt: number;
      cargoType: (typeof CARGO_TYPE)[number]["key"];
      packagingType: (typeof PACKAGING_TYPE)[number]["key"];
      hazardGoods: 0 | 1;
      doubleLoading: 0 | 1;
    }[];
    puAddr: string;
    doAddr: string;
    loadingStatus: (typeof LOADING_STATUS_RADIOS)[number]["key"];
  };
}

const OrderInfo = ({ orderInfo }: OrderInfoProps) => {
  const { t } = useTranslation();

  const deliveryCargoes = orderInfo.cargoes.map((cargo) => ({
    cargoType:
      findLookupTableLabel(CARGO_TYPE, cargo.cargoType) ?? cargo.cargoType,
    weight: cargo.weight,
    cargoCnt: cargo.cargoCnt,
  }));

  const additionalCargoInfo = deliveryCargoes.slice(1);

  return (
    <S.OrderInfoWrapper>
      <LabelContent css={S.labelcontent} label="Cargo" direction="horizontal">
        <S.CargoBox>
          <Badge
            css={S.badge}
            variant="primary"
            content={orderInfo.loadingStatus}
          />
          <span>
            {t(deliveryCargoes[0].cargoType as Languages)} /{" "}
            {deliveryCargoes[0].weight}kg / {deliveryCargoes[0].cargoCnt}Qty
          </span>
          {!!additionalCargoInfo.length && (
            <Tooltip
              css={S.tooltip}
              tooltipBtnContent={`+${additionalCargoInfo.length}`}
              position="BOTTOM_RIGHT"
              contents={additionalCargoInfo.map((item) => (
                <S.tooltipInner>
                  {t(item.cargoType as Languages)} / {item.weight}kg /{" "}
                  {item.cargoCnt}Qty
                </S.tooltipInner>
              ))}
            />
          )}
        </S.CargoBox>
      </LabelContent>
      <S.Division />
      <LabelContent
        css={S.labelcontent}
        label="Pick-up"
        direction="horizontal"
        icon={<PickUpPointIcon />}
      >
        <span>{orderInfo.puAddr}</span>
      </LabelContent>
      <S.Division />
      <LabelContent
        css={S.labelcontent}
        label="Drop-off"
        direction="horizontal"
        icon={<DropOffPointIcon />}
      >
        <span>{orderInfo.doAddr}</span>
      </LabelContent>
    </S.OrderInfoWrapper>
  );
};

export default OrderInfo;
