import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const heading = (theme: Theme) => css`
  & > h1 {
    ${theme.font.bold_18};
    margin-bottom: 20px;
  }
`;

export const editorImg = css`
  img {
    max-width: 100%;
  }
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 32px;
`;
