import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./ErrorMsg.styled";

interface ErrorMsgProps {
  className?: string;
  errorMsg: string;
}

const ErrorMsg = ({ className, errorMsg }: ErrorMsgProps) => {
  const { t } = useTranslation();

  // NOTE: 대부분의 에러메시지를 가지고 있는 hook-form의 message type이 string이라 일단 as Languages 타입 단언
  return (
    <S.ErrorMsg className={className}>{t(errorMsg as Languages)}</S.ErrorMsg>
  );
};

export default ErrorMsg;
