import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Section = styled.section`
  ${({ theme }) => css`
    min-width: ${theme.size.SECTION_MIN_WIDTH};
    max-width: ${theme.size.SECTION_MAX_WIDTH};
    margin: 0 auto;
    padding: 32px 40px;
  `};
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_24};
    margin-bottom: 25px;
  `}
`;
