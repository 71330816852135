import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const ToolBoxWrapper = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: ${`calc(${theme.size.HEADER_HEIGHT} + ${theme.size.TABLE_HEADER_HEIGHT})`};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border: 1px solid ${theme.color.gray_30};
    border-bottom: 0;
    padding: 0 22px 0 24px;
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.STICKY};
  `}
`;

export const ToolBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`;

export const SelectedCount = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: relative;
    color: ${theme.color.primary_40};

    ::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -16px;
      width: 1px;
      height: 12px;
      background-color: ${theme.color.gray_20};
      transform: translateY(-50%);
    }
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    height: 883px;
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
    border-bottom: 0;
    cursor: default;

    & > thead {
      position: sticky;
      top: ${`calc(${theme.size.HEADER_HEIGHT} + ${theme.size.TABLE_HEADER_HEIGHT})`};
      z-index: ${theme.zIndex.STICKY};

      tr {
        border-top: 1px solid ${theme.color.gray_20};
      }
    }

    tbody > tr:nth-of-type(20) {
      border-bottom: 0;
    }
  `}
`;

const TableRow = styled.tr`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    height: 42px;
    border-bottom: 1px solid ${theme.color.gray_20};

    & > td:nth-last-of-type(2) {
      border-right: 0;
    }
  `}
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.color.white};

    > tr:nth-of-type(20) {
      border-bottom: 0;
    }
  `}
`;

export const HeadRow = styled(TableRow)`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    background-color: ${theme.color.gray_10};

    & > :last-child {
      border-right: 0;
    }
  `}
`;

export const Th = styled.th`
  ${({ theme }) => css`
    display: block;
    border-right: 1px solid ${theme.color.gray_30};
    padding: 0px 12px;
    color: ${theme.color.gray_60};
    text-align: left;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const CheckTh = styled(Th)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const Row = styled(TableRow)`
  & > :last-child {
    border-right: 0;
  }

  :nth-of-type(20) {
    border-bottom: 0;
  }
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: block;
    border-right: 1px solid ${theme.color.gray_30};
    padding: 0px 12px;
    color: ${theme.color.black};
    text-align: left;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & svg {
      margin: 10px 0;
    }
  `}
`;

export const CheckTd = styled(Td)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

interface SelectableRowProps {
  isSelected?: boolean;
}

export const SelectRow = styled(TableRow)<SelectableRowProps>`
  ${({ theme, isSelected }) => css`
    background-color: ${isSelected && theme.color.gray_00};

    &:hover {
      background-color: ${theme.color.gray_00};
    }
  `}
`;

const mixinRowBtn = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
`;

export const RowBtn = styled.button`
  ${mixinRowBtn}
`;

export const RowLink = styled(Link)`
  ${mixinRowBtn}
`;

export const NoData = styled.tr`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;

    & > td {
      ${theme.font.regular_14};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: ${theme.color.gray_60};

      &:last-of-type {
        border-right: 0;
      }

      & > svg {
        width: 52px;
        height: 52px;
        margin-bottom: 8px;
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;

export const CheckTableSkeleton = styled(Table)<{
  gridTemplateColumns: string;
}>`
  ${({ gridTemplateColumns }) => css`
    tr {
      grid-template-columns: ${gridTemplateColumns};
    }
  `}
`;

export const closeIcon = (theme: Theme) => css`
  width: 16px;
  height: 16px;

  & > path {
    fill: ${theme.color.gray_50};
  }
`;
