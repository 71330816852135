import React from "react";
import { keys } from "lodash-es";

import { DataSection, StatusLabel } from "components";
import {
  calculateTotalAmount,
  comma,
  formatICTDateTime,
  formatOrderNum,
} from "utils";
import { SETTLEMNET_TABLE_HEADER } from "assets";
import type { GetSettlementServerModel } from "types";
import * as S from "./SettlementTable.styled";

interface SettlementSectionProps {
  hasRequiredFilter: boolean;
  offers?: GetSettlementServerModel;
  refetch: () => void;
  dataUpdatedAt: number;
}

const SettlementSection = ({
  hasRequiredFilter,
  offers,
  refetch,
  dataUpdatedAt,
}: SettlementSectionProps) => {
  const formatTableData = (
    data: GetSettlementServerModel["offers"][number],
    key: keyof typeof SETTLEMNET_TABLE_HEADER,
  ) => {
    switch (key) {
      case "orderNumber":
        return data[key] ? formatOrderNum(data[key]) : "-";

      case "status":
        return <StatusLabel status={data[key]} />;

      case "price":
        const totalPrice = comma(
          calculateTotalAmount(`${data.price}`, `${data.vat}`),
        );
        return <span>{totalPrice} ₫</span>;

      case "completed":
        return formatICTDateTime(data[key]);

      default:
        return data[key] || "-";
    }
  };

  const handleDownload = () => {};

  return (
    <DataSection
      dataUpdatedAt={hasRequiredFilter ? dataUpdatedAt : null}
      totalData={hasRequiredFilter ? offers?.pageInfo.totalData! : null}
      refetch={refetch}
      // activeBtns={ TODO: 개발 포함 여부 확인필요
      //   <DataSection.ExcelDownloadBtn
      //     isDisabled={!hasRequiredFilter || !offers?.offers.length}
      //     handleDownload={handleDownload}
      //   />
      // }
    >
      <DataSection.Table
        css={S.table}
        title="Settlement"
        columnTable={SETTLEMNET_TABLE_HEADER}
      >
        {hasRequiredFilter ? (
          !!offers?.offers.length &&
          offers?.offers.map((offer) => (
            <DataSection.Table.SelectRowMovePage
              id={offer.offerId}
              key={offer.offerId}
              path={offer.offerId}
            >
              {keys(SETTLEMNET_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    offer,
                    key as keyof typeof SETTLEMNET_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))
        ) : (
          <DataSection.Table.NoData />
        )}
      </DataSection.Table>
    </DataSection>
  );
};

export default SettlementSection;
