import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { loginAPI, logoutAPI } from "api";
import { useAuth } from "contexts";
import { PATH } from "constants/index";
import type { APIError, LoginQueryModel, LoginServerModel } from "types";

export const useLogin = () => {
  const { auth } = useAuth();

  return useMutation<LoginServerModel, APIError, LoginQueryModel>({
    mutationFn: (req) => loginAPI(req),
    onSuccess: (res) => {
      auth.save(res);
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();

  const { auth } = useAuth();

  return useMutation<unknown, APIError>({
    mutationFn: () => logoutAPI(auth.refreshToken),
    onSuccess: () => {
      auth.clear();

      navigate(PATH.ROOT);
    },
  });
};
