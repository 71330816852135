import React from "react";

import { LabelContent, ProfileInfo, StatusLabel } from "components";
import { formatICTDateTime, formatPhoneNum } from "utils";
import { DELIVERY_LOG_LABELS } from "assets";
import type { AllocatedDriverStatus, DeliveryLog } from "types";
import * as S from "./AllocationDriver.styled";

interface AllocationDriverProps {
  profileUrl: string;
  allocationPath?: string;
  name: string;
  phone: string;
  status: AllocatedDriverStatus;
  plateNumber: string;
  deliveryLog: DeliveryLog;
}

const AllocationDriver = ({
  profileUrl,
  allocationPath,
  name,
  phone,
  status,
  plateNumber,
  deliveryLog,
}: AllocationDriverProps) => {
  const hasDeliveryLog = !!Object.values(deliveryLog).filter(Boolean).length;

  return (
    <S.DriverWrapper>
      <ProfileInfo
        profileUrl={profileUrl}
        allocationPath={allocationPath}
        name={name}
        phone={formatPhoneNum(phone)}
      />
      <S.StatusPlateNumber>
        <LabelContent
          css={S.labelContent}
          direction="horizontal"
          label="Status"
        >
          <StatusLabel status={status} />
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          direction="horizontal"
          label="Plate number"
        >
          <S.Gray80Text>{plateNumber}</S.Gray80Text>
        </LabelContent>
      </S.StatusPlateNumber>
      {hasDeliveryLog && (
        <S.DeliveryLogWrapper>
          {Object.entries(deliveryLog).map(
            ([key, time]) =>
              time && (
                <LabelContent
                  key={key}
                  css={S.logLabelContent}
                  direction="horizontal"
                  label={
                    DELIVERY_LOG_LABELS[key as keyof typeof DELIVERY_LOG_LABELS]
                  }
                >
                  <S.Gray80Text>{formatICTDateTime(time)}</S.Gray80Text>
                </LabelContent>
              ),
          )}
        </S.DeliveryLogWrapper>
      )}
    </S.DriverWrapper>
  );
};

export default AllocationDriver;
