import React, { useRef, useState, useId } from "react";
import { useTranslation } from "react-i18next";

import { useKeyTrap, useOnClickOutside } from "hooks";
import { CloseIcon, CloseLightIcon } from "assets";
import type { TooltipPropsType } from "types";
import * as S from "./Tooltip.styled";

const Tooltip = ({
  className,
  title,
  contents,
  tooltipBtnContent,
  position = "BOTTOM_RIGHT",
}: TooltipPropsType) => {
  const { t } = useTranslation();

  const openerRef = useRef(null);
  const tooltipRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const uuid = useId();

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>): void => {
    e?.preventDefault();
    e?.stopPropagation();

    setIsOpen(false);
  };

  useOnClickOutside(tooltipRef, handleClose, openerRef.current);
  useKeyTrap(tooltipRef, handleClose);

  return (
    <S.Root className={className} aria-describedby={uuid}>
      <S.OpenerBtn
        ref={openerRef}
        type="button"
        aria-label={isOpen ? "open tooltip" : "close tooltip"}
        aria-expanded={isOpen}
        aria-controls={uuid}
        onClick={handleToggle}
      >
        {typeof tooltipBtnContent === "string"
          ? tooltipBtnContent
          : React.createElement(tooltipBtnContent)}
      </S.OpenerBtn>
      {isOpen && (
        <S.TooltipBox ref={tooltipRef} id={uuid} position={position}>
          <S.CloseBtn
            type="button"
            aria-label="close tooltip"
            onClick={handleClose}
          >
            <CloseIcon />
          </S.CloseBtn>
          {/* TODO: 기획, 디자인 확정 되면 변경 필요할 수 있음 */}
          {title && <S.Title>{t(title)}</S.Title>}
          <S.Desc>{contents}</S.Desc>
        </S.TooltipBox>
      )}
    </S.Root>
  );
};

export default Tooltip;
