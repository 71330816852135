import React from "react";

import { ConfirmModal } from "components";
import * as S from "./RejectModal.styled";

interface RejectModalProps {
  modalRef: React.RefObject<HTMLDialogElement>;
  isLoading: boolean;
  handleOfferReject: () => void;
}

const RejectModal = ({
  modalRef,
  isLoading,
  handleOfferReject,
}: RejectModalProps) => {
  return (
    <ConfirmModal
      css={S.modal}
      ref={modalRef}
      title="Reject this quotation?"
      desc="You cannot proceed with this quotation after rejecting."
      activeBtnName="Reject"
      closeBtnName="Close"
      isLoading={isLoading}
      activeFn={handleOfferReject}
    />
  );
};

export default RejectModal;
