import React from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { ErrorMsg } from "components";
import type { Languages } from "types";
import * as S from "./FormInput.styled";

export interface FormInputProps {
  className?: string;
  type?: "text" | "password";
  placeholder: Languages;
  maxLength?: number;
  disabled?: boolean;
  register: UseFormRegisterReturn<string>;
  hasError?: boolean;
  errorMsg?: string;
  value?: string;
}

const FormInput = ({
  className,
  type = "text",
  placeholder,
  maxLength,
  disabled,
  register,
  hasError,
  errorMsg,
  value,
}: FormInputProps) => {
  const { t } = useTranslation();

  return (
    <S.FormInput className={className}>
      <S.Input
        type={type}
        placeholder={t(placeholder)}
        maxLength={maxLength}
        disabled={disabled}
        hasError={hasError}
        value={value}
        {...register}
      />
      {errorMsg && <ErrorMsg css={S.errorMsg} errorMsg={errorMsg} />}
    </S.FormInput>
  );
};

export default FormInput;
