import { useState, useRef, useEffect } from "react";

import { useKeyTrap, useOnClickOutside } from "hooks";

const useDropdown = (
  selectedValue: string | null,
  handleSelect?: (value: string) => void,
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
) => {
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickOption = (option: string) => () => {
    if (handleSelect && option !== selectedValue) {
      handleSelect(option);
    }

    handleClose();
  };

  const handleOpener = () => {
    isOpen ? handleClose() : handleOpen();
  };

  useOnClickOutside(dropdownRef, handleClose);
  useKeyTrap(optionsRef, handleClose);

  useEffect(() => {
    if (isOpen) {
      handleFocusCondition && handleFocusCondition();
    } else {
      handleBlurCondition && handleBlurCondition();
    }
  }, [isOpen]);

  return {
    dropdownRef,
    optionsRef,
    isOpen,
    handleOpener,
    handleClickOption,
  };
};

export default useDropdown;
