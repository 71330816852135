import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { getAllQuery, getPrevPageNum, getNextPageNum } from "utils";

const useQueryPagination = (maxPageCount: number, totalPages: number) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) || 1,
  );

  const handlePrevPage = useCallback(() => {
    if (currentPage === 1) return;

    const prevPageNum = getPrevPageNum(currentPage, maxPageCount);

    setSearchParams({
      ...getAllQuery(searchParams),
      page: String(prevPageNum),
    });
  }, [currentPage, searchParams]);

  const handleFirstPage = useCallback(() => {
    if (currentPage === 1) return;

    setSearchParams({
      ...getAllQuery(searchParams),
      page: "1",
    });
  }, [currentPage, searchParams, totalPages]);

  const handleNextPage = useCallback(() => {
    if (currentPage === totalPages) return;

    const nextPageNum = getNextPageNum(currentPage, maxPageCount, totalPages);

    setSearchParams({
      ...getAllQuery(searchParams),
      page: String(nextPageNum),
    });
  }, [currentPage, maxPageCount, searchParams, totalPages]);

  const handleLastPage = useCallback(() => {
    if (currentPage === totalPages) return;

    setSearchParams({
      ...getAllQuery(searchParams),
      page: String(totalPages),
    });
  }, [currentPage, searchParams, totalPages]);

  const handleClickNum = useCallback(
    (idx: number) => () => {
      setSearchParams({
        ...getAllQuery(searchParams),
        page: String(idx),
      });
    },
    [currentPage, searchParams],
  );

  useEffect(() => {
    setCurrentPage(Number(searchParams.get("page")) || 1);
  }, [searchParams.get("page")]);

  return {
    currentPage,
    handlePrevPage,
    handleFirstPage,
    handleNextPage,
    handleLastPage,
    handleClickNum,
  };
};

export default useQueryPagination;
