import { css } from "@emotion/react";

import { theme } from "./themes";

export const mixin_ul = css`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const mixin_li = css`
  display: flex;
  align-items: center;
  column-gap: 9px;
  grid-template-columns: 16px 1fr;

  & > span,
  time {
    ${theme.font.regular_14};
    color: ${theme.color.gray_80};
  }
`;

export const mixin_detail_button = css`
  ${theme.font.bold_15};
  padding: 2px 4px;
  border: 1px solid ${theme.color.gray_30};
  border-radius: 5px;
  color: ${theme.color.gray_50};

  :hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const mixin_tab_navbar = css`
  height: 50px;
  padding: 20px 20px 10px;
  border-bottom: 1px solid ${theme.color.gray_20};
`;

export const mixin_scrollbar = css`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${theme.color.gray_20};
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    margin-right: 2px;
    border-radius: 100px;
    background-color: ${theme.color.gray_40};
  }
`;

export const mixin_horizontal_scrollbar = css`
  overflow-x: overlay;

  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    margin-right: 2px;
    border-radius: 50px;
    background-color: ${theme.color.gray_50_50};
  }
  @supports not (overflow: overlay) {
    scrollbar-width: thin;
    scrollbar-color: ${theme.color.gray_50_50} transparent;
  }
`;

export const mixin_table_horizontal_scrollbar = css`
  ${mixin_horizontal_scrollbar}

  ::-webkit-scrollbar {
    border-top: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
  }
`;

export const mixin_hidden_scroll = css`
  /* overflow css 삭제 */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    /* NOTE: 주석처리 하지 않으면 스크롤이 제대로 나타나지 않습니다 */
    /* display: none; */
  }
`;

export const mixin_overflow_overlay = css`
  overflow-y: overlay;
  @supports not (overflow: overlay) {
    overflow: auto;
  }
`;

export const mixin_list_layout_main_header = css`
  position: sticky;
  top: 0;
  background-color: ${theme.color.white};
`;

export const mixin_list_layout_detail_body = css`
  height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT})`};
  ${mixin_scrollbar};
`;

export const mixin_list_layout_main_body = css`
  height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_MAIN_TAB_HEIGHT})`};
  ${mixin_scrollbar};
`;

export const mixin_hr = css`
  height: 8px;
  border: 0;
  background-color: ${theme.color.gray_20};
`;

export const mixin_limit_one_line = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const mixin_limit_two_line = css`
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const mixin_table_pagination = css`
  position: relative;
  display: flex;
  justify-content: center;
  height: 60px;
  border: 1px solid ${theme.color.gray_30};
  border-top: 0;
  padding: 12px 20px;
  background-color: ${theme.color.white};
`;

export const mixinBtn_ghost_g = css`
  ${theme.font.regular_14};
  color: ${theme.color.gray_60};

  &:not(:disabled):hover {
    color: ${theme.color.gray_80};
  }
`;

export const mixinBtn_ghost_b = css`
  ${theme.font.regular_14};
  color: ${theme.color.primary_40};

  &:not(:disabled):hover {
    color: ${theme.color.primary_50};
  }

  &:disabled {
    color: ${theme.color.gray_60};
  }
`;
