import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LabelContentTable, InternalLink } from "components";
import { comma, formatICTDateTime, formatOrderNum } from "utils";
import type {
  GetMemberDetailTruckDetailServerModel,
  TruckingCompanyType,
} from "types";
import * as S from "./MemberDetailTruckDetailDrivingRecords.styled";

interface MemberDetailTruckDetailDrivingRecordsProps {
  truck: GetMemberDetailTruckDetailServerModel;
}

const MemberDetailTruckDetailDrivingRecords = ({
  truck,
}: MemberDetailTruckDetailDrivingRecordsProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const memberType = searchParams.get("memberType") as TruckingCompanyType;

  return (
    <LabelContentTable variant="underline" css={S.labelContentTable}>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment time">
          {comma(truck.totalShipmentTime || 0)} {t("hours")}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment distance">
          {comma(truck.totalShipmentDistance || 0)} km
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Last shipping on">
          {truck.lastShippingOn
            ? formatICTDateTime(truck.lastShippingOn || "", "DD/MM/YYYY")
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Recent allocation">
          {formatOrderNum(truck.recentAllocation || "") || "-"}
          <InternalLink
            css={S.allocationLinkButton}
            variant="outlineXSmall"
            to={`allocation?memberType=${memberType}`}
          >
            {t("See all")}
          </InternalLink>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Completed orders">
          {truck.progressed}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default MemberDetailTruckDetailDrivingRecords;
