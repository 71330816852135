import React, { useState, useEffect } from "react";

import { DefaultProfileIcon } from "assets";
import DefaultProfileSvg from "assets/icon/ic_defaultProfile.svg";
import * as S from "./Profile.styled";

interface ProfileProps {
  className?: string;
  src?: any;
}

const Profile = ({ className, src }: ProfileProps) => {
  const [isSrcErr, setIsSrcErr] = useState(!!src);
  const handleImgError = (e: any) => {
    e.target.src = DefaultProfileSvg;
    setIsSrcErr(false);
  };

  useEffect(() => {
    if (src) {
      setIsSrcErr(true);
    }
  }, [src]);

  return (
    <S.Root className={className} data-status={isSrcErr}>
      {src ? (
        <img src={src} onError={handleImgError} alt="profile" />
      ) : (
        <DefaultProfileIcon css={S.profileIcon} />
      )}
    </S.Root>
  );
};

export default Profile;
