import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { useGoogleMap } from "contexts";
import { Division, Profile } from "components";
import {
  useAlignCenter,
  useGpsPolylines,
  useLocationMarker,
  useToast,
} from "hooks";
import { clientLanguage } from "stores";
import { addrFormatter, formatICTDateTime } from "utils";
import {
  DropOffPointIcon,
  PhoneIcon,
  PickUpPointIcon,
  SuccessIcon,
  TruckIcon,
} from "assets";
import { TOAST_MSG } from "constants/index";
import type { GetOrderManagementMapClientModel } from "types";
import * as S from "./DeliveryCard.styled";

interface DeliveryCardProps {
  deliveries: GetOrderManagementMapClientModel["delivery"];
  googleMapCenterLatLng: {
    pickUp: { lat: number; lng: number; type: "PICK" };
    dropOff: { lat: number; lng: number; type: "DROP" };
  };
}

const DeliveryCard = ({
  deliveries,
  googleMapCenterLatLng,
}: DeliveryCardProps) => {
  const languages = useRecoilValue(clientLanguage);

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const { addToast } = useToast();
  const { googleMap } = useGoogleMap();
  const { displayLocationMarker } = useLocationMarker("Allocation");

  const { pickUp, dropOff } = googleMapCenterLatLng;

  const handleOrderCardClick = (index: number) => (): void => {
    setSelectedCardIndex(index);

    !deliveries[selectedCardIndex].gpsAvailable &&
      addToast(TOAST_MSG.WARNING.CANNOT_FIND_GPS);
  };

  useGpsPolylines(deliveries[selectedCardIndex].coord);
  useAlignCenter([pickUp, dropOff]);

  useEffect(() => {
    if (!googleMap) return;

    displayLocationMarker([pickUp, dropOff]);
  }, [googleMap]);

  return (
    <>
      {deliveries.map((delivery, i) => (
        <S.AllocationContents
          key={i}
          isSelected={selectedCardIndex === i}
          onClick={handleOrderCardClick(i)}
        >
          <S.DriverInfoWrapper>
            <S.DriverProfileInfo>
              <Profile />
              <span>{delivery.driverName}</span>
              <div>
                <SuccessIcon css={S.checkIcon(!!delivery.gpsAvailable)} />
                <span>GPS</span>
              </div>
            </S.DriverProfileInfo>
            <S.DriverInfo>
              <TruckIcon />
              <span>
                {languages === "en"
                  ? delivery.truckTypeEn
                  : delivery.truckTypeVi}{" "}
                ({delivery.plateNumber})
              </span>
            </S.DriverInfo>
            <S.DriverInfo>
              <PhoneIcon />
              <span>{delivery.phone}</span>
            </S.DriverInfo>
          </S.DriverInfoWrapper>
          <Division />
          <S.AddressWrapper>
            <S.AddressBox>
              <PickUpPointIcon />
              <span>
                {addrFormatter(delivery.puAddr, delivery.puAddrDetail)}
              </span>
              <span>{formatICTDateTime(delivery.puAtd)}</span>
            </S.AddressBox>
            <S.AddressBox>
              <DropOffPointIcon />
              <span>
                {addrFormatter(delivery.doAddr, delivery.doAddrDetail)}
              </span>
              <span>{formatICTDateTime(delivery.doAtd)}</span>
            </S.AddressBox>
          </S.AddressWrapper>
        </S.AllocationContents>
      ))}
    </>
  );
};

export default DeliveryCard;
