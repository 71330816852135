import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface RegisteredMemberPaginationProps {
  pageInfo?: PageInfo;
}

const RegisteredMemberPagination = ({
  pageInfo,
}: RegisteredMemberPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default RegisteredMemberPagination;
