import React from "react";
import { useTranslation } from "react-i18next";

import { upperCaseToUpperFirst } from "utils";
import type { Languages, Status } from "types";
import * as S from "./StatusLabel.styled";

interface StatusLabelProps {
  className?: string;
  status: Status;
}

const StatusLabel = ({ className, status }: StatusLabelProps) => {
  const { t } = useTranslation();

  const formatStatus = upperCaseToUpperFirst(status) as Languages;

  // TODO: 리팩토링 필요
  switch (status) {
    case "APPROVED":
    case "COMPLETED":
    case "ANSWERED":
    case "ALLOCATED":
    case "AVAILABLE":
      return <S.GrayLabel className={className}>{t(formatStatus)}</S.GrayLabel>;

    case "SHIPPING":
    case "ONGOING":
      return <S.BlueLabel className={className}>{t(formatStatus)}</S.BlueLabel>;

    case "ALLOCATION":
    case "WAITING":
    case "PENDING":
    case "REQUESTED":
      return (
        <S.OrangeLabel className={className}>{t(formatStatus)}</S.OrangeLabel>
      );

    case "REJECTED":
    case "CANCELED":
    case "EMPTY_TRUCK":
    case "UNAVAILABLE":
      return <S.RedLabel className={className}>{t(formatStatus)}</S.RedLabel>;

    case "ACCEPTED":
      return <S.BlueLabel>{t("Selected")}</S.BlueLabel>;

    case "CANCELED":
    case "CANCELLED": //NOTE: 서버에 수정요청드렸으나, db 손봐야해서 수정이 어렵다고 전달받아 추가
    case "REJECTED_BY_SENDER":
      return <S.RedLabel className={className}>{t("Canceled")}</S.RedLabel>;

    case "REJECTED_BY_RECEIVER":
      return <S.RedLabel className={className}>{t("Rejected")}</S.RedLabel>;

    case "TO_DROPOFF":
    case "TO_PICKUP":
      return <S.BlueLabel className={className}>{t("Shipping")}</S.BlueLabel>;

    default:
      return <div>-</div>;
  }
};

export default StatusLabel;
