import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useToast } from "hooks";
import { usePostNotice } from "services";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { CreateNoticeForm } from "types";

const useCreatNotice = () => {
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { mutate: createNoticeMutate } = usePostNotice();

  const formMethod = useForm<CreateNoticeForm>({
    mode: "onTouched",
    defaultValues: {
      shipper: false,
      forwarder: false,
      carrier: false,
      driver: false,
      subject: "",
      description: "",
    },
  });

  const handleIsEmpty = (count: number, description?: string) => {
    if (count === 0 && description === "<p><br></p>") {
      formMethod.setError("description", {
        type: "required",
        message: "",
      });
    }
  };

  const handleClearError = (data: string) => {
    const regex = /(<([^>]+)>)/gi;
    const newData = data;
    const result = newData.replace(regex, (match, tag) => {
      if (tag.startsWith("<img")) {
        return tag;
      }
      return "";
    });

    if (result.length !== 0) {
      formMethod.clearErrors("description");
    }
  };

  const handleCreateNotice = () => {
    const createNotice = (data: CreateNoticeForm) => {
      if (!data.carrier && !data.driver && !data.forwarder && !data.shipper)
        return;

      createNoticeMutate(
        {
          body: {
            shipper: data.shipper ? 1 : 0,
            forwarder: data.forwarder ? 1 : 0,
            carrier: data.carrier ? 1 : 0,
            driver: data.driver ? 1 : 0,
            subject: data.subject,
            description: data.description,
          },
        },
        {
          onSuccess: (data) => {
            navigate(
              `${PATH.SUPPORT}/${data.noticeId}?tab=${TAB.SUPPORT.NOTICE}`,
            );
            addToast(TOAST_MSG.SUCCESS.NOTICE_ADD_SUCCESS);
          },
        },
      );
    };

    formMethod.handleSubmit(createNotice)();
  };

  return {
    formMethod,
    handleIsEmpty,
    handleClearError,
    handleCreateNotice,
  };
};

export default useCreatNotice;
