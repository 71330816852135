import React from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import {
  LabelContentTable,
  FormInput,
  LabelContent,
  ErrorMsg,
  MyAccountButtons,
} from "components";
import { useToggle } from "hooks";
import {
  findLookupTableLabel,
  formatICTDateTime,
  formatPhoneNum,
  makeNameString,
  numericOnly,
} from "utils";
import { GRADE_TYPE_RADIOS } from "assets";
import { VALID_MSG } from "constants/index";
import { useMyAccount } from "./hooks";
import * as S from "./MyAccountDefaultInfo.styled";

const MyAccountDefaultInfo = () => {
  const { t } = useTranslation();

  const [isEdit, handleChangeEdit] = useToggle();
  const { formMethod, myAccount, isLoadingChangeMyAccount, changeMyAccount } =
    useMyAccount(isEdit, handleChangeEdit);

  const myAccountLabel =
    myAccount && findLookupTableLabel(GRADE_TYPE_RADIOS, myAccount.grade);

  if (!myAccount) return null;

  return (
    <div>
      <FormProvider {...formMethod}>
        <S.Title>{t("Default information")}</S.Title>
        <LabelContentTable variant="bg">
          {/* TODO: whiteBg로 변경 예정 */}
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Name">
              {isEdit ? (
                <div>
                  <S.NameWrapper
                    hasError={
                      !!formMethod.formState.errors.lastName?.message ||
                      !!formMethod.formState.errors.firstName?.message
                    }
                  >
                    <LabelContent
                      isRequired
                      label="Last name"
                      direction="vertical"
                    >
                      <FormInput
                        placeholder="Last name"
                        maxLength={50}
                        hasError={
                          !!formMethod.formState.errors.lastName?.message
                        }
                        register={formMethod.register("lastName")}
                      />
                    </LabelContent>
                    <LabelContent label="Middle name" direction="vertical">
                      <FormInput
                        placeholder="Middle name"
                        maxLength={50}
                        register={formMethod.register("middleName")}
                      />
                    </LabelContent>
                    <LabelContent
                      isRequired
                      label="First name"
                      direction="vertical"
                    >
                      <FormInput
                        placeholder="First name"
                        maxLength={50}
                        hasError={
                          !!formMethod.formState.errors.firstName?.message
                        }
                        register={formMethod.register("firstName")}
                      />
                    </LabelContent>
                  </S.NameWrapper>
                  {(formMethod.formState.errors.lastName?.message ||
                    formMethod.formState.errors.firstName?.message) && (
                    <ErrorMsg css={S.errorMsg} errorMsg={VALID_MSG.REQUIRED} />
                  )}
                </div>
              ) : (
                makeNameString(
                  myAccount.lastName,
                  myAccount.middleName,
                  myAccount.firstName,
                )
              )}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Grade">
              {myAccount && myAccountLabel ? t(myAccountLabel) : "-"}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Phone number">
              {isEdit ? (
                <LabelContent
                  isRequired
                  label="Phone number"
                  direction="vertical"
                >
                  <FormInput
                    css={S.phoneFormInput}
                    placeholder="Phone number"
                    value={numericOnly(formMethod.watch("phone"))}
                    maxLength={15}
                    hasError={!!formMethod.formState.errors.phone?.message}
                    errorMsg={
                      formMethod.formState.errors.phone?.message as string
                    } //TODO:타입 수정 필요
                    register={formMethod.register("phone")}
                  />
                </LabelContent>
              ) : (
                formatPhoneNum(myAccount.phone)
              )}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Created date">
              {myAccount && formatICTDateTime(myAccount.created, "DD/MM/YYYY")}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Last access">
              {myAccount &&
                formatICTDateTime(myAccount.lastAccess, "DD/MM/YYYY")}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </LabelContentTable>
        <MyAccountButtons
          isEdit={isEdit}
          isLoadingChangeMyAccount={isLoadingChangeMyAccount}
          handleChangeEdit={handleChangeEdit}
          handleSubmit={formMethod.handleSubmit(changeMyAccount)}
        />
      </FormProvider>
    </div>
  );
};

export default MyAccountDefaultInfo;
