import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TruckStatusInfoWrapper = styled.div<{ hasBorderBottom: boolean }>`
  ${({ theme, hasBorderBottom }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-bottom: ${hasBorderBottom && `1px solid ${theme.color.gray_30}`};
    padding: 20px;
    background-color: ${theme.color.gray_00};

    & > div > div > label {
      ${theme.font.medium_14};
    }
  `}
`;
export const labelContent = css`
  display: grid;
  grid-template-columns: 120px 1fr;
  column-gap: 8px;
`;

export const addrLabelContent = css`
  ${labelContent};
  align-items: unset;
`;

export const TruckStatusInfoContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_80};
  `}
`;
