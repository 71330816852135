import { ax } from "api/axios";
import type {
  GetQuotationDetailServerModel,
  GetQuotationQueryModel,
  GetQuotationServerModel,
  PostSendtoShipperQueryModel,
} from "types";

export const getQuotationsAPI = async (req: GetQuotationQueryModel) => {
  const { data } = await ax.get<GetQuotationServerModel>("/offer", {
    params: req.query,
  });

  return data;
};

export const getQuotationDetailAPI = async (orderId: string) => {
  const { data } = await ax.get<GetQuotationDetailServerModel>(
    `/offer/detail/${orderId}`,
  );

  return data;
};

export const postSendtoShipperAPI = async (
  req: PostSendtoShipperQueryModel,
) => {
  const { data } = await ax.post(`/offer/select/${req.offerId}`, req.body);

  return data;
};

export const patchQuotationRejectAPI = async (offerId: string) => {
  const { data } = await ax.patch(`/offer/refuse/${offerId}`);

  return data;
};
