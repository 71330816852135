/* eslint-disable no-useless-escape */
export const checkEmailValidation = (data: string): boolean => {
  if (!data) {
    return false;
  }

  const regExp = RegExp(
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@([\w-])+(\.[a-zA-Z]{2,4}){1,2}$/,
  );
  return regExp.test(data);
};

export const checkPasswordValidation = (password: string): boolean => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 체크
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // 소문자 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckThreeOfTwo =
    checkList.filter((element) => true === element).length >= 2;
  // 4개 중 2개가 true 이면 true

  return boolenCheckThreeOfTwo;
};

export const checkNumberValidate = (number: string): string => {
  const check = /[^0-9]/g;

  return (number = number.replace(check, ""));
};

export const checkPhoneNumberValidation = (phoneNumber: string): boolean => {
  const regExp = /^\d{3}-\d{3,4}-\d{4}$/;

  return regExp.test(phoneNumber);
};

export const checkTelephoneNumberValidation = (
  telephoneNumber: string,
): boolean => {
  const regExp = /^\d{2,3}-\d{3,4}-\d{4}$/;

  return regExp.test(telephoneNumber);
};

export const checkUrlValidation = (data: string): boolean => {
  const regExp = RegExp(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
  );

  return regExp.test(data);
};

export const checkPasswordLengthValidation = (password: string): boolean => {
  const checkLength = /^.{8,16}$/; //  길이 체크

  return checkLength.test(password);
};

export const checkTwoDecimalDigit = (value: string): boolean => {
  const regExp = /^(\d+(\.\d{0,2})?)$/;

  return regExp.test(value);
};

const CARGO_SIZE_LIMIT_VALUE = 1000;

export const checkCargoSizeLimit = (cargoSize: string): boolean =>
  +cargoSize < CARGO_SIZE_LIMIT_VALUE;
