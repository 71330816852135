import { useEffect, useState } from "react";

const useSearchInputWithoutQuery = <T>(
  originDatas: T[] | undefined,
  filteredKey: keyof T,
) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredDatas, setFilteredDatas] = useState<T[]>([]);

  const handleSearch = (search: string) => {
    if (!originDatas) return;

    setSearchValue(search);
    const filteredValue = originDatas.filter((data) =>
      (data[filteredKey] as unknown as string)
        .toLowerCase()
        .includes(search.toLowerCase()),
    );
    setFilteredDatas(filteredValue);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length > 100) return;
    setSearchValue(value);
  };

  const handleResetInput = () => {
    if (!originDatas) return;

    setSearchValue("");
    setFilteredDatas(originDatas);
  };

  useEffect(() => {
    if (!originDatas) return;

    setFilteredDatas(originDatas);
    return () => {
      setSearchValue("");
    };
  }, [originDatas]);

  return {
    searchValue,
    filteredDatas,
    handleSearch,
    handleChangeInput,
    handleResetInput,
  };
};

export default useSearchInputWithoutQuery;
