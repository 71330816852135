import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.black};
    margin: 20px 0 8px;
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    & > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_70};
    }
  `}
`;

export const GoBackBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.bold_15};
    padding: 8px 14px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 5px;
    color: ${theme.color.gray_60};
  `}
`;
