import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast, useToggle } from "hooks";
import {
  useDeleteLinkDriverTruck,
  useGetDriverDetail,
  useGetLinkDriverTrucks,
  usePostLinkDriverTruck,
  usePutDriverDefaultInfo,
} from "services";
import { makeS3PresignedUrlFileUuids } from "api";
import { SCHEMA } from "utils";
import { TOAST_MSG, VALID_MSG } from "constants/index";
import type {
  AutoCompleteDropdown,
  DriverDefaultInfoForm,
  PropertyStatus,
  S3FileUploadType,
  TruckingCompanyType,
} from "types";

const initForm = {
  status: "AVAILABLE" as PropertyStatus,
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  linked: { name: "", id: "" },
  memo: "",
  license: null,
  editLinkState: false,
};

const schema = yup.object({
  status: SCHEMA.STATUS_AVAILABLE,
  lastName: SCHEMA.REQUIRED_STRING,
  middleName: SCHEMA.OPTIONAL_STRING,
  firstName: SCHEMA.REQUIRED_STRING,
  phone: SCHEMA.REQUIRED_STRING,
  linked: SCHEMA.SINGLE_AUTOCOMPLETE,
  memo: SCHEMA.OPTIONAL_STRING.test(
    "maxLength",
    VALID_MSG.INVALID_MEMO_200,
    (value) => (value?.length ?? 0) <= 200,
  ),
  license: SCHEMA.FILELIST.test(
    "required",
    VALID_MSG.REQUIRED,
    (value) => !!value,
  ),
  editLinkState: SCHEMA.CHECK_BOOLEAN,
});

const useMemberDetailRegisteredDriverDefaultInfo = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();

  const formMethod = useForm<DriverDefaultInfoForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const memberType = searchParams.get("memberType");
  const query = {
    companyId: memberId!,
    companyType: (memberType === "forwarder"
      ? "forwardercarrier"
      : memberType) as TruckingCompanyType,
  };

  const { data } = useGetDriverDetail(
    { driverId: driverId!, query },
    !!driverId,
  );
  const { data: linkDriverTrucks } = useGetLinkDriverTrucks(
    { query },
    formMethod.watch("editLinkState"),
  );
  const { mutate: postLinkDriverTruck } = usePostLinkDriverTruck();
  const { mutate: putDriverDefaultInfo } = usePutDriverDefaultInfo();
  const { mutate: deleteLinkDriverTruck } = useDeleteLinkDriverTruck();

  const [isEdit, handleIsEdit] = useToggle();
  const { addToast } = useToast();

  const formatLinkTrucks = linkDriverTrucks?.map((truck) => ({
    name: truck.registNumber,
    id: truck.truckId,
  }));

  const handleDeleteFile = (name: keyof DriverDefaultInfoForm) => (): void => {
    formMethod.resetField(name);
  };

  const handleSubmit = formMethod.handleSubmit(async (data): Promise<void> => {
    const license = data.license;

    const files: S3FileUploadType = {
      ...(license !== null &&
        typeof license !== "string" && {
          license: { file: license[0], path: "license" },
        }),
    };

    const fileUuids = await makeS3PresignedUrlFileUuids(files);

    const req = {
      driverId: driverId!,
      query,
      body: {
        lastName: data.lastName.trim(),
        middleName: data.middleName.trim() ?? "",
        firstName: data.firstName.trim(),
        status: data.status,
        memo: data.memo.trim() ?? "",
        phone: data.phone,
        license: fileUuids.license,
      },
    };

    putDriverDefaultInfo(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.DRIVER_LINK_UPDATE_DONE);
        handleIsEdit();
      },
    });
  });

  const handleEditBtn = (): void => {
    handleIsEdit();
    formMethod.setValue("editLinkState", false);
  };

  const handleLinkState = (): void => {
    formMethod.setValue("editLinkState", !formMethod.watch("editLinkState"));

    if (formMethod.watch("editLinkState")) {
      if (!linkDriverTrucks) return;

      const currentLinkedTruck = formatLinkTrucks?.find(
        (truck) => truck.name === data?.linkedRegistNumber,
      );

      formMethod.setValue("linked", currentLinkedTruck ?? { id: "", name: "" });
    }
  };

  const handleSelectLink = (linkDriver: AutoCompleteDropdown): void => {
    formMethod.setValue("linked", linkDriver);

    if (!formMethod.watch("linked").id && linkDriver.name) {
      formMethod.setError(`linked`, {
        message: VALID_MSG.PLATE_NUMBER,
      });
      return;
    }

    if (formMethod.watch("linked").id || !linkDriver.name) {
      formMethod.clearErrors(`linked`);
      return;
    }
  };

  const handleUnlinkAll = (): void => {
    deleteLinkDriverTruck(
      { driverId: driverId!, query },
      {
        onSuccess: () => addToast(TOAST_MSG.SUCCESS.DRIVER_LINK_UPDATE_DONE),
      },
    );
  };

  const handleUpdateLink = (): void => {
    if (data?.linkedRegistNumber === formMethod.watch("linked.name"))
      return formMethod.setValue("editLinkState", false);

    if (!formMethod.watch("linked.name")) {
      handleUnlinkAll();
      formMethod.setValue("editLinkState", false);
    } else {
      const req = {
        driverId: driverId!,
        query,
        body: { truckId: formMethod.watch("linked.id") },
      };

      postLinkDriverTruck(req, {
        onSuccess: () => {
          formMethod.setValue("editLinkState", false);
          addToast(TOAST_MSG.SUCCESS.DRIVER_LINK_UPDATE_DONE);
        },
        onError: (err) => {
          console.log(err); //TODO: 에러코드 확인이 필요해 확인 요청 상태 -> 추후 잘못된 truckId 입력 시 에러 추가 필요
        },
      });
    }
  };

  useEffect(() => {
    if (!data) return;

    formMethod.reset(
      {
        status: data.status ?? "AVAILABLE",
        lastName: data.lastName ?? "",
        middleName: data.middleName ?? "",
        firstName: data.firstName ?? "",
        phone: data.phone ?? "",
        linked:
          formatLinkTrucks && formMethod.watch("editLinkState")
            ? formatLinkTrucks.find(
                (truck) => truck.name === data.linkedRegistNumber,
              )
            : { id: "", name: "" },
        memo: data.memo ?? "",
        license: data.license || null,
        editLinkState: linkDriverTrucks && false,
      },
      { keepDefaultValues: true },
    );
  }, [data, linkDriverTrucks, isEdit]);

  return {
    isEdit,
    data,
    formMethod,
    formatLinkTrucks,
    handleDeleteFile,
    handleSubmit,
    handleLinkState,
    handleEditBtn,
    handleUpdateLink,
    handleSelectLink,
    handleUnlinkAll,
  };
};

export default useMemberDetailRegisteredDriverDefaultInfo;
