import React from "react";

import { Filter } from "components";
import {
  SUPPORT_COMPANY_TYPE_RADIOS,
  SUPPORT_INQUIRY_CATEGORY_RADIOS,
} from "assets";

const InquiryFilter = () => {
  const searchLabels = [
    {
      queryKey: "category",
      label: "Category",
      options: SUPPORT_INQUIRY_CATEGORY_RADIOS,
    },
    {
      queryKey: "companyType",
      label: "Company type",
      options: SUPPORT_COMPANY_TYPE_RADIOS,
    },
  ] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Category">
          <Filter.Radio
            queryKey="category"
            radios={SUPPORT_INQUIRY_CATEGORY_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row>
        <Filter.Condtion conditionName="Company type">
          <Filter.Radio
            queryKey="companyType"
            radios={SUPPORT_COMPANY_TYPE_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default InquiryFilter;
