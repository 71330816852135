import React from "react";

import { LabelContent } from "components";
import type { LatLngWithNull, TruckStatus } from "types";
import * as S from "./TruckStatusInfo.styled";

interface TruckStatusInfoProps {
  truckStatus: TruckStatus;
  truckInfo: {
    addr: string;
    speed: number;
    temperature: number;
  } & LatLngWithNull;
}

const TruckStatusInfo = ({ truckStatus, truckInfo }: TruckStatusInfoProps) => {
  return (
    <S.TruckStatusInfoWrapper hasBorderBottom={truckStatus === "WAITING"}>
      <LabelContent
        css={S.labelContent}
        label="Location"
        direction="horizontal"
      >
        <S.TruckStatusInfoContent>{`${truckInfo.lat ?? "-"}, ${
          truckInfo.lng ?? "-"
        }`}</S.TruckStatusInfoContent>
      </LabelContent>
      <LabelContent
        css={S.addrLabelContent}
        label="Address"
        direction="horizontal"
      >
        <S.TruckStatusInfoContent>
          {truckInfo.addr ?? "-"}
        </S.TruckStatusInfoContent>
      </LabelContent>
      <LabelContent css={S.labelContent} label="Speed" direction="horizontal">
        <S.TruckStatusInfoContent>
          {truckInfo.speed ?? 0} km
        </S.TruckStatusInfoContent>
      </LabelContent>
      <LabelContent
        css={S.labelContent}
        label="Temperature"
        direction="horizontal"
      >
        <S.TruckStatusInfoContent>
          {truckInfo.temperature ?? 0}°C/NA
        </S.TruckStatusInfoContent>
      </LabelContent>
    </S.TruckStatusInfoWrapper>
  );
};

export default TruckStatusInfo;
