import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AutoCompletes, LinkDialogBtn, Profile } from "components";
import { useMemberType, useToast } from "hooks";
import {
  useGetLinkTruckDriver,
  usePostMemberDetailConnectLink,
} from "services";
import { TOAST_MSG, VALID_MSG } from "constants/index";
import type { AutoCompleteDropdown } from "types";
import * as S from "./LinkingDriver.styled";

interface LinkingDriverProps {
  isEditDisabled: boolean;
  linkedDrivers: {
    driverId: string;
    name: string;
    profileS3Key: string | null;
    profileUrl: string | null;
  }[];
}

const LinkingDriver = ({
  isEditDisabled,
  linkedDrivers,
}: LinkingDriverProps) => {
  const { t } = useTranslation();

  const { memberId, truckId } = useParams();

  const [linkType, setIsLinkType] = useState<"edit" | null>(null);

  const { addToast } = useToast();
  const { memberType } = useMemberType();

  const query = { companyId: memberId!, companyType: memberType };

  const { data: linkableDrivers } = useGetLinkTruckDriver({ query });
  const { mutate: connectLinkMutate } = usePostMemberDetailConnectLink();

  const formMethod = useForm<{
    autoCompletes: AutoCompleteDropdown[];
  }>({ defaultValues: { autoCompletes: [{ name: "", id: "" }] } });
  const { fields, append, remove, update } = useFieldArray({
    control: formMethod.control,
    name: "autoCompletes",
  });

  const handleAddLinkClick = (): void => {
    setIsLinkType("edit");
  };

  const handleEditClick = (): void => {
    setIsLinkType("edit");
  };

  const handleUpdateClick = formMethod.handleSubmit((data) => {
    if (data.autoCompletes.filter(({ id }) => id).length === 0) return;

    connectLinkMutate(
      {
        truckId: truckId!,
        query,
        body: {
          driverIds: data.autoCompletes.map((link) => link.id).filter(Boolean),
        },
      },
      {
        onSuccess: () => {
          setIsLinkType(null);
          addToast(TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
        },
        onError: (err) => {
          if (err.response?.data.response === "DRIVER_ALREADY_LINKED") {
            addToast(TOAST_MSG.WARNING.DRIVER_ALREADY_LINK);
            return;
          }

          addToast(TOAST_MSG.WARNING.BAD_REQUEST);
        },
      },
    );
  });

  const handleUnlinkClick = (): void => {
    connectLinkMutate(
      { truckId: truckId!, query, body: { driverIds: [] } },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
        },
        onError: () => {
          addToast(TOAST_MSG.WARNING.BAD_REQUEST);
        },
      },
    );
  };

  const handleLinkSelect =
    (index: number) =>
    (selectedDropdown: AutoCompleteDropdown): void => {
      update(index, selectedDropdown);

      if (
        !formMethod.watch("autoCompletes")[index].id &&
        selectedDropdown.name
      ) {
        formMethod.setError(`autoCompletes.${index}`, {
          message: VALID_MSG.DRIVER_NAME,
        });
        return;
      }

      if (
        formMethod.watch("autoCompletes")[index].id ||
        !selectedDropdown.name
      ) {
        formMethod.clearErrors(`autoCompletes.${index}`);
        return;
      }
    };

  const handleLinkSlotAdd = (): void => {
    append({ name: "", id: "" }, { shouldFocus: false });
  };

  const handleLinkSlotDelete = (index: number) => (): void => {
    remove(index);
  };

  const handleCancelClick = (): void => {
    setIsLinkType(null);

    formMethod.reset({
      autoCompletes:
        linkedDrivers.length === 0
          ? [{ id: "", name: "" }]
          : linkedDrivers.map(({ driverId, name }) => ({
              id: driverId,
              name: name,
            })),
    });
  };

  useEffect(() => {
    formMethod.reset({
      autoCompletes:
        linkedDrivers.length === 0
          ? [{ id: "", name: "" }]
          : linkedDrivers.map(({ driverId, name }) => ({
              id: driverId,
              name: name,
            })),
    });
  }, [linkedDrivers]);

  return (
    <FormProvider {...formMethod}>
      {linkType === null ? (
        linkedDrivers.length > 0 ? (
          <S.DriverListWrapper>
            {linkedDrivers.map((driver, i) => (
              <S.ProfileList key={i}>
                <S.ProfileWrapper>
                  <Profile css={S.profile} src={driver.profileUrl} />
                  <S.DriverName>{driver.name}</S.DriverName>
                </S.ProfileWrapper>
                {!isEditDisabled && i === 0 && (
                  <LinkDialogBtn
                    handleLinkState={handleEditClick}
                    handleUnlinkAll={handleUnlinkClick}
                  />
                )}
              </S.ProfileList>
            ))}
          </S.DriverListWrapper>
        ) : isEditDisabled ? (
          "-"
        ) : (
          <S.AddBtn type="button" onClick={handleAddLinkClick}>
            {`+ ${t("Add a link")}`}
          </S.AddBtn>
        )
      ) : (
        <S.AutoCompletesWrapper>
          <AutoCompletes
            placeholder="Enter the driver's name"
            id="links"
            dropdowns={
              linkableDrivers?.map((driver) => ({
                id: driver.driverId,
                name: driver.name,
              })) || []
            }
            fields={fields}
            handleDropdownSelect={handleLinkSelect}
            handleDropdownSlotAdd={handleLinkSlotAdd}
            handleDropdownSlotDelete={handleLinkSlotDelete}
          />

          <S.AutocompletesButtonWrapper>
            <S.CancelBtn type="button" onClick={handleCancelClick}>
              {t("Cancel")}
            </S.CancelBtn>
            <S.UpdateBtn type="button" onClick={handleUpdateClick}>
              {t("Update")}
            </S.UpdateBtn>
          </S.AutocompletesButtonWrapper>
        </S.AutoCompletesWrapper>
      )}
    </FormProvider>
  );
};

export default LinkingDriver;
