import React from "react";

import { Filter } from "components";

const MonitoringFilter = () => {
  const searchLabels = [
    {
      queryKey: "companyName",
      label: "Company name",
    },
  ] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Company name">
          <Filter.Input
            queryKey="companyName"
            placeholder="Search by company name"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default MonitoringFilter;
