import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Tab, ListPageLayout, Inquiry, Notice, InternalLink } from "components";
import { PlusCircleIcon } from "assets";
import { TAB } from "constants/index";
import * as S from "./Support.styled";

const Support = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const SUPPORT_TABS = [
    { key: TAB.SUPPORT.INQUIRY, label: "1:1 Inquiry" },
    { key: TAB.SUPPORT.NOTICE, label: "Notice" },
  ] as const;

  return (
    <ListPageLayout>
      <Tab css={S.layout} tabs={SUPPORT_TABS}>
        {searchParams.get("tab") === "notice" && (
          <InternalLink
            css={S.link}
            variant="ghostNoUnderlinePrimary"
            to="notice/create"
          >
            <PlusCircleIcon css={S.icon} /> {t("Add notice")}
          </InternalLink>
        )}
        <Tab.TabList />
        <Tab.TabPanel
          render={{
            [TAB.SUPPORT.INQUIRY]: <Inquiry />,
            [TAB.SUPPORT.NOTICE]: <Notice />,
          }}
        />
      </Tab>
    </ListPageLayout>
  );
};

export default Support;
