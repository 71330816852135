import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface RegisteredDriverPaginationProps {
  pageInfo?: PageInfo;
}

const RegisteredDriverPagination = ({
  pageInfo,
}: RegisteredDriverPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default RegisteredDriverPagination;
