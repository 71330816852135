import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { MEMBER_DETAIL_REGISTERED_DRIVER } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${MEMBER_DETAIL_REGISTERED_DRIVER};
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  border-radius: 2px;
  padding: 10px 20px;
  color: ${theme.color.white};
  text-decoration: none;
  background-color: ${theme.color.primary_10};
`;

export const Driver = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > span {
    margin-right: 8px;
  }
`;
