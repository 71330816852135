import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const tableOfContent = css`
  margin-top: 40px;

  & > div > section {
    width: 764px;

    &:nth-of-type(2) > div > div {
      margin-bottom: 16px;
    }

    &:nth-of-type(6) {
      padding-bottom: 0;
    }
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  display: flex;
  width: fit-content;
  padding: 9px 19px;
  text-decoration: none;
  background-color: ${theme.color.white};
`;

export const layout = css`
  position: relative;
  width: fit-content;
  min-width: fit-content;
  margin: 0 auto;

  & > h1 {
    margin-bottom: 40px;
  }
`;

export const BottomBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  margin: 32px 0 0 auto;
`;

export const cargoTracking = (theme: Theme) => css`
  ${theme.font.medium_14};
  column-gap: 12px;
  width: fit-content;

  svg {
    margin-right: 0;

    path {
      fill: ${theme.color.gray_40};
    }
  }
`;
