import React from "react";
import { useRecoilValue } from "recoil";

import { keys } from "lodash-es";

import { DataSection, StatusLabel, Profile } from "components";
import { useCurrentQueryParams } from "hooks";
import { clientLanguage } from "stores";
import { formatICTDateTime } from "utils";
import { MEMBER_REGISTERED_TRUCK_TABLE_HEADER, SuccessIcon } from "assets";
import type { GetMemberDetailTrucksClientModel } from "types";
import * as S from "./RegisteredTruck.styled";

interface RegisteredTruckTableProps {
  trucks?: GetMemberDetailTrucksClientModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const RegisteredTruckTable = ({
  trucks,
  dataUpdatedAt,
  refetch,
}: RegisteredTruckTableProps) => {
  const languages = useRecoilValue(clientLanguage);

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const formatTableData = (
    data: GetMemberDetailTrucksClientModel["trucks"][number],
    key: keyof typeof MEMBER_REGISTERED_TRUCK_TABLE_HEADER,
  ): string | React.ReactNode => {
    switch (key) {
      case "status":
        return data[key] ? <StatusLabel status={data[key]} /> : "-";

      case "truckType":
        return languages === "en" ? data.truckTypeEn : data.truckTypeVi;

      case "truckOption":
        return languages === "en" ? data.truckOptionEn : data.truckOptionVi;

      case "created":
        return data[key] ? formatICTDateTime(data[key], "DD/MM/YYYY") : "-";

      case "gpsAvailable":
        return (
          <S.IconWrapper isGps={!!data.gpsAvailable}>
            <SuccessIcon />
          </S.IconWrapper>
        );

      case "linked":
        return (
          <S.Truck hasCount={!!data[key]}>
            {data[key] ? (
              <>
                <Profile src={data?.profileUrl ?? ""} />
                <S.Name>{data?.driverName || "-"}</S.Name>
              </>
            ) : (
              <span>-</span>
            )}
            <S.Count>({data[key] ?? 0})</S.Count>
          </S.Truck>
        );

      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={trucks ? trucks.pageInfo.totalData : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Registered truck"
        columnTable={MEMBER_REGISTERED_TRUCK_TABLE_HEADER}
      >
        {trucks?.trucks.length &&
          trucks.trucks.map((truck) => (
            <DataSection.Table.SelectRowMovePage
              id={truck.truckId}
              key={truck.truckId}
              path={`registeredTruck/${truck.truckId}?memberType=${memberType}`}
              //NOTE: registered 상세에서 memberType이 필요하여 같이 넘겨줘야함(history 및 이전 페이지로 이동 시 사용)
            >
              {keys(MEMBER_REGISTERED_TRUCK_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    truck,
                    key as keyof typeof MEMBER_REGISTERED_TRUCK_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default RegisteredTruckTable;
