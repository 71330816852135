import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface RegisteredGaragePaginationProps {
  pageInfo?: PageInfo;
}

const RegisteredManageLinkPagination = ({
  pageInfo,
}: RegisteredGaragePaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default RegisteredManageLinkPagination;
