import { ax } from "api/axios";

import type {
  GetLinkingCompanysQueryModel,
  GetLinkingCompanysServerModel,
  GetLinkingsQueryModel,
  GetLinkingsServerModel,
  PatchLinkingCompanysQueryModel,
} from "types";

export const getLinkingsAPI = async (req: GetLinkingsQueryModel) => {
  const { data } = await ax.get<GetLinkingsServerModel>("/link", {
    params: req.query,
  });

  return data;
};

export const getLinkCompanysAPI = async (req: GetLinkingCompanysQueryModel) => {
  const { data } = await ax.get<GetLinkingCompanysServerModel[]>(
    `/link/${req.companyId}`,
    { params: req.query },
  );

  return data;
};

export const patchLinkCompanysAPI = async (
  req: PatchLinkingCompanysQueryModel,
) => {
  const { data } = await ax.patch(`/link/${req.companyId}`, req.body, {
    params: req.query,
  });

  return data;
};
