import React from "react";

import { addZeroFirstString } from "utils";
import * as S from "./Timer.styled";

interface TimerProps {
  className?: string;
  timerText: string;
  initTime?: number;
  min: number;
  sec: number;
  limitTime: number;
}

const Timer = ({ timerText, className, min, sec, limitTime }: TimerProps) => {
  return (
    <S.Time className={className} data-istimeover={limitTime <= 60}>
      {timerText} {addZeroFirstString(min)}:{addZeroFirstString(sec)}
    </S.Time>
  );
};

export default Timer;
