import { useState, useEffect, useId } from "react";
import { useSearchParams } from "react-router-dom";

const useFilterRadio = (queryKey: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const uuid = useId();

  const [selectRadio, setSelectRadio] = useState(
    searchParams.get(queryKey) ?? "ALL",
  );

  const handleClickRadio = (queryKey: string, key: string) => () => {
    key === "ALL"
      ? searchParams.delete(queryKey)
      : searchParams.set(queryKey, key);

    searchParams.get("page")
      ? searchParams.set("page", "1")
      : searchParams.append("page", "1");

    setSearchParams(searchParams);
    setSelectRadio(key);
  };

  useEffect(() => {
    setSelectRadio(searchParams.get(queryKey) ?? "ALL");
  }, [searchParams]);

  return {
    uuid,
    selectRadio,
    handleClickRadio,
  };
};

export default useFilterRadio;
