import { TooltipIcon } from "assets/icon";

export const SENDER_TOOLTIP = {
  title: "Sender",
  contents: "Company requested for settlement",
  tooltipBtnContent: TooltipIcon,
} as const;

export const RECIPIENT_TOOLTIP = {
  title: "Recipient",
  contents: "Company requesting settlement",
  tooltipBtnContent: TooltipIcon,
} as const;
