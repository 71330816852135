import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  width: 764px;
`;

export const NameWrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) => css`
    display: flex;
    column-gap: 10px;
    margin-bottom: ${!hasError && "16px"};
  `}
`;

export const errorMsg = css`
  margin-bottom: 16px;
`;

export const textarea = css`
  height: 146px;
  padding: 12px 12px 38px 12px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 12px 0 0 auto;
`;

export const LicenseInputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > span:first-of-type {
      ${theme.font.regular_13};
      padding: 4px 0 0;
      color: ${theme.color.gray_50};
    }
  `}
`;

export const content = css`
  & > div:last-of-type {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div:first-of-type > input {
      width: 220px;
    }
  }
`;

export const autoComplete = css`
  ul {
    width: 220px;
  }
`;

export const autoCompleteCancelButton = (theme: Theme) => css`
  ${theme.font.medium_14};
  color: ${theme.color.gray_50};
`;

export const updateButton = (theme: Theme) => css`
  ${theme.font.medium_14};
  color: ${theme.color.primary_40};

  &:disabled {
    color: ${theme.color.primary_20};
    /* TODO: 피그마에 맞는 컬러로 수정 필요 */
  }
`;

export const AutoCompleteBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  width: 100%;

  & > button {
    width: fit-content;
  }
`;

export const AutoCompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
