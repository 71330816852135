import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  FormInput,
  LabelContentTable,
  ErrorMsg,
  UnitSuffix,
} from "components";
import { useMemberType, useToast, useToggle } from "hooks";
import { usePutTruckSpecification } from "services";
import { clientLanguage, truckTypeOptions } from "stores";
import {
  checkCargoSizeLimit,
  checkTwoDecimalDigit,
  formatTrimDecimalZero,
  formatterDecimal,
  formatTruckOptions,
  formatTruckTypeOption,
  formatTruckTypes,
  getNumPeriods,
  getYears,
  selectDropdownForm,
  formatVin,
} from "utils";
import { HOOKFORM_ERR } from "assets";
import { TOAST_MSG, VALID_MSG } from "constants/index";
import type {
  DropdownOptionType,
  GetMemberDetailTruckDetailServerModel,
  Languages,
  PutTruckSpecificationServerModel,
} from "types";
import * as S from "./MemberDetailTruckDetailSpecification.styled";

interface TruckSpecificationForm {
  truckType: DropdownOptionType;
  truckOption: DropdownOptionType;
  length: string;
  height: string;
  width: string;
  brand: string;
  model: string;
  year: DropdownOptionType;
  vin: string;
}

interface MemberDetailTruckDetailSpecificationProps {
  truck: GetMemberDetailTruckDetailServerModel;
}

const TRUCK_SPECIFICATION_INIT_STATE = {
  truckType: { key: "", label: "" as Languages },
  truckOption: { key: "", label: "" as Languages },
  length: "",
  width: "",
  height: "",
  brand: "",
  model: "",
  year: { key: "", label: "" as Languages },
  vin: "",
};

const schema = yup.object({
  truckType: yup
    .mixed<DropdownOptionType>()
    .required(VALID_MSG.REQUIRED)
    .test("required", VALID_MSG.REQUIRED, (value) => !!value?.key),
  truckOption: yup
    .mixed<DropdownOptionType>()
    .required(VALID_MSG.REQUIRED)
    .test("required", VALID_MSG.REQUIRED, (value) => !!value?.key),
  length: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;

      return +value <= 13.56;
    }),
  width: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;
      return +value <= 2.35;
    }),
  height: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;

      return +value <= 2.7;
    }),
  brand: yup.string().defined(),
  model: yup.string().defined(),
  year: yup.mixed<DropdownOptionType>().defined(),
  vin: yup
    .string()
    .defined()
    .test("checkVin", VALID_MSG.VIN, (value) => value.length === 17)
    .required(VALID_MSG.REQUIRED),
});

const MemberDetailTruckDetailSpecification = ({
  truck,
}: MemberDetailTruckDetailSpecificationProps) => {
  const { t } = useTranslation();
  const { truckId } = useParams();
  const { pathname } = useLocation();

  const [isEdit, handleIsEdit] = useToggle();
  const { addToast } = useToast();
  const { memberType } = useMemberType();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptionsRecoil = useRecoilValue(truckTypeOptions);

  const formMethod = useForm<TruckSpecificationForm>({
    mode: "onTouched",
    defaultValues: TRUCK_SPECIFICATION_INIT_STATE,
    resolver: yupResolver(schema),
  });

  const { mutate: updateTruckSpcificationMutate } = usePutTruckSpecification();

  const matchedTypeOptions = formatTruckTypeOption(
    `${truck.truckOptionId}`,
    language,
    truckTypeOptionsRecoil || [],
  );

  const getFormattedSizeValue = (
    prevValue: string,
    currentValue: string,
  ): string => {
    return (currentValue && !checkTwoDecimalDigit(currentValue)) ||
      !checkCargoSizeLimit(currentValue)
      ? prevValue
      : currentValue;
  };

  const handleBlurSizeInput = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    formMethod.setValue(
      name as "width" | "height" | "length",
      `${+formatterDecimal(value ?? "")}`,
    );
  };

  const handleCancelClick = (): void => {
    formMethod.reset();
    handleIsEdit();
  };

  const handleEditClick = formMethod.handleSubmit((data): void => {
    const changeCheckKeys = [
      "truckOption",
      "width",
      "height",
      "length",
      "brand",
      "model",
      "year",
      "vin",
    ] as const;

    const body = changeCheckKeys.reduce((acc, key) => {
      if (key === "truckOption") {
        if (+data[key].key !== truck.truckOptionId) {
          acc.truckOptionId = +data[key].key;
        }
      } else if (key === "year") {
        if (+data[key].key !== truck.year) {
          acc[key] = +data[key].key;
        }
      } else if (key === "height" || key === "length" || key === "width") {
        if (
          formatTrimDecimalZero(+data[key]) !==
          formatTrimDecimalZero(truck[key])
        ) {
          acc[key] = +data[key];
        }
      } else {
        if (data[key] !== truck[key]) {
          acc[key] = data[key];
        }
      }
      return acc;
    }, {} as PutTruckSpecificationServerModel["body"]);

    if (!Object.keys(body).length) {
      handleIsEdit();
      return;
    } else {
      const query = {
        companyId: pathname.split("/")[2],
        companyType: memberType,
      };

      updateTruckSpcificationMutate(
        { truckId: truckId!, query, body },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
            handleIsEdit();
          },
          onError: (err) => {
            if (err.response?.data.response === "VIN_EXISTS") {
              formMethod.setError("vin", {
                type: "validate",
                message: HOOKFORM_ERR.VIN_EXISTS,
              });
            }
          },
        },
      );
    }
  });

  useEffect(() => {
    const year =
      getYears.find(({ key }) => +key === +truck.year) || getYears[0];

    formMethod.reset({
      truckType: matchedTypeOptions.truckType,
      truckOption: matchedTypeOptions.truckOption,
      length: `${truck.length}`,
      width: `${truck.width}`,
      height: `${truck.height}`,
      brand: truck.brand,
      model: truck.model,
      vin: truck.vin,
      year,
    });
  }, [truck]);

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Truck type"
            isRequired={isEdit}
          >
            {isEdit ? (
              <S.ErrorMessageWrapper>
                <Controller
                  name="truckType"
                  control={formMethod.control}
                  render={({ field: { value, onChange } }) => {
                    const truckTypes = formatTruckTypes(
                      language,
                      truckTypeOptionsRecoil || [],
                    );

                    const handleSelect = (key: string): void => {
                      selectDropdownForm(truckTypes, onChange)(key);
                      formMethod.setValue("truckOption", {
                        key: "",
                        label: "",
                      });
                    };

                    return (
                      <Dropdown
                        placeholder="Select the truck type"
                        hasError={
                          !!formMethod.formState.errors.truckType?.message
                        }
                        options={truckTypes}
                        selectedOption={value}
                        handleSelect={handleSelect}
                      />
                    );
                  }}
                />
                {formMethod.formState.errors.truckType?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.truckType?.message}
                  />
                )}
              </S.ErrorMessageWrapper>
            ) : (
              matchedTypeOptions.truckType.label
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Truck option"
            isRequired={isEdit}
          >
            {isEdit ? (
              <S.ErrorMessageWrapper>
                <Controller
                  name="truckOption"
                  control={formMethod.control}
                  render={({ field: { value, onChange } }) => {
                    const truckOptions = formatTruckOptions(
                      language,
                      truckTypeOptionsRecoil || [],
                      formMethod.watch("truckType")?.key!,
                    );

                    return (
                      <Dropdown
                        placeholder="Select the truck option"
                        hasError={
                          !!formMethod.formState.errors.truckOption?.message
                        }
                        options={truckOptions}
                        selectedOption={value}
                        handleSelect={selectDropdownForm(
                          truckOptions,
                          onChange,
                        )}
                      />
                    );
                  }}
                />
                {formMethod.formState.errors.truckOption?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.truckOption?.message}
                  />
                )}
              </S.ErrorMessageWrapper>
            ) : (
              matchedTypeOptions.truckOption.label
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Container size"
            isRequired={isEdit}
          >
            {isEdit ? (
              <div>
                <S.UnitSuffixWrapper>
                  <UnitSuffix
                    id="length"
                    unit="m"
                    value={formMethod.watch("length")}
                    err={!!formMethod.formState.errors.length?.message}
                    placeholder="Length"
                    register={formMethod.register("length", {
                      setValueAs: (currentValue: string) =>
                        getFormattedSizeValue(
                          `${formMethod.watch("length")}`,
                          currentValue,
                        ),
                      onBlur: handleBlurSizeInput,
                    })}
                  />
                  x
                  <UnitSuffix
                    id="width"
                    unit="m"
                    value={formMethod.watch("width")}
                    err={!!formMethod.formState.errors.width?.message}
                    placeholder="Depth"
                    register={formMethod.register("width", {
                      setValueAs: (currentValue: string) =>
                        getFormattedSizeValue(
                          `${formMethod.watch("length")}`,
                          currentValue,
                        ),
                      onBlur: handleBlurSizeInput,
                    })}
                  />
                  x
                  <UnitSuffix
                    id="height"
                    unit="m"
                    value={formMethod.watch("height")}
                    err={!!formMethod.formState.errors.height?.message}
                    placeholder="Height"
                    register={formMethod.register("height", {
                      setValueAs: (currentValue: string) =>
                        getFormattedSizeValue(
                          `${formMethod.watch("length")}`,
                          currentValue,
                        ),
                      onBlur: handleBlurSizeInput,
                    })}
                  />
                </S.UnitSuffixWrapper>
                <S.Desc>
                  {`- ${t(
                    "Maximum: Length 13.56m, Depth 2.35m, Height 2.7m" as Languages,
                  )}`}
                </S.Desc>
              </div>
            ) : (
              `${truck.length}m x ${truck.width}m x ${truck.height}m`
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Brand"
          >
            {isEdit ? (
              <FormInput
                placeholder="Brand"
                register={formMethod.register("brand")}
              />
            ) : (
              truck.brand || "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Model"
          >
            {isEdit ? (
              <FormInput
                placeholder="Model"
                register={formMethod.register("model")}
              />
            ) : (
              truck.model || "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.year} label="Year">
            {isEdit ? (
              <Controller
                name="year"
                control={formMethod.control}
                render={({ field: { value, onChange } }) => {
                  const years = getNumPeriods(1990, new Date().getFullYear());

                  return (
                    <Dropdown
                      options={years}
                      selectedOption={value}
                      handleSelect={selectDropdownForm(years, onChange)}
                    />
                  );
                }}
              />
            ) : (
              truck.year || "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="VIN"
            isRequired={isEdit}
          >
            {isEdit ? (
              <S.ErrorMessageWrapper>
                <FormInput
                  placeholder="Enter 17 characters"
                  maxLength={17}
                  hasError={!!formMethod.formState.errors.vin?.message}
                  value={formMethod.watch("vin")}
                  register={formMethod.register("vin", {
                    setValueAs: formatVin,
                  })}
                />
                {!!formMethod.formState.errors.vin?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.vin.message}
                  />
                )}
              </S.ErrorMessageWrapper>
            ) : (
              truck.vin
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.ButtonWrapper>
        {isEdit ? (
          <>
            <Button
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleCancelClick}
            />
            <Button
              variant="primaryMedium"
              label="Update"
              handleClickBtn={handleEditClick}
            />
          </>
        ) : (
          <Button
            variant="outlineMedium"
            label="Edit"
            handleClickBtn={handleIsEdit}
          />
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default MemberDetailTruckDetailSpecification;
