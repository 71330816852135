import React from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Filter } from "components";
import { clientLanguage, truckTypeOptions } from "stores";
import { formatTruckOptions, formatTruckTypes } from "utils";
import type { Languages } from "types";

const RegisteredTruckFilter = () => {
  const [searchParams] = useSearchParams();

  const language = useRecoilValue(clientLanguage);
  const recoilTruckTypeOptions = useRecoilValue(truckTypeOptions) || [];
  const truckTypes = formatTruckTypes(language, recoilTruckTypeOptions);
  const truckOptions = formatTruckOptions(
    language,
    recoilTruckTypeOptions,
    searchParams.get("truckTypeId") ?? "",
  );

  const searchLabels = [
    { queryKey: "plateNumber", label: "Plate number" },
    {
      queryKey: "status",
      label: "Status",
      options: [
        { key: "ALL", label: "All" },
        { key: "AVAILABLE", label: "Available" },
        { key: "UNAVAILABLE", label: "Unavailable" },
      ],
    },
    { queryKey: "linked", label: "Linking" },
    {
      queryKey: "truckTypeId",
      label: "Truck type",
      options: truckTypes,
      link: "truckOptionId",
    },
    {
      queryKey: "truckOptionId",
      label: "Truck option",
      options: truckOptions,
    },
  ] as const;

  const excludeResetQueryKeys = ["memberType"];

  const selectedTruckOptions = searchParams.get("truckTypeId")
    ? truckOptions
    : undefined;

  return (
    <Filter
      searchLabels={searchLabels}
      excludeResetQueryKeys={excludeResetQueryKeys}
    >
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio
            queryKey="status"
            radios={[
              { key: "ALL", label: "All" },
              { key: "AVAILABLE", label: "Available" },
              { key: "UNAVAILABLE", label: "Unavailable" },
            ]}
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Plate number">
          <Filter.Input
            queryKey="plateNumber"
            placeholder="Search by plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input queryKey="linked" placeholder="Search by driver name" />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Truck type">
          <Filter.Dropdown
            queryKey="truckTypeId"
            placeholder="Select the truck type"
            dropdowns={truckTypes}
            link="truckOptionId"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Truck option">
          <Filter.Dropdown
            queryKey="truckOptionId"
            placeholder="Select the truck option"
            dropdowns={selectedTruckOptions}
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default RegisteredTruckFilter;
