import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast, useToggle } from "hooks";
import { useGetDriverDetail, usePutDriverAccountInfo } from "services";
import { SCHEMA } from "utils";
import { TOAST_MSG } from "constants/index";
import type { DriverAccountInfoForm, TruckingCompanyType } from "types";

const initForm = {
  bankName: "",
  bankBranch: "",
  bankAccountNo: "",
};

const schema = yup.object({
  bankName: SCHEMA.OPTIONAL_STRING,
  bankBranch: SCHEMA.OPTIONAL_STRING,
  bankAccountNo: SCHEMA.OPTIONAL_STRING,
});

const useMemberDetailRegisteredDriverAccountInfo = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();

  const memberType = searchParams.get("memberType");
  const { data } = useGetDriverDetail(
    {
      driverId: driverId!,
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
    },
    !!driverId,
  );
  const { mutate: putDriverAccount } = usePutDriverAccountInfo();

  const [isEdit, handleIsEdit] = useToggle();
  const { addToast } = useToast();

  const formMethod = useForm<DriverAccountInfoForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const accountInfo = {
    bankName: data?.bankName ?? "",
    bankBranch: data?.bankBranch ?? "",
    bankAccountNo: data?.bankAccountNo ?? "",
  };

  const handleSubmit = formMethod.handleSubmit((data): void => {
    if (isEqual(accountInfo, formMethod.getValues())) return handleIsEdit();

    const req = {
      driverId: driverId!,
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
      body: {
        bankName: data.bankName ?? "",
        bankBranch: data.bankBranch ?? "",
        bankAccountNo: data.bankAccountNo ?? "",
      },
    };

    putDriverAccount(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.DRIVER_LINK_UPDATE_DONE);
        handleIsEdit();
      },
    });
  });

  useEffect(() => {
    if (!data) return;

    formMethod.reset({
      bankName: data.bankName ?? "",
      bankBranch: data.bankBranch ?? "",
      bankAccountNo: data.bankAccountNo ?? "",
    });
  }, [data, isEdit]);

  return { isEdit, data, formMethod, handleIsEdit, handleSubmit };
};

export default useMemberDetailRegisteredDriverAccountInfo;
