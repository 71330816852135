import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  DriverAllocationSection,
  PageBackHeader,
  QueryPagination,
} from "components";
import { useFilterParams } from "hooks";
import { useGetDriverAllocation } from "services";
import { TAB, PATH } from "constants/index";
import { MEMBER_DETAIL_DRIVER_ALLOCATION_TABLE_HEADER } from "assets";
import type { GetDriverAllocationQueryModel, TruckingCompanyType } from "types";
import * as S from "./MemberDetailRegisteredDriverDetailAllocation.styled";

const MemberDetailRegisteredDriverDetailAllocation = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<GetDriverAllocationQueryModel["query"]>([
    "page",
    "pageSize",
    "companyId",
    "companyType",
  ]);

  const driverDetailPath = `${PATH.MEMBER}/${memberId}/${TAB.MEMBER.REGISTERED_DRIVER}/${driverId}`;
  const memberType = searchParams.get("memberType");

  const req = {
    driverId: driverId!,
    query: {
      ...filters,
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data, dataUpdatedAt, refetch } = useGetDriverAllocation(req);

  return (
    <S.Root>
      <h3 className="a11y">driver allocation</h3>
      <S.TitleWrapper>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Allocation list"
          path={`${driverDetailPath}?memberType=${memberType}`}
        />
        <span>({data?.driverName})</span>
      </S.TitleWrapper>
      <DriverAllocationSection
        data={data?.order}
        columns={MEMBER_DETAIL_DRIVER_ALLOCATION_TABLE_HEADER}
        dataUpdatedAt={dataUpdatedAt}
        pageInfo={data?.pageInfo}
        refetch={refetch}
      />
      <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
    </S.Root>
  );
};

export default MemberDetailRegisteredDriverDetailAllocation;
