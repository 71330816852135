import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as P from "pages";
import { AuthRoute, GoogleMapLayout, PublicRoute } from "components";
import { PATH } from "constants/index";

interface RouterProps {
  children: React.ReactNode;
}

export default function Router({ children }: RouterProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={children}>
          <Route path="*" element={<P.NotFound />} />
          <Route element={<PublicRoute />}>
            <Route path={PATH.ROOT} element={<P.Login />} />
            <Route path={PATH.RESET_PASSWORD} element={<P.ResetPassword />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route path={PATH.MEMBER}>
              <Route index element={<P.Member />} />
              <Route path=":memberId">
                <Route path="" element={<P.MemberDetail />} />
                <Route
                  path="membershipPlan"
                  element={<P.MembershipPlanDetail />}
                />
                <Route path="registeredDriver">
                  <Route
                    path=":driverId"
                    element={<P.MemberRegisteredDriverDetail />}
                  />
                  <Route
                    path="create"
                    element={<P.MemberDetailRegisteredDriverCreate />}
                  />
                  <Route
                    path=":driverId/allocation"
                    element={<P.MemberDetailRegisteredDriverDetailAllocation />}
                  />
                </Route>
                <Route path="registeredTruck">
                  <Route
                    path="create"
                    element={<P.MemberRegisteredTruckCreate />}
                  />
                  <Route
                    path=":truckId"
                    element={<P.MemberRegisteredTruckDetail />}
                  />
                  <Route
                    path=":truckId/allocation"
                    element={<P.MemberDetailRegisteredTruckDetailAllocation />}
                  />
                </Route>
                <Route path="registeredGarage">
                  <Route
                    path="create"
                    element={<P.MemberDetailRegisteredGarageCreate />}
                  />
                  <Route
                    path="edit/:garageId"
                    element={<P.MemberDetailRegisteredGarageEdit />}
                  />
                </Route>
                <Route path="registeredMember">
                  <Route
                    path="create"
                    element={<P.MemberDetailRegisteredMemberCreate />}
                  />
                  <Route
                    path="edit/:staffId"
                    element={<P.MemberDetailRegisteredMemberEdit />}
                  />
                </Route>
              </Route>
              <Route path="create" element={<P.MemberCreate />} />
              <Route path="edit/:memberId" element={<P.MemberEdit />} />
            </Route>
            <Route path={PATH.LINKING}>
              <Route index element={<P.Linking />} />
            </Route>
            <Route path={PATH.MONITORING}>
              <Route index element={<P.Monitoring />} />
              <Route element={<GoogleMapLayout />}>
                <Route path=":id" element={<P.MonitoringMap />} />
              </Route>
            </Route>
            <Route path={PATH.QUOTATION}>
              <Route index element={<P.Quotation />} />
              <Route path=":id" element={<P.QuotationDetail />} />
            </Route>
            <Route path={PATH.ORDER_MANAGEMENT}>
              <Route index element={<P.OrderManagement />} />
              <Route path=":id" element={<P.OrderManagementDetail />} />
              <Route element={<GoogleMapLayout />}>
                <Route path="map" element={<P.OrderManagementMap />} />
              </Route>
            </Route>
            <Route path={PATH.SETTLEMENT}>
              <Route index element={<P.Settlement />} />
              <Route path=":settlementId" element={<P.SettlementDetail />} />
            </Route>
            <Route path={PATH.SUPPORT}>
              <Route index element={<P.Support />} />
              <Route path=":supportId" element={<P.SupportDetail />} />
              <Route path="notice/create" element={<P.SupportNoticeCreate />} />
              <Route
                path="notice/edit/:supportId"
                element={<P.SupportNoticeEdit />}
              />
            </Route>
            <Route path={PATH.SETTING}>
              <Route path="" element={<P.Setting />} />
              <Route path="myAccount" element={<P.MyAccount />} />
              <Route path="registeredMember">
                <Route index element={<P.RegisteredMember />} />
                <Route
                  path=":memberId"
                  element={<P.RegisteredMemberDetail />}
                />
                <Route path="create" element={<P.RegisteredMemberCreate />} />
                <Route
                  path="edit/:memberId"
                  element={<P.RegisteredMemberEdit />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
