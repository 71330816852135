import type { Languages } from "types";

export const findLookupTableLabel = <
  T extends { key: string; label: Languages },
>(
  lookupTableDatas: ReadonlyArray<T>,
  key: T["key"],
): T["label"] | null => {
  const selectedDatas = lookupTableDatas.filter((data) => data.key === key);
  if (!selectedDatas.length) return null;

  return selectedDatas[0].label;
};
