import { css } from "@emotion/react";

export const labelContentTable = css`
  width: 764px;
`;

export const viewDetailsButton = () => css`
  margin-left: 10px;
`;

export const allocationLinkButton = css`
  margin-left: 10px;
`;

export const editButton = css`
  margin: 12px 0 0 auto;
`;
