import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const ButtonFoldOrderNumber = styled.button<{
  isFold: boolean;
}>`
  ${({ theme, isFold }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 294px 1fr;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_20};
    padding: 12px 20px;
    background-color: ${isFold ? theme.color.white : theme.color.gray_00};

    &:hover {
      background-color: ${theme.color.gray_00};
    }
  `}
`;

export const OrderInfoFoldBtnContentWrapper = styled.h3`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    display: flex;
  `}
`;

export const foldButton = (isFold: boolean) => (theme: Theme) =>
  css`
    justify-self: center;
    transform: rotate(${isFold ? 0 : 180}deg);

    & > path {
      fill: ${theme.color.black};
    }
  `;
