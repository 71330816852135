import React from "react";
import { useTranslation } from "react-i18next";

import { Button, DetailModal } from "components";
import { useModal, useToast } from "hooks";
import { TOAST_MSG } from "constants/index";
import { CopyIcon } from "assets";
import type { Languages } from "types";
import * as S from "./PlateNumberModal.styled";

interface CommonProps {
  plateNumbers: string[];
  handleCancelClick: () => void;
}

interface PlateNumberModalSelect extends CommonProps {
  type: "select";
  handleRowClick: (plate: string) => () => void;
}

interface PlateNumberCopyModal extends CommonProps {
  type: "copy";
  handleRowClick?: never;
}

type PlateNumberModalProps = PlateNumberModalSelect | PlateNumberCopyModal;

const PlateNumberModal = React.forwardRef(
  (
    {
      type,
      plateNumbers,
      handleCancelClick,
      handleRowClick,
    }: PlateNumberModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    const { handleModalClose } = useModal();
    const { addToast } = useToast();

    const handleCopyClick = (plate: string) => (): void => {
      navigator.clipboard.writeText(plate);

      addToast(TOAST_MSG.SUCCESS.COPY_PLATE_DONE);
      handleModalClose();
    };

    return (
      <DetailModal
        css={S.modal}
        ref={ref}
        title="GPS company registered vehicles"
        negBtnLabel=""
        closeBtnFn={handleCancelClick}
      >
        <S.ListTitle>{t("Plate number")}</S.ListTitle>
        <S.PlateNumberWrapper>
          {plateNumbers.map((plateNumber, i) => (
            <li key={i}>
              {type === "select" && handleRowClick ? (
                <S.PlateNumberButton
                  type="button"
                  onClick={handleRowClick(plateNumber)}
                >
                  {plateNumber}
                </S.PlateNumberButton>
              ) : (
                <S.PlateNumberCopyButtonWrapper>
                  {plateNumber}{" "}
                  <Button
                    css={S.button}
                    variant="icon"
                    icon={<CopyIcon />}
                    label=""
                    handleClickBtn={handleCopyClick(plateNumber)}
                  />
                </S.PlateNumberCopyButtonWrapper>
              )}
            </li>
          ))}
        </S.PlateNumberWrapper>
      </DetailModal>
    );
  },
);

PlateNumberModal.displayName = "PlateNumberModal";

export default PlateNumberModal;
