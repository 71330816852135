import React from "react";

import { LabelContentTable } from "components";
import { formatOrderNum, formatPhoneNum } from "utils";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementDefaultInfo.styled";

interface OrderManagementDefaultInfoProps {
  defaultInformation?: GetOrderManagementDetailServerModel["defaultInformation"];
}

const OrderManagementDefaultInfo = ({
  defaultInformation,
}: OrderManagementDefaultInfoProps) => {
  if (!defaultInformation) return null;

  return (
    <LabelContentTable variant="underline">
      <S.OrderNumber>
        {formatOrderNum(defaultInformation.orderNumber)}
      </S.OrderNumber>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Shipper company" labelWidth={200}>
          {defaultInformation.client}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Trucking company" labelWidth={200}>
          {defaultInformation.truckingCompany} (
          {formatPhoneNum(defaultInformation.truckingCompanyPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementDefaultInfo;
