import React, { useEffect, useState } from "react";

import { DetailModal } from "components";
import { useModal } from "hooks";
import { convertToNumberTypeLatLng, limitGoogleAddressLength } from "utils";
import { PickUpPointIcon } from "assets";
import { DEFAULT_MAP_COORD } from "constants/index";
import type { AddressSearchType, LatLng } from "types";
import useAddrSearchGoogleMap from "./hooks/useAddrSearchGoogleMap";
import useAddrSearchLocationMarker from "./hooks/useAddrSearchLocationMarker";
import * as S from "./LocSearchModal.styled";

interface LocSearchModalProps {
  className?: string;
  value: AddressSearchType;
  handleAddrSelect: (value: AddressSearchType) => void;
}

const LocSearchModal = React.forwardRef(
  (
    { className, value, handleAddrSelect }: LocSearchModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const [locationInfo, setLocationInfo] = useState<AddressSearchType>({
      address: "",
      buildingName: "",
      city: "",
      coord: {
        lng: 0,
        lat: 0,
      },
    });

    const { handleModalClose } = useModal();

    const handleMapClick = (e: google.maps.MapMouseEvent) => {
      const clickedLocation = {
        lat: e.latLng?.lat() ?? 0,
        lng: e.latLng?.lng() ?? 0,
      };

      getLocationByCoord(clickedLocation);
    };

    const getLocationByCoord = async (coord: LatLng) => {
      const geocoder = new google.maps.Geocoder();
      const convertedLatLngs = convertToNumberTypeLatLng(coord);

      await geocoder.geocode({ location: convertedLatLngs }).then((res) => {
        const address = limitGoogleAddressLength(
          res.results[0].formatted_address,
        );
        const buildingName = res.results[0].address_components[0].long_name;
        const city = res.results[0].address_components[2].long_name; // TODO: 값 확인 필요
        const placeId = res.results[0].place_id;

        const newLocation = {
          address,
          buildingName,
          city,
          placeId,
          coord: convertedLatLngs,
        };

        setLocationInfo(newLocation);
      });
    };

    const handleConfirm = () => {
      handleAddrSelect(locationInfo);
      handleModalClose();
    };

    const { ref: mapRef, googleMap } = useAddrSearchGoogleMap(
      value.coord.lng,
      value.coord.lat,
      handleMapClick,
    );

    useAddrSearchLocationMarker(
      googleMap!,
      locationInfo,
      !!locationInfo.coord.lat && !!locationInfo.coord.lng,
    );

    useEffect(() => {
      const coord = {
        lng: value.coord.lng || DEFAULT_MAP_COORD.lng,
        lat: value.coord.lat || DEFAULT_MAP_COORD.lat,
      };

      getLocationByCoord(coord);
    }, []);

    return (
      <DetailModal
        className={className}
        css={S.searchLocModal}
        ref={ref}
        title="Choose from map"
        posBtnLabel="Confirm"
        callbackFn={handleConfirm}
      >
        <S.LocInfoWrapper>
          <PickUpPointIcon />
          <S.Location>{locationInfo.buildingName}</S.Location>
          <S.Address>{locationInfo.address}</S.Address>
        </S.LocInfoWrapper>
        <S.Map ref={mapRef} />
      </DetailModal>
    );
  },
);

LocSearchModal.displayName = "LocSearchModal";

export default LocSearchModal;
