import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";

import { CheckboxBtn, Portal } from "components";
import { NoResultIcon, CloseIcon } from "assets";
import type { ColumnTable, Languages } from "types";
import useTableScrollTop from "../hooks/useTableScrollTop";
import * as S from "./CheckTable.styled";

interface CheckTableProps {
  className?: string;
  children: React.ReactNode;
  toolBtns?: React.ReactNode;
  title?: string;
  columnTable: ColumnTable;
  selectedCount?: number;
  isAllChecked: boolean;
  handleAllCheck?: () => void;
  handleAllUnCheck?: () => void;
}

interface RowProps {
  className?: string;
  children: React.ReactNode;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface SelectRowProps extends RowProps {
  id: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  selectFn?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface SelectRowMovePageProps extends RowProps {
  id: string;
  path: string;
}

interface TdProps {
  className?: string;
  children: React.ReactNode;
}

const CheckTable = ({
  className,
  children,
  toolBtns,
  title,
  columnTable,
  selectedCount,
  isAllChecked,
  handleAllCheck,
  handleAllUnCheck,
}: CheckTableProps) => {
  const { t } = useTranslation();

  const { tableRef, toolBoxRef } = useTableScrollTop();

  return (
    <>
      {!!selectedCount && (
        <S.ToolBoxWrapper ref={toolBoxRef}>
          <S.ToolBox>
            <S.SelectedCount>
              {selectedCount} {t("Selected")}
            </S.SelectedCount>
            {toolBtns && toolBtns}
          </S.ToolBox>
          <button
            type="button"
            aria-label="close checked info"
            onClick={handleAllUnCheck}
          >
            <CloseIcon css={S.closeIcon} />
          </button>
        </S.ToolBoxWrapper>
      )}
      <S.Table className={className} ref={tableRef}>
        <caption className="a11y">{title}</caption>
        <thead>
          <S.HeadRow>
            <S.CheckTh>
              <CheckboxBtn
                shape="rectangle"
                isChecked={isAllChecked}
                handleCheck={isAllChecked ? handleAllUnCheck : handleAllCheck}
              />
            </S.CheckTh>
            {Object.values(columnTable).map((value, i) => (
              <S.Th key={i} title={value}>
                {t(value as Languages)}
              </S.Th>
            ))}
          </S.HeadRow>
        </thead>
        <S.Tbody>
          {isEmpty(children) ? <CheckTable.NoData /> : children}
        </S.Tbody>
      </S.Table>
    </>
  );
};

CheckTable.Row = function Row({
  className,
  children,
  handleMouseOver,
  handleMouseLeave,
}: RowProps) {
  return (
    <S.Row
      className={className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </S.Row>
  );
};

CheckTable.SelectRow = function SelectRow({
  className,
  children,
  id,
  isSelected = false,
  isDisabled,
  selectFn: selectCb,
}: SelectRowProps) {
  const [domReady, setDomReady] = useState(false);

  const rowId = `table-row-${id}`;

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <S.RowBtn type="button" disabled={isDisabled} onClick={selectCb}>
            <span className="a11y">select row</span>
          </S.RowBtn>
        </td>
      </Portal>
    </>
  );
};

CheckTable.SelectRowMovePage = function SelectRowMovePage({
  className,
  children,
  id,
  path,
}: SelectRowMovePageProps) {
  const [domReady, setDomReady] = useState(false);

  const rowId = `table-row-${id}`;

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <S.SelectRow className={className} id={rowId}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <S.RowLink to={path}>
            <span className="a11y">select row</span>
          </S.RowLink>
        </td>
      </Portal>
    </>
  );
};

CheckTable.Td = function Td({ className, children }: TdProps) {
  return <S.Td className={className}>{children}</S.Td>;
};

CheckTable.CheckTd = function CheckTd({ className, children }: TdProps) {
  return <S.CheckTd className={className}>{children}</S.CheckTd>;
};

CheckTable.NoData = function NoData() {
  return (
    <S.NoData>
      <td>
        <NoResultIcon />
        No results found
      </td>
    </S.NoData>
  );
};

export default CheckTable;
