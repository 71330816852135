import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: calc(50% - 42px); // section 높이의 반 - button 높이
    left: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 20px;
    border-radius: 0 5px 5px 0;
    background-color: ${theme.color.white};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
    transition-duration: 0.5s;
    z-index: ${theme.zIndex.FOLDBUTTON};
  `}
`;

export const arrowIcon = (isFold: boolean) => (theme: Theme) =>
  css`
    transform: ${isFold ? "rotate(180deg)" : "rotate(0deg)"};
    path {
      fill: ${theme.color.gray_80};
    }
  `;
