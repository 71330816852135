import React from "react";
import { keys } from "lodash-es";

import { DataSection, StatusLabel } from "components";
import { formatICTDateTime, formatOrderNum, addrFormatter } from "utils";
import { ORDER_MANAGEMENT_TABLE_HEADER } from "assets";
import type { GetOrderManagementServerModel } from "types";
import * as S from "./OrderManagementTable.styled";

interface OrderManagementTableProps {
  orders?: GetOrderManagementServerModel;
  hasRequiredFilter: boolean;
  dataUpdatedAt: number;
  refetch: () => void;
}

const OrderManagementTable = ({
  orders,
  hasRequiredFilter,
  dataUpdatedAt,
  refetch,
}: OrderManagementTableProps) => {
  const formatTableData = (
    data: GetOrderManagementServerModel["orders"][number],
    key: keyof GetOrderManagementServerModel["orders"][number],
  ) => {
    switch (key) {
      case "orderNumber":
        return data[key] ? formatOrderNum(data[key]) : "-";

      case "status":
        return <StatusLabel status={data[key]} />;

      case "doAddress":
        return addrFormatter(data[key], data.doAddressDetail) ?? "-";

      case "puAddress":
        return addrFormatter(data[key], data.puAddressDetail) ?? "-";

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key], "DD/MM/YY, HH:mm");

      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={hasRequiredFilter ? dataUpdatedAt : null}
      totalData={hasRequiredFilter ? orders?.pageInfo.totalData! : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Shipper"
        columnTable={ORDER_MANAGEMENT_TABLE_HEADER}
      >
        {hasRequiredFilter ? (
          !!orders?.orders.length &&
          orders?.orders.map((order) => (
            <DataSection.Table.SelectRowMovePage
              id={order.orderId}
              key={order.orderId}
              path={order.orderId}
            >
              {keys(ORDER_MANAGEMENT_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(order, key as keyof typeof order)}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))
        ) : (
          <DataSection.Table.NoData />
        )}
      </DataSection.Table>
    </DataSection>
  );
};

export default OrderManagementTable;
