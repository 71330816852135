import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { MEMBERSHIP_PLAN_TABLE_HEADER_COLUMNS } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${MEMBERSHIP_PLAN_TABLE_HEADER_COLUMNS};
  }
`;

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  border-radius: 2px;
  padding: 10px 20px;
  color: ${theme.color.white};
  text-decoration: none;
  background-color: ${theme.color.primary_10};
`;

export const row = (theme: Theme) => css`
  & > td:last-of-type > button {
    display: none;
  }

  &:hover > td:last-of-type > button {
    display: flex;
  }
`;

export const Button = styled.button`
  ${({ theme }) => css`
    padding: 8px;
    background-color: ${theme.color.gray_10};

    & > svg {
      color: ${theme.color.gray_60};
    }
  `}
`;
