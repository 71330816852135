import React from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable } from "components";
import { findLookupTableLabel, formatICTDateTime, makeNameString } from "utils";
import { GRADE_TYPE_RADIOS } from "assets";
import type { GetRegisteredMemberDetailServerModel } from "types";
import * as S from "./MemberInfo.styled";

interface MemberInfoProps {
  info?: GetRegisteredMemberDetailServerModel;
}

const MemberInfo = ({ info }: MemberInfoProps) => {
  const { t } = useTranslation();

  if (!info) return null;

  const gradeLabel = findLookupTableLabel(GRADE_TYPE_RADIOS, info.grade);

  return (
    <LabelContentTable variant="bg">
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content
          css={S.labelContentTable}
          label="Member name"
        >
          {makeNameString(info.lastName, info.middleName, info.firstName)}
        </LabelContentTable.Content>
        <LabelContentTable.Content
          css={S.labelContentTable}
          label="Created date"
        >
          {info.created ? formatICTDateTime(info.created) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Grade">
          {gradeLabel ? t(gradeLabel) : "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Last access">
          {info.lastAccess ? formatICTDateTime(info.lastAccess) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Phone number">
          {info.phone}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Login ID">
          {info.email}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default MemberInfo;
