import { atom } from "recoil";

import type { Toast, TruckTypeOption } from "types";

export const modalCompoState = atom<React.ReactNode[] | []>({
  key: "modalCompoState",
  default: [],
});

export const isModalOpenState = atom({
  key: "isModalOpenState",
  default: false,
});

export const toastAtom = atom<[] | Toast[]>({
  key: "toastAtom",
  default: [],
});

export const isTimeStartAtom = atom({
  key: "isTimeStartAtom",
  default: false,
});

export const isTimeOutAtom = atom({
  key: "isTimeOutAtom",
  default: false,
});

export const isTimeResetAtom = atom({
  key: "isTimeResetAtom",
  default: false,
});

export const truckTypeOptions = atom<TruckTypeOption[] | null>({
  key: "truckTypeOptions",
  default: null,
});

export const beforePagePathState = atom<string | null>({
  key: "beforePagePathState",
  default: null,
});
