import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

export const IconBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 10px;
    right: 12px;

    & > svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme.color.gray_40};
      }
    }
  `}
`;

export const CheckWrapper = styled.div<{
  isCheck: boolean;
  hasError?: boolean;
}>`
  ${({ theme, isCheck, hasError }) => css`
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: ${hasError
      ? theme.color.red_20
      : isCheck
      ? theme.color.green_20
      : theme.color.gray_60};

    & > svg {
      width: 18px;
      height: 18px;

      path {
        fill: ${hasError
          ? theme.color.red_20
          : isCheck
          ? theme.color.green_20
          : theme.color.gray_40};
      }
    }
  `}
`;
