import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const AllocationInfoWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 20px;
    background-color: ${theme.color.gray_00};

    & > h3 {
      ${theme.font.semibold_16};
      margin-bottom: 12px;
    }
  `}
`;

export const OrderCardWrapper = styled.ul`
  /* NOTE: 디자인 변경 가능성 있음, 추후 수정 필요 */
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-left: 12px;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 28px 1fr 58px;
    align-items: center;

    column-gap: 8px;
    margin-bottom: 8px;

    & > figure {
      width: 28px;
      height: 28px;

      & > svg {
        width: 14px;
        height: 14px;
      }
    }

    & > span {
      ${theme.font.medium_15};
      text-align: start;
    }
  `}
`;

export const driverInfo = (theme: Theme) => css`
  margin-bottom: 12px;
  border-bottom: 1px solid ${theme.color.gray_30};
  padding-bottom: 12px;
`;

export const checkIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    & > path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `;

export const GpsBoxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 2px;
    padding: 3px 8px;

    & > span {
      ${theme.font.medium_12};
      color: ${theme.color.gray_80};
    }

    & > svg {
      width: 16px;
    }
  `}
`;

export const DeliveryLogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const logLabelContent = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 110px;
  column-gap: 16px;

  & > div {
    & > label {
      ${theme.font.medium_14};
      color: ${theme.color.gray_80};
    }
  }
  & > span {
    ${theme.font.regular_14};
    text-align: end;
  }
`;

export const Gray70Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_70};
  `}
`;

export const OrderCard = styled.div<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    border: ${isSelected
      ? `1px solid ${theme.color.primary_40}`
      : `1px solid ${theme.color.gray_35}`};
    border-radius: 5px;
    padding: 0;
    background-color: ${isSelected && theme.color.primary_10};
    cursor: pointer;
  `}
`;

export const OrderCardButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 15px 19px;

    &:disabled {
      background-color: ${theme.color.gray_20};
    }
  `}
`;

export const icon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;

export const DriverInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

export const DriverInfoRow = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    column-gap: 8px;
    align-items: center;
  `}
`;
