import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useCurrentQueryParams, useModal, useToast } from "hooks";
import { usePutLicenseApprove } from "services";
import { COMPANY_LOOKUP_TABLE, HOOKFORM_ERR } from "assets";
import { TOAST_MSG } from "constants/index";
import type { LicenseApproveForm } from "types";

const useChangeStatusForm = () => {
  const { memberId } = useParams();

  const { mutate: licenseApproveMutate, isLoading } = usePutLicenseApprove();

  const formMethod = useForm<LicenseApproveForm>({
    mode: "onTouched",
    defaultValues: {
      status: "APPROVE",
      reason: { key: "", label: "" },
    },
  });

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];
  const { handleModalClose } = useModal();
  const { addToast } = useToast();

  const handleConfirm = formMethod.handleSubmit((data) => {
    if (!data.reason.key && data.status === "REJECT") {
      formMethod.setError("reason", { message: HOOKFORM_ERR.REQUIRE });
      return;
    }

    const req = {
      companyId: memberId!,
      query: {
        status: data.status,
        companyType:
          COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE],
      },

      body: {
        ...(data.status === "REJECT" && { reason: data.reason.key }),
      },
    };

    licenseApproveMutate(req, {
      onSuccess: () => {
        if (req.query.status === "APPROVE") {
          addToast(TOAST_MSG.SUCCESS.MEMBER_STATUS_CHANGE_APPROVE_SUCCESS);
        }
        if (req.query.status === "REJECT") {
          addToast(TOAST_MSG.SUCCESS.MEMBER_STATUS_CHANGE_REJECT_SUCCESS);
        }
        handleModalClose();
      },
    });
  });

  return { formMethod, isLoading, handleConfirm };
};

export default useChangeStatusForm;
