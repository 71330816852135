import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { MEMBER_DETAIL_REGISTERED_MANAGE_LINK } from "assets";

export const table = css`
  & > thead {
    position: static;
  }

  tr {
    grid-template-columns: ${MEMBER_DETAIL_REGISTERED_MANAGE_LINK};

    & > td:nth-of-type(5) {
      display: flex;
      justify-content: center;
      border-right: 0;
      padding: 0;
      z-index: 10;
    }
  }
`;

export const td = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Linked = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  column-gap: 8px;
  align-items: center;
  width: 100%;
`;

export const Driver = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  align-items: center;

  & > figure {
    margin-right: 8px;
  }

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const LinkingDriverNumber = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;

export const linkButton = (hasLinkedDriver: boolean) => (theme: Theme) =>
  css`
    & > svg {
      margin: 0;

      & > path {
        fill: ${hasLinkedDriver ? theme.color.primary_40 : theme.color.gray_40};
      }
    }
  `;

export const linkIcon = css`
  width: 20px;
  height: 20px;
`;

export const selectRow = (isSelected: boolean) => (theme: Theme) =>
  css`
    background-color: ${isSelected && theme.color.gray_00};
  `;
