import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "hooks";
import { useGetMyAccount, usePutMyAccount } from "services";
import { SCHEMA } from "utils";
import { TOAST_MSG } from "constants/index";
import type { EditMyAccountForm } from "types";

const schema = yup.object({
  lastName: SCHEMA.REQUIRED_STRING,
  middleName: SCHEMA.OPTIONAL_STRING,
  firstName: SCHEMA.REQUIRED_STRING,
  phone: SCHEMA.REQUIRED_STRING,
});

const useMyAccount = (isEdit: boolean, handleChangeEdit: () => void) => {
  const { data: myAccount } = useGetMyAccount();
  const { mutate: changeMyAccountMutate, isLoading: isLoadingChangeMyAccount } =
    usePutMyAccount();

  const formMethod = useForm({
    mode: "onTouched",
    defaultValues: { firstName: "", middleName: "", lastName: "", phone: "" },
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const changeMyAccount = (data: EditMyAccountForm) => {
    changeMyAccountMutate(
      {
        body: {
          firstName: data.firstName.trim(),
          middleName: data.middleName.trim() ?? null,
          lastName: data.lastName.trim(),
          phone: data.phone.trim(),
        },
      },
      {
        onSuccess: () => {
          handleChangeEdit();
          addToast(TOAST_MSG.SUCCESS.ACCOUNT_CHANGE_SUCCESS);
        },
        onError: (err) => {
          err.response?.data.response === "PHONE_EXISTS"
            ? addToast(TOAST_MSG.WARNING.STAFF_PHONE_EXISTS)
            : addToast(TOAST_MSG.WARNING.BAD_REQUEST);
        },
      },
    );
  };

  useEffect(() => {
    if (!myAccount) return;

    formMethod.reset({
      firstName: myAccount.firstName,
      middleName: myAccount.middleName ?? "",
      lastName: myAccount.lastName,
      phone: myAccount.phone,
    });
  }, [myAccount, isEdit]);

  return { formMethod, myAccount, isLoadingChangeMyAccount, changeMyAccount };
};

export default useMyAccount;
