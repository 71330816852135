import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: ${theme.size.LIST_LAYOUT_HEADER_HEIGHT};
    padding: 15px 20px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const FrontWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    display: flex;
    align-items: center;
    column-gap: 10px;
  `}
`;

export const customLink = css`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
  }
`;
