import React from "react";
import { useFormContext } from "react-hook-form";

import { LabelContentTable, FormInput, FormPasswordInput } from "components";
import { checkEmailValidation, onlyNumber } from "utils";
import { HOOKFORM_ERR } from "assets";
import type { MemberForm } from "types";
import * as S from "./MemberUserInfoForm.styled";

interface UserInfoProps {
  type: "create" | "edit";
}

const MemberUserInfoForm = ({ type }: UserInfoProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<MemberForm>();

  return (
    <>
      <LabelContentTable
        css={S.labelContentTable}
        variant="empty"
        subject="User information"
      >
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Name" isRequired>
            <S.NameInputWrapper>
              <FormInput
                css={S.input}
                maxLength={50}
                placeholder="First name"
                hasError={!!errors.managerFirstName?.message}
                errorMsg={errors.managerFirstName?.message}
                register={register("managerFirstName", {
                  required: HOOKFORM_ERR.REQUIRE,
                })}
              />
              <FormInput
                css={S.input}
                maxLength={50}
                placeholder="Middle name (Optional)"
                register={register("managerMiddleName")}
              />
              <FormInput
                css={S.input}
                maxLength={50}
                placeholder="Last name"
                hasError={!!errors.managerLastName?.message}
                errorMsg={errors.managerLastName?.message}
                register={register("managerLastName", {
                  required: HOOKFORM_ERR.REQUIRE,
                })}
              />
            </S.NameInputWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Phone number" isRequired>
            <FormInput
              css={S.input}
              maxLength={15}
              placeholder="Only numbers"
              value={watch("managerPhone")}
              hasError={!!errors.managerPhone?.message}
              errorMsg={errors.managerPhone?.message}
              register={register("managerPhone", {
                required: HOOKFORM_ERR.REQUIRE,
                setValueAs: onlyNumber,
              })}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Login ID" isRequired>
            <FormInput
              css={S.input}
              maxLength={200}
              placeholder="Login ID"
              hasError={!!errors.managerEmail?.message}
              errorMsg={errors.managerEmail?.message}
              register={register("managerEmail", {
                required: HOOKFORM_ERR.REQUIRE,
                validate: (value) =>
                  checkEmailValidation(value) || HOOKFORM_ERR.VALID_EMAIL,
              })}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {type === "create" && (
          <>
            <LabelContentTable.Row>
              <LabelContentTable.Content label="Password" isRequired>
                <FormPasswordInput
                  css={S.input}
                  confirmType="password"
                  placeholder="Enter password"
                  hasError={!!errors.managerPassword?.message}
                  password={watch("managerPassword")}
                  register={register("managerPassword", {
                    required: HOOKFORM_ERR.REQUIRE,
                  })}
                />
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LabelContentTable.Row>
              <LabelContentTable.Content label="Password confirm" isRequired>
                <FormPasswordInput
                  css={S.input}
                  confirmType="passwordConfirm"
                  placeholder={"Enter confirm password"}
                  hasError={!!errors.managerPasswordConfirm?.message}
                  password={{
                    password: watch("managerPassword"),
                    passwordConfirm: watch("managerPasswordConfirm"),
                  }}
                  register={register("managerPasswordConfirm", {
                    required: HOOKFORM_ERR.REQUIRE,
                  })}
                />
              </LabelContentTable.Content>
            </LabelContentTable.Row>
          </>
        )}
      </LabelContentTable>
      <S.Division />
    </>
  );
};

export default MemberUserInfoForm;
