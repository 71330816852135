import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { InternalLink, LabelContentTable } from "components";
import { useGetDriverDetail } from "services";
import { comma, formatICTDateTime, formatOrderNum } from "utils";
import type { TruckingCompanyType } from "types";
import * as S from "./MemberDetailRegisteredDriverDrivingRecords.styled";

const MemberDetailRegisteredDriverDrivingRecords = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const memberType = searchParams.get("memberType");

  const { data } = useGetDriverDetail(
    {
      driverId: driverId!,
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
    },
    !!driverId,
  );

  if (!data) return null;

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Total shipment time">
            {comma(data.totalTransitTime || "") || "0"} {t("hours")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Total shipment distance">
            {comma(data.totalTransitDistance || "") || "0"} km
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Last shipping on">
            {data.lastShippingOn
              ? formatICTDateTime(data.lastShippingOn, "DD-MM-YYYY")
              : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Recent allocation">
            {formatOrderNum(data.recentAllocation ?? "0")}{" "}
            <InternalLink
              css={S.allocationLinkButton}
              variant="outlineXSmall"
              to={`allocation?memberType=${memberType}`}
            >
              {t("See all")}
            </InternalLink>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Completed orders">
            {data.progressed ?? 0}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </>
  );
};

export default MemberDetailRegisteredDriverDrivingRecords;
