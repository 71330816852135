import React from "react";
import dayjs from "dayjs";

import {
  CalendarInput,
  DetailModal,
  ErrorMsg,
  FormRadioBtn,
  FormInput,
  LabelContent,
} from "components";
import { comma, deleteComma, formatICTDateTime, numericOnly } from "utils";
import {
  MEMBER_MEMBERSHIP_PLANS_RADIOS,
  MEMBERSHIP_PLANS_STATUS_RADIOS,
  PAYMENT_METHOD_PLAN_RADIOS,
  HOOKFORM_ERR,
} from "assets";
import type { GetMemberCompanyPlanServerModel } from "types";
import useMembershipPlanForm from "./hook/useMembershipPlanForm";
import * as S from "./MembershipPlanModal.styled";

interface MembershipPlanModalProps {
  title: "Edit" | "Add";
  data?: GetMemberCompanyPlanServerModel["plans"];
  id?: string;
}

const MembershipPlanModal = React.forwardRef(
  (
    { title, data, id }: MembershipPlanModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const filteredPlan = data?.find((item) => item.companyPlanId === id);

    const {
      formMethod,
      isLoadingPostPlan,
      isLoadingPutPlan,
      selectedDates,
      handleChangeDate,
      handleConfirm,
    } = useMembershipPlanForm(filteredPlan);

    const dateValue = formMethod.watch("startDate")
      ? `${formatICTDateTime(
          formMethod.watch("startDate"),
          "DD/MM/YYYY",
        )} ~ ${formatICTDateTime(
          formatICTDateTime(dayjs(formMethod.watch("startDate")), "DD") === "31"
            ? dayjs(formMethod.watch("startDate")).add(1, "month")
            : dayjs(formMethod.watch("startDate"))
                .add(1, "month")
                .subtract(1, "day"),
          "DD/MM/YYYY",
        )}`
      : "";

    return (
      <DetailModal
        css={S.modal}
        ref={ref}
        title={`${title} membership plan`}
        isDisabled={Object.keys(formMethod.formState.errors).length > 0}
        isLoading={isLoadingPostPlan || isLoadingPutPlan}
        posBtnLabel="Confirm"
        callbackFn={handleConfirm}
      >
        <LabelContent
          css={S.labelcontent}
          label="Plan"
          direction="vertical"
          isRequired
        >
          <FormRadioBtn
            css={S.radio}
            isDisabled //TODO: 기획에서 disabled 처리 요청하여 임의 disabled 처리
            radioList={MEMBER_MEMBERSHIP_PLANS_RADIOS}
            currentValue={formMethod.watch("planType")}
            register={formMethod.register("planType")}
          />
        </LabelContent>
        <LabelContent
          css={S.labelcontent}
          label="Period"
          direction="vertical"
          isRequired
        >
          <CalendarInput
            css={(theme) =>
              S.calendarInput(
                theme,
                !!formMethod.formState.errors.startDate?.message,
              )
            }
            type="date"
            isViewPeriod
            value={dateValue}
            selectedDate={selectedDates}
            handleChangeDate={handleChangeDate}
          />
          {formMethod.formState.errors.startDate?.message && (
            <ErrorMsg
              errorMsg={formMethod.formState.errors.startDate?.message}
            />
          )}
        </LabelContent>
        <LabelContent
          css={S.labelcontent}
          label="Total"
          direction="vertical"
          isRequired
        >
          <S.InputWrapper>
            <FormInput
              placeholder="Only numbers"
              hasError={!!formMethod.formState.errors.total?.message}
              errorMsg={formMethod.formState.errors.total?.message}
              maxLength={10}
              value={formMethod.watch("total")}
              register={formMethod.register("total", {
                required: HOOKFORM_ERR.REQUIRE,
                setValueAs: (value) => comma(deleteComma(numericOnly(value))),
              })}
            />
            <span>₫</span>
          </S.InputWrapper>
        </LabelContent>
        <LabelContent
          css={S.labelcontent}
          label="Payment method"
          direction="vertical"
          isRequired
        >
          <FormRadioBtn
            css={S.radio}
            radioList={PAYMENT_METHOD_PLAN_RADIOS}
            currentValue={formMethod.watch("pay")}
            register={formMethod.register("pay")}
          />
        </LabelContent>
        <LabelContent
          css={S.labelcontent}
          label="Status"
          direction="vertical"
          isRequired
        >
          <FormRadioBtn
            css={S.radio}
            radioList={MEMBERSHIP_PLANS_STATUS_RADIOS}
            currentValue={formMethod.watch("status")}
            register={formMethod.register("status")}
          />
        </LabelContent>
      </DetailModal>
    );
  },
);

MembershipPlanModal.displayName = "MembershipPlanModal";

export default MembershipPlanModal;
