import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { ERROR_CODE } from "assets";
import { TokenService } from "utils";
import { renewAccessTokenAPI } from "./auth";

export class AxiosService {
  private instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  constructor(private tokenService: TokenService) {}

  setInterceptors() {
    this.instance.interceptors.request.use(this.onRequest, this.onRequestError);
    this.instance.interceptors.response.use(
      this.onResponse,
      this.onResponseError,
    );

    return this.instance;
  }

  private onRequest = (config: AxiosRequestConfig = {}): any => {
    const headers = config.headers || {};
    const accessToken = this.tokenService.getAccessToken();

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return { ...config, headers };
  };

  private onRequestError = (err: AxiosError) => {
    return Promise.reject(err);
  };

  private onResponse = (response: AxiosResponse) => {
    return response;
  };

  private onResponseError = async (err: AxiosError<any>): Promise<unknown> => {
    const { response: errorResponse } = err;

    if (err.name === "AxiosError" && err.message.includes("status code 404")) {
      if (err.response?.data.response !== ERROR_CODE.EMAIL_NOT_FOUND) {
        // window.location.replace("/notFound"); // NOTE: 임시 주석, 추후 삭제
      }
    }

    switch (errorResponse?.data.response) {
      case ERROR_CODE.ACCESSTOKEN_EXPIRED:
        return this.tokenService.resetTokenAndReattemptRequest(
          err,
          renewAccessTokenAPI,
        );
      case ERROR_CODE.DUPLICATE_SIGNIN_DETECTED:
      case ERROR_CODE.UNAUTHORIZED:
        this.tokenService.expireSession();
        break;
    }

    return Promise.reject(err);
  };
}
