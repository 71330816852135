import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  DetailModal,
  FormPasswordInput,
  LabelContent,
  ErrorMsg,
} from "components";
import { SCHEMA } from "utils";
import { HOOKFORM_ERR } from "assets";
import type { ChangePasswordForm } from "types";
import * as S from "./ChangePasswordModal.styled";

const FORM_DEFAULT_VALUE = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const schema = yup.object({
  currentPassword: SCHEMA.PASSWORD,
  password: SCHEMA.PASSWORD,
  confirmPassword: SCHEMA.CONFIRM_PASSWORD,
});

interface ChangePasswordModalProps {
  isLoading: boolean;
  changePassword: (
    data: ChangePasswordForm,
    onError: () => void,
  ) => SubmitHandler<ChangePasswordForm>;
}

const ChangePasswordModal = React.forwardRef(
  (
    { isLoading, changePassword }: ChangePasswordModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      register,
      watch,
      formState: { errors },
      setError,
      handleSubmit,
    } = useForm<ChangePasswordForm>({
      mode: "onTouched",
      defaultValues: FORM_DEFAULT_VALUE,
      resolver: yupResolver(schema),
    });

    const handleChangePassword = (): void => {
      const error = (): void => {
        setError("currentPassword", {
          message: "Is not a current password.",
        });
      };
      handleSubmit(changePassword(watch(), error))();
    };

    return (
      <DetailModal
        ref={ref}
        title="Change password"
        posBtnLabel="Update"
        isLoading={isLoading}
        isDisabled={Object.keys(errors).length > 0}
        callbackFn={handleChangePassword}
      >
        <S.Wrapper>
          <LabelContent
            css={S.label}
            isRequired
            label="Current password"
            direction="horizontal"
          >
            <FormPasswordInput
              css={S.input}
              placeholder="Enter current password"
              hasError={!!errors.currentPassword?.message}
              password={watch("currentPassword")}
              confirmType="currentPassword"
              register={register("currentPassword", {
                required: HOOKFORM_ERR.REQUIRE,
              })}
            />
            {errors.currentPassword?.message && (
              <ErrorMsg errorMsg={errors.currentPassword?.message} />
            )}
          </LabelContent>
          <LabelContent
            css={S.label}
            isRequired
            label="New password"
            direction="horizontal"
          >
            <FormPasswordInput
              css={S.input}
              placeholder="Enter current password"
              hasError={!!errors.password?.message}
              password={watch("password")}
              confirmType="password"
              register={register("password", {
                required: HOOKFORM_ERR.REQUIRE,
              })}
            />
          </LabelContent>
          <LabelContent
            css={S.label}
            isRequired
            label="Confirm password"
            direction="horizontal"
          >
            <FormPasswordInput
              css={S.input}
              placeholder="Enter current password"
              hasError={!!errors.confirmPassword?.message}
              confirmType="passwordConfirm"
              password={{
                password: watch("password"),
                passwordConfirm: watch("confirmPassword"),
              }}
              register={register("confirmPassword", {
                required: HOOKFORM_ERR.REQUIRE,
              })}
            />
          </LabelContent>
        </S.Wrapper>
      </DetailModal>
    );
  },
);

ChangePasswordModal.displayName = "ChangePasswordModal";

export default ChangePasswordModal;
