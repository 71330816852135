import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  height: 40px;
`;

export const calendarInput = (theme: Theme) => css`
  border: 0;
  padding: 0 44px 0 12px;
  background-color: ${theme.color.white};

  &::placeholder {
    color: ${theme.color.gray_50};
  }
`;

export const calendarDialog = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-right: 20px;

  > button {
    justify-content: flex-end;
  }
`;

export const icon = (theme: Theme) =>
  css`
    width: 20px;
    height: 20px;

    path {
      fill: ${theme.color.gray_40};
    }
  `;
