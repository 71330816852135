import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  InternalLink,
  PreviousPageLink,
  MemberInfo,
  PageLayout,
} from "components";
import { useGetRegisteredMemberDetail } from "services";
import { SETTING_REGISTERED_MEMBER_DETAIL_LAYOUT } from "assets";
import { PATH } from "constants/index";
import * as S from "./RegisteredMemberDetail.styled";

const RegisteredMemberDetail = () => {
  const { memberId } = useParams();
  const { t } = useTranslation();

  const { data: manageInfo } = useGetRegisteredMemberDetail(memberId!);

  return (
    <PageLayout
      css={S.layout}
      layoutStaticData={SETTING_REGISTERED_MEMBER_DETAIL_LAYOUT}
    >
      <MemberInfo info={manageInfo} />
      <S.LinkWrapper>
        <PreviousPageLink
          variant="outlineMedium"
          to={PATH.SETTING_REGISTERED_MEMBER}
        >
          {t("Back to list")}
        </PreviousPageLink>
        <InternalLink
          variant="primaryMedium"
          to={`${PATH.SETTING_REGISTERED_MEMBER}/edit/${memberId}`}
        >
          {t("Edit information")}
        </InternalLink>
      </S.LinkWrapper>
    </PageLayout>
  );
};

export default RegisteredMemberDetail;
