import React from "react";
import { keys } from "lodash-es";

import { DataSection, StatusLabel } from "components";
import { formatICTDateTime, formatOrderNum } from "utils";
import { QUOTATION_TABLE_HEADER } from "assets";
import type { GetQuotationServerModel } from "types";
import * as S from "./QuotationTable.styled";

interface QuotationTableProps {
  quotations?: GetQuotationServerModel;
  hasRequiredFilter: boolean;
  dataUpdatedAt: number;
  refetch: () => void;
}

const QuotationTable = ({
  quotations,
  hasRequiredFilter,
  dataUpdatedAt,
  refetch,
}: QuotationTableProps) => {
  const formatTableData = (
    data: GetQuotationServerModel["orders"][number],
    key: keyof typeof QUOTATION_TABLE_HEADER,
  ) => {
    switch (key) {
      case "orderNumber":
        return data[key] ? formatOrderNum(data[key]) : "-";

      case "status":
        return <StatusLabel status={data[key]} />;

      case "created":
        return formatICTDateTime(data[key]);

      default:
        return data[key] || "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={hasRequiredFilter ? dataUpdatedAt : null}
      totalData={hasRequiredFilter ? quotations?.pageInfo.totalData! : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Quotation Management"
        columnTable={QUOTATION_TABLE_HEADER}
      >
        {!!quotations?.orders.length &&
          quotations?.orders.map((quotation) => (
            <DataSection.Table.SelectRowMovePage
              id={quotation.orderId}
              key={quotation.orderId}
              path={quotation.orderId}
            >
              {keys(QUOTATION_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    quotation,
                    key as keyof typeof QUOTATION_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default QuotationTable;
