import type { GetTruckGarageServerModel, Languages } from "types";

export const getTruckGarageData = (
  garages: GetTruckGarageServerModel[],
): {
  key: string;
  label: Languages;
}[] => {
  const formattedGarages = garages.map(
    (garages: { garageId: string; name: Languages }) => ({
      key: garages.garageId,
      label: garages.name,
    }),
  );

  return formattedGarages;
};

export const addPrefixToUrlIfNeeded = (url: string, prefix: string): string => {
  if (!url) return "";
  return url.includes(prefix) ? url : `${prefix}/${url}`;
};
