import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormFileInput, LabelContentTable } from "components";
import { HOOKFORM_ERR } from "assets";
import type { MemberForm } from "types";
import * as S from "./MemberBusinessLicenseForm.styled";

const MemberBusinessLicenseForm = () => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    resetField,
    register,
  } = useFormContext<MemberForm>();

  const isRegisteredCompany = watch("registered") === "YES";

  const handleDeleteFile = (name: keyof MemberForm) => (): void =>
    resetField(name);

  return (
    <LabelContentTable
      css={S.labelContentTable}
      variant="empty"
      subject="Business license"
    >
      {(watch("companyType") === "CARRIER" ||
        watch("companyType") === "FORWARDERCARRIER") && (
        <>
          <LabelContentTable.Row>
            <LabelContentTable.Content
              label={"Front of license"}
              isRequired={isRegisteredCompany}
            >
              <S.InputWrapper>
                <FormFileInput
                  css={S.input}
                  placeholder={
                    "Upload front of transportation business license"
                  }
                  hasErr={!!errors.transportLicense1?.message}
                  file={watch("transportLicense1")}
                  register={register("transportLicense1", {
                    required: isRegisteredCompany
                      ? HOOKFORM_ERR.REQUIRE
                      : false,
                  })}
                  handleDeleteFile={handleDeleteFile("transportLicense1")}
                />
                <S.Desc>
                  - {t("File format: jpg, jpeg, png or pdf(Limit 5MB)")}
                </S.Desc>
              </S.InputWrapper>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content
              label={"Back of license"}
              isRequired={isRegisteredCompany}
            >
              <S.InputWrapper>
                <FormFileInput
                  css={S.input}
                  placeholder={"Upload back of transportation business license"}
                  hasErr={!!errors.transportLicense2?.message}
                  file={watch("transportLicense2")}
                  register={register("transportLicense2", {
                    required: isRegisteredCompany
                      ? HOOKFORM_ERR.REQUIRE
                      : false,
                  })}
                  handleDeleteFile={handleDeleteFile("transportLicense2")}
                />
                <S.Desc>
                  - {t("File format: jpg, jpeg, png or pdf(Limit 5MB)")}
                </S.Desc>
              </S.InputWrapper>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </>
      )}
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Front of ERC"
          isRequired={isRegisteredCompany}
        >
          <S.InputWrapper>
            <FormFileInput
              css={S.input}
              placeholder="Upload front of ERC"
              hasErr={!!errors.license1?.message}
              file={watch("license1")}
              register={register("license1", {
                required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
              })}
              handleDeleteFile={handleDeleteFile("license1")}
            />
            <S.Desc>
              - {t("File format: jpg, jpeg, png or pdf(Limit 5MB)")}
            </S.Desc>
          </S.InputWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Back of ERC"
          isRequired={isRegisteredCompany}
        >
          <S.InputWrapper>
            <FormFileInput
              css={S.input}
              placeholder="Upload back of ERC"
              hasErr={!!errors.license2?.message}
              file={watch("license2")}
              register={register("license2", {
                required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
              })}
              handleDeleteFile={handleDeleteFile("license2")}
            />
            <S.Desc>
              - {t("File format: jpg, jpeg, png or pdf(Limit 5MB)")}
            </S.Desc>
          </S.InputWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default MemberBusinessLicenseForm;
