import React, { useEffect } from "react";
import { isEmpty, keys, upperFirst } from "lodash-es";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DetailModal, CheckboxBtn, CheckTable, SearchInput } from "components";
import {
  useCheckTable,
  useModal,
  useSearchInputWithoutQuery,
  useToast,
} from "hooks";
import { useGetLinkingCompanys, usePatchLinkingCompanys } from "services";
import { LINKING_FILTER_STATUS_RADIOS } from "assets";
import { TOAST_MSG } from "constants/index";
import type { ColumnTable, Languages, LinkSelectCompanyInfo } from "types";
import * as S from "./LinkCompanySearchModal.styled";

interface LinkCompanySearchModalProps {
  className?: string;
  id: string;
  title: string;
  columns: ColumnTable;
}

const LinkCompanySearchModal = React.forwardRef(
  (
    { className, id, title, columns }: LinkCompanySearchModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    type QueryType = (typeof LINKING_FILTER_STATUS_RADIOS)[number]["key"];
    const queryType = (searchParams.get("tab") as QueryType) ?? "shipper";

    const { mutate: linkingCompanysMutate } = usePatchLinkingCompanys();
    const { data: linkingCompanys } = useGetLinkingCompanys({
      companyId: id,
      query: { type: queryType },
    });

    const {
      searchValue,
      filteredDatas,
      handleSearch,
      handleChangeInput,
      handleResetInput,
    } = useSearchInputWithoutQuery<LinkSelectCompanyInfo>(
      linkingCompanys,
      "name",
    );
    const {
      isChecked,
      isAllChecked,
      checkedIds,
      setCheckedIds,
      handleCheck,
      handleAllCheck,
      handleAllUnCheck,
    } = useCheckTable(linkingCompanys, "companyId");
    const { handleModalClose } = useModal();
    const { addToast } = useToast();

    const formatTableData = (
      data: LinkSelectCompanyInfo,
      key: keyof LinkSelectCompanyInfo,
    ): string | 0 | 1 => {
      if (key === "type") {
        return data[key] ? t(upperFirst(data.type) as Languages) : "-"; //NOTE: lodash 사용으로 인해 단언 처리(반환값 string)
      } else {
        return data[key] ?? "-";
      }
    };

    const handleCloseSelectCompany = () => {
      if (!linkingCompanys || !checkedIds) return;

      const req = {
        companyId: id,
        query: { type: queryType },
        body: {
          links: linkingCompanys
            .filter((item) => checkedIds.includes(item.companyId))
            .map((item) => ({ companyId: item.companyId, type: item.type })),
        },
      };

      linkingCompanysMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.LINK_COMPANYS_DONE);
          handleModalClose();
        },
      });
    };

    useEffect(() => {
      if (!linkingCompanys) return;

      setCheckedIds(
        linkingCompanys
          .filter(({ checked }) => checked === 1)
          .map((linkingCompany) => linkingCompany.companyId),
      );
    }, [linkingCompanys]);

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        className={className}
        type="submit"
        title={title as Languages} //NOTE: 회사 이름 = title (Languages 타입으로 인해 단언처리)
        posBtnLabel={"Select"}
        isDisabled={isEmpty(checkedIds)}
        callbackFn={handleCloseSelectCompany}
      >
        <SearchInput
          css={S.searchInput}
          placeholder="Search by company name"
          value={searchValue}
          handleChange={handleChangeInput}
          handleSearch={handleSearch}
          handleReset={handleResetInput}
        />
        <CheckTable
          css={(theme) =>
            S.checkTable(
              theme,
              linkingCompanys?.length === filteredDatas.length,
            )
          }
          isAllChecked={isAllChecked}
          columnTable={columns}
          handleAllCheck={handleAllCheck}
          handleAllUnCheck={handleAllUnCheck}
        >
          {filteredDatas.map((item) => (
            <CheckTable.SelectRow
              css={(theme) => S.selectRow(theme, isChecked(item.companyId))}
              id={item.companyId}
              key={item.companyId}
              selectFn={handleCheck(item.companyId)}
            >
              <CheckTable.CheckTd>
                <CheckboxBtn
                  shape="rectangle"
                  isChecked={isChecked(item.companyId)}
                  handleCheck={handleCheck(item.companyId)}
                />
              </CheckTable.CheckTd>
              {keys(columns).map((key) => (
                <CheckTable.Td key={key}>
                  {formatTableData(item, key as keyof typeof item)}
                </CheckTable.Td>
              ))}
            </CheckTable.SelectRow>
          ))}
        </CheckTable>
      </DetailModal>
    );
  },
);

LinkCompanySearchModal.displayName = "LinkCompanySearchModal";
export default LinkCompanySearchModal;
