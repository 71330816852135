import React from "react";
import { Link } from "react-router-dom";

import { Profile } from "components";
import { formatPhoneNum } from "utils";
import * as S from "./ProfileInfo.styled";

interface ProfileProps {
  profileUrl: string;
  allocationPath?: string;
  name: string;
  phone: string;
}

const ProfileInfo = ({
  profileUrl,
  allocationPath,
  name,
  phone,
}: ProfileProps) => {
  return (
    <S.Profile>
      <Profile css={S.profileImg} src={profileUrl} />
      <div>
        {allocationPath ? (
          <Link css={S.name} to={allocationPath}>
            {name}
          </Link>
        ) : (
          <S.Name>{name}</S.Name>
        )}
        <S.Phone>{formatPhoneNum(phone)}</S.Phone>
      </div>
    </S.Profile>
  );
};

export default ProfileInfo;
