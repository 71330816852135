import React from "react";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { DataSection, StatusLabel } from "components";
import { useGetMembers } from "services";
import { useFilterParams } from "hooks";
import { formatICTDateTime } from "utils";
import { MEMBER_TABLE_HEADER } from "assets";
import type { GetMembersQueryModel, GetMembersServerModel } from "types";
import * as S from "./ForwarderTable.styled";

const ForwarderTable = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<
    GetMembersQueryModel["query"] & { search?: string }
  >(["status", "name", "page"]);

  const { data, dataUpdatedAt, refetch } = useGetMembers({
    query: { ...filters, companyType: "FORWARDER" },
  });

  if (!data) return null;

  const formatTableData = (
    data: GetMembersServerModel["members"][number],
    key: keyof typeof MEMBER_TABLE_HEADER,
  ) => {
    switch (key) {
      case "status":
        return <StatusLabel status={data[key]} />;

      case "registered":
        return data[key] === "YES" ? t("Yes") : t("No");

      case "created":
        return formatICTDateTime(data[key]);

      default:
        return data[key] || "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Forwarder"
        columnTable={MEMBER_TABLE_HEADER}
      >
        {data.members.length &&
          data.members.map((forwarder) => (
            <DataSection.Table.SelectRowMovePage
              id={forwarder.companyId}
              key={forwarder.companyId}
              path={`${forwarder.companyId}?memberType=forwarder`}
            >
              {keys(MEMBER_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    forwarder,
                    key as keyof typeof MEMBER_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default ForwarderTable;
