import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { DetailLayout, MemberDetailRegisteredGarageForm } from "components";
import { useToast } from "hooks";
import { useEditGarage, useGetGarageDetail } from "services";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { TruckingCompanyType } from "types";
import { useMemeberDetailRegisteredGarageForm } from "components/domain/member/detail/tab/registeredGarage/detail/garageForm/hooks";

const MemberDetailRegisteredGarageEdit = () => {
  const { memberId, garageId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const memberType = searchParams.get("memberType");
  const req = {
    garageId: garageId!,
    query: {
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data: garage } = useGetGarageDetail(req);
  const { formMethod } = useMemeberDetailRegisteredGarageForm({ data: garage });
  const { mutate: editGarageMutate } = useEditGarage();
  const { addToast } = useToast();

  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_GARAGE}`;

  const editGarage = formMethod.handleSubmit((data): void => {
    const req = {
      garageId: garageId!,
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
      body: {
        name: data.name.trim(),
        address: data.garageAddress.address,
        addressDetail: data.garageAddress.addressDetail,
        lng: +data.garageAddress.coord.lng,
        lat: +data.garageAddress.coord.lat,
        capacity: +data.capacity,
      },
    };

    editGarageMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.GARAGE_UPDATE);
        navigate(beforeUrl);
      },
      onError: (error) => {
        if (error.response?.data.response === "GARAGE_NAME_EXISTS") {
          addToast(TOAST_MSG.WARNING.GARAGE_NAME_EXISTS);
        }
        if (error.response?.data.response === "GARAGE_FULL") {
          addToast(TOAST_MSG.WARNING.GARAGE_FULL);
        }
      },
    });
  });

  return (
    <DetailLayout>
      <FormProvider {...formMethod}>
        <MemberDetailRegisteredGarageForm
          formType="edit"
          handleSubmit={editGarage}
        />
      </FormProvider>
    </DetailLayout>
  );
};

export default MemberDetailRegisteredGarageEdit;
