import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { CheckIcon } from "assets";

interface DropdownWrapperProps {
  isOpen: boolean;
}

export const Dropdown = styled.div`
  position: relative;
  height: 40px;
`;

interface DropdownBtnProps {
  hasError?: boolean;
}

export const DropdownBtn = styled.button<DropdownBtnProps>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
    padding: 0 12px;

    &[disabled] {
      background-color: ${theme.color.gray_10};
    }

    &[data-haserr="true"] {
      border-color: ${theme.color.red_20};
    }

    &[aria-expanded="true"] {
      border: 1px solid ${theme.color.primary_40};

      & > svg {
        transform: rotate(180deg);
      }
    }

    & > svg {
      fill: ${theme.color.black};
    }
  `}
`;

export const SelectedValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.black};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_60};
  `}
`;

export const CustomCheckboxIcon = styled(CheckIcon)`
  display: none;
  width: 16px;
  height: 16px;
`;

export const OptionWrapper = styled.ul<DropdownWrapperProps>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    display: ${isOpen ? "inherit" : "none"};
    top: calc(100% + 4px);
    width: 100%;
    max-height: 402px;
    border: 1px solid ${theme.color.gray_30};
    border-bottom: 0;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_dropdown};
    z-index: ${theme.zIndex.DROPDOWN};
    overflow: auto;
  `}
`;

export const Option = styled.li`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;

    &:last-of-type {
      border-bottom: 1px solid ${theme.color.gray_30};
    }
  `}
`;

interface OptionBtnProps {
  status?: string;
}

export const OptionBtn = styled.button<OptionBtnProps>`
  ${({ theme, status }) => css`
    ${theme.font.regular_14};

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 0 12px;
    color: ${theme.color.gray_60};
    background-color: ${theme.color.white};
    word-break: break-all;

    :hover {
      color: ${theme.color.gray_80};
      background-color: ${theme.color.gray_10};
    }

    &[data-selected="true"] {
      color: ${status === "default" && theme.color.primary_40};

      svg {
        display: ${status === "default" && "block"};

        path {
          fill: ${status === "default" && theme.color.primary_40};
        }
      }
    }
  `}
`;

export const NoOptionData = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    column-gap: 8px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 28px 0;
  `}
`;

export const noOptionIcon = css`
  width: 20px;
  height: 20px;
`;

export const noOptionDataMsg = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_20};
  `}
`;
