import { css, Theme } from "@emotion/react";

export const downloadBtnWrapper = css`
  & > div {
    display: flex;
    column-gap: 20px;
  }
`;

export const row = (hasValue: boolean) => (theme: Theme) =>
  css`
    &:focus-within {
      border-bottom: ${hasValue && `1px solid ${theme.color.primary_40}`};

      & > div > div > span {
        color: ${hasValue && theme.color.primary_40};
        background-color: ${hasValue && theme.color.primary_10};
      }
    }
  `;

export const contentFormWrapper = (isCreateReply: boolean) => css`
  & > div {
    padding: ${isCreateReply && `0 !important`};
  }
`;

export const contentForm = css`
  height: 120px;
  border: 0;
  border-radius: 0;
  padding: 14px 20px 42px 20px;

  & > div {
    left: 12px;
  }
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const labelContentTable = css`
  margin-bottom: 32px;
`;
