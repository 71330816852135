import React from "react";

import { LabelContentTable } from "components";
import { addrFormatter, formatICTDateTime, formatPhoneNum } from "utils";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementPickupInfo.styled";

interface OrderManagementPickupInfoProps {
  pickUpInfo?: GetOrderManagementDetailServerModel["pickUpInfo"];
}

const OrderManagementPickupInfo = ({
  pickUpInfo,
}: OrderManagementPickupInfoProps) => {
  if (!pickUpInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address" labelWidth={200}>
          <S.AddressWrapper>
            <div>{pickUpInfo.puName}</div>
            <S.AddressContent>
              {addrFormatter(pickUpInfo.puAddr, pickUpInfo.puAddrDetail)}
            </S.AddressContent>
          </S.AddressWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge" labelWidth={200}>
          {pickUpInfo.puPic} ({formatPhoneNum(pickUpInfo.puPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA" labelWidth={200}>
          {formatICTDateTime(pickUpInfo.puEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD" labelWidth={200}>
          {pickUpInfo.puEtd ? formatICTDateTime(pickUpInfo.puEtd) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementPickupInfo;
