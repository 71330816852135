import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  AutoCompletes,
  Button,
  Card,
  DetailLayout,
  Dropdown,
  ErrorMsg,
  FormFileInput,
  FormInput,
  InternalLink,
  LabelContent,
  PageBackHeader,
  PlateNumberModal,
  UnitSuffix,
} from "components";
import { useModal, useToast } from "hooks";
import {
  useGetLinkTruckDriver,
  useGetTruckGarage,
  useGetTruckGpss,
  usePostTruckCreate,
} from "services";
import { makeS3PresignedUrlFileUuids } from "api";
import { clientLanguage, truckTypeOptions } from "stores";
import {
  checkCargoSizeLimit,
  checkTwoDecimalDigit,
  formatPlateNumber,
  formatterDecimal,
  formatTruckOptions,
  formatTruckTypes,
  formatVin,
  getTruckGarageData,
  getYears,
  selectDropdownForm,
} from "utils";
import { HOOKFORM_ERR } from "assets";
import { PATH, TAB, TOAST_MSG, VALID_MSG } from "constants/index";
import type {
  AutoCompleteDropdown,
  DropdownOptionType,
  Languages,
  S3FileUploadType,
  TruckingCompanyType,
} from "types";
import * as S from "./MemberDetailRegisteredTruckCreate.styled";

interface TruckForm {
  plateNumber: string;
  autoCompletes: AutoCompleteDropdown[];
  garage: DropdownOptionType;
  truckType: DropdownOptionType;
  truckOption: DropdownOptionType;
  length: string;
  depth: string;
  height: string;
  brand: string;
  model: string;
  year: DropdownOptionType;
  vin: string;
  vehicleRegistration: FileList | null;
  truckPhoto: FileList | null;
}

const TRUCK_FORM_INIT_STATE = {
  plateNumber: "",
  autoCompletes: [{ name: "", id: "" }],
  garage: { key: "", label: "" as Languages },
  truckType: { key: "", label: "" as Languages },
  truckOption: { key: "", label: "" as Languages },
  length: "",
  depth: "",
  height: "",
  brand: "",
  model: "",
  year: getYears[0],
  vin: "",
  vehicleRegistration: null,
  truckPhoto: null,
};

const schema = yup.object({
  plateNumber: yup.string().required(VALID_MSG.REQUIRED),
  autoCompletes: yup.mixed<AutoCompleteDropdown[]>().defined(),
  garage: yup
    .mixed<DropdownOptionType>()
    .required(VALID_MSG.REQUIRED)
    .test("required", VALID_MSG.REQUIRED, (value) => !!value?.key),
  truckType: yup
    .mixed<DropdownOptionType>()
    .required(VALID_MSG.REQUIRED)
    .test("required", VALID_MSG.REQUIRED, (value) => !!value?.key),
  truckOption: yup
    .mixed<DropdownOptionType>()
    .required(VALID_MSG.REQUIRED)
    .test("required", VALID_MSG.REQUIRED, (value) => !!value?.key),
  length: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;

      return +value <= 13.56;
    }),
  depth: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;
      return +value <= 2.35;
    }),
  height: yup
    .string()
    .required(VALID_MSG.REQUIRED)
    .test("notZero", VALID_MSG.NOT_ZERO, (value) => value !== "0")
    .test("maxValue", VALID_MSG.MAX_VALUE_LIMIT, (value) => {
      if (!value) return true;

      return +value <= 2.7;
    }),
  brand: yup.string().defined(),
  model: yup.string().defined(),
  year: yup.mixed<DropdownOptionType>().defined(),
  vin: yup
    .string()
    .defined()
    .test("checkVin", VALID_MSG.VIN, (value) => value.length === 17)
    .required(VALID_MSG.REQUIRED),
  vehicleRegistration: yup
    .mixed<FileList>()
    .required(VALID_MSG.REQUIRED)
    .nullable()
    .test("required", VALID_MSG.REQUIRED, (value) => {
      return value instanceof FileList && value.length > 0;
    }),
  truckPhoto: yup.mixed<FileList>().defined().nullable(),
});

const MemberDetailRegisteredTruckCreate = () => {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptionsRecoil = useRecoilValue(truckTypeOptions);

  const formMethod = useForm<TruckForm>({
    mode: "onTouched",
    defaultValues: TRUCK_FORM_INIT_STATE,
    resolver: yupResolver(schema),
  });
  const { fields, update, append, remove } = useFieldArray({
    control: formMethod.control,
    name: "autoCompletes",
  });

  const memberType = searchParams.get("memberType");
  const query = {
    companyId: memberId!,
    companyType: (memberType === "forwarder"
      ? "forwardercarrier"
      : memberType) as TruckingCompanyType,
  };

  const { data: truckGarages } = useGetTruckGarage({ query });
  const { data: truckGpss } = useGetTruckGpss({ query });
  const { data: linkTruckDrivers } = useGetLinkTruckDriver({ query });
  const { mutate: truckCreateMutate } = usePostTruckCreate();

  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const truckTypes = formatTruckTypes(language, truckTypeOptionsRecoil || []);
  const truckOptions = formatTruckOptions(
    language,
    truckTypeOptionsRecoil || [],
    formMethod.watch("truckType")?.key!,
  );

  const formatLinkDrivers = linkTruckDrivers?.reduce(
    (acc: AutoCompleteDropdown[], current) => {
      if (current.truckId === memberId || !current.truckId) {
        acc.push({ name: current.name, id: current.driverId });
      }

      return acc;
    },
    [],
  );

  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_TRUCK}`;

  const controlledFields = fields.map((field, i) => ({
    ...field,
    ...formMethod.watch("autoCompletes")[i],
  }));

  const handleLinkSelect =
    (idx: number) =>
    (linkDriver: AutoCompleteDropdown): void => {
      update(idx, linkDriver);

      if (!formMethod.watch("autoCompletes")[idx].id && linkDriver.name) {
        formMethod.setError(`autoCompletes.${idx}`, {
          message: VALID_MSG.DRIVER_NAME,
        });
        return;
      }

      if (formMethod.watch("autoCompletes")[idx].id || !linkDriver.name) {
        formMethod.clearErrors(`autoCompletes.${idx}`);
        return;
      }
    };

  const handleAddLink = (): void => {
    append({ name: "", id: "" }, { shouldFocus: false });
  };

  const handleLinkDelete = (i: number) => (): void => {
    remove(i);
  };

  const getFormattedSizeValue = (
    prevValue: string,
    currentValue: string,
  ): string => {
    return (currentValue && !checkTwoDecimalDigit(currentValue)) ||
      !checkCargoSizeLimit(currentValue)
      ? prevValue
      : currentValue;
  };

  const handleBlurSizeInput = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    formMethod.setValue(
      name as "length" | "depth" | "height",
      `${+formatterDecimal(value ?? "")}`,
    );
  };

  const handlePlateListOpen = (): void => {
    !!truckGpss
      ? handleModalOpen(
          <Controller
            name="plateNumber"
            control={formMethod.control}
            render={({ field: { onChange } }) => {
              const handleRowClick = (plate: string) => (): void => {
                onChange(plate);
                handleModalClose();
              };

              const handleCancelClick = (): void => {
                handleModalClose();
              };

              return (
                <PlateNumberModal
                  ref={modalRef}
                  type="select"
                  plateNumbers={truckGpss}
                  handleRowClick={handleRowClick}
                  handleCancelClick={handleCancelClick}
                />
              );
            }}
          />,
        )()
      : addToast(TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  const handleFileDelete = (name: string) => (): void => {
    if (name === "vehicleRegistration") {
      formMethod.setError(name, { message: VALID_MSG.REQUIRED });
    }

    formMethod.resetField(name as keyof TruckForm);
  };

  const handleAddClick = formMethod.handleSubmit(
    async (data): Promise<void> => {
      if (data.vehicleRegistration === null) return;

      const files: S3FileUploadType = {
        vehicleRegistration: {
          file: data.vehicleRegistration[0],
          path: "license",
        },
        ...(data.truckPhoto && {
          truckPhoto: { file: data.truckPhoto[0], path: "profile" },
        }),
      };

      const fileUuids = await makeS3PresignedUrlFileUuids(files);

      const body = {
        plateNumber: data.plateNumber,
        garageId: data.garage.key,
        driverIds: data.autoCompletes.filter(({ id }) => !!id).length
          ? data.autoCompletes.map(({ id }) => id)
          : [],
        registration: fileUuids.vehicleRegistration,
        ...(data.truckPhoto && { truckPhoto: fileUuids.truckPhoto }),
        truckOptionId: +data.truckOption.key,
        width: +data.depth,
        length: +data.length,
        height: +data.height,
        brand: data.brand.trim(),
        model: data.model.trim(),
        year: +data.year.key,
        vin: data.vin,
      };

      truckCreateMutate(
        { query, body },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.TRUCK_CREATE_DONE);
            navigate(beforeUrl);
          },
          onError: (error) => {
            if (error.response?.data.response === "PLATE_NUMBER_EXISTS") {
              formMethod.setError("plateNumber", {
                type: "validate",
                message: HOOKFORM_ERR.PLATE_NUMBER_EXISTS,
              });
            } else if (error.response?.data.response === "VIN_EXISTS") {
              formMethod.setError("plateNumber", {
                type: "validate",
                message: HOOKFORM_ERR.VIN_EXISTS,
              });
            } else if (error.response?.data.response === "GARAGE_FULL") {
              addToast(TOAST_MSG.WARNING.GARAGE_FULL);
            }
          },
        },
      );
    },
  );

  if (!truckGarages) return null;

  return (
    <FormProvider {...formMethod}>
      <DetailLayout>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Add truck"
          path={beforeUrl}
        />
        <Card css={S.card}>
          <Card.Content css={S.cardContent} heading="Default information">
            <LabelContent
              css={S.plateNumberWrapper}
              label="Plate number"
              direction="vertical"
              isRequired
            >
              <FormInput
                maxLength={11}
                placeholder="Search by plate number"
                hasError={!!formMethod.formState.errors.plateNumber?.message}
                value={formMethod.watch("plateNumber")}
                register={formMethod.register("plateNumber", {
                  setValueAs: formatPlateNumber,
                })}
              />
              <S.PlateList>
                {`- ${t("Do you want to check plate number list?")}`}
                <Button
                  css={S.viewDetailsButton}
                  variant="text"
                  label="View details"
                  handleClickBtn={handlePlateListOpen}
                />
              </S.PlateList>
              {formMethod.formState.errors.plateNumber?.message && (
                <ErrorMsg
                  errorMsg={
                    formMethod.formState.errors.plateNumber
                      ?.message as Languages
                  }
                />
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Parked garage"
              direction="vertical"
              isRequired
            >
              <Controller
                name="garage"
                control={formMethod.control}
                render={({ field: { value, onChange } }) => {
                  const options = getTruckGarageData(truckGarages);

                  return (
                    <Dropdown
                      disabled={!options?.length}
                      hasError={!!formMethod.formState.errors.garage?.message}
                      placeholder="Select the garage"
                      options={options}
                      selectedOption={value}
                      handleSelect={selectDropdownForm(options, onChange)}
                    />
                  );
                }}
              />
              {formMethod.formState.errors.garage?.message && (
                <ErrorMsg
                  errorMsg={
                    formMethod.formState.errors.garage?.message as Languages
                  }
                />
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Linking"
              direction="vertical"
            >
              {formatLinkDrivers && (
                <AutoCompletes
                  placeholder="Enter the driver's name"
                  id="link"
                  dropdowns={formatLinkDrivers}
                  fields={controlledFields}
                  handleDropdownSelect={handleLinkSelect}
                  handleDropdownSlotDelete={handleLinkDelete}
                  handleDropdownSlotAdd={handleAddLink}
                />
              )}
            </LabelContent>
          </Card.Content>
          <Card.Content css={S.cardContent} heading="Specification">
            <LabelContent
              css={S.labelContent}
              label="Truck type"
              direction="vertical"
              isRequired
            >
              <Controller
                name="truckType"
                control={formMethod.control}
                render={({ field: { value, onChange } }) => {
                  const selectDropdown = (key: string) => {
                    formMethod.resetField("truckOption");
                    selectDropdownForm(truckTypes, onChange)(key);
                  };

                  return (
                    <Dropdown
                      placeholder="Select the truck type"
                      hasError={
                        !!formMethod.formState.errors.truckType?.message
                      }
                      options={truckTypes}
                      selectedOption={value}
                      handleSelect={selectDropdown}
                    />
                  );
                }}
              />
              {formMethod.formState.errors.truckType?.message && (
                <ErrorMsg
                  errorMsg={
                    formMethod.formState.errors.truckType?.message as Languages
                  }
                />
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Truck option"
              direction="vertical"
              isRequired
            >
              <Controller
                name="truckOption"
                control={formMethod.control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      placeholder="Select the truck option"
                      hasError={
                        !!formMethod.formState.errors.truckOption?.message
                      }
                      options={truckOptions}
                      selectedOption={value}
                      disabled={!formMethod.watch("truckType").key}
                      handleSelect={selectDropdownForm(truckOptions, onChange)}
                    />
                  );
                }}
              />
              {formMethod.formState.errors.truckOption?.message && (
                <ErrorMsg
                  errorMsg={
                    formMethod.formState.errors.truckOption
                      ?.message as Languages
                  }
                />
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Container size"
              direction="vertical"
              isRequired
            >
              <S.UnitSuffixWrapper>
                <UnitSuffix
                  id="length"
                  unit="m"
                  value={formMethod.watch("length")}
                  err={formMethod.formState.errors.length?.message}
                  placeholder="Length"
                  register={formMethod.register("length", {
                    setValueAs: (currentValue: string) =>
                      getFormattedSizeValue(
                        `${formMethod.watch("length")}`,
                        currentValue,
                      ),
                    onBlur: handleBlurSizeInput,
                  })}
                />
                x
                <UnitSuffix
                  id="depth"
                  unit="m"
                  value={formMethod.watch("depth")}
                  err={formMethod.formState.errors.depth?.message}
                  placeholder="Depth"
                  register={formMethod.register("depth", {
                    setValueAs: (currentValue: string) =>
                      getFormattedSizeValue(
                        `${formMethod.watch("depth")}`,
                        currentValue,
                      ),
                    onBlur: handleBlurSizeInput,
                  })}
                />
                x
                <UnitSuffix
                  id="height"
                  unit="m"
                  value={formMethod.watch("height")}
                  err={formMethod.formState.errors.height?.message}
                  placeholder="Height"
                  register={formMethod.register("height", {
                    setValueAs: (currentValue: string) =>
                      getFormattedSizeValue(
                        `${formMethod.watch("height")}`,
                        currentValue,
                      ),
                    onBlur: handleBlurSizeInput,
                  })}
                />
              </S.UnitSuffixWrapper>
              <S.Desc>
                {`- ${t(
                  "Maximum: Length 13.56m, Depth 2.35m, Height 2.7m" as Languages,
                )}`}
              </S.Desc>
              {(formMethod.formState.errors?.length?.message ||
                formMethod.formState.errors?.depth?.message ||
                formMethod.formState.errors?.height?.message) && (
                <ErrorMsg
                  errorMsg={
                    (formMethod.formState.errors?.length
                      ?.message as Languages) ||
                    (formMethod.formState.errors?.depth
                      ?.message as Languages) ||
                    (formMethod.formState.errors?.height?.message as Languages)
                  }
                />
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Brand"
              direction="vertical"
            >
              <FormInput
                placeholder="Brand"
                value={formMethod.watch("brand")}
                maxLength={100}
                register={formMethod.register("brand")}
              />
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Model"
              direction="vertical"
            >
              <FormInput
                placeholder="Model"
                value={formMethod.watch("model")}
                maxLength={100}
                register={formMethod.register("model")}
              />
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Year"
              direction="vertical"
            >
              <Controller
                name="year"
                control={formMethod.control}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    css={S.yearDropdown}
                    placeholder=""
                    options={getYears}
                    selectedOption={value || getYears[0]}
                    handleSelect={selectDropdownForm(getYears, onChange)}
                  />
                )}
              />
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="VIN"
              direction="vertical"
              isRequired
            >
              <FormInput
                placeholder="Enter 17 characters"
                value={formMethod.watch("vin")}
                hasError={!!formMethod.formState.errors.vin?.message}
                maxLength={17}
                register={formMethod.register("vin", {
                  required: "You can’t leave this empty.",
                  setValueAs: formatVin,
                  validate: (value) =>
                    value.length === 17 || "Please enter a valid VIN.",
                })}
              />
              {formMethod.formState.errors.vin?.message && (
                <ErrorMsg
                  errorMsg={
                    formMethod.formState.errors.vin?.message as Languages
                  }
                />
              )}
            </LabelContent>
          </Card.Content>
          <Card.Content css={S.cardContent} heading="Attachments">
            <LabelContent
              css={S.labelContent}
              label="Vehicle registration certificate"
              direction="vertical"
              isRequired
            >
              <FormFileInput
                placeholder={"Upload vehicle registration certificate"}
                file={formMethod.watch("vehicleRegistration")}
                hasErr={
                  !!formMethod.formState.errors.vehicleRegistration?.message
                }
                register={formMethod.register("vehicleRegistration")}
                handleDeleteFile={handleFileDelete("vehicleRegistration")}
              />
              <S.Desc>
                {`- ${t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}`}
              </S.Desc>
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Truck photo"
              direction="vertical"
            >
              <FormFileInput
                placeholder="Upload vehicle registration certificate"
                file={formMethod.watch("truckPhoto")}
                hasErr={false}
                register={formMethod.register("truckPhoto")}
                handleDeleteFile={handleFileDelete("truckPhoto")}
              />
              <S.Desc>
                {`- ${t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}`}
              </S.Desc>
            </LabelContent>
          </Card.Content>
        </Card>
        <S.ButtonWrapper>
          <InternalLink variant="outlineMedium" to={beforeUrl}>
            {t("Cancel")}
          </InternalLink>
          <Button
            variant="primaryMedium"
            label="Add"
            handleClickBtn={handleAddClick}
          />
        </S.ButtonWrapper>
      </DetailLayout>
    </FormProvider>
  );
};

export default MemberDetailRegisteredTruckCreate;
