import React, { useState } from "react";

import { AccountIdForm, CreateNewPassword } from "components";
import * as S from "./ResetPassword.styled";

const ResetPassword = () => {
  const [isSuccessEmailCheck, setIsSuccessEmailCheck] = useState(false);

  const handleCheckEmail = () => {
    setIsSuccessEmailCheck(true);
  };

  return (
    <S.Root>
      <S.Form>
        {!isSuccessEmailCheck ? (
          <>
            <S.Title>Reset password</S.Title>
            <S.P>
              Enter the email of the account that you wish to reset the password
              <br />
              for. We'll send you a verification code.
            </S.P>
             
          </>
        ) : (
          <S.PasswordTitle>Create a new password</S.PasswordTitle>
        )}
        {!isSuccessEmailCheck ? (
          <AccountIdForm emailCheckSuccessFn={handleCheckEmail} />
        ) : (
          <CreateNewPassword />
        )}
      </S.Form>
    </S.Root>
  );
};

export default ResetPassword;
