import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const layout = (theme: Theme) => css`
  & > h1 {
    ${theme.font.bold_16};
    margin-bottom: 32px;
  }
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin: 40px 0;
    border: 0;
    border-top: 1px solid ${theme.color.gray_20};
  `}
`;
