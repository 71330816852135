import React from "react";

import { ErrorMsg, AccountInput, PasswordInput, Button } from "components";
import { LoginLogoLargeIcon } from "assets";
import useLoginForm from "./hooks/useLoginForm";
import * as S from "./Login.styled";

const Login = () => {
  const { loginError, formMethod, handleSubmit, handleClearLoginError } =
    useLoginForm();

  return (
    <S.Login>
      <LoginLogoLargeIcon />
      <S.Form onSubmit={handleSubmit}>
        <S.InputWrapper>
          <AccountInput
            value={formMethod.watch("id")}
            label="Email"
            id="email"
            register={formMethod.register("id", {
              onChange: handleClearLoginError,
            })}
          />
          <PasswordInput
            value={formMethod.watch("password")}
            label="Password"
            id="password"
            type="password"
            register={formMethod.register("password", {
              onChange: handleClearLoginError,
            })}
          />
          {loginError?.message ? (
            <ErrorMsg errorMsg={loginError.message} />
          ) : formMethod.formState.errors?.id?.message ? (
            <ErrorMsg errorMsg={formMethod.formState.errors?.id?.message} />
          ) : (
            formMethod.formState.errors?.password?.message && (
              <ErrorMsg
                errorMsg={formMethod.formState.errors?.password?.message}
              />
            )
          )}
        </S.InputWrapper>
        <Button
          css={S.button}
          type="submit"
          variant="primaryLarge"
          isDisabled={
            !!Object.keys(formMethod.formState.errors).length || !!loginError
          }
          label="Sign in"
        />
        {/* <Link css={S.linkResetPassword} to="/resetPassword"> //NOTE: 이번 업데이트에서 제외하기로 하여 주석처리함
          Reset password
        </Link> */}
      </S.Form>
    </S.Login>
  );
};

export default Login;
