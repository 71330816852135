import { useQuery } from "@tanstack/react-query";

import { getCommonPlayerAPI, getTruckTypeOptionsAPI } from "api";

export const useGetCommonPlayer = () => {
  return useQuery({
    queryKey: ["commonPlayer"],
    queryFn: () => getCommonPlayerAPI(),
  });
};

export const useGetTruckTypeOptions = (enabled: boolean) => {
  return useQuery({
    queryKey: ["truckOptions"],
    queryFn: () => getTruckTypeOptionsAPI(),
    enabled,
  });
};
