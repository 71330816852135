import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  cancelOrderAPI,
  getOrderDetailAPI,
  getOrderManagementAPI,
  getOrderManagementHistoryTrackingAPI,
} from "api";
import type {
  APIError,
  DeleteOrderManagementQueryModel,
  GetOrderManagementQueryModel,
  GetOrderManagementServerModel,
} from "types";

const orderKeys = {
  all: ["order"],
  lists: () => [...orderKeys.all, "list"],
  list: (filters: GetOrderManagementQueryModel) => [
    ...orderKeys.lists(),
    { filters },
  ],
  details: () => [...orderKeys.all, "detail"] as const,
  detail: (id: string) => [...orderKeys.details(), id] as const,
  trackingHistories: () =>
    [...orderKeys.all, "OrderDetailTrackingHistory"] as const,
  trackingHistory: (orderId: string) =>
    [...orderKeys.trackingHistories(), orderId] as const,
};

export const useGetOrderManagements = (
  req: GetOrderManagementQueryModel,
  options?: UseQueryOptions<
    GetOrderManagementServerModel,
    APIError,
    GetOrderManagementServerModel,
    ReturnType<typeof orderKeys.list>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: orderKeys.list(req),
    queryFn: () => getOrderManagementAPI(req),
  });
};

export const useGetOrderDetail = (orderId: string) => {
  return useQuery({
    queryKey: orderKeys.detail(orderId),
    queryFn: () => getOrderDetailAPI(orderId),
  });
};

export const useCancelOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, DeleteOrderManagementQueryModel>({
    mutationFn: (req) => cancelOrderAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(orderKeys.detail(req.orderId));
    },
  });
};

export const useOrderDetailTrackingHistory = (orderId: string) => {
  return useQuery({
    queryKey: orderKeys.trackingHistory(orderId),
    queryFn: () => getOrderManagementHistoryTrackingAPI(orderId),
  });
};
