import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface ShipperPaginationProps {
  pageInfo?: PageInfo;
}

const ShipperPagination = ({ pageInfo }: ShipperPaginationProps) => {
  if (!pageInfo) return null;

  return <QueryPagination pageInfo={pageInfo} />;
};

export default ShipperPagination;
