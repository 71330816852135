import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { checkPasswordValidation } from "utils";
import { HOOKFORM_ERR } from "assets";
import { PATH } from "constants/index";

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const schema = yup.object({
  newPassword: yup
    .string()
    .defined()
    .test("check", HOOKFORM_ERR.VALID_PASSWORD, (value) =>
      checkPasswordValidation(value),
    )
    .required(HOOKFORM_ERR.PASSWORD)
    .min(8, HOOKFORM_ERR.PASSWORD_LENGTH)
    .max(16, HOOKFORM_ERR.PASSWORD_LENGTH),
  confirmPassword: yup
    .string()
    .defined()
    .required(HOOKFORM_ERR.PASSWORD)
    .oneOf(
      [yup.ref("newPassword")],
      "Password and confirm password does not match.",
    ),
});

const useCreateNewPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues: { newPassword: "", confirmPassword: "" },
    resolver: yupResolver(schema),
  });

  const handleResetPassword = (data: ResetPasswordForm) => {
    navigate(PATH.ROOT); // TODO: api 연동 후 코드 추가예정
  };

  return {
    register,
    watch,
    errors,
    clearErrors,
    handleSubmit: handleSubmit(handleResetPassword),
  };
};

export default useCreateNewPassword;
