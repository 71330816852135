import React from "react";
import ReactDOM from "react-dom/client";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";

import App from "./App";
import "assets/lang/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);
