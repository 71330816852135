import { makeS3PresignedUrlFileUuids } from "api";
import { COMPANY_LOOKUP_TABLE } from "assets";

type MemberImg = {
  license1: string | FileList | null;
  license2: string | FileList | null;
  transportLicense1: string | FileList | null;
  transportLicense2: string | FileList | null;
};

type ImgUrl = {
  license1: string | null;
  license2: string | null;
  transportLicense1: string | null;
  transportLicense2: string | null;
};

export const makeMemberS3PresignedUrlFileUuids = async (
  companyType: (typeof COMPANY_LOOKUP_TABLE)[keyof typeof COMPANY_LOOKUP_TABLE],
  { license1, license2, transportLicense1, transportLicense2 }: MemberImg,
) => {
  const imgUrl: ImgUrl = {
    license1: null,
    license2: null,
    transportLicense1: null,
    transportLicense2: null,
  };

  const res = await makeS3PresignedUrlFileUuids({
    ...(typeof license1 === "object" &&
      license1 && {
        license1: { file: license1?.[0], path: "license" },
      }),
    ...(typeof license2 === "object" &&
      license2 && {
        license2: { file: license2?.[0], path: "license" },
      }),
    ...((companyType === "CARRIER" || companyType === "FORWARDERCARRIER") &&
      typeof transportLicense1 === "object" &&
      transportLicense1 && {
        transportLicense1: { file: transportLicense1?.[0], path: "license" },
      }),
    ...((companyType === "CARRIER" || companyType === "FORWARDERCARRIER") &&
      typeof transportLicense2 === "object" &&
      transportLicense2 && {
        transportLicense2: { file: transportLicense2?.[0], path: "license" },
      }),
  });

  imgUrl.license1 = res.license1;
  imgUrl.license2 = res.license2;
  imgUrl.transportLicense1 = res.transportLicense1;
  imgUrl.transportLicense2 = res.transportLicense2;

  return {
    license1: imgUrl.license1,
    license2: imgUrl.license2,
    transportLicense1: imgUrl.transportLicense1,
    transportLicense2: imgUrl.transportLicense2,
  };
};
