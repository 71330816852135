import React, { ButtonHTMLAttributes } from "react";

import * as S from "./SubmitBtn.styled";

interface SubmitBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  hasError?: boolean;
}

const SubmitBtn = ({ label, hasError }: SubmitBtnProps) => {
  return <S.Button disabled={hasError}>{label}</S.Button>;
};

export default SubmitBtn;
