import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  position: relative;
  height: 36px;
`;

export const SearchBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: calc(50% - 10px);
    left: 12px;
    width: 24px;
    height: 24px;

    svg path {
      fill: ${theme.color.gray_70};
    }
  `}
`;

export const input = (theme: Theme) => css`
  height: 100%;
  padding: 0 calc(12px + 20px) 0 calc(12px + 20px + 8px);
  border: 1px solid ${theme.color.gray_30};
  border-radius: 0;
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    right: 12px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;
