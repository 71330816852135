import React from "react";
import { useTranslation } from "react-i18next";

import { formatICTDateTime } from "utils";
import { GaragePointIcon } from "assets"; //TODO: 임의로 garageIcon 사용-> 추후 아이콘 전체적으로 수정 필요
import type { PickDropType } from "types";
import * as S from "./AllocationLocation.styled";

interface AllocationLocationProps {
  children: React.ReactNode;
}

const AllocationLocation = ({ children }: AllocationLocationProps) => {
  return <S.Root>{children}</S.Root>;
};

interface AllocationLocationDetailProps {
  type: PickDropType;
  destination: string;
  state: string;
}

AllocationLocation.Detail = function DetailItem({
  type,
  destination,
  state,
}: AllocationLocationDetailProps) {
  const { t } = useTranslation();

  const State = (state: string) => {
    switch (state) {
      case "ONGOING":
        return <S.Ongoing>{t("Ongoing")}</S.Ongoing>;
      case "-":
      case undefined:
      case null:
        return <S.NotYet>-</S.NotYet>;
      default:
        return <S.Time>{formatICTDateTime(state)}</S.Time>;
    }
  };

  return (
    <S.PointDetail>
      <div>
        <GaragePointIcon css={S.pointIcon(type)} />
        <S.PointDestination>{destination}</S.PointDestination>
      </div>
      {State(state)}
    </S.PointDetail>
  );
};

export default AllocationLocation;
