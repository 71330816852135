import { ax } from "api/axios";
import type {
  GetSettlementDetailServerModel,
  GetSettlementQueryModel,
  GetSettlementServerModel,
  putSettlementStatusChangeQueryModel,
} from "types";

export const getSettlementsAPI = async (req: GetSettlementQueryModel) => {
  const { data } = await ax.get<GetSettlementServerModel>("/settlement/admin", {
    params: req.query,
  });

  return data;
};

export const getSettlementDetailAPI = async (offerId: string) => {
  const { data } = await ax.get<GetSettlementDetailServerModel>(
    `/settlement/${offerId}/admin`,
  );

  return data;
};

export const putSettlementStatusChangeAPI = async (
  req: putSettlementStatusChangeQueryModel,
) => {
  const { data } = await ax.put(`/settlement/${req.offerId}`, req.body);

  return data;
};
