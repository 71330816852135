import { useId } from "react";
import { useSetRecoilState } from "recoil";

import { toastAtom } from "stores";
import { Toast } from "types";

const useToast = () => {
  const setToastCompo = useSetRecoilState(toastAtom);
  const id = useId();
  let cnt = 0;

  const addToast = (toast: Omit<Toast, "id">) => {
    cnt += 1;

    setToastCompo((prev) => [{ id: id + cnt, ...toast }, ...prev]);
  };

  const removeToast = (id: string) =>
    setToastCompo((prev) => prev.filter((toast) => toast.id !== id));

  return { addToast, removeToast };
};

export default useToast;
