import React from "react";
import { keys } from "lodash-es";

import { Button, StatusLabel, Table, Tooltip } from "components";
import { useModal } from "hooks";
import { calculateTotalAmount, formatPrice } from "utils";
import { MemoIcon, QUOTATION_DETAIL_TABLE_HEADER } from "assets";
import type { GetQuotationDetailServerModel } from "types";
import { SendToShipperModal, QuotationDetailsModal } from "./container";
import * as S from "./QuotationSection.styled";

interface QuotationSectionProps {
  defaultInfo?: GetQuotationDetailServerModel["defaultInfo"];
  histories?: GetQuotationDetailServerModel["history"];
}

const QuotationSection = ({
  defaultInfo,
  histories,
}: QuotationSectionProps) => {
  const { modalRef, handleModalOpen } = useModal();

  if (!defaultInfo || !histories) return null;

  const formatTableData = (
    history: GetQuotationDetailServerModel["history"][number],
    key: keyof typeof QUOTATION_DETAIL_TABLE_HEADER,
  ) => {
    switch (key) {
      case "status":
        return (
          <StatusLabel
            css={S.status(history[key])}
            status={
              defaultInfo.offerStatus === "COMPLETED" &&
              history[key] === "ACCEPTED"
                ? defaultInfo.offerStatus
                : history[key]
            }
          />
        );

      case "truckingCompany":
        return (
          <S.TruckingCompanyWrapper>
            <span>{history.name}</span>
            {history.remarks && (
              <Tooltip
                css={S.tooltipBtn}
                title="Memo"
                contents={history.remarks}
                tooltipBtnContent={MemoIcon}
              />
            )}
          </S.TruckingCompanyWrapper>
        );

      case "price": {
        const totalPrice = formatPrice(
          calculateTotalAmount(`${history.price}`, `${history.vat}`),
        );

        return (
          <S.PriceWrapper>
            <span>{totalPrice}</span>
          </S.PriceWrapper>
        );
      }

      case "":
        if (history.status === "ANSWERED") {
          return (
            <S.BtnWrapper>
              <Button
                variant="primaryMedium"
                label="Reply"
                handleClickBtn={handleModalOpen(
                  <SendToShipperModal modalRef={modalRef} history={history} />,
                )}
              />
            </S.BtnWrapper>
          );
        }

        if (history.status === "ACCEPTED") {
          return (
            <S.BtnWrapper>
              <Button
                css={S.detailsBtn}
                variant="ghostGray"
                label="Details"
                handleClickBtn={handleModalOpen(
                  <QuotationDetailsModal
                    modalRef={modalRef}
                    quotationHistory={history}
                    companyInfo={{
                      truckingCompany: history.name,
                      receivedPrice: formatPrice(
                        calculateTotalAmount(
                          `${history.price}`,
                          `${history.vat}`,
                        ),
                      ),
                    }}
                    paymentMethod={history.pay}
                    trucks={history.trucks}
                  />,
                )}
              />
            </S.BtnWrapper>
          );
        }
        break;

      default:
        break;
    }
  };

  return (
    <Table
      css={S.table}
      title="Quotation Table"
      columnTable={QUOTATION_DETAIL_TABLE_HEADER}
    >
      {histories.length &&
        histories.map((history, i) => (
          <Table.Row css={S.row} key={i}>
            {keys(QUOTATION_DETAIL_TABLE_HEADER).map((key) => (
              <Table.Td key={key} css={S.status(history.status)}>
                {formatTableData(
                  history,
                  key as keyof typeof QUOTATION_DETAIL_TABLE_HEADER,
                )}
              </Table.Td>
            ))}
          </Table.Row>
        ))}
    </Table>
  );
};

export default QuotationSection;
