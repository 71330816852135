import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import { useGetMemberDetailRegisteredMember } from "services";
import type {
  TruckingCompanyType,
  GetMemberDetailRegisteredMemberQueryModel,
} from "types";
import {
  RegisteredMemberFilter,
  RegisteredMemberPagination,
  RegisteredMemberTable,
} from "./container";

const MemberDetailRegisteredMember = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<
    GetMemberDetailRegisteredMemberQueryModel["query"]
  >(["page", "name"]);

  const memberType = searchParams.get("memberType");
  const req = {
    query: {
      ...filters,
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { data, dataUpdatedAt, refetch } =
    useGetMemberDetailRegisteredMember(req);

  return (
    <>
      <RegisteredMemberFilter />
      <RegisteredMemberTable
        staffs={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <RegisteredMemberPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default MemberDetailRegisteredMember;
