import React from "react";
import { useSearchParams } from "react-router-dom";

import { ShipperMonitoringMap, TruckMonitoringMap } from "components";
import { COMPANY_LOOKUP_TABLE } from "assets";

const MonitoringMap = () => {
  const [searchParams] = useSearchParams();

  const companyType = searchParams.get(
    "companyType",
  ) as keyof typeof COMPANY_LOOKUP_TABLE;

  return (
    <>
      {companyType === "shipper" ? (
        <ShipperMonitoringMap />
      ) : (
        <TruckMonitoringMap />
      )}
    </>
  );
};

export default MonitoringMap;
