import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  TruckAllocationSection,
  PageBackHeader,
  QueryPagination,
} from "components";
import { useFilterParams } from "hooks";
import { useGetMemberDetailTruckDetailAllocation } from "services";
import { TAB, PATH } from "constants/index";
import { MEMBER_DETAIL_TRUCK_ALLOCATION_TABLE_HEADER } from "assets";
import type {
  GetMemberDetailTruckDetailAllocationQueryModel,
  TruckingCompanyType,
} from "types";
import * as S from "./MemberDetailRegisteredTruckDetailAllocation.styled";

const MemberDetailRegisteredTruckDetailAllocation = () => {
  const { memberId, truckId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<
    GetMemberDetailTruckDetailAllocationQueryModel["query"]
  >(["page", "perSize", "companyId", "companyType"]);

  const driverDetailPath = `${PATH.MEMBER}/${memberId}/${TAB.MEMBER.REGISTERED_TRUCK}/${truckId}`;
  const memberType = searchParams.get("memberType");

  const req = {
    truckId: truckId!,
    query: {
      ...filters,
      pageSize: "20",
      companyId: memberId!,
      companyType:
        memberType === "forwarder"
          ? "forwardercarrier"
          : (memberType as TruckingCompanyType),
    },
  };

  const { data, dataUpdatedAt, refetch } =
    useGetMemberDetailTruckDetailAllocation(req);

  return (
    <S.Root>
      <S.TitleWrapper>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Allocation list"
          path={`${driverDetailPath}?memberType=${memberType}`}
        />
        <span>({data?.plateName})</span>
      </S.TitleWrapper>
      <TruckAllocationSection
        data={data?.allocationList}
        columns={MEMBER_DETAIL_TRUCK_ALLOCATION_TABLE_HEADER}
        dataUpdatedAt={dataUpdatedAt}
        pageInfo={data?.pageInfo}
        refetch={refetch}
      />
      <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
    </S.Root>
  );
};

export default MemberDetailRegisteredTruckDetailAllocation;
