import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
`;

export const OpenerBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 100%;

    & > svg {
      width: 20px;
      height: 20px;
      margin-top: 4px;
      pointer-events: none;

      & > path {
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;

export const TooltipBox = styled.dl<{ position: "BOTTOM_RIGHT" }>`
  //  NOTE: 현재 BOTTOM_RIGHT의 경우밖에 없어 해당 css만 추가
  ${({ theme, position }) => css`
    position: absolute;
    top: ${position === "BOTTOM_RIGHT" && "calc(100% + 4px)"};
    left: ${position === "BOTTOM_RIGHT" && "0"};
    width: 307px;
    border-radius: 2px;
    padding: 12px 16px;
    background-color: ${theme.color.secondary};
    box-shadow: ${theme.boxShadow.shadow_regular};
    z-index: 10;
  `}
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 12px;
    right: 12px;

    & > svg {
      width: 12px;
      height: 12px;

      & > path {
        fill: ${theme.color.white};
        pointer-events: none;
      }
    }
  `}
`;

export const Title = styled.dt`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 4px;
    color: ${theme.color.white};
  `}
`;

export const Desc = styled.dd`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_50};
    white-space: pre-line;
  `}
`;
