import React from "react";
import { keys } from "lodash-es";
import { useTranslation } from "react-i18next";

import { DataSection, StatusLabel } from "components";
import { findLookupTableLabel, formatICTDateTime } from "utils";
import {
  SUPPORT_COMPANY_TYPE_RADIOS,
  SUPPORT_INQUIRY_CATEGORY_RADIOS,
  SUPPORT_INQUIRY_TABLE_HEADER,
} from "assets";
import type { GetInquiriesServerModel } from "types";
import * as S from "./InquiryTable.styled";

interface InquiryTableProps {
  inquiries?: GetInquiriesServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const InquiryTable = ({
  inquiries,
  dataUpdatedAt,
  refetch,
}: InquiryTableProps) => {
  const { t } = useTranslation();

  const formatTableData = (
    data: GetInquiriesServerModel["inquires"][number],
    key: keyof GetInquiriesServerModel["inquires"][number],
  ) => {
    switch (key) {
      case "status":
        return <StatusLabel status={data[key]} />;

      case "category":
        const categoryLabel = findLookupTableLabel(
          SUPPORT_INQUIRY_CATEGORY_RADIOS,
          data[key],
        );

        return categoryLabel ? t(categoryLabel) : "-";

      case "companyType":
        const companyTypeLabel = findLookupTableLabel(
          SUPPORT_COMPANY_TYPE_RADIOS,
          data[key],
        );

        return companyTypeLabel ? t(companyTypeLabel) : "-";

      case "created":
      case "answered":
        return data[key]
          ? formatICTDateTime(data[key], "DD/MM/YY, HH:mm")
          : "-";

      default:
        return data[key] ?? "-";
    }
  };

  if (!inquiries) return null;

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={inquiries?.pageInfo.totalData!}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Shipper"
        columnTable={SUPPORT_INQUIRY_TABLE_HEADER}
      >
        {inquiries?.inquires.length &&
          inquiries.inquires.map((inquiry) => (
            <DataSection.Table.SelectRowMovePage
              id={inquiry.inquiryId}
              key={inquiry.inquiryId}
              path={inquiry.inquiryId}
            >
              {keys(SUPPORT_INQUIRY_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(inquiry, key as keyof typeof inquiry)}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default InquiryTable;
