import React from "react";
import { useParams } from "react-router-dom";
import { isNil, keys } from "lodash-es";

import { AlertModal, Button, DataSection, InternalLink } from "components";
import { useCurrentQueryParams, useModal, useToast } from "hooks";
import { useDeleteGarage } from "services";
import { addrFormatter, comma } from "utils";
import {
  MEMBER_REGISTERED_GARAGE_TABLE_HEADER,
  EditIcon,
  TrashIcon,
} from "assets";
import { TOAST_MSG } from "constants/index";
import type { GetMemberDetailGaragesServerModel } from "types";
import * as S from "./RegisteredGarage.styled";

interface RegisteredGarageTableProps {
  garages?: GetMemberDetailGaragesServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}
const RegisteredGarageTable = ({
  garages,
  dataUpdatedAt,
  refetch,
}: RegisteredGarageTableProps) => {
  const { memberId } = useParams();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const { addToast } = useToast();
  const { modalRef, handleModalClose, handleModalOpen } = useModal();
  const { isLoading, mutate: deleteGarageMutate } = useDeleteGarage();

  const formatTableData = (
    data: GetMemberDetailGaragesServerModel["garages"][number],
    key: keyof typeof MEMBER_REGISTERED_GARAGE_TABLE_HEADER,
  ): string | React.ReactNode => {
    switch (key) {
      case "address":
        return addrFormatter(data.address, data.addressDetail) ?? "-";

      case "usage":
        return isNil(data.usage) ? "-" : comma(data.usage);

      case "capacity":
        return isNil(data.capacity) ? "-" : comma(data.capacity);

      case "": {
        const handleDeleteButtonClick = (garageId: string) => (): void => {
          const req = {
            garageId,
            query: {
              companyId: memberId!,
              companyType:
                memberType === "forwarder"
                  ? ("forwardercarrier" as const)
                  : ("carrier" as const),
            },
          };

          deleteGarageMutate(req, {
            onSuccess: () => addToast(TOAST_MSG.SUCCESS.GARAGE_DELETE),
            onError: (error) => {
              if (error.response?.data.response === "CONNECTED_TRUCK_EXISTS") {
                addToast(TOAST_MSG.WARNING.DELETE_GARAGE_EXISTING_TRUCK);
              }
            },
            onSettled: handleModalClose,
          });
        };

        return (
          <S.ButtonWrapper>
            <Button
              css={S.deleteButton}
              variant="icon"
              label=""
              icon={<TrashIcon css={S.icon} />}
              handleClickBtn={handleModalOpen(
                <AlertModal
                  css={S.alertModal}
                  ref={modalRef}
                  isLoading={isLoading}
                  title="Delete the garage"
                  desc="Are you sure you want to delete the garage?"
                  negBtnLabel="Delete"
                  closeBtnLabel="Cancel"
                  callbackFn={handleDeleteButtonClick(data.garageId)}
                />,
              )}
            />
            <InternalLink
              css={S.editLink}
              variant="icon"
              to={`registeredGarage/edit/${data.garageId}?memberType=${memberType}`}
            >
              <EditIcon css={S.icon} />
            </InternalLink>
          </S.ButtonWrapper>
        );
      }
      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={garages ? garages.pageInfo.totalData : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="garage list"
        columnTable={MEMBER_REGISTERED_GARAGE_TABLE_HEADER}
      >
        {garages?.garages.length &&
          garages?.garages.map((garage) => (
            <DataSection.Table.Row key={garage.garageId}>
              {keys(MEMBER_REGISTERED_GARAGE_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    garage,
                    key as keyof typeof MEMBER_REGISTERED_GARAGE_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.Row>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default RegisteredGarageTable;
