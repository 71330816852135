import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable, ModalPagination } from "components";
import { findLookupTableLabel, formatQuantity, formatWeight } from "utils";
import { CARGO_TYPE, PACKAGING_TYPE } from "assets";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationCargoDetails.styled";

interface QuotationCargoDetailsProps {
  cargoes?: GetQuotationDetailServerModel["cargoes"];
}

const QuotationCargoDetails = ({ cargoes }: QuotationCargoDetailsProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const handleMovePage = (pageNumber: number) => setCurrentPage(pageNumber);

  if (!cargoes) return null;

  const cargo = cargoes[currentPage - 1];

  const cargoTypeLabel = findLookupTableLabel(CARGO_TYPE, cargo.cargoType);
  const packagingTypeLabel = findLookupTableLabel(
    PACKAGING_TYPE,
    cargo.packagingType,
  );
  const hazardGoodsLabel = cargo.hazardGoods ? t("Yes") : t("No");
  const doubleLoadingLabel = cargo.doubleLoading ? t("Yes") : t("No");

  return (
    <>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Type" labelWidth={200}>
            {cargoTypeLabel ? t(cargoTypeLabel) : cargo.cargoType}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Packaging type" labelWidth={200}>
            {packagingTypeLabel ? t(packagingTypeLabel) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Cargo" labelWidth={200}>
            {`${cargo.width}m x ${cargo.height}m x ${
              cargo.length
            }m / ${formatWeight(cargo.weight)} / ${formatQuantity(
              cargo.cargoCnt,
            )}`}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Hazard goods" labelWidth={200}>
            {hazardGoodsLabel}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Stackable" labelWidth={200}>
            {doubleLoadingLabel}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <ModalPagination
        css={S.pagination}
        currentPage={currentPage}
        pageInfo={{
          currentPage,
          dataPerPage: 10,
          startRow: 1,
          totalData: cargoes.length,
          totalPages: cargoes.length,
        }}
        movePage={handleMovePage}
      />
    </>
  );
};

export default QuotationCargoDetails;
