import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Filter, CompanySearchModal } from "components";
import { useModal } from "hooks";
import { useGetCommonPlayer } from "services";
import { selectCompanyInfo, getAllQuery } from "utils";
import { COMPANY_COLUMNS, QUOTATION_FILTER_STATUS_RADIOS } from "assets";
import type { CompanyInfo, Languages } from "types";

const QuotationFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedShipper, setSelectedShipper] = useState<CompanyInfo[]>([]);
  const [selectedSender, setSelectedSender] = useState<CompanyInfo[]>([]);

  const { data: companies } = useGetCommonPlayer();

  const { modalRef } = useModal();

  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: QUOTATION_FILTER_STATUS_RADIOS,
    },
    { queryKey: "orderNumber", label: "Order number" },
    { queryKey: "date", label: "Date" },
    {
      queryKey: "shipperId",
      label: "Shipper name",
      options: companies?.map((company) => ({
        key: company.id,
        label: company.name as Languages,
      })),
    },
    {
      queryKey: "senderId",
      label: "Sender name",
      options: companies?.map((company) => ({
        key: company.id,
        label: company.name as Languages,
      })),
    },
  ] as const;

  const handleCompanySelect =
    (type: "shipper" | "sender") =>
    (companyInfo: CompanyInfo[]): void => {
      setSearchParams({
        ...getAllQuery(searchParams),
        ...(companyInfo[0] &&
          (type === "shipper"
            ? { shipperId: companyInfo[0].id }
            : { senderId: companyInfo[0].id })),
        page: "1",
      });
    };

  useEffect(() => {
    if (!companies) return;

    setSelectedShipper(
      selectCompanyInfo(companies, searchParams.get("shipperId") ?? ""),
    );
    setSelectedSender(
      selectCompanyInfo(companies, searchParams.get("senderId") ?? ""),
    );
  }, [companies, searchParams.get("shipperId"), searchParams.get("senderId")]);

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status" isRequired>
          <Filter.Radio
            queryKey="status"
            radios={QUOTATION_FILTER_STATUS_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Order number">
          <Filter.Input
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Date" isRequired>
          <Filter.Calendar queryKey="date" />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Sender name">
          <Filter.Modal
            selectModalData={selectedSender[0]?.name}
            placeholder="Search by sender name"
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedSender}
                tableDatas={companies ?? []}
                handleCompanySelect={handleCompanySelect("sender")}
              />
            }
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Shipper name">
          <Filter.Modal
            selectModalData={selectedShipper[0]?.name}
            placeholder="Search by shipper name"
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedShipper}
                tableDatas={companies ?? []}
                handleCompanySelect={handleCompanySelect("shipper")}
              />
            }
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default QuotationFilter;
