import React, { useRef } from "react";
import { useRecoilValue } from "recoil";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NoResult, QueryPagination } from "components";
import { useAlignCenter, useMonitoringTruckMarker } from "hooks";
import { useGetMonitoringTruckDetail } from "services";
import { clientLanguage } from "stores";
import { foldCardAnimate, formatICTDateTime, formatPhoneNum } from "utils";
import type { GetMonitoringTrucksClientModel } from "types";
import {
  ButtonFoldTruck,
  DriverInfo,
  TruckAllocationInfo,
  TruckStatusInfo,
} from "../card";
import { useTruckMarkerEventListener, useTruckSelect } from "../hooks";
import * as S from "./Monitorings.styled";

interface ShippingMonitoringsProps {
  data: GetMonitoringTrucksClientModel;
}

const ShippingMonitorings = ({ data }: ShippingMonitoringsProps) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const languages = useRecoilValue(clientLanguage);
  const truckRef = useRef<{ [key: string]: HTMLLIElement | null }>({});

  const truckId = searchParams.get("truckId")!;

  const { data: truckDetail } = useGetMonitoringTruckDetail(
    {
      truckId,
      query: {
        companyId: id!,
        companyType: searchParams.get("companyType")!,
      },
    },
    !!truckId,
  );

  const { truckMarkers } = useMonitoringTruckMarker(data.trucks);

  const monitoringTrucks = data.trucks.map((truck, i) => ({
    ...truck,
    ...truckMarkers[i],
  }));

  const { handleTruckCardClick, handleTruckMarkerClick } = useTruckSelect(
    monitoringTrucks,
    truckRef,
  );

  useTruckMarkerEventListener(handleTruckMarkerClick, monitoringTrucks);
  useAlignCenter(data.coords);

  return (
    <>
      <S.MonitoringListWrapper>
        {data.trucks.length ? (
          data.trucks.map((truck) => {
            const isFold = truck.truckId !== truckId;

            return (
              <li key={truck.truckId}>
                <ButtonFoldTruck
                  isFold={isFold}
                  status="SHIPPING"
                  truck={{
                    registNumber: truck.registNumber,
                    truckType:
                      languages === "en"
                        ? truck.truckTypeEn
                        : truck.truckTypeVi,
                  }}
                  handleFoldBtnClick={handleTruckCardClick(truck.truckId)}
                />
                {!isFold && truckDetail && (
                  <S.TruckDetailInfoWrapper
                    layout
                    initial="closed"
                    animate={isFold ? "closed" : "open"}
                    exit="closed"
                    variants={foldCardAnimate(
                      searchParams.get("type") === "card",
                    )}
                    transition={{ duration: 0.3 }}
                  >
                    <TruckStatusInfo
                      truckStatus="SHIPPING"
                      truckInfo={{
                        lat: truck.lat,
                        lng: truck.lng,
                        addr: truck.addr,
                        speed: truck.speed,
                        temperature: truck.temperature,
                      }}
                    />
                    <DriverInfo
                      driverInfo={{
                        title: "Driver",
                        name: truckDetail.driverInfo.name ?? "-",
                        phone: formatPhoneNum(truckDetail.driverInfo.phone),
                        addr: truckDetail.driverInfo.started
                          ? `(${t("Starts")}) ${formatICTDateTime(
                              truckDetail.driverInfo.started,
                            )}`
                          : "-",
                      }}
                    />
                    <TruckAllocationInfo
                      trucksAllocations={truckDetail.allocations}
                    />
                  </S.TruckDetailInfoWrapper>
                )}
              </li>
            );
          })
        ) : (
          <NoResult />
        )}
      </S.MonitoringListWrapper>
      <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
    </>
  );
};

export default ShippingMonitorings;
