import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 24px;
  margin-bottom: 32px;
`;

export const label = (theme: Theme) => css`
  & > div > label {
    display: block;
    margin-bottom: 4px;
    color: ${theme.color.gray_60};
  }
`;

export const input = css`
  width: 446px;
`;
