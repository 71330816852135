import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  TableOfContent,
  MemberDetailTruckDetailDefautInfo,
  MemberDetailTruckDetailDrivingRecords,
  MemberDetailTruckDetailSpecification,
  Button,
  ConfirmModal,
  InternalLink,
} from "components";
import { useMemberType, useModal, useToast } from "hooks";
import { useDeleteTruck, useGetTruckDetail } from "services";
import { DeleteTruckIcon } from "assets";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import * as S from "./MemberDetailRegisteredTruckDetail.styled";

const MemberDetailRegisteredTruckDetail = () => {
  const { t } = useTranslation();

  const { memberId, truckId } = useParams();
  const navigate = useNavigate();

  const { memberType } = useMemberType();
  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const memberDetailTruckListPath = `${PATH.MEMBER}/${memberId}?tab=${
    TAB.MEMBER.REGISTERED_TRUCK
  }&memberType=${memberType === "forwardercarrier" ? "forwarder" : "carrier"}`;

  const query = {
    truckId: truckId!,
    query: { companyId: memberId!, companyType: memberType },
  };

  const { data: truck } = useGetTruckDetail(query);
  const { mutate: deleteTruckMutate } = useDeleteTruck();

  const handleTruckDelete = (): void => {
    const deleteTruck = () => {
      deleteTruckMutate(query, {
        onSuccess: () => {
          navigate(memberDetailTruckListPath);
          addToast(TOAST_MSG.SUCCESS.TRUCK_DELETE_DONE);
        },
      });
      handleModalClose();
    };

    handleModalOpen(
      <ConfirmModal
        ref={modalRef}
        title="Delete the truck"
        desc="Are you sure you want to delete the truck?"
        btnType="alert"
        activeBtnName="Confirm"
        activeFn={deleteTruck}
      />,
    )();
  };

  if (!truck) return null;

  return (
    <S.Layout>
      <TableOfContent
        pageName={t("Truck details")}
        stickyBottom={
          <Button
            css={S.button}
            type="button"
            variant="text"
            label="Delete the truck"
            icon={<DeleteTruckIcon />}
            handleClickBtn={handleTruckDelete}
          />
        }
        contents={[
          {
            heading: { headingLevel: "h2", text: "Default information" },
            content: <MemberDetailTruckDetailDefautInfo truck={truck} />,
          },
          {
            heading: { headingLevel: "h2", text: "Specification" },
            content: <MemberDetailTruckDetailSpecification truck={truck} />,
          },
          {
            heading: { headingLevel: "h2", text: "Driving records" },
            content: <MemberDetailTruckDetailDrivingRecords truck={truck} />,
          },
        ]}
      />
      <InternalLink
        css={S.backLink}
        variant="outlineMedium"
        to={memberDetailTruckListPath}
      >
        {t("Back to list")}
      </InternalLink>
    </S.Layout>
  );
};

export default MemberDetailRegisteredTruckDetail;
