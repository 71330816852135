import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  width: 344px;
  height: 564px;
`;

export const ImgWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 500px;
    border: 1px solid ${theme.color.gray_30};
    overflow: hidden;
    background-color: ${theme.color.gray_30};
  `}
`;

export const Img = styled.img<{
  position: { oldX: number; oldY: number; x: number; y: number };
  rangeValue: number;
}>`
  ${({ theme, rangeValue, position }) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: ${rangeValue === 1 ? "cursor" : "grab"};
    transform: ${`translate(${position.x}px, ${position.y}px) scale(${rangeValue}) ;`};
    margin: 0 auto;
  `}
`;

export const BottomWrapper = styled.div`
  position: relative;
  padding: 14px 0;
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 50px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font: ${theme.font.regular_14};
    transform: translate(-50%);

    & > span {
      padding: 6px 5px;
    }
  `}
`;

export const arrowLeftIcon = css`
  transform: rotate(180deg);
`;

export const ZoomBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
`;

export const RangeBar = styled.input<{ zoomValue: string }>`
  ${({ theme, zoomValue }) => css`
    width: 60px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid ${theme.color.gray_30};
    background-image: linear-gradient(
      ${theme.color.primary_10},
      ${theme.color.primary_10}
    );
    background-color: ${theme.color.gray_30};
    background-size: ${zoomValue} 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid ${theme.color.primary_10};
      background-color: ${theme.color.white};
      cursor: pointer;
    }
  `}
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg > path {
      fill: ${theme.color.gray_70};
    }

    &:disabled {
      cursor: not-allowed;

      & > svg > path {
        fill: ${theme.color.gray_30};
      }
    }
  `}
`;

export const RotateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;
