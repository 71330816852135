import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const tableOfContent = css`
  margin-top: 40px;

  & > div > section {
    width: 764px;
  }
`;

export const layout = (isOngoing: boolean) => (theme: Theme) =>
  css`
    margin: 0 auto;

    & > div > div {
      row-gap: ${isOngoing && "0px"};

      section:nth-last-of-type(3) {
        border-bottom-style: ${isOngoing && "dashed"};
      }
      & > section:nth-last-of-type(2) {
        margin-bottom: ${isOngoing && "32px"};
        border-top: ${isOngoing && "0px"};
        background-color: ${isOngoing && theme.color.gray_10};
      }
    }
  `;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    column-gap: 12px;
    width: fit-content;
    margin: 32px 0 0 auto;
  `}
`;

export const link = (theme: Theme) => css`
  padding: 9px 19px;
  text-decoration: none;
  background-color: ${theme.color.white};
`;
