import React from "react";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { DataSection } from "components";
import { findLookupTableLabel, formatICTDateTime } from "utils";
import {
  SETTING_REGISTERED_MEMBER_TABLE_HEADER,
  GRADE_TYPE_RADIOS,
} from "assets";
import type { GetRegisteredMemberServerModel } from "types";
import * as S from "./RegisteredMemberTable.styled";

interface RegisteredMemberTableProps {
  admins?: GetRegisteredMemberServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const RegisteredMemberTable = ({
  admins,
  dataUpdatedAt,
  refetch,
}: RegisteredMemberTableProps) => {
  const { t } = useTranslation();

  const formatTableData = (
    data: GetRegisteredMemberServerModel["admins"][number],
    key: keyof typeof SETTING_REGISTERED_MEMBER_TABLE_HEADER,
  ) => {
    switch (key) {
      case "grade":
        const gradeLabel = findLookupTableLabel(GRADE_TYPE_RADIOS, data[key]);

        return <>{gradeLabel ? t(gradeLabel) : "-"}</>;

      case "created":
      case "lastAccess":
        return data[key] ? formatICTDateTime(data[key]) : "-";

      default:
        return data[key] || "-";
    }
  };

  if (!admins) return null;

  return (
    <DataSection
      css={S.dataSection}
      dataUpdatedAt={dataUpdatedAt}
      totalData={admins.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="member"
        columnTable={SETTING_REGISTERED_MEMBER_TABLE_HEADER}
      >
        {admins.admins.length &&
          admins.admins.map((admin) => (
            <DataSection.Table.SelectRowMovePage
              id={admin.adminId}
              key={admin.adminId}
              path={admin.adminId}
            >
              {keys(SETTING_REGISTERED_MEMBER_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    admin,
                    key as keyof typeof SETTING_REGISTERED_MEMBER_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default RegisteredMemberTable;
