import {
  LinkIcon,
  PersonIcon,
  SupportIcon,
  SettlementIcon,
  SettingIcon,
  MonitoringIcon,
  TruckIcon,
  QuotationIcon,
} from "assets";
import { PATH } from "constants/index";

const NAV = [
  {
    path: PATH.MEMBER,
    content: "Member",
    Icon: PersonIcon,
  },
  {
    path: PATH.LINKING,
    content: "Linking",
    Icon: LinkIcon,
  },
  {
    path: PATH.QUOTATION,
    content: "Quotation management",
    Icon: QuotationIcon,
  },
  {
    path: PATH.MONITORING,
    content: "Monitoring",
    Icon: MonitoringIcon,
  },
  {
    path: PATH.ORDER_MANAGEMENT,
    content: "Order management",
    Icon: TruckIcon,
  },
  {
    path: PATH.SETTLEMENT,
    content: "Settlement",
    Icon: SettlementIcon,
  },
  {
    path: PATH.SUPPORT,
    content: "Support",
    Icon: SupportIcon,
  },
  {
    path: PATH.SETTING,
    content: "Settings",
    Icon: SettingIcon,
  },
] as const;

export default NAV;
