import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const dialog = (theme: Theme) => css`
  & > button {
    width: 20px;
    height: 20px;

    svg path {
      fill: ${theme.color.gray_50};
    }

    &:hover {
      opacity: 100%;
    }
  }
`;

export const PopupBox = styled.div`
  min-width: 87px;
  width: 100%;
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    width: 100%;
    padding: 8px 12px;
    color: ${theme.color.gray_80};
    text-align: left;

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;
