import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const heading = (theme: Theme) => css`
  & > h1 {
    ${theme.font.bold_18}
    margin-bottom: 32px;
  }
`;

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
