import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import i18n from "i18next";

import { useAuth } from "contexts";
import { PublicLayout } from "components";
import { PATH } from "constants/index";

const PublicRoute = () => {
  const { auth } = useAuth();

  useEffect(() => {
    i18n.changeLanguage("en"); //TODO: 초기 언어 : en -> 기획에 따라 변경 필요
  }, []);

  return auth.isAuth ? (
    <Navigate to={PATH.MEMBER} />
  ) : (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
