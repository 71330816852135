import React from "react";
import { Link } from "react-router-dom";

import { button } from "style";
import * as S from "./InternalLink.styled";

interface InternalLinkProps {
  className?: string;
  isDisabled?: boolean;
  variant: keyof typeof button;
  state?: { [key: string]: any }; // NOTE: 타입 추정이 어려워 any 작성
  to: string;
  isScrollToTop?: boolean;
  children: React.ReactNode;
}

const InternalLink = ({
  className,
  children,
  variant,
  state,
  to: path,
  isScrollToTop = true,
}: InternalLinkProps) => {
  const handleLinkClick = (): void => {
    isScrollToTop && window.scrollTo({ top: 0 });
  };

  return (
    <Link
      css={(theme) => S.internalLink(theme, variant)}
      className={className}
      to={path}
      state={state}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
};

export default InternalLink;
