import React from "react";
import { useTranslation } from "react-i18next";

import {
  DialogBtn,
  ExternalLink,
  MoreDialog,
  AllocationLocation,
} from "components";
import { addrFormatter, formatOrderNum } from "utils";
import { PATH } from "constants/index";
import type { GetMonitoringTruckDetailServerModel } from "types";
import * as S from "./TruckAllocationInfo.styled";

interface TruckAllocationInfoProps {
  trucksAllocations: GetMonitoringTruckDetailServerModel["allocations"];
}

const TruckAllocationInfo = ({
  trucksAllocations,
}: TruckAllocationInfoProps) => {
  const { t } = useTranslation();

  return (
    <S.AllocationInfoWrapper>
      <h3>{t("Allocation list")}</h3>
      <S.OrderCardWrapper>
        {trucksAllocations.length &&
          trucksAllocations.map((allocation, i) => (
            <S.AllocationCard key={i}>
              <S.OrderCardTitleWrapper>
                <ExternalLink
                  variant="ghostNoUnderlineGray"
                  href={`${PATH.ORDER_MANAGEMENT}/${allocation.orders[0].orderId}`}
                >
                  <S.OrderCardTitle>
                    {formatOrderNum(allocation.orders?.[0]?.orderNumber)}
                  </S.OrderCardTitle>
                </ExternalLink>
                {allocation.orders.length > 1 && (
                  <DialogBtn
                    css={S.moreBtn}
                    popup={() => <MoreDialog orders={allocation.orders} />}
                  >
                    {allocation.orders.length - 1}+
                  </DialogBtn>
                )}
              </S.OrderCardTitleWrapper>
              <AllocationLocation>
                {allocation.deliveries.map((delivery, i) => (
                  <AllocationLocation.Detail
                    key={i}
                    type={delivery.type}
                    destination={addrFormatter(
                      delivery.addr,
                      delivery.addrDetail,
                    )}
                    state={delivery.completed || "-"}
                  />
                ))}
              </AllocationLocation>
            </S.AllocationCard>
          ))}
      </S.OrderCardWrapper>
    </S.AllocationInfoWrapper>
  );
};

export default TruckAllocationInfo;
