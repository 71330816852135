import React from "react";
import { FormProvider } from "react-hook-form";

import {
  MemberBusinessInfoForm,
  MemberBusinessLicenseForm,
  MemberGpsApi,
  MemberFormBtns,
  MemberUserInfoForm,
  PageLayout,
} from "components";
import { CREATE_MEMBER_LAYOUT } from "assets";
import { useCreateMember } from "./hooks";
import * as S from "./MemberCreate.styled";

const MemberCreate = () => {
  const {
    isLoadingCreateMember,
    isLoadingCreateDummyMember,
    createMember,
    formMethod,
  } = useCreateMember();

  return (
    <PageLayout css={S.layout} layoutStaticData={CREATE_MEMBER_LAYOUT}>
      <FormProvider {...formMethod}>
        <form onSubmit={formMethod.handleSubmit(createMember)}>
          <S.AbsoluteInput
            type="email"
            name="user_email_randomString"
            id="email_randomString"
          />
          <S.AbsoluteInput type="password" />
          <MemberBusinessInfoForm />
          <MemberBusinessLicenseForm />
          <MemberUserInfoForm type="create" />
          {(formMethod.watch("companyType") === "CARRIER" ||
            formMethod.watch("companyType") === "FORWARDERCARRIER") && (
            <MemberGpsApi />
          )}
          <MemberFormBtns
            isLoading={isLoadingCreateMember || isLoadingCreateDummyMember}
          />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default MemberCreate;
