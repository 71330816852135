import React from "react";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { Button, CheckboxBtn, DataSection } from "components";
import { useCheckTable, useModal } from "hooks";
import { formatICTDateTime } from "utils";
import { PinIcon, SUPPORT_NOTICE_TABLE_HEADER } from "assets";
import { TAB } from "constants/index";
import type { GetNoticesServerModel } from "types";
import { DeleteNoticeModal, PinNoticeModal, UnPinNoticeModal } from "../modal";
import { usePinNotice } from "../hooks";
import * as S from "./NoticeTable.styled";

interface NoticeTableProps {
  data?: GetNoticesServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const NoticeTable = ({ data, dataUpdatedAt, refetch }: NoticeTableProps) => {
  const { t } = useTranslation();

  const {
    isChecked,
    isAllChecked,
    checkedIds,
    handleCheck,
    handleAllCheck,
    handleAllUnCheck,
  } = useCheckTable(data?.notices, "noticeId");
  const { modalRef, handleModalOpen } = useModal();
  const { handlePinNotice, handleUnPinNotice, handleDeleteNotice } =
    usePinNotice(checkedIds, handleAllUnCheck);

  const formatTableData = (
    notice: GetNoticesServerModel["notices"][number],
    key: keyof typeof SUPPORT_NOTICE_TABLE_HEADER,
    i: number,
  ) => {
    switch (key) {
      case "subject":
        return notice.pinned === 1 ? (
          <S.SubjectWrapper>
            <PinIcon css={S.icon} />
            {notice.subject}
          </S.SubjectWrapper>
        ) : (
          notice.subject
        );

      case "companyType":
        const companyTypes: string[] = [];

        notice.isShipper && companyTypes.push(t("Shipper"));
        notice.isForwarder && companyTypes.push(t("Forwarder"));
        notice.isCarrier && companyTypes.push(t("Carrier"));
        notice.isDriver && companyTypes.push(t("Driver"));

        return companyTypes.join("/ ");

      case "created":
        return formatICTDateTime(notice[key]) ?? "-";

      default:
        return notice[key] ?? "-";
    }
  };

  const activeBtnList = () => {
    return (
      <S.BtnWrapper>
        <Button
          variant="ghostGray"
          label="Pin"
          handleClickBtn={handleModalOpen(
            <PinNoticeModal ref={modalRef} activeFn={handlePinNotice} />,
          )}
        />
        <Button
          variant="ghostGray"
          label="Unpin"
          handleClickBtn={handleModalOpen(
            <UnPinNoticeModal ref={modalRef} activeFn={handleUnPinNotice} />,
          )}
        />
        <Button
          variant="ghostRed"
          label="Delete"
          handleClickBtn={handleModalOpen(
            <DeleteNoticeModal ref={modalRef} activeFn={handleDeleteNotice} />,
          )}
        />
      </S.BtnWrapper>
    );
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
    >
      <DataSection.CheckTable
        css={S.table}
        selectedCount={checkedIds?.length}
        toolBtns={activeBtnList()}
        columnTable={SUPPORT_NOTICE_TABLE_HEADER}
        isAllChecked={isAllChecked}
        handleAllCheck={handleAllCheck}
        handleAllUnCheck={handleAllUnCheck}
      >
        {data?.notices.length &&
          data.notices.map((notice, i) => (
            <DataSection.CheckTable.SelectRowMovePage
              id={notice.noticeId}
              key={notice.noticeId}
              path={`${notice.noticeId}?tab=${TAB.SUPPORT.NOTICE}`}
            >
              <DataSection.CheckTable.CheckTd>
                <CheckboxBtn
                  shape="rectangle"
                  isChecked={isChecked(notice.noticeId)}
                  handleCheck={handleCheck(notice.noticeId)}
                />
              </DataSection.CheckTable.CheckTd>
              {keys(SUPPORT_NOTICE_TABLE_HEADER).map((key) => (
                <DataSection.CheckTable.Td key={key}>
                  {formatTableData(
                    notice,
                    key as keyof typeof SUPPORT_NOTICE_TABLE_HEADER,
                    i,
                  )}
                </DataSection.CheckTable.Td>
              ))}
            </DataSection.CheckTable.SelectRowMovePage>
          ))}
      </DataSection.CheckTable>
    </DataSection>
  );
};

export default NoticeTable;
