import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { MEMBER_DETAIL_REGISTERED_TRUCK } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${MEMBER_DETAIL_REGISTERED_TRUCK};
  }
`;

export const IconWrapper = styled.div<{ isGps: boolean }>`
  ${({ theme, isGps }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    & > svg > path {
      fill: ${isGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `}
`;

export const Truck = styled.div<{ hasCount?: boolean }>`
  ${({ hasCount }) => css`
    display: grid;
    grid-template-columns: ${hasCount ? "24px 1fr 24px" : "1fr 24px"};
    align-items: center;
    column-gap: 8px;
    height: 40px;
  `}
`;

export const Name = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Count = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;
