import { css } from "@emotion/react";

import { color } from "./color";
import { font } from "./font";

const minxinBadge = css`
  ${font.semibold_12};
  display: flex;
  align-items: center;
  column-gap: 4px;
  align-self: flex-start;
  width: fit-content;
  border-radius: 2px;
  padding: 2px 8px;

  & > svg {
    width: 12px;
    height: 12px;
  }
`;

export const badge = {
  primary: css`
    ${minxinBadge};
    color: ${color.primary_50};
    background-color: ${color.primary_20};

    & > svg > path {
      fill: ${color.primary_50};
    }
  `,
  red: css`
    ${minxinBadge};
    color: ${color.red_10};
    background-color: ${color.red_30};

    & > svg > path {
      fill: ${color.red_30};
    }
  `,
  gray: css`
    ${minxinBadge};
    color: ${color.gray_60};
    background-color: ${color.primary_20};

    & > svg > path {
      fill: ${color.gray_60};
    }
  `,
};

export type BadgeType = typeof badge;
