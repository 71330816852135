import React from "react";

import {
  Button,
  Foldable1DepthLayout,
  QuerySearchInput,
  Tab,
} from "components";
import { useGetMonitoringTrucks } from "services";
import { MONITORING_QUERY_PARAMS, MONITORING_TABS, ResetIcon } from "assets";
import { ShippingMonitorings, WaitingMonitorings } from "./list";
import useMonitoringMap from "./hooks/useMonitoringMap";
import * as S from "./MonitoringMap.styled";

const TruckMonitoringMap = () => {
  const { makeMonitoringQuery, heading, companyType, deleteSearchParams } =
    useMonitoringMap();

  const { data, refetch } = useGetMonitoringTrucks(
    { query: makeMonitoringQuery("trucks") },
    companyType !== "shipper",
  );

  const render = {
    shipping: data && <ShippingMonitorings data={data} />,
    waiting: data && <WaitingMonitorings data={data} />,
  };

  const resetClick = (): void => {
    refetch();
    deleteSearchParams();
  };

  return (
    <Foldable1DepthLayout heading={heading}>
      <QuerySearchInput
        css={S.querySearchInput}
        placeholder="Search by plate number"
        searchQuery="search"
        deleteQueries={[
          MONITORING_QUERY_PARAMS.PAGE,
          MONITORING_QUERY_PARAMS.TRUCK_ID,
          MONITORING_QUERY_PARAMS.TYPE,
        ]}
      />
      <Tab
        css={S.tab}
        tabs={MONITORING_TABS}
        keepQueries={[MONITORING_QUERY_PARAMS.COMPANY_TYPE]}
      >
        <S.TabListWrapper>
          <Tab.TabList css={S.tabList} />
          <Button
            css={S.resetButton}
            variant="ghostNoUnderlineGray"
            icon={<ResetIcon />}
            label="Reset"
            handleClickBtn={resetClick}
          />
        </S.TabListWrapper>
        <Tab.TabPanel css={S.tabPanel} render={render} />
      </Tab>
    </Foldable1DepthLayout>
  );
};

export default TruckMonitoringMap;
