import { calcTableWidth } from "utils/table"; // NOTE: util 파일 추가 후 import 에러발생하여 경로수정

export const MEMBER_TABLE_HEADER_COLUMNS = calcTableWidth([
  "160px",
  "170px",
  "100px",
  ["318px", "auto"],
  "140px",
  "240px",
  "160px",
]);

export const MEMBERSHIP_PLAN_TABLE_HEADER_COLUMNS = calcTableWidth([
  "180px",
  "120px",
  "120px",
  "120px",
  "140px",
  "180px",
  "auto",
  "52px",
]);

export const LINKING_TABLE_HEADER_COLUMNS = calcTableWidth([
  "160px",
  "260px",
  "120px",
  "110px",
]);

export const QUOTATION_TABLE_HEADER_COLUMNS = calcTableWidth([
  "160px",
  "130px",
  "180px",
  "180px",
  "auto",
]);

export const QUOTATION_DETAIL_TABLE_HEADER_COLUMNS = calcTableWidth([
  "120px",
  "260px",
  "180px",
  "135px",
]);

export const QUOTATION_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS = calcTableWidth([
  "233px",
  "200px",
  "200px",
]);

export const ORDER_MANAGEMENT_TABLE_HEADER_COLUMNS = calcTableWidth([
  "132px",
  "155px",
  "160px",
  "80px",
  "230px",
  "150px",
  "230px",
  "150px",
]);

export const ORDER_MANAGEMENT_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS =
  calcTableWidth(["270px", "260px", "166px"]);

export const COMPANY_SELECT_TABLE_CELL_SIZES = calcTableWidth([
  "260px",
  "150px",
  "auto",
]);

export const COMPANY_SELECT_LINK_TABLE_CELL_SIZES = calcTableWidth([
  "40px",
  "260px",
  "150px",
  "auto",
]);

export const MEMBER_DETAIL_REGISTERED_TRUCK = calcTableWidth([
  "96px",
  "110px",
  "60px",
  "170px",
  "170px",
  "72px",
  "140px",
  "140px",
  "212px",
  "115px",
]);

export const MEMBER_DETAIL_REGISTERED_GARAGE = calcTableWidth([
  "200px",
  "auto",
  "128px",
  "128px",
  "64px",
]);

export const MEMBER_DETAIL_REGISTERED_DRIVER = calcTableWidth([
  "130px",
  "300px",
  "180px",
  "180px",
  "170px",
  "200px",
  "128px",
]);

export const MEMBER_DETAIL_ALLOCATIONS = calcTableWidth([
  "120px",
  "170px",
  "140px",
  "190px",
  "190px",
  "170px",
  "170px",
  "137px",
]);

export const MEMBER_DETAIL_REGISTERED_MANAGE_LINK = calcTableWidth([
  "180px",
  ["230px", "600px"],
  "160px",
  ["286px", "800px"],
  "40px",
]);

export const MEMBER_DETAIL_REGISTERED_MEMBER = calcTableWidth([
  "160px",
  "auto",
  "400px",
  "160px",
  "325px",
  "64px",
]);

export const SETTLEMENT_TABLE_HEADER_COLUMNS = calcTableWidth([
  "150px",
  "200px",
  "250px",
  "250px",
  "200px",
  ["239px", "auto"],
]);

export const SUPPORT_INQUIRY_TABLE_HEADER_COLUMNS = calcTableWidth([
  "120px",
  "120px",
  "180px",
  ["257px", "auto"],
  "140px",
  "160px",
  "150px",
  "150px",
]);

export const SUPPORT_NOTICE_TABLE_HEADER_COLUMNS = calcTableWidth([
  "40px",
  ["810px", "auto"],
  "290px",
  "150px",
]);

export const SETTING_REGISTERED_MEMBER_TABLE_HEADER_COLUMNS = calcTableWidth([
  "180px",
  "180px",
  "300px",
  "140px",
  "auto",
]);

export const MONITORING_COMPANYS_TABLE_HEADER_COLUMNS = calcTableWidth([
  "200px",
  "260px",
  "auto",
]);
