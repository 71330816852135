import React from "react";

import { Filter } from "components";
import { MEMBER_FILTER_STATUS_RADIOS } from "assets";

const MemberFilter = () => {
  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: MEMBER_FILTER_STATUS_RADIOS,
    },
    { queryKey: "name", label: "Company name" },
  ] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio
            queryKey="status"
            radios={MEMBER_FILTER_STATUS_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row>
        <Filter.Condtion conditionName="Company name">
          <Filter.Input queryKey="name" placeholder="Search by company name" />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default MemberFilter;
