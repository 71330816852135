import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg path {
      fill: ${theme.color.black};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.black};
  `}
`;

export const card = css`
  width: 564px;
  margin-bottom: 12px;
`;

export const cardContent = css`
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const labelContent = css`
  margin-bottom: 28px;
`;

export const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  column-gap: 10px;
`;

export const dropdown = css`
  width: 250px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin-left: auto;
  width: fit-content;
`;

export const pageBackHeader = (theme: Theme) => css`
  padding-left: 0;

  & > div > span {
    ${theme.font.bold_18};
  }
`;
