import React from "react";

import { INIT_PAGE_INFO, INIT_MAX_PAGE_COUNT } from "assets";
import type { PageInfo } from "types";

import Pagination from "../pagination/Pagination";
import useModalPaginationBtn from "./hooks/useModalPaginationBtn";

interface ModalPaginationProps {
  className?: string;
  hasDoubleBtn?: boolean;
  currentPage: number;
  pageInfo?: PageInfo;
  maxPageCount?: number;
  movePage: (page: number) => void;
}

const ModalPagination = ({
  className,
  hasDoubleBtn = true,
  currentPage,
  pageInfo = INIT_PAGE_INFO,
  maxPageCount = INIT_MAX_PAGE_COUNT,
  movePage,
}: ModalPaginationProps) => {
  const { totalPages } = pageInfo;

  const {
    handlePrevPage,
    handleFirstPage,
    handleNextPage,
    handleLastPage,
    handleClickNum,
  } = useModalPaginationBtn(currentPage, maxPageCount, totalPages, movePage);

  return (
    <Pagination
      className={className}
      hasDoubleBtn={hasDoubleBtn}
      currentPage={currentPage}
      totalPages={totalPages}
      maxPageCount={maxPageCount}
      handlePrevPage={handlePrevPage}
      handleFirstPage={handleFirstPage}
      handleNextPage={handleNextPage}
      handleLastPage={handleLastPage}
      handleClickNum={handleClickNum}
    />
  );
};

export default ModalPagination;
