import { useCallback } from "react";

import { getPrevPageNum, getNextPageNum } from "utils";

const useModalPaginationBtn = (
  currentPage: number,
  maxPageCount: number,
  totalPages: number,
  movePage: (page: number) => void,
) => {
  const handlePrevPage = useCallback(() => {
    if (currentPage === 1) return;

    const prevPageNum = getPrevPageNum(currentPage, maxPageCount);

    movePage(prevPageNum);
  }, [currentPage]);

  const handleFirstPage = useCallback(() => {
    if (currentPage === 1) return;

    movePage(1);
  }, [currentPage]);

  const handleNextPage = useCallback(() => {
    if (currentPage === totalPages) return;

    const nextPageNum = getNextPageNum(currentPage, maxPageCount, totalPages);

    movePage(nextPageNum);
  }, [currentPage]);

  const handleLastPage = useCallback(() => {
    if (currentPage === totalPages) return;

    movePage(totalPages);
  }, [currentPage]);

  const handleClickNum = useCallback(
    (idx: number) => () => {
      movePage(idx);
    },
    [currentPage],
  );

  return {
    handlePrevPage,
    handleFirstPage,
    handleNextPage,
    handleLastPage,
    handleClickNum,
  };
};

export default useModalPaginationBtn;
