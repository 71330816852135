import React from "react";

import { Filter } from "components";

const RegisteredMemberFilter = () => {
  const searchLabels = [{ queryKey: "name", label: "Member name" }] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Member name">
          <Filter.Input queryKey="name" placeholder="Search by member name" />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default RegisteredMemberFilter;
