import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface QuotationPaginationProps {
  pageInfo?: PageInfo;
}

const QuotationPagination = ({ pageInfo }: QuotationPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default QuotationPagination;
