import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  Button,
  Card,
  CheckboxBtn,
  DetailLayout,
  ErrorMsg,
  FormFileInput,
  FormInput,
  FormPasswordInput2,
  InternalLink,
  LabelContent,
  PageBackHeader,
} from "components";
import { numericOnly } from "utils";
import { CloseIcon, HOOKFORM_ERR } from "assets";
import { VALID_MSG } from "constants/index";
import type { Languages } from "types";
import { useMemberDetailRegisteredDriverCreate } from "./hooks";
import * as S from "./MemberDetailRegisteredDriverCreate.styled";

const MemberDetailRegisteredDriverCreate = () => {
  const { t } = useTranslation();

  const {
    formMethod,
    beforeUrl,
    formatLinkTrucks,
    handleSelectLink,
    handleDeleteFile,
    handleCheckAgree,
    handleAddClick,
  } = useMemberDetailRegisteredDriverCreate();

  return (
    <DetailLayout css={S.layout}>
      <PageBackHeader
        css={S.pageBackHeader}
        title="Add the driver"
        path={beforeUrl}
      />
      <FormProvider {...formMethod}>
        <Card css={S.card}>
          <Card.Content headingLevel="h2" heading="Default information">
            <S.ContentWrapper>
              <S.NameWrapper>
                <div>
                  <LabelContent
                    isRequired
                    label="Last name"
                    direction="vertical"
                  >
                    <FormInput
                      placeholder="Last name"
                      maxLength={50}
                      hasError={!!formMethod.formState.errors.lastName?.message}
                      register={formMethod.register("lastName")}
                    />
                  </LabelContent>
                  <LabelContent label="Middle name" direction="vertical">
                    <FormInput
                      placeholder={`(${t("Optional")})` as Languages}
                      maxLength={50}
                      register={formMethod.register("middleName")}
                    />
                  </LabelContent>
                  <LabelContent
                    isRequired
                    label="First name"
                    direction="vertical"
                  >
                    <FormInput
                      placeholder="First name"
                      maxLength={50}
                      hasError={
                        !!formMethod.formState.errors.firstName?.message
                      }
                      register={formMethod.register("firstName")}
                    />
                  </LabelContent>
                </div>
                {(formMethod.formState.errors.lastName?.message ||
                  formMethod.formState.errors.firstName?.message) && (
                  <ErrorMsg errorMsg={VALID_MSG.REQUIRED} />
                )}
              </S.NameWrapper>
              <LabelContent
                isRequired
                label="Phone number"
                direction="vertical"
              >
                <FormInput
                  placeholder="Only numbers"
                  maxLength={15}
                  value={numericOnly(formMethod.watch("phone"))}
                  hasError={!!formMethod.formState.errors.phone?.message}
                  errorMsg={formMethod.formState.errors.phone?.message}
                  register={formMethod.register("phone")}
                />
              </LabelContent>
              <LabelContent isRequired label="Linking" direction="vertical">
                <AutoComplete
                  placeholder="Search by plate number"
                  hasError={!!formMethod.formState.errors.linked?.message}
                  id={formMethod.watch("linked.id")}
                  dropdowns={formatLinkTrucks || [{ name: "", id: "" }]}
                  value={formMethod.watch("linked.name")}
                  selectDropdown={handleSelectLink}
                  register={formMethod.register("linked.name")}
                />
                {!!formMethod.formState.errors.linked?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.linked?.message}
                  />
                )}
              </LabelContent>
            </S.ContentWrapper>
          </Card.Content>
          <Card.Content headingLevel="h2" heading="Attachments">
            <S.ContentWrapper>
              <LabelContent
                isRequired
                label="Driver's license"
                direction="vertical"
              >
                <FormFileInput
                  placeholder="Upload driver’s license"
                  hasErr={!!formMethod.formState.errors.license?.message}
                  file={formMethod.watch("license")}
                  register={formMethod.register("license")}
                  handleDeleteFile={handleDeleteFile("license")}
                />
                {!!formMethod.formState.errors.license?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.license?.message}
                  />
                )}
              </LabelContent>
              <LabelContent
                label="Profile picture"
                tooltip={{
                  title: "Profile picture",
                  contents: "Profile picture are recommenede in a 1:1 ratio.",
                  tooltipBtnContent: CloseIcon,
                }}
                direction="vertical"
              >
                <FormFileInput
                  placeholder="Upload driver profile picture"
                  hasErr={!!formMethod.formState.errors.profile?.message}
                  file={formMethod.watch("profile")}
                  register={formMethod.register("profile")}
                  handleDeleteFile={handleDeleteFile("profile")}
                />
                <S.Desc>
                  {`- ${t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}`}
                </S.Desc>
              </LabelContent>
            </S.ContentWrapper>
          </Card.Content>
          <Card.Content heading="ID and password">
            <S.ContentWrapper>
              <LabelContent label="Login ID" direction="vertical" isRequired>
                <FormInput
                  placeholder="Login ID"
                  maxLength={16}
                  hasError={!!formMethod.formState.errors.id}
                  errorMsg={formMethod.formState.errors.id?.message}
                  register={formMethod.register("id", {
                    required: HOOKFORM_ERR.NOT_EMPTY,
                  })}
                />
              </LabelContent>
              <LabelContent label="Password" direction="vertical" isRequired>
                <FormPasswordInput2
                  placeholder="Password"
                  maxLength={16}
                  hasError={!!formMethod.formState.errors.password}
                  errorMsg={formMethod.formState.errors.password?.message}
                  register={formMethod.register("password")}
                />
              </LabelContent>
              <LabelContent
                label="Confirm password"
                direction="vertical"
                isRequired
              >
                <FormPasswordInput2
                  placeholder="Confirm password"
                  maxLength={16}
                  hasError={!!formMethod.formState.errors.confirmPassword}
                  errorMsg={
                    formMethod.formState.errors.confirmPassword?.message
                  }
                  register={formMethod.register("confirmPassword")}
                />
              </LabelContent>
            </S.ContentWrapper>
          </Card.Content>
          <Card.Content headingLevel="h2" heading="Account information">
            <S.ContentWrapper>
              <LabelContent label="Name of bank" direction="vertical">
                <FormInput
                  placeholder="Name of bank"
                  maxLength={50}
                  register={formMethod.register("bankName")}
                />
              </LabelContent>
              <LabelContent label="Branch" direction="vertical">
                <FormInput
                  placeholder="Branch"
                  maxLength={50}
                  register={formMethod.register("bankBranch")}
                />
              </LabelContent>
              <LabelContent label="Account number" direction="vertical">
                <FormInput
                  placeholder="Only numbers"
                  maxLength={50}
                  value={numericOnly(formMethod.watch("bankAccountNo"))}
                  register={formMethod.register("bankAccountNo")}
                />
              </LabelContent>
            </S.ContentWrapper>
          </Card.Content>
          <S.AgreementProperty>
            <CheckboxBtn
              css={S.checkbtn}
              shape="rectangle"
              label="I consent to providing personal information to third parties."
              isChecked={!!formMethod.watch("isProvideInfo")}
              register={formMethod.register("isProvideInfo", {
                onChange: handleCheckAgree,
              })}
            />
            <CheckboxBtn
              css={S.checkbtn}
              shape="rectangle"
              label="I agree to the collection and use of personal information."
              isChecked={!!formMethod.watch("isAgreeCollection")}
              register={formMethod.register("isAgreeCollection", {
                onChange: handleCheckAgree,
              })}
            />
            {(formMethod.formState.errors?.isAgreeCollection?.message ||
              formMethod.formState.errors?.isProvideInfo?.message) && (
              <ErrorMsg errorMsg={HOOKFORM_ERR.REQUIRE} />
            )}
          </S.AgreementProperty>
        </Card>
        <S.ButtonWrapper>
          <InternalLink variant="outlineMedium" to={beforeUrl}>
            {t("Cancel")}
          </InternalLink>
          <Button
            variant="primaryMedium"
            label="Add"
            isDisabled={Object.keys(formMethod.formState.errors).length > 0}
            handleClickBtn={handleAddClick}
          />
        </S.ButtonWrapper>
      </FormProvider>
    </DetailLayout>
  );
};

export default MemberDetailRegisteredDriverCreate;
