import React from "react";
import { useTranslation } from "react-i18next";

import { useLogout } from "services";
import { LogoSmallIcon, LogoutIcon } from "assets";
import LangDropdown from "./langDropdown/LangDropdown";
import * as S from "./Header.styled";

export default function Header() {
  const { t } = useTranslation();

  const { mutate: logoutMutate } = useLogout();

  const handleLogout = () => {
    logoutMutate();
  };

  return (
    <S.Header>
      <LogoSmallIcon />
      <S.Wrapper>
        <LangDropdown />
        <S.LogoutBtn type="button" onClick={handleLogout}>
          <LogoutIcon css={S.logoutIcon} />
          {t("Sign out")}
        </S.LogoutBtn>
      </S.Wrapper>
    </S.Header>
  );
}
