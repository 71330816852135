import React from "react";
import dayjs from "dayjs";

import useFreeDate from "./hooks/useFreeDate";
import * as S from "./FreeDate.styled";

interface FreeDateProps {
  className?: string;
  isThisMonth?: boolean;
  currentDate: dayjs.Dayjs[] | [];
  date: dayjs.Dayjs;
  disabled?: boolean;
  hoveredDate: dayjs.Dayjs | string;
  changeHoveredDate: (date: dayjs.Dayjs | string) => void;
  changeMonth?: () => void;
  handleClick: (date: dayjs.Dayjs[]) => () => void;
}

const FreeDate = ({
  className,
  isThisMonth,
  currentDate,
  date,
  disabled,
  hoveredDate,
  handleClick,
  changeMonth,
  changeHoveredDate,
}: FreeDateProps) => {
  const {
    isToday,
    isSelectedDates,
    isSelectedStartDate,
    isSelectedEndDate,
    isHoveredIncluded,
    isHoveredBeforeSelectedDate,
    isHoveredAfterSelectedDate,
    weekIdx,
    handleMouseOver,
    handleClickDate,
  } = useFreeDate(
    currentDate,
    date,
    hoveredDate,
    changeHoveredDate,
    handleClick,
    isThisMonth,
    changeMonth,
  );

  return (
    <S.FreeDate
      className={className}
      aria-selected={!!currentDate.length}
      isToday={isToday}
      isThisMonth={isThisMonth ?? false}
      isSelectedDate={isSelectedStartDate || isSelectedEndDate}
      isHoverCases={isSelectedDates || isHoveredIncluded}
      isLeftCircleCases={
        weekIdx === 0 ||
        (isSelectedStartDate && !!isHoveredAfterSelectedDate) ||
        (currentDate.length === 2 && isSelectedStartDate) ||
        (currentDate.length === 1 &&
          !!isHoveredBeforeSelectedDate &&
          date.isSame(hoveredDate))
      }
      isRightCircleCases={
        weekIdx === 6 ||
        (isSelectedEndDate && !!isHoveredBeforeSelectedDate) ||
        (currentDate.length === 2 && isSelectedEndDate) ||
        (currentDate.length === 1 &&
          !!isHoveredAfterSelectedDate &&
          date.isSame(hoveredDate))
      }
    >
      <S.Btn
        type="button"
        disabled={disabled}
        onMouseEnter={handleMouseOver(date)}
        onClick={handleClickDate(date)}
      >
        {date.format("D")}
      </S.Btn>
    </S.FreeDate>
  );
};

export default FreeDate;
