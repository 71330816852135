import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { SUPPORT_NOTICE_TABLE_HEADER_COLUMNS } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${SUPPORT_NOTICE_TABLE_HEADER_COLUMNS};
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const icon = css`
  width: 16px;
  height: 16px;
`;
