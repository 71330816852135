import React from "react";
import { useParams } from "react-router-dom";

import { DetailModal, Dropdown } from "components";
import { useDropdownValue, useModal, useToast } from "hooks";
import { usePutSettlementStatusChange } from "services";
import { SETTLEMENT_STATUS_DROPDOWN_OPTIONS } from "assets";
import { TOAST_MSG } from "constants/index";
import * as S from "./SettlementSelectModal.styled";

const SettlementSelectModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const { settlementId } = useParams();

    const { mutate: changeStatusSettlementMutate } =
      usePutSettlementStatusChange();

    const { handleModalClose } = useModal();
    const { addToast } = useToast();
    const { selectedOption, handleSelect } = useDropdownValue(
      SETTLEMENT_STATUS_DROPDOWN_OPTIONS,
    );

    const handleSelectStatus = () => {
      if (selectedOption.key) {
        const req = {
          offerId: settlementId!,
          body: {
            status:
              selectedOption.key as (typeof SETTLEMENT_STATUS_DROPDOWN_OPTIONS)[number]["key"],
          },
        };
        changeStatusSettlementMutate(req, {
          onSuccess: () => {
            if (selectedOption.key === "CONFIRM") {
              addToast(TOAST_MSG.SUCCESS.SETTLEMENT_STATUS_CONFIRM_SUCCESS);
            }
            if (selectedOption.key === "REJECT") {
              addToast(TOAST_MSG.SUCCESS.SETTLEMENT_STATUS_REJECT_SUCCESS);
            }

            handleModalClose();
          },
        });
      }
    };

    return (
      <DetailModal
        css={S.modal}
        ref={ref}
        title="Change status"
        closeBtnLabel="Cancel"
        posBtnLabel="Change"
        isDisabled={!selectedOption.key}
        callbackFn={handleSelectStatus}
      >
        <Dropdown
          css={S.dropdown}
          placeholder="-Select-"
          options={SETTLEMENT_STATUS_DROPDOWN_OPTIONS}
          selectedOption={selectedOption}
          handleSelect={handleSelect}
        />
      </DetailModal>
    );
  },
);

SettlementSelectModal.displayName = "SettlementSelectModal";

export default SettlementSelectModal;
