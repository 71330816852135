import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface MonitoringPaginationProps {
  pageInfo: PageInfo;
}

const MonitoringPagination = ({ pageInfo }: MonitoringPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default MonitoringPagination;
