import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import { getS3PresignedUrlAPI } from "api";
import { downloadFile, getFileAPI } from "utils";
import type { APIError, FileRequestType } from "types";

export const useDownloadFile = () => {
  return useMutation<AxiosResponse, APIError, FileRequestType>({
    mutationFn: async (req) => {
      const url = await getS3PresignedUrlAPI(req.fileKey);
      return await getFileAPI(url);
    },
    onSuccess: (file: AxiosResponse, req) => {
      downloadFile(file, req.fileName);
    },
  });
};
