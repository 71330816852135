import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useToast } from "hooks";
import { usePostRegisteredMember } from "services";
import { makeCryptoFunction } from "utils";
import { PATH, TOAST_MSG } from "constants/index";
import type { RegisteredMemberForm } from "types";

const useCreateRegisteredMember = () => {
  const navigate = useNavigate();

  const { mutate: postRegisteredMemberMutate } = usePostRegisteredMember();

  const formMethod = useForm<RegisteredMemberForm>({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      grade: "MODERATOR",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { addToast } = useToast();

  const handleCreateRegisteredMember = formMethod.handleSubmit((data) => {
    const req = {
      body: {
        firstName: data.firstName.trim(),
        middleName: data.middleName.trim() ?? "",
        lastName: data.lastName.trim(),
        phone: data.phone.trim(),
        grade: data.grade,
        email: data.email.trim(),
        password: makeCryptoFunction(data.password),
      },
    };

    postRegisteredMemberMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.REGISTERED_MEMBER_ADD_SUCCESS);
        navigate(PATH.SETTING_REGISTERED_MEMBER);
      },
      onError: (err) => {
        if (err.response?.data.response === "EMAIL_EXISTS") {
          addToast(TOAST_MSG.WARNING.SETTING_REGISTERED_MEMBER_EMAIL_EXISTS);
        }
        if (err.response?.data.response === "PHONE_EXISTS") {
          addToast(TOAST_MSG.WARNING.SETTING_REGISTERED_MEMBER_PHONE_EXISTS);
        }
      },
    });
  });

  return { formMethod, handleSubmit: handleCreateRegisteredMember };
};

export default useCreateRegisteredMember;
