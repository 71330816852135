import React from "react";

import { TriangleArrowIcon } from "assets";
import * as S from "./FoldBtn.styled";

interface FoldBtnProps {
  className?: string;
  isFold: boolean;
  handleClickFold: () => void;
}

const FoldBtn = ({ className, isFold, handleClickFold }: FoldBtnProps) => {
  return (
    <S.Root className={className} type="button" onClick={handleClickFold}>
      <TriangleArrowIcon css={S.arrowIcon(isFold)} />
    </S.Root>
  );
};

export default FoldBtn;
