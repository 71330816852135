import styled from "@emotion/styled";

import { infoWindow } from "style";

export const GoogleMap = styled.section`
  ${infoWindow.common};

  width: 100%;
  height: 100%;
`;
