import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface InquiryPaginationProps {
  pageInfo?: PageInfo;
}

const InquiryPagination = ({ pageInfo }: InquiryPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default InquiryPagination;
