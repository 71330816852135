import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { COMPANY_SELECT_LINK_TABLE_CELL_SIZES } from "assets";

export const detailModal = css`
  width: 689px;
  height: 654px;
  padding: 32px;
`;

export const searchInput = css`
  width: 300px;
  margin-bottom: 12px;
`;

export const CheckTableWrapper = styled.div`
  max-height: 460px;
`;

export const checkTable = (theme: Theme, isLast: boolean) => css`
  height: 420px;
  border-bottom: 1px solid ${theme.color.gray_30};

  th {
    border-right: 1px solid ${theme.color.gray_30};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};
  }

  tbody {
    height: 380px;
    display: block;
    overflow-x: hidden;

    & > tr:last-of-type {
      border-bottom: ${isLast && "0"};
    }
  }

  tr {
    grid-template-columns: ${COMPANY_SELECT_LINK_TABLE_CELL_SIZES};
    padding: 0;

    & > td {
      border-right: 1px solid ${theme.color.gray_30};

      :nth-last-of-type(2) {
        border-right: 0;
      }
    }
  }
`;

export const selectRow = (theme: Theme, isChecked: boolean) => css`
  background-color: ${isChecked
    ? theme.color.primary_10_10
    : theme.color.white};
`;
