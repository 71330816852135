import React from "react";

import { DetailModal } from "components";
import type { Languages } from "types";

interface AlertModalProps {
  className?: string;
  title: Languages;
  desc: Languages;
  isLoading: boolean;
  negBtnLabel: Languages;
  closeBtnLabel: Languages;
  isDisabled?: boolean;
  callbackFn: () => void;
}

const AlertModal = React.forwardRef<HTMLDialogElement, AlertModalProps>(
  (
    { className, title, desc, negBtnLabel, isLoading, isDisabled, callbackFn },
    ref,
  ) => {
    return (
      <DetailModal
        className={className}
        ref={ref}
        title={title}
        desc={desc}
        isLoading={isLoading}
        isDisabled={isDisabled}
        negBtnLabel={negBtnLabel}
        callbackFn={callbackFn}
      />
    );
  },
);

AlertModal.displayName = "AlertModal";

export default AlertModal;
