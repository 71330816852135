import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  FormInput,
  FormFileInput,
  FormRadioBtn,
  LabelContent,
  LabelContentTable,
  Textarea,
  ProfileInfo,
  DownloadBtn,
  AutoComplete,
  ErrorMsg,
  LinkDialogBtn,
} from "components";
import { useDownloadFile } from "services";
import {
  formatICTDateTime,
  makeNameString,
  numericOnly,
  upperCaseToUpperFirst,
} from "utils";
import { VALID_MSG } from "constants/index";
import type { Languages } from "types";
import { useMemberDetailRegisteredDriverDefaultInfo } from "./hooks";
import * as S from "./MemberDetailRegisteredDriverDefaultInfo.styled";

const MemberDetailRegisteredDriverDefaultInfo = () => {
  const { t } = useTranslation();

  const { mutate: downloadPictureMutate } = useDownloadFile();

  const {
    isEdit,
    data,
    formMethod,
    formatLinkTrucks,
    handleDeleteFile,
    handleSubmit,
    handleLinkState,
    handleEditBtn,
    handleUpdateLink,
    handleSelectLink,
    handleUnlinkAll,
  } = useMemberDetailRegisteredDriverDefaultInfo();

  if (!data) return null;

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Driver">
            {isEdit ? (
              <div>
                <S.NameWrapper
                  hasError={
                    !!formMethod.formState.errors.lastName?.message ||
                    !!formMethod.formState.errors.firstName?.message
                  }
                >
                  <LabelContent
                    isRequired
                    label="Last name"
                    direction="vertical"
                  >
                    <FormInput
                      placeholder="Last name"
                      maxLength={50}
                      hasError={!!formMethod.formState.errors.lastName?.message}
                      register={formMethod.register("lastName")}
                    />
                  </LabelContent>
                  <LabelContent label="Middle name" direction="vertical">
                    <FormInput
                      placeholder="Middle name"
                      maxLength={50}
                      register={formMethod.register("middleName")}
                    />
                  </LabelContent>
                  <LabelContent
                    isRequired
                    label="First name"
                    direction="vertical"
                  >
                    <FormInput
                      placeholder="First name"
                      maxLength={50}
                      hasError={
                        !!formMethod.formState.errors.firstName?.message
                      }
                      register={formMethod.register("firstName")}
                    />
                  </LabelContent>
                </S.NameWrapper>
                {(formMethod.formState.errors.lastName?.message ||
                  formMethod.formState.errors.firstName?.message) && (
                  <ErrorMsg css={S.errorMsg} errorMsg={VALID_MSG.REQUIRED} />
                )}
                <LabelContent
                  isRequired
                  label="Phone number"
                  direction="vertical"
                >
                  <FormInput
                    placeholder="Phone number"
                    value={numericOnly(formMethod.watch("phone"))}
                    maxLength={15}
                    hasError={!!formMethod.formState.errors.phone?.message}
                    errorMsg={formMethod.formState.errors.phone?.message}
                    register={formMethod.register("phone")}
                  />
                </LabelContent>
              </div>
            ) : (
              <ProfileInfo
                profileUrl={data.profileUrl || ""}
                name={makeNameString(
                  data.lastName,
                  data.middleName,
                  data.firstName,
                )}
                phone={data.phone}
              />
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            {isEdit ? (
              <FormRadioBtn
                radioList={[
                  { key: "AVAILABLE", label: "Available" },
                  { key: "UNAVAILABLE", label: "Unavailable" },
                ]}
                currentValue={formMethod.watch("status")}
                register={formMethod.register("status")}
              />
            ) : (
              <>{t(upperCaseToUpperFirst(data.status) as Languages)}</>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(data.created, "DD/MM/YYYY")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.content} label="Linking">
            {formMethod.watch("editLinkState") ? (
              <>
                <S.AutoCompleteWrapper>
                  <AutoComplete
                    css={S.autoComplete}
                    placeholder="Search by plate number"
                    id={formMethod.watch("linked.id")}
                    hasError={!!formMethod.formState.errors.linked?.message}
                    dropdowns={formatLinkTrucks || [{ name: "", id: "" }]}
                    value={formMethod.watch("linked.name")}
                    selectDropdown={handleSelectLink}
                    register={formMethod.register("linked.name")}
                  />
                  {!!formMethod.formState.errors.linked?.message && (
                    <ErrorMsg
                      errorMsg={formMethod.formState.errors.linked?.message}
                    />
                  )}
                </S.AutoCompleteWrapper>
                <S.AutoCompleteBtnWrapper>
                  <Button
                    css={S.autoCompleteCancelButton}
                    variant="text"
                    label="Cancel"
                    handleClickBtn={handleLinkState}
                  />
                  <Button
                    css={S.updateButton}
                    variant="text"
                    label="Update"
                    isDisabled={!!formMethod.formState.errors.linked?.message}
                    handleClickBtn={handleUpdateLink}
                  />
                </S.AutoCompleteBtnWrapper>
              </>
            ) : data.linkedRegistNumber ? (
              <>
                {data.linkedRegistNumber}
                {!isEdit && (
                  <LinkDialogBtn
                    handleLinkState={handleLinkState}
                    handleUnlinkAll={handleUnlinkAll}
                  />
                )}
              </>
            ) : isEdit ? (
              "-"
            ) : (
              <Button
                variant="ghostNoUnderlinePrimary"
                label={`+ ${t("Add a link")}` as Languages}
                handleClickBtn={handleLinkState}
              />
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Memo">
            {isEdit ? (
              <Textarea
                css={S.textarea}
                id="memo"
                placeholder="Memo"
                maxLength={200}
                value={formMethod.watch("memo") || ""}
                register={formMethod.register("memo")}
              />
            ) : (
              <>{data.memo || "-"}</>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            isRequired={isEdit}
            label={isEdit ? "Driver's license" : "Attached file"}
          >
            {isEdit ? (
              <S.LicenseInputWrapper>
                <FormFileInput
                  placeholder="Upload front of transportation business license"
                  hasErr={!!formMethod.formState.errors.license?.message}
                  file={formMethod.watch("license")}
                  register={formMethod.register("license")}
                  handleDeleteFile={handleDeleteFile("license")}
                />
                <span>
                  - {t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}
                </span>
                {!!formMethod.formState.errors.license?.message && (
                  <ErrorMsg
                    errorMsg={formMethod.formState.errors.license?.message}
                  />
                )}
              </S.LicenseInputWrapper>
            ) : (
              <DownloadBtn
                downloadFileNamePrefix="File"
                fileName="Driver’s license"
                fileKey={data.license}
                downloadFileMutate={downloadPictureMutate}
              />
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.ButtonWrapper>
        {isEdit ? (
          <>
            <Button
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleEditBtn}
            />
            <Button
              variant="primaryMedium"
              label="Update"
              isDisabled={Object.keys(formMethod.formState.errors).length > 0}
              handleClickBtn={handleSubmit}
            />
          </>
        ) : (
          <Button
            variant="outlineMedium"
            label="Edit"
            handleClickBtn={handleEditBtn}
          />
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default MemberDetailRegisteredDriverDefaultInfo;
