import React from "react";
import { useRecoilValue } from "recoil";

import { Table } from "components";
import { clientLanguage, truckTypeOptions } from "stores";
import { TRUCK_LIST_TABLE_HEADER } from "assets";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementTruckList.styled";

interface OrderManagementTruckListProps {
  trucks?: GetOrderManagementDetailServerModel["trucks"];
}

const OrderManagementTruckList = ({
  trucks,
}: OrderManagementTruckListProps) => {
  const language = useRecoilValue(clientLanguage);
  const truckOptions = useRecoilValue(truckTypeOptions);

  if (!trucks) return null;

  return (
    <Table css={S.table} columnTable={TRUCK_LIST_TABLE_HEADER}>
      {Object.entries(trucks).map(([key, value]) => {
        const truckOption = truckOptions?.find(
          ({ truckOptionId }) => truckOptionId === +key,
        );

        return (
          <Table.Row key={key}>
            <Table.Td>
              {truckOption?.[language === "en" ? "typeEn" : "typeVi"]}
            </Table.Td>
            <Table.Td>
              {truckOption?.[language === "en" ? "optionEn" : "optionVi"]}
            </Table.Td>
            <Table.Td>{value}</Table.Td>
          </Table.Row>
        );
      })}
    </Table>
  );
};

export default OrderManagementTruckList;
