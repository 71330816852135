import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Time = styled.time`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: inline-block;
    color: ${theme.color.primary_10};

    &[data-istimeover="true"] {
      color: ${theme.color.red_20};
    }
  `}
`;
