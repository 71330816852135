import { useState, useEffect } from "react";
import { useImmer } from "use-immer";

const useImgPen = (zoomLevel: any) => {
  const [isPanning, setIsPanning] = useState(false);
  const [position, setPosition] = useImmer({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0,
  });

  const handleMouseDown = (e: any) => {
    e.preventDefault();

    if (zoomLevel === 1) return;

    setIsPanning(true);
    setPosition((draft) => {
      draft.oldX = e.clientX;
      draft.oldY = e.clientY;
      draft.x = position.x;
      draft.y = position.y;
    });
  };

  useEffect(() => {
    const mouseup = () => {
      setIsPanning(false);
    };
    const mousemove = (e: any) => {
      if (isPanning) {
        setPosition((draft) => {
          draft.oldX = e.clientX;
          draft.oldY = e.clientY;
          draft.x = position.x + e.clientX - position.oldX;
          draft.y = position.y + e.clientY - position.oldY;
        });
      }
    };

    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", mousemove);
    return () => {
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", mousemove);
    };
  });

  useEffect(() => {
    if (zoomLevel === 1) {
      setPosition((draft) => {
        draft.oldX = 0;
        draft.oldY = 0;
        draft.x = 0;
        draft.y = 0;
      });
    }
  }, [zoomLevel]);

  return { position, handleMouseDown };
};

export default useImgPen;
