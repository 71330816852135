import React from "react";
import { pick } from "lodash-es";

import {
  ListPageLayout,
  OrderManagementFilter,
  OrderManagementPagination,
  OrderManagementTable,
} from "components";
import { useFilterParams } from "hooks";
import { useGetOrderManagements } from "services";
import type { GetOrderManagementQueryModel } from "types";
import * as S from "./Ordermanagement.styled";

interface Filter
  extends Omit<GetOrderManagementQueryModel["query"], "startDate" | "endDate"> {
  date?: string;
}

const OrderManagement = () => {
  const requiredFilterKeys = ["startDate", "endDate"];

  const { filters } = useFilterParams<Filter>([
    "page",
    "status",
    "companyId",
    "date",
    "orderNumber",
  ]);

  const hasRequiredFilter =
    Object.keys(pick(filters, requiredFilterKeys)).length ===
    requiredFilterKeys.length;

  const { data, refetch, dataUpdatedAt } = useGetOrderManagements(
    { query: { ...filters, status: filters.status ?? "ALL" } },
    { enabled: hasRequiredFilter },
  );

  return (
    <ListPageLayout css={S.layout} heading="Order management">
      <OrderManagementFilter />
      <OrderManagementTable
        orders={data}
        hasRequiredFilter={hasRequiredFilter}
        refetch={refetch}
        dataUpdatedAt={dataUpdatedAt}
      />
      <OrderManagementPagination pageInfo={data?.pageInfo} />
    </ListPageLayout>
  );
};

export default OrderManagement;
