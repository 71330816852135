import React from "react";
import { useRecoilValue } from "recoil";
import { lowerCase, upperFirst } from "lodash-es";

import { Badge } from "components";
import { clientLanguage } from "stores";
import { DropdownIcon, TruckIcon } from "assets";
import type { Languages, TruckStatus } from "types";
import * as S from "./ButtonFoldTruck.styled";

interface ButtonFoldTruckProps {
  isFold: boolean;
  truck: {
    registNumber: string;
    truckType: string;
  };
  status: TruckStatus;
  handleFoldBtnClick: () => void;
}

const ButtonFoldTruck = ({
  isFold,
  truck,
  status,
  handleFoldBtnClick,
}: ButtonFoldTruckProps) => {
  const languages = useRecoilValue(clientLanguage);

  if (!truck) return null;

  return (
    <S.ButtonFoldTruck
      type="button"
      isFold={isFold}
      onClick={handleFoldBtnClick}
    >
      <S.ButtonFoldTruckContentWrapper>
        <Badge
          css={S.badge}
          variant={status === "SHIPPING" ? "primary" : "gray"}
          icon={<TruckIcon />}
          content={upperFirst(lowerCase(status)) as Languages}
        />
        <h3>
          {truck.registNumber}
          <span>({truck.truckType})</span>
        </h3>
      </S.ButtonFoldTruckContentWrapper>
      <DropdownIcon css={S.foldBtn(isFold)} />
    </S.ButtonFoldTruck>
  );
};

export default ButtonFoldTruck;
