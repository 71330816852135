import React from "react";

import { AccountInput, ErrorMsg, Timer } from "components";
import { useAccountId } from "hooks";
import type { Languages } from "types";
import * as S from "./AccountIdForm.styled";

interface VerifyAccountInfo {
  emailCheckSuccessFn: () => void;
}

const AccountIdForm = ({ emailCheckSuccessFn }: VerifyAccountInfo) => {
  const {
    isEmailVerifiDone,
    min,
    sec,
    limitTime,
    isTimeout,
    formMethod,
    handleSendVerifiEmail,
  } = useAccountId();

  return (
    <>
      <S.Form onSubmit={emailCheckSuccessFn}>
        <S.InputWrapper>
          <AccountInput
            css={S.verifyInput}
            id="email"
            label="Email"
            value={formMethod.watch("email")}
            disabled={isEmailVerifiDone}
            register={formMethod.register("email")}
          />
          <S.EmailVerifyBtn
            type="button"
            disabled={!!formMethod.formState.errors?.email?.message}
            onClick={handleSendVerifiEmail}
          >
            {!isEmailVerifiDone ? `Verify` : `Re-Verify`}
          </S.EmailVerifyBtn>
        </S.InputWrapper>
        {formMethod.formState.errors?.email?.message && (
          <ErrorMsg
            css={S.alertMsg}
            errorMsg={formMethod.formState.errors.email.message}
          />
        )}
        {isEmailVerifiDone && (
          <>
            <AccountInput
              css={S.verifyCodeInput}
              id="authCode"
              label={"Verification Code" as Languages} //TODO: 디자인 확정 이후 문구 요청필요
              value={formMethod.watch("authCode") ?? ""}
              register={formMethod.register("authCode")}
            />
            {formMethod.formState.errors?.authCode?.message && (
              <ErrorMsg
                css={S.verifiAlertMsg}
                errorMsg={formMethod.formState.errors?.authCode?.message}
              />
            )}
            <Timer
              css={S.timer}
              min={min}
              sec={sec}
              limitTime={limitTime}
              timerText="Expiration time"
            />
          </>
        )}
        <S.GoToChangePassword
          disabled={
            isTimeout ||
            !isEmailVerifiDone ||
            !!formMethod.formState.errors?.authCode?.message
          }
        >
          Next
        </S.GoToChangePassword>
      </S.Form>
    </>
  );
};

export default AccountIdForm;
