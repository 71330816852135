import React, { createContext, useContext, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash-es";

import { useTab } from "hooks";
import type { TabType } from "types";
import * as S from "./Tab.styled";

const TabContentContext = createContext<{
  tabs: readonly TabType[];
  selectedTab: string;
  queryParams: (key: string) => string;
}>({
  tabs: [],
  selectedTab: "",
  queryParams: (key: string) => "",
});
interface TabProps {
  className?: string;
  tabs: readonly TabType[];
  keepQueries?: string[];
  children: ReactNode;
}

const Tab = ({ className, tabs, children, keepQueries }: TabProps) => {
  const { selectedTab, queryParams } = useTab(tabs, keepQueries);

  return (
    <TabContentContext.Provider value={{ tabs, selectedTab, queryParams }}>
      <section className={className}>{children}</section>
    </TabContentContext.Provider>
  );
};

interface TabListProps {
  className?: string;
  tabCount?: { [key: string]: number };
}

Tab.TabList = function TablList({ className, tabCount }: TabListProps) {
  const { t } = useTranslation();

  const { tabs, selectedTab, queryParams } = useContext(TabContentContext);

  return (
    <S.TabList role="tablist" className={className}>
      {tabs.map(({ key, label }) => {
        return (
          <S.Tab key={key} role="tab" isSelected={selectedTab === key}>
            <Link
              id={key}
              role="tab"
              aria-controls={label}
              to={`?${queryParams(key)}`}
              replace
            >
              <span>{t(label)}</span>
              {tabCount && (
                <span>
                  {!isUndefined(tabCount[key]) ? `(${tabCount[key]})` : ""}
                </span>
              )}
            </Link>
          </S.Tab>
        );
      })}
    </S.TabList>
  );
};

interface TabPanelProps {
  render: { [key: string]: ReactNode };
  className?: string;
  handleClickOpen?: () => void;
}

Tab.TabPanel = function TabPanel({
  render,
  className,
  handleClickOpen,
}: TabPanelProps) {
  const { selectedTab } = useContext(TabContentContext);

  return (
    <div
      id={selectedTab}
      role="tabpanel"
      aria-labelledby={selectedTab}
      className={className}
      onClick={handleClickOpen}
    >
      {render[selectedTab]}
    </div>
  );
};

export default Tab;
