import { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { useSearchParams } from "react-router-dom";

const useFilterInput = (
  queryKey: string,
  handleFocusCondition: () => void,
  handleBlurCondition: () => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const applyBtnRef = useRef<HTMLButtonElement | null>(null);

  const [isVisibleApplyBtn, setVisibleApplyBtn] = useState(false);
  const [searchValue, setSearchValue] = useState(
    searchParams.get(queryKey) ?? "",
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!searchValue) return;

    searchParams.get(queryKey)
      ? searchParams.set(queryKey, searchValue)
      : searchParams.append(queryKey, searchValue);

    searchParams.get("page")
      ? searchParams.set("page", "1")
      : searchParams.append("page", "1");

    setSearchParams(searchParams);

    (document.activeElement as HTMLElement).blur();
    setVisibleApplyBtn(false);
  };

  const handleFocus = () => {
    handleFocusCondition();
    setVisibleApplyBtn(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement === applyBtnRef.current) return;

      handleBlurCondition();

      setVisibleApplyBtn(false);
    }, 0);
  };

  useEffect(() => {
    setSearchValue(searchParams.get(queryKey) ?? "");
  }, [searchParams]);

  return {
    inputRef,
    applyBtnRef,
    isVisibleApplyBtn,
    searchValue,
    handleFocus,
    handleBlur,
    handleInput,
    handleForm,
  };
};

export default useFilterInput;
