import React from "react";

import { LabelContentTable, StatusLabel } from "components";
import { formatOrderNum } from "utils";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationDefaultInfo.styled";

interface QuotationDefaultInfoProps {
  defaultInfo?: GetQuotationDetailServerModel["defaultInfo"];
}

const QuotationDefaultInfo = ({ defaultInfo }: QuotationDefaultInfoProps) => {
  if (!defaultInfo) return null;

  return (
    <>
      <S.OrderNumber>{formatOrderNum(defaultInfo.orderNumber)}</S.OrderNumber>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Sender" labelWidth={200}>
            {defaultInfo.sender}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status" labelWidth={200}>
            <StatusLabel status={defaultInfo.offerStatus} />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Shipper" labelWidth={200}>
            {defaultInfo.client}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </>
  );
};

export default QuotationDefaultInfo;
