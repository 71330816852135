import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.figure`
  ${({ theme }) => css`
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &[data-status="false"] {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${theme.color.gray_30};
      background-color: ${theme.color.gray_10};

      & > img {
        width: 50%;
        height: 50%;
        min-width: 12px;
        min-height: 12px;
      }
    }
  `}
`;

export const profileIcon = css`
  width: 24px;
  height: 24px;
`;
