import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ConfirmModal } from "components";
import { useModal, useToast } from "hooks";
import { useDeleteDriver } from "services";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { TruckingCompanyType } from "types";

const useMemberDetailRegisteredDriverDetail = () => {
  const { memberId, driverId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: deleteDriverMutate } = useDeleteDriver();

  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const memberType = searchParams.get("memberType");
  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_DRIVER}`;

  const handleDriverDelete = (): void => {
    const query = {
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    };

    const deleteDriver = () => {
      deleteDriverMutate(
        { driverId: driverId!, query },
        {
          onSuccess: () => {
            navigate(beforeUrl);
            addToast(TOAST_MSG.SUCCESS.DRIVER_DELETE_DONE);
          },
        },
      );
      handleModalClose();
    };

    handleModalOpen(
      <ConfirmModal
        ref={modalRef}
        title="Delete the driver"
        desc="Are you sure you want to delete the driver?"
        btnType="alert"
        activeBtnName="Delete"
        activeFn={deleteDriver}
      />,
    )();
  };
  return { beforeUrl, handleDriverDelete };
};

export default useMemberDetailRegisteredDriverDetail;
