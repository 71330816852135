import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

interface RootProps {
  as?: string;
}

export const Root = styled.dialog<RootProps>`
  ${({ theme, as }) => css`
    position: ${as === "dialog" ? "absolute" : "static"};
    top: calc(100% + 4px);
    width: 292px;
    border: 1px solid ${theme.color.gray_20};
    border-radius: 5px;
    padding: 19px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_regular};
    z-index: ${theme.zIndex.CALENDAR};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const MonthYear = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > span {
      ${theme.font.medium_16};
      color: ${theme.color.black};
    }

    &[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }

    :hover {
      > div:last-of-type {
        border-radius: 50%;
        background-color: ${theme.color.gray_10};
      }
    }
  `}
`;

export const MonthYearContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      ${theme.font.medium_15};
    }
  `}
`;

export const MonthWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }
`;

export const MonthBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 100%;
    border-radius: 100px;
    color: ${theme.color.black};

    &:hover {
      background-color: ${theme.color.primary_10_10};
    }

    &[aria-selected="false"] {
      &[aria-current="true"] {
        border: 1px solid ${theme.color.black};

        &:hover {
          background-color: ${theme.color.gray_10};
        }
      }
    }

    &[aria-selected="true"] {
      color: ${theme.color.white};
      background-color: ${theme.color.primary_40};
    }
  `}
`;

export const OpenMonthWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  `}
`;

export const WeekRow = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 36px);
    margin-bottom: 12px;

    > li {
      ${theme.font.regular_12};
      color: ${theme.color.gray_70};
      text-align: center;
    }
  `}
`;

export const DateRow = styled.ul`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 36px);
    row-gap: 4px;
    margin-bottom: 16px;
    color: ${theme.color.gray_20};
  `}
`;

export const MoveBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const MoveBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    :hover {
      border-radius: 50%;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const arrowLeft = css`
  transform: rotate(180deg);
`;

export const arrowDown = css`
  rotate: -90deg;
  transform: rotate(180deg);
`;

export const PeriodWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border: 1px solid ${theme.color.gray_30};
    padding: 9px 12px;

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      path {
        fill: ${theme.color.gray_40};
      }
    }
  `}
`;

export const Period = styled.div``;

export const DateWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const Date = styled.div<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? theme.color.black : theme.color.gray_50};
  `}
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  height: 36px;
`;

export const ResetBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    padding: 0 18px;
    border: 1px solid ${theme.color.gray_20};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};

    &:not(:disabled):hover {
      border: 1px solid ${theme.color.gray_30};
      background-color: ${theme.color.gray_20};
    }

    &:disabled {
      opacity: 0.6;
    }
  `}
`;

export const ApplyBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 74px;
    border-radius: 2px;
    padding: 0 18px;
    color: ${theme.color.white};
    background-color: ${theme.color.primary_40};

    &:not(:disabled):hover {
      background-color: ${theme.color.primary_50};
    }

    &:disabled {
      opacity: 0.6;
    }
  `}
`;

export const icon = (theme: Theme) => css`
  width: 20px;
  height: 20px;

  path {
    fill: ${theme.color.black} !important;
  }
`;
