import React from "react";

import {
  MyAccountDefaultInfo,
  MyAccountLoginID,
  SettingLayout,
} from "components";
import { SETTING_MENU } from "assets";
import * as S from "./MyAccount.styled";

const MyAccount = () => {
  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <S.Wrapper>
        <MyAccountDefaultInfo />
        <MyAccountLoginID />
      </S.Wrapper>
    </SettingLayout>
  );
};

export default MyAccount;
