import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const button = css`
  width: fit-content;
  margin: 0 0 0 auto;
`;

export const UpdateButtonsWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin: 0 0 0 auto;

  & > button {
    width: fit-content;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
`;
