import { useState, useEffect } from "react";

const useTimer = (initTime: number = 180) => {
  const [isTimeStart, setIsTimeStart] = useState(false);
  const [isTimeReset, setIsTimeReset] = useState(false);
  const [isTimeout, setIsTimeOut] = useState(false);

  const [limitTime, setLimitTime] = useState(initTime);
  const [min, setMin] = useState(Math.floor(initTime / 60));
  const [sec, setSec] = useState(initTime % 60);

  const resetTimer = () => {
    setIsTimeReset(!isTimeReset);
    setIsTimeOut(false);
  };

  const startTimer = () => {
    setIsTimeStart(true);
  };

  useEffect(() => {
    if (!isTimeStart) return;

    const interval = setInterval(() => {
      if (limitTime === 0) {
        clearInterval(interval);
      } else {
        setLimitTime(limitTime - 1);
        setMin(Math.floor((limitTime - 1) / 60));
        setSec((limitTime - 1) % 60);
        if (limitTime - 1 === 0) {
          setIsTimeOut(true);
          clearInterval(interval);
        }
      }
      if (isTimeout) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimeStart, limitTime, isTimeout]);

  useEffect(() => {
    setLimitTime(initTime);
    setMin(Math.floor(initTime / 60));
    setSec(initTime % 60);
  }, [isTimeReset]);

  useEffect(() => {
    return () => {
      setIsTimeOut(false);
    };
  }, [isTimeout]);

  return {
    isTimeout,
    limitTime,
    min,
    sec,
    resetTimer,
    startTimer,
    isTimeReset,
  };
};

export default useTimer;
