import { useQuery } from "@tanstack/react-query";

import {
  getMonitoringCompanysAPI,
  getMonitoringOrderDetailAPI,
  getMonitoringOrdersAPI,
  getMonitoringTruckDetailAPI,
  getMonitoringTrucksAPI,
} from "api";
import type {
  GetMonitoringCompanysQueryModel,
  GetMonitoringOrderDetailQueryModel,
  GetMonitoringOrdersQueryModel,
  GetMonitoringTruckDetailQueryModel,
  GetMonitoringTrucksQueryModel,
} from "types";

const monitoringKeys = {
  all: ["monitoring"],
  lists: () => [...monitoringKeys.all, "list"] as const,
  list: (filters: GetMonitoringCompanysQueryModel) => [
    ...monitoringKeys.lists(),
    { filters },
  ],
  trucks: () => [...monitoringKeys.all, "truck"],
  truck: (filters: GetMonitoringTrucksQueryModel) => [
    ...monitoringKeys.trucks(),
    { filters },
  ],
  orders: () => [...monitoringKeys.all, "order"],
  order: (filters: GetMonitoringOrdersQueryModel) => [
    ...monitoringKeys.orders(),
    { filters },
  ],
  details: () => [...monitoringKeys.all, "detail"] as const,
  detail: (id: string, type: "truck" | "order") =>
    [...monitoringKeys.details(), { id, type }] as const,
};

export const useGetMonitoringCompanys = (
  req: GetMonitoringCompanysQueryModel,
) => {
  return useQuery({
    queryKey: monitoringKeys.list(req),
    queryFn: () => getMonitoringCompanysAPI(req),
  });
};

export const useGetMonitoringTrucks = (
  req: GetMonitoringTrucksQueryModel,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: monitoringKeys.truck(req),
    queryFn: () => getMonitoringTrucksAPI(req),
    enabled,
  });
};

export const useGetMonitoringOrders = (
  req: GetMonitoringOrdersQueryModel,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: monitoringKeys.order(req),
    queryFn: () => getMonitoringOrdersAPI(req),
    enabled,
  });
};

export const useGetMonitoringOrderDetail = (
  req: GetMonitoringOrderDetailQueryModel,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: monitoringKeys.detail(req.orderId, "order"),
    queryFn: () => getMonitoringOrderDetailAPI(req),
    enabled,
  });
};

export const useGetMonitoringTruckDetail = (
  req: GetMonitoringTruckDetailQueryModel,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: monitoringKeys.detail(req.truckId, "truck"),
    queryFn: () => getMonitoringTruckDetailAPI(req),
    enabled,
  });
};
