import React from "react";

import { QueryPagination } from "components";
import { useGetMembers } from "services";
import { useFilterParams } from "hooks";
import type { GetMembersQueryModel } from "types";

const ShipperPagination = () => {
  const { filters } = useFilterParams<
    GetMembersQueryModel["query"] & { search?: string }
  >(["status", "name", "page"]);

  const { data } = useGetMembers({
    query: { ...filters, companyType: "SHIPPER" },
  });

  if (!data) return null;

  return (
    <QueryPagination
      pageInfo={{
        currentPage: data.pageInfo.currentPage,
        dataPerPage: data.pageInfo.dataPerPage,
        startRow: data.pageInfo.startRow,
        totalData: data.pageInfo.totalData,
        totalPages: data.pageInfo.totalPages,
      }}
    />
  );
};

export default ShipperPagination;
