import React from "react";

import { StatusLabel } from "components";
import { formatICTDateTime, formatOrderNum } from "utils";
import { PersonIcon, PhoneIcon, TimeIcon } from "assets";
import type { GetOrderManagementMapServerModel } from "types";
import * as S from "./TrackingHistoryInfo.styled";

interface TrackingHistoryInfoProps {
  orderInfo: GetOrderManagementMapServerModel;
}

const TrackingHistoryInfo = ({ orderInfo }: TrackingHistoryInfoProps) => {
  return (
    <S.OrderInfoWrapper>
      <S.OrderInfoRow>
        <h3>{formatOrderNum(orderInfo.orderNumber) ?? "-"}</h3>
        <StatusLabel status={orderInfo.status} />
      </S.OrderInfoRow>
      <S.OrderInfoContentWrapper>
        <S.OrderInfoContentBox>
          <PersonIcon />
          <span>{orderInfo.truckingCompany ?? "-"}</span>
        </S.OrderInfoContentBox>
        <S.OrderInfoContentBox>
          <PhoneIcon />
          <span>{orderInfo.truckingCompanyPhone ?? "-"}</span>
        </S.OrderInfoContentBox>
        <S.OrderInfoContentBox>
          <TimeIcon />
          <time>
            {orderInfo.puEta
              ? `(ETA) ${formatICTDateTime(orderInfo.puEta)}`
              : "-"}
          </time>
        </S.OrderInfoContentBox>
      </S.OrderInfoContentWrapper>
    </S.OrderInfoWrapper>
  );
};

export default TrackingHistoryInfo;
