import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getMembersAPI,
  getMemberDetailAPI,
  postMemberAPI,
  getMemberDetailDriversAPI,
  getMemberDetailDriverDetailAPI,
  getMemberDetailGaragesAPI,
  getMemberDetailTrucksAPI,
  getMemberDetailTruckDetailAPI,
  getMemberCompanyPlanAPI,
  postMemberCompanyPlanAPI,
  putMemberCompanyPlanAPI,
  putMemberAPI,
  postMemberDummyAPI,
  putMemberDummyAPI,
  putLicenseApproveAPI,
  getTruckGPSAPI,
  getTruckGarageAPI,
  postTruckCreateAPI,
  getTruckLinkDriverAPI,
  getMemberDetailRegisteredMemberAPI,
  deleteMemberDetailRegisteredMemberAPI,
  createMemberDetailRegisteredMemberAPI,
  getMemberDetailRegisteredMemberDetailAPI,
  editMemberDetailRegisteredMemberDetailAPI,
  putTruckSpecificationAPI,
  createMemberDetailGaragesAPI,
  deleteTruckAPI,
  getLinkDriverTrucksAPI,
  postLinkDriverTruckAPI,
  deleteLinkDriverTruckAPI,
  getMemeberDetailLinksAPI,
  getMemberDetailStaffsAPI,
  getMemberDetailGarageDetailAPI,
  editMemeberDetailGarageAPI,
  deleteMemberDetailGarageAPI,
  putDriverDefaultInfoAPI,
  putDriveAccountInfoAPI,
  postDriverCreateAPI,
  changeDriverPasswordAPI,
  getDriverAllocationAPI,
  deleteDriverAPI,
  postMemberDetailConnectLinkAPI,
  getMemberDetailLinkedDriverSearchAPI,
  putTruckDefaultInformationAPI,
  getMemberDetailTruckDetailAllocationAPI,
} from "api";
import {
  APIError,
  GetMemberDetailDriversQueryModel,
  GetMemberDetailGaragesQueryModel,
  GetMemberDetailTrucksQueryModel,
  GetMemberCompanyPlanQueryModel,
  GetMemberDetailQueryModel,
  GetMembersQueryModel,
  PostMemberCompanyPlanQueryModel,
  PostMemberDummyQueryModel,
  PostMemberQueryModel,
  PutLicenseApproveQueryModel,
  PutMemberCompanyPlanQueryModel,
  PutMemberDummyQueryModel,
  PutMemberQueryModel,
  GetTruckGPSsQueryModel,
  GetTruckGarageQueryModel,
  PostTruckCreateQueryModel,
  GetTruckLinkDriverQueryModel,
  GetMemberDetailRegisteredMemberQueryModel,
  TruckingCompanyType,
  DeleteMemberDetailRegisteredMemberQueryModel,
  CreateMemberDetailRegisteredMemberQueryModel,
  GetMemberDetailRegisteredMemberDetailQueryModel,
  EditMemberDetailRegisteredMemberDetailQueryModel,
  GetMemberDetailTruckDetailQueryModel,
  PutTruckSpecificationServerModel,
  GetMemberDetailDriverDetailQueryModel,
  CreateMemberDetailGarageQueryModel,
  PutTruckDefaultInformationQueryModel,
  DeleteTruckQueryModel,
  GetLinkDriverTrucksQueryModel,
  PostLinkDriverTruckQueryModel,
  DeleteLinkDriverTruckQueryModel,
  GetMemberLinksQueryModel,
  GetMemberDetailStaffsQueryModel,
  GetMemberDetailGarageQueryModel,
  EditMemeberDetailGarageQueryModel,
  DeleteMemeberDetailGarageQueryModel,
  PutDriverDefaultInfoQueryModel,
  PutDriverAccountInfoQueryModel,
  PostDriverCreateQueryModel,
  ChangeDriverPasswordQueryModel,
  GetDriverAllocationQueryModel,
  DeleteDriverQueryModel,
  PostMemberDetailConnectLinkQueryModel,
  GetMemberDetailLinkedDriverSearchQueryModel,
  GetMemberDetailTruckDetailAllocationQueryModel,
} from "types";

const memberKeys = {
  all: ["member"],
  lists: () => [...memberKeys.all, "list"],
  list: (req: GetMembersQueryModel) => [...memberKeys.lists(), req],
  details: () => [...memberKeys.all, "detail"],
  detail: (req: GetMemberDetailQueryModel) => [...memberKeys.details(), req],
  drivers: () => [...memberKeys.all, "driver"],
  driver: (req: GetMemberDetailDriversQueryModel) => [
    ...memberKeys.drivers(),
    req,
  ],
  driverDetails: () => [...memberKeys.all, "driverDetail"],
  driverDetail: (driverId: string) => [
    ...memberKeys.driverDetails(),
    { driverId },
  ],
  driverAllocations: () => [...memberKeys.all, "driverAllocation"],
  driverAllocation: (driverId: string) => [
    ...memberKeys.driverAllocations(),
    { driverId },
  ],
  linkDriverTrucks: () => [...memberKeys.all, "linkDriverTruck"],
  linkDriverTruck: (req: GetLinkDriverTrucksQueryModel) =>
    [...memberKeys.linkDriverTrucks(), req] as const,
  garages: () => [...memberKeys.all, "garages"],
  garage: (req: GetMemberDetailGaragesQueryModel) => [
    ...memberKeys.garages(),
    req,
  ],
  garageDetails: () => [...memberKeys.all, "staffDetails"],
  garageDetail: (filters: GetMemberDetailGarageQueryModel) => [
    ...memberKeys.garageDetails(),
    { filters },
  ],
  staffs: () => [...memberKeys.all, "staffs"],
  staff: (req: GetMemberDetailStaffsQueryModel) => [
    ...memberKeys.staffs(),
    req,
  ],
  carrierTrucks: () => [...memberKeys.all, "carrierTruck"],
  carrierTruck: (req: GetMemberDetailTrucksQueryModel) => [
    ...memberKeys.carrierTrucks(),
    req,
  ],
  carrierTruckDetails: () => [...memberKeys.all, "carrierTruckDetail"],
  carrierTruckDetail: (truckId: string) => [
    ...memberKeys.carrierTruckDetails(),
    { truckId },
  ],
  plans: () => [...memberKeys.all, "plan"],
  plan: (req: GetMemberCompanyPlanQueryModel) => [...memberKeys.plans(), req],
  truckGpss: () => [...memberKeys.all, "gps"],
  truckGps: (req: GetTruckGPSsQueryModel) => [...memberKeys.truckGpss(), req],
  truckGarages: () => [...memberKeys.all, "garages"],
  truckGarage: (req: GetTruckGarageQueryModel) => [
    ...memberKeys.truckGarages(),
    req,
  ],
  linkTruckDrivers: () => [...memberKeys.all, "linkTruckDriver"],
  linkTruckDriver: (req: GetTruckLinkDriverQueryModel) => [
    ...memberKeys.linkTruckDrivers(),
    req,
  ],
  members: () => [...memberKeys.all, "registeredMembersDetailMember"],
  member: (
    companyType: TruckingCompanyType,
    filters: GetMemberDetailRegisteredMemberQueryModel,
  ) => [...memberKeys.members(), { companyType, filters }] as const,
  memberDetails: () => [
    ...memberKeys.all,
    "registeredMembersDetailMemberDetail",
  ],
  memberDetail: (
    companyType: TruckingCompanyType,
    filters: GetMemberDetailRegisteredMemberDetailQueryModel,
  ) => [...memberKeys.memberDetails(), { companyType, filters }] as const,
  links: () => [...memberKeys.all, "regieteredLinks"] as const,
  link: (companyType: TruckingCompanyType, filters: GetMemberLinksQueryModel) =>
    [...memberKeys.links(), { companyType, filters }] as const,
  memberDetailTruckDetailAllocations: () => [
    ...memberKeys.all,
    "memberDetailTruckDetailAllocations",
  ],
  memberDetailTruckDetailAllocation: (
    req: GetMemberDetailTruckDetailAllocationQueryModel,
  ) => [...memberKeys.memberDetailTruckDetailAllocations(), { req }],
};

export const useGetMembers = (req: GetMembersQueryModel) => {
  return useQuery({
    queryKey: memberKeys.list(req),
    queryFn: () => getMembersAPI(req),
  });
};

export const useGetMemberDetail = (
  req: GetMemberDetailQueryModel,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: memberKeys.detail(req),
    queryFn: () => getMemberDetailAPI(req),
    enabled,
  });
};

export const usePostMember = () => {
  return useMutation<unknown, APIError, PostMemberQueryModel>({
    mutationFn: (req) => postMemberAPI(req),
  });
};

export const usePutMember = () => {
  return useMutation<unknown, APIError, PutMemberQueryModel>({
    mutationFn: (req) => putMemberAPI(req),
  });
};

export const useGetDrivers = (req: GetMemberDetailDriversQueryModel) => {
  return useQuery({
    queryKey: memberKeys.driver(req),
    queryFn: () => getMemberDetailDriversAPI(req),
  });
};

export const useGetDriverDetail = (
  req: GetMemberDetailDriverDetailQueryModel,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: memberKeys.driverDetail(req.driverId),
    queryFn: () => getMemberDetailDriverDetailAPI(req),
    enabled,
  });
};

export const useGetDriverAllocation = (req: GetDriverAllocationQueryModel) => {
  return useQuery({
    queryKey: memberKeys.driverAllocation(req.driverId),
    queryFn: () => getDriverAllocationAPI(req),
  });
};

export const useDeleteDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: DeleteDriverQueryModel) => deleteDriverAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.driverDetails(),
      });
    },
  });
};

export const usePostDriverCreate = () => {
  return useMutation<unknown, APIError, PostDriverCreateQueryModel>({
    mutationFn: (req) => postDriverCreateAPI(req),
  });
};

export const usePutDriverDefaultInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PutDriverDefaultInfoQueryModel) =>
      putDriverDefaultInfoAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.driverDetails(),
      });
    },
  });
};

export const useChangeDriverPassword = () => {
  return useMutation<unknown, APIError, ChangeDriverPasswordQueryModel>({
    mutationFn: (req) => changeDriverPasswordAPI(req),
  });
};

export const usePutDriverAccountInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PutDriverAccountInfoQueryModel) =>
      putDriveAccountInfoAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.driverDetails(),
      });
    },
  });
};

export const useGetLinkDriverTrucks = (
  req: GetLinkDriverTrucksQueryModel,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: memberKeys.linkDriverTruck(req),
    queryFn: () => getLinkDriverTrucksAPI(req),
    enabled,
  });
};

export const usePostLinkDriverTruck = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostLinkDriverTruckQueryModel>({
    mutationFn: (req) => postLinkDriverTruckAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.driverDetails(),
      });
    },
  });
};

export const useDeleteLinkDriverTruck = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, DeleteLinkDriverTruckQueryModel>({
    mutationFn: (req) => deleteLinkDriverTruckAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.driverDetails(),
      });
    },
  });
};

export const useGetGarages = (req: GetMemberDetailGaragesQueryModel) => {
  return useQuery({
    queryKey: memberKeys.garage(req),
    queryFn: () => getMemberDetailGaragesAPI(req),
  });
};

export const useCreateGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateMemberDetailGarageQueryModel>({
    mutationFn: (req) => createMemberDetailGaragesAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.garages()),
  });
};

export const useGetGarageDetail = (req: GetMemberDetailGarageQueryModel) => {
  return useQuery({
    queryKey: memberKeys.garage(req),
    queryFn: () => getMemberDetailGarageDetailAPI(req),
  });
};

export const useEditGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, EditMemeberDetailGarageQueryModel>({
    mutationFn: (req) => editMemeberDetailGarageAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.garages()),
  });
};

export const useDeleteGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, DeleteMemeberDetailGarageQueryModel>({
    mutationFn: (req) => deleteMemberDetailGarageAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.garages()),
  });
};

export const useGetStaffs = (req: GetMemberDetailStaffsQueryModel) => {
  return useQuery({
    queryKey: memberKeys.staff(req),
    queryFn: () => getMemberDetailStaffsAPI(req),
  });
};

export const useGetTrucks = (req: GetMemberDetailTrucksQueryModel) => {
  return useQuery({
    queryKey: memberKeys.carrierTruck(req),
    queryFn: () => getMemberDetailTrucksAPI(req),
  });
};

export const useGetTruckDetail = (
  req: GetMemberDetailTruckDetailQueryModel,
) => {
  return useQuery({
    queryKey: memberKeys.carrierTruckDetail(req.truckId),
    queryFn: () => getMemberDetailTruckDetailAPI(req),
  });
};

export const useGetPlans = (req: GetMemberCompanyPlanQueryModel) => {
  return useQuery({
    queryKey: memberKeys.plan(req),
    queryFn: () => getMemberCompanyPlanAPI(req),
  });
};

export const usePostPlan = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostMemberCompanyPlanQueryModel>({
    mutationFn: (req) => postMemberCompanyPlanAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.plans(),
      });
    },
  });
};

export const usePutPlan = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PutMemberCompanyPlanQueryModel>({
    mutationFn: (req) => putMemberCompanyPlanAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.plans(),
      });
    },
  });
};

export const usePostMemberDummy = () => {
  return useMutation<unknown, APIError, PostMemberDummyQueryModel>({
    mutationFn: (req) => postMemberDummyAPI(req),
  });
};

export const usePutMemberDummy = () => {
  return useMutation<unknown, APIError, PutMemberDummyQueryModel>({
    mutationFn: (req: PutMemberDummyQueryModel) => putMemberDummyAPI(req),
  });
};

export const usePutLicenseApprove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PutLicenseApproveQueryModel) => putLicenseApproveAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.details(),
      });
    },
  });
};

export const useGetTruckGpss = (req: GetTruckGPSsQueryModel) => {
  return useQuery({
    queryKey: memberKeys.truckGps(req),
    queryFn: () => getTruckGPSAPI(req),
  });
};

export const useGetTruckGarage = (req: GetTruckGarageQueryModel) => {
  return useQuery({
    queryKey: memberKeys.truckGarage(req),
    queryFn: () => getTruckGarageAPI(req),
  });
};

export const usePostTruckCreate = () => {
  return useMutation<unknown, APIError, PostTruckCreateQueryModel>({
    mutationFn: (req) => postTruckCreateAPI(req),
  });
};

export const useGetLinkTruckDriver = (req: GetTruckLinkDriverQueryModel) => {
  return useQuery({
    queryKey: memberKeys.linkTruckDriver(req),
    queryFn: () => getTruckLinkDriverAPI(req),
  });
};

export const useGetMemberDetailRegisteredMember = (
  req: GetMemberDetailRegisteredMemberQueryModel,
) =>
  useQuery({
    queryKey: memberKeys.member(req.query.companyType, req),
    queryFn: () => getMemberDetailRegisteredMemberAPI(req),
  });

export const useCreateMemberDetailRegisteredMember = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError,
    CreateMemberDetailRegisteredMemberQueryModel
  >({
    mutationFn: (req) => createMemberDetailRegisteredMemberAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.members()),
  });
};

export const useDeleteMemberDetailRegisteredMember = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError,
    DeleteMemberDetailRegisteredMemberQueryModel
  >({
    mutationFn: (req) => deleteMemberDetailRegisteredMemberAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.members()),
  });
};

export const useGetMemberDetailRegisteredMemberDetail = (
  req: GetMemberDetailRegisteredMemberDetailQueryModel,
) =>
  useQuery({
    queryKey: memberKeys.memberDetail(req.query.companyType, req),
    queryFn: () => getMemberDetailRegisteredMemberDetailAPI(req),
  });

export const useEditMemberDetailRegisteredMember = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError,
    EditMemberDetailRegisteredMemberDetailQueryModel
  >({
    mutationFn: (req) => editMemberDetailRegisteredMemberDetailAPI(req),
    onSuccess: () => queryClient.invalidateQueries(memberKeys.members()),
  });
};

export const usePutTruckSpecification = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PutTruckSpecificationServerModel>({
    mutationFn: (req) => putTruckSpecificationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.carrierTruckDetails(),
      });
    },
  });
};

export const usePutTruckDefaultInfomation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PutTruckDefaultInformationQueryModel>({
    mutationFn: (req) => putTruckDefaultInformationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.carrierTruckDetails(),
      });
    },
  });
};

export const useDeleteTruck = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: DeleteTruckQueryModel) => deleteTruckAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberKeys.carrierTruckDetails(),
      });
    },
  });
};

export const useMemeberDetailLinks = (req: GetMemberLinksQueryModel) =>
  useQuery({
    queryKey: memberKeys.link(req.query.companyType, req),
    queryFn: () => getMemeberDetailLinksAPI(req),
  });

const linkedDriverKey = ["linkedDriver"];

export const usePostMemberDetailConnectLink = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostMemberDetailConnectLinkQueryModel>({
    mutationFn: (req) => postMemberDetailConnectLinkAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(memberKeys.links());
      queryClient.invalidateQueries(linkedDriverKey);
      queryClient.invalidateQueries(memberKeys.carrierTruckDetails());
    },
  });
};

export const useGetLinkManageDriverSearch = (
  req: GetMemberDetailLinkedDriverSearchQueryModel,
  enabled: boolean,
) =>
  useQuery({
    queryKey: linkedDriverKey,
    queryFn: () => getMemberDetailLinkedDriverSearchAPI(req),
    enabled,
  });

export const useGetMemberDetailTruckDetailAllocation = (
  req: GetMemberDetailTruckDetailAllocationQueryModel,
) => {
  return useQuery({
    queryKey: memberKeys.memberDetailTruckDetailAllocation(req),
    queryFn: () => getMemberDetailTruckDetailAllocationAPI(req),
  });
};
