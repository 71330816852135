import React from "react";

import { ConfirmModal } from "components";

interface PinNoticeModalProps {
  activeFn: () => void;
}

const PinNoticeModal = React.forwardRef(
  (
    { activeFn }: PinNoticeModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="Pin"
        desc={"Are you sure you want to pin the notice?"}
        activeBtnName="Confirm"
        closeBtnName="Close"
        activeFn={activeFn}
      />
    );
  },
);

PinNoticeModal.displayName = "PinNoticeModal";

export default PinNoticeModal;
