import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  DetailLayout,
  Dropdown,
  FormInput,
  FormPasswordInput2,
  InternalLink,
  LabelContent,
  PageBackHeader,
} from "components";
import { useToast } from "hooks";
import { useCreateMemberDetailRegisteredMember } from "services";
import { makeCryptoFunction, numericOnly } from "utils";
import { GRADE_TYPE_RADIOS, HOOKFORM_ERR } from "assets";
import { PATH, TAB, TOAST_MSG } from "constants/index";
import type { TruckingCompanyType, Languages } from "types";
import { useMemberDetailRegisteredMemberCreate } from "./hooks";
import * as S from "./MemberDetailRegisteredMemberCreate.styled";

const MemberDetailRegisteredMemberCreate = () => {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: createMemberDetailRegisteredMemberMutate } =
    useCreateMemberDetailRegisteredMember();
  const { addToast } = useToast();
  const { formMethod } = useMemberDetailRegisteredMemberCreate();

  const memberType = searchParams.get("memberType");
  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_MEMBER}`;

  const handleAddClick = formMethod.handleSubmit((data): void => {
    const req = {
      query: {
        companyId: memberId!,
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
      body: {
        lastName: data.lastName.trim(),
        firstName: data.firstName.trim(),
        phone: data.phone.trim(),
        email: data.email.trim(),
        password: makeCryptoFunction(data.password),
        grade: data.grade.key,
        ...(data.middleName && { middleName: data.middleName.trim() }),
      },
    };

    createMemberDetailRegisteredMemberMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.MEMBER_CREATE_DONE);
        navigate(beforeUrl);
      },
      onError: (err) => {
        switch (err.response?.data.response) {
          case "EMAIL_EXISTS":
            return formMethod.setError("email", {
              type: "validate",
              message: HOOKFORM_ERR.EMAIL_EXISTS,
            });
          case "PHONE_EXISTS":
            return formMethod.setError("phone", {
              type: "validate",
              message: HOOKFORM_ERR.PHONE_EXISTS,
            });
        }
      },
    });
  });

  return (
    <DetailLayout>
      <PageBackHeader
        css={S.pageBackHeader}
        title="Add member"
        path={beforeUrl}
      />
      <Card css={S.card}>
        <Card.Content css={S.content} heading="Default information">
          <S.NameWrapper>
            <LabelContent
              css={S.labelContent}
              label="Last name"
              direction="vertical"
              isRequired
            >
              <FormInput
                placeholder="Last name"
                maxLength={50}
                hasError={!!formMethod.formState.errors.lastName}
                errorMsg={formMethod.formState.errors.lastName?.message}
                register={formMethod.register("lastName")}
              />
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Middle name"
              direction="vertical"
            >
              <FormInput
                placeholder={`(${t("Optional")})` as Languages} // NOTE: Optional 번역이 있어 해당과 같이 처리
                maxLength={50}
                register={formMethod.register("middleName")}
              />
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="First name"
              direction="vertical"
              isRequired
            >
              <FormInput
                placeholder="First name"
                maxLength={50}
                hasError={!!formMethod.formState.errors.firstName}
                errorMsg={formMethod.formState.errors.firstName?.message}
                register={formMethod.register("firstName")}
              />
            </LabelContent>
          </S.NameWrapper>
          <LabelContent
            css={S.labelContent}
            label="Phone number"
            direction="vertical"
            isRequired
          >
            <FormInput
              placeholder="Only numbers"
              maxLength={15}
              value={numericOnly(formMethod.watch("phone"))}
              hasError={!!formMethod.formState.errors.phone}
              errorMsg={formMethod.formState.errors.phone?.message}
              register={formMethod.register("phone")}
            />
          </LabelContent>
          <LabelContent label="Grade" isRequired direction="vertical">
            <Controller
              control={formMethod.control}
              name="grade"
              render={({ field: { value, onChange } }) => {
                const handleSelect = (grade: string) => {
                  const selectedGrade = GRADE_TYPE_RADIOS.findIndex(
                    ({ key }) => key === grade,
                  );

                  onChange(GRADE_TYPE_RADIOS[selectedGrade]);
                };

                return (
                  <Dropdown
                    css={S.dropdown}
                    options={GRADE_TYPE_RADIOS}
                    selectedOption={value}
                    handleSelect={handleSelect}
                  />
                );
              }}
            />
          </LabelContent>
        </Card.Content>
        <Card.Content css={S.content} heading="ID and password">
          <LabelContent
            css={S.labelContent}
            label="Login ID"
            direction="vertical"
            isRequired
          >
            <FormInput
              placeholder="Login ID"
              maxLength={320}
              hasError={!!formMethod.formState.errors.email}
              errorMsg={formMethod.formState.errors.email?.message}
              register={formMethod.register("email", {
                required: HOOKFORM_ERR.NOT_EMPTY,
              })}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Password"
            direction="vertical"
            isRequired
          >
            <FormPasswordInput2
              placeholder="Password"
              maxLength={16}
              hasError={!!formMethod.formState.errors.password}
              errorMsg={formMethod.formState.errors.password?.message}
              register={formMethod.register("password")}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Confirm password"
            direction="vertical"
            isRequired
          >
            <FormPasswordInput2
              placeholder="Confirm password"
              maxLength={16}
              hasError={!!formMethod.formState.errors.confirmPassword}
              errorMsg={formMethod.formState.errors.confirmPassword?.message}
              register={formMethod.register("confirmPassword")}
            />
          </LabelContent>
        </Card.Content>
      </Card>
      <S.ButtonWrapper>
        <InternalLink variant="outlineMedium" to={beforeUrl}>
          {t("Cancel")}
        </InternalLink>
        <Button
          variant="primaryMedium"
          label="Add"
          isDisabled={Object.keys(formMethod.formState.errors).length > 0}
          handleClickBtn={handleAddClick}
        />
      </S.ButtonWrapper>
    </DetailLayout>
  );
};

export default MemberDetailRegisteredMemberCreate;
