import { css } from "@emotion/react";

export const layout = css`
  position: relative;
`;

export const link = css`
  position: absolute;
  right: 0;
`;

export const icon = css`
  width: 12px;
  height: 12px;
`;
