import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const EmailVerifyBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    position: absolute;
    right: 0px;
    bottom: 28px;
    border-radius: 2px;
    border: 1px solid ${theme.color.gray_30};
    padding: 5px 10px;
    color: ${theme.color.gray_20};
    background-color: ${theme.color.white};

    :disabled {
      opacity: 0.2;
    }
  `}
`;

export const verifyInput = (theme: Theme) => css`
  margin-bottom: 0;

  & > input {
    padding-right: 56px;

    &:-webkit-autofill:disabled {
      -webkit-box-shadow: 0 0 0px 1000px ${theme.color.gray_10} inset;
    }
    &:disabled {
      background-color: ${theme.color.gray_10};
    }
  }
`;

export const alertMsg = css`
  margin-bottom: 0;
`;

export const verifiAlertMsg = css`
  margin-top: 8px;
`;

export const verifyCodeInput = css`
  & > input {
    margin-bottom: 0;
  }
`;

export const GoToChangePassword = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    width: 100%;
    height: 52px;
    margin-top: 20px;
    color: ${theme.color.white};
    background-color: ${theme.color.primary_10};

    &:not(:disabled):hover {
      background-color: ${theme.color.primary_20};
    }

    &:disabled {
      background-color: ${theme.color.primary_10};
    }
  `}
`;

export const timer = css`
  margin: 8px 0 20px;
`;
