import React, { useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { Dropdown } from "components";
import { useDropdownValue } from "hooks";
import { clientLanguage } from "stores";
import {
  COCOTRUCK_LANGUAGE,
  LANG_DROPDOWN_OPTIONS,
  LanguageIcon,
} from "assets";

import * as S from "./LangDropdown.styled";

const LangDropdown = () => {
  const { t } = useTranslation();

  const [language, setLanguage] = useRecoilState(clientLanguage);

  const { selectedOption, handleSelect } = useDropdownValue(
    LANG_DROPDOWN_OPTIONS,
    language,
  );

  const handleSelectLang = useCallback((optionKey: string) => {
    if (
      window.confirm(
        t(
          "If you change the language, the content you are writing will not be saved. Do you want to proceed?",
        ),
      )
    ) {
      setLanguage(optionKey as keyof typeof COCOTRUCK_LANGUAGE);
      handleSelect(optionKey);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <S.LanguageWrapper>
      <LanguageIcon />
      <Dropdown
        css={S.dropdown}
        options={LANG_DROPDOWN_OPTIONS}
        selectedOption={selectedOption}
        handleSelect={handleSelectLang}
      />
    </S.LanguageWrapper>
  );
};

export default LangDropdown;
