import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 8px;
  `}
`;

export const NameWrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) => css`
    display: flex;
    column-gap: 10px;
    width: 500px;
    margin-bottom: ${!hasError && "16px"};
  `}
`;

export const errorMsg = css`
  margin-bottom: 16px;
`;

export const phoneFormInput = css`
  width: 500px;
`;
