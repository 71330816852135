import React from "react";
import { useTranslation } from "react-i18next";

import { MoreIcon } from "assets";
import DialogBtn from "../DialogBtn";
import * as S from "./LinkDialogBtn.styled";

interface LinkDialogBtnProps {
  handleLinkState: () => void;
  handleUnlinkAll: () => void;
}

const LinkDialogBtn = ({
  handleLinkState,
  handleUnlinkAll,
}: LinkDialogBtnProps) => {
  const { t } = useTranslation();

  return (
    <DialogBtn
      css={S.dialog}
      popup={() => (
        <S.PopupBox>
          <ul>
            <li>
              <S.Btn onClick={handleLinkState}>{t("Edit")}</S.Btn>
            </li>
            <li>
              <S.Btn onClick={handleUnlinkAll}>{t("Unlink all")}</S.Btn>
            </li>
          </ul>
        </S.PopupBox>
      )}
    >
      <MoreIcon />
    </DialogBtn>
  );
};

export default LinkDialogBtn;
