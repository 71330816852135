import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import vi from "./vi.json";

export const fallbackLng = "en";

export const resources = {
  en: { translation: en },
  vi: { translation: vi },
} as const;

export const languages = [
  resources["en"]["translation"],
  resources["vi"]["translation"],
] as const;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
});

export default i18n;
