import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "contexts";
import { PATH } from "constants/index";
import type { Languages } from "types";
import * as S from "./SettingLayout.styled";

interface SettingLayoutProps {
  settingMenu: readonly { readonly label: string; readonly path: string }[];
  children: React.ReactNode;
}

const SettingLayout = ({ settingMenu, children }: SettingLayoutProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { auth } = useAuth();

  useEffect(() => {
    if (pathname !== PATH.SETTING) return;

    navigate(PATH.SETTING, { replace: true });
  }, [pathname]);

  return (
    <S.Layout>
      <nav>
        <S.Title>
          {t("Settings")}
          <span>{t("Manage your COCOTRUCK account.")}</span>
        </S.Title>
        <S.MenuList>
          {settingMenu.map(({ path, label }) => {
            if (
              auth.adminGrade === "MANAGER" ||
              (auth.adminGrade === "MODERATOR" && label !== "Registered member")
            ) {
              return (
                <S.MenuItem key={label}>
                  <NavLink css={S.navLink} to={path}>
                    {t(label as Languages)}
                  </NavLink>
                </S.MenuItem>
              );
            }
          })}
        </S.MenuList>
      </nav>
      {children}
    </S.Layout>
  );
};

export default SettingLayout;
