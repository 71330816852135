import React from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

import type { Languages } from "types";
import Input from "../Input";
import * as S from "./UnitSuffixInput.styled";

interface UnitSuffixInputProps {
  className?: string;
  id: string;
  placeholder: Languages;
  value: any;
  err: any;
  unit: any;
  maxLength?: number;
  disabled?: boolean;
  register?: UseFormRegisterReturn<string>;
}

const UnitSuffixInput = ({
  className,
  id,
  placeholder,
  value,
  err,
  maxLength,
  disabled,
  unit,
  register,
}: UnitSuffixInputProps) => {
  return (
    <S.Root className={className}>
      <Input
        disabled={disabled}
        id={id}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        hasError={!!err}
        register={register}
      />
      <S.UnitSuffix>{unit}</S.UnitSuffix>
    </S.Root>
  );
};

export default UnitSuffixInput;
