import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = styled.section`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-flow: column;
    row-gap: 32px;
    border: 1px solid ${theme.color.gray_30};
    padding: 32px;
    background-color: ${theme.color.white};
  `}
`;

export const HeadingWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_60};
    padding-bottom: 12px;
  `}
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    color: ${theme.color.black};
  `}
`;
