import type {
  GetMembersServerModel,
  TableHeaderKeysWithServerModel,
} from "types";

export const CARRIER_MANAGEMENT_TABLE_HEADER = {
  category: "Status",
  companyName: "Carrier name",
  addr: "Address",
  phone: "Tel.",
  email: "Email",
  linked: "Linked",
  create: "Create Date",
} as const;

export const SHIPPER_SUPPORT_TABLE_HEADER = {
  status: "Status",
  category: "Category",
  orderNumber: "Order Number",
  subject: "Subject",
  companyName: "Company Name",
  creator: "Creator",
  createdDate: "Created Date",
} as const;

export const CARRIER_SUPPORT_TABLE_HEADER = {
  status: "Status",
  category: "Category",
  orderNumber: "Order Number",
  subject: "Subject",
  companyName: "Company Name",
  creator: "Creator",
  createdDate: "Created Date",
} as const;

export const FORWARDER_SUPPORT_TABLE_HEADER = {
  status: "Status",
  category: "Category",
  orderNumber: "Order Number",
  subject: "Subject",
  companyName: "Company Name",
  creator: "Creator",
  createdDate: "Created Date",
} as const;

export const smallModeratorListTable = {
  name: "Moderator name",
  phone: "Phone number",
  email: "Login ID",
  completedOrders: "Completed orders",
  grade: "Grade",
  created: "Created on",
};

export const smallTransitHistoryListTable = {
  orderNumber: "Order number",
  shipperName: "Shipper",
  pu: "Pick-up",
  do: "Drop-off",
  puDatetime: "Pick-up date",
  doDatetime: "Drop-off date",
};

export const carrierTruckListTable = {
  plateNumber: "Plate number",
  truckType: "Truck type",
  truckOption: "Truck option",
  parkedGarage: "Parked garage",
  linked: "Linked",
  created: "Created on",
};

export const carrierGarageTable = {
  name: "Garage name",
  addr: "Address",
  garageUsage: "Garage usage",
};

export const carrierDriverTable = {
  name: "Driver name",
  phone: "Phone number",
  linked: "Linked",
  lastShippingOn: "Last shipping on",
  created: "Created on",
};

export const shipperManagementTable = {
  companyName: "Company name",
  addr: "Address",
  phone: "Tel.",
  leaderName: "Leader",
  leaderPosition: "Leader Position",
  taxcode: "Tax code",
  category: "Status",
};

export const accountInformationMemberTable = {
  memberName: "Member name",
  phoneNumber: "Phone number",
  memberId: "Member ID",
  numberOfOrder: "Number of order(s)",
  created: "Created",
};
export const accountInformationMemberTableWidth =
  "18.0412% 21.3917% 25.7731% 20.6185% 14.1752%";

export const transitListTable = {
  orderNumber: "Order number",
  carrierName: "Carrier",
  shipperName: "Shipper",
  puName: "Pick-up",
  doName: "Drop-off",
  poDatetime: "Pick-up date",
  duDatetime: "Drop-off date",
  orderMethod: "Order method",
};

export const truckTransitListTable = {
  orderNumber: "Order number",
  shipperName: "Shipper",
  pu: "Pick-up",
  do: "Drop-off",
  puDatetime: "Pick-up date",
  doDatetime: "Drop-off date",
  drivingDriver: "Driving driver",
  orderMethod: "Order method",
};

export const driverTransitListTable = {
  orderNumber: "Order number",
  shipperName: "Shipper",
  pu: "Pick-up",
  do: "Drop-off",
  puDatetime: "Pick-up date",
  doDatetime: "Drop-off date",
  drivingTruck: "Driving driver",
  orderMethod: "Order method",
};

export const quotationTable = {
  carrier: "Carrier",
  freight: "Freight",
  quoted: "Quoted",
  status: "Status",
};

export const quotationTableWidth = "26.4150% 26.4150% 30.9433% 16.2264%";

export const supportCarrierListTable = {
  category: "Category",
  carrierName: "Carrier",
  name: "Inquirer name",
  title: "Title",
  created: "Created on",
  status: "Status",
};

export const supportShipperListTable = {
  category: "Category",
  shipperName: "Shipper",
  name: "Inquirer name",
  title: "Title",
  created: "Created on",
  status: "Status",
};

export const supportListNoticeTable = {
  checkTable: "",
  detailId: "No.",
  service: "Service",
  category: "Category",
  title: "Title",
  created: "Created on",
};

export const moderatorListTable = {
  id: "Login ID",
  name: "Name",
  recentAccess: "Recent access",
  created: "Created on",
};

export const moderatorListTableWidth = "30.5835% 27.1629% 22.1327% 20.1207%";

export const moderatorDetailTable = {
  moderatorName: "Moderator name",
  phoneNumber: "Phone number",
  loginId: "Login ID",
  completedOrders: "Completed orders",
  grade: "Grade",
  createdOn: "Created on",
};

export const moderatorTableWidth =
  "18.0412% 16.2371% 23.1958% 18.0412% 11.5979% 12.8865%";

export const transitHistoryTable = {
  orderNumber: "Order number",
  shipper: "Shipper",
  pickUp: "Pick-up",
  dropOff: "Drop-off",
  pickUpDate: "Pick-up date",
  dropOffDate: "Drop-off date",
};

export const transitHistoryWidth =
  "15.4639% 15.4639% 19.0721% 19.0721% 15.4639% 15.4639%";

export const carrierTruckDetail = [
  {
    title: "Default information",
    keyList: {
      plateNumber: "Plate number",
      parkedGarage: "Parked garage",
      linked: "Linked",
      attached: "Attached",
    },
  },
  {
    title: "Specification",
    keyList: {
      brand: "Brand",
      model: "Model",
      year: "Year",
      vin: "VIN",
      truckType: "Truck type",
      truckOption: "Truck option",
      containerSize: "Container size",
    },
  },
  { title: "Memo" },
];

export const carrierDriverDetail = [
  {
    title: "Default information",
    keyList: {
      name: "Driver name",
      phone: "Phone number",
      linked: "Linked",
      id: "Login ID",
      lastShippingOn: "Last shipping on",
      created: "Created on",
      attached: "Attached",
    },
  },
  { title: "Memo" },
];

export const STATICS_TABLE_HEADER = {
  name: "Company Name",
  Pending: "Pending",
  Shipping: "Shipping",
  Completed: "Completed",
  Cancelled: "Cancelled",
  Total: "Total",
};

export const MEMBER_TABLE_HEADER: TableHeaderKeysWithServerModel<
  Omit<GetMembersServerModel["members"][number], "companyId">
> = {
  status: "Status",
  name: "Company name",
  registered: "Registered",
  addr: "Address",
  phone: "Tel.",
  email: "E-mail",
  created: "Created date",
} as const;

export const MEMBERSHIP_PLAN_TABLE_HEADER = {
  companyName: "Company name",
  planName: "Plan",
  startDate: "Start date",
  endDate: "End date",
  total: "Total",
  pay: "Payment method",
  status: "Status",
  "": "",
} as const;

export const LINKING_TABLE_HEADER = {
  type: "Type",
  name: "Company name",
  taxcode: "Tax code",
  linked: "Linking",
} as const;

export const ORDER_MANAGEMENT_TABLE_HEADER = {
  status: "Status",
  orderNumber: "Order number",
  client: "Company name",
  trucks: "Trucks",
  puAddress: "Pick-up address",
  puEta: "ETA",
  doAddress: "Drop-off address",
  doEta: "ETA",
} as const;

export const QUOTATION_TABLE_HEADER = {
  orderNumber: "Order number",
  status: "Status",
  sender: "Sender",
  shipper: "Shipper",
  created: "Created date",
} as const;

export const COMPANY_COLUMNS = {
  //TODO: 확인 필요
  name: "Company",
  type: "Type",
  taxcode: "Tax code",
} as const;

export const MEMBER_REGISTERED_TRUCK_TABLE_HEADER = {
  status: "Status",
  plateNumber: "Plate number",
  gpsAvailable: "GPS",
  truckType: "Truck type",
  truckOption: "Truck option",
  year: "Year",
  progressed: "Completed orders",
  parkedGarage: "Parked garage",
  linked: "Linking",
  created: "Created date",
};

export const MEMBER_REGISTERED_GARAGE_TABLE_HEADER = {
  name: "Garage name",
  address: "Garage address",
  usage: "Garage usage",
  capacity: "Capacity",
  "": "",
};

export const MEMBER_REGISTERED_DRIVER_TABLE_HEADER = {
  status: "Status",
  name: "Driver name",
  phone: "Phone number",
  linkedRegistNumber: "Linking",
  lastShippingOn: "Last shipping on",
  progressed: "Completed orders",
  created: "Created date",
};

export const MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER = {
  registNumber: "Plate number",
  truckTypeId: "Truck type",
  truckOptionId: "Truck option",
  drivers: "Linking",
  "": "",
};

export const MEMBER_REGISTERED_MEMBER_TABLE_HEADER = {
  grade: "Grade",
  name: "Member name",
  email: "Login ID",
  lastAccess: "Last access",
  created: "Created date",
  "": "",
};

export const SETTLEMNET_TABLE_HEADER = {
  status: "Status",
  orderNumber: "Order number",
  sender: "Sender",
  recipient: "Recipient",
  price: "Total",
  completed: "Completed date",
} as const;

export const SUPPORT_INQUIRY_TABLE_HEADER = {
  status: "Status",
  category: "Category",
  companyType: "Company type",
  subject: "Subject",
  name: "Name",
  orderNumber: "Order number",
  created: "Created date",
  answered: "Answered date",
};

export const SUPPORT_NOTICE_TABLE_HEADER = {
  subject: "Subject",
  companyType: "Company type",
  created: "Created date",
};

export const QUOTATION_DETAIL_TABLE_HEADER = {
  status: "Status",
  truckingCompany: "Company",
  price: "Price",
  "": "",
};

export const TRUCK_LIST_TABLE_HEADER = {
  truckType: "Truck type",
  truckOption: "Truck option",
  numberOfTrucks: "Number of trucks",
};

export const SETTING_REGISTERED_MEMBER_TABLE_HEADER = {
  grade: "Grade",
  name: "Member name",
  email: "Login ID",
  lastAccess: "Last access",
  created: "Created date",
};

export const MONITORING_COMPANYS_TABLE_HEADER = {
  companyType: "Type",
  name: "Company name",
  addr: "Address",
};

export const MEMBER_DETAIL_DRIVER_ALLOCATION_TABLE_HEADER = {
  status: "Status",
  orderNumber: "Order number",
  client: "Company",
  puAddr: "Pick-up",
  doAddr: "Drop-off",
  puEta: "Pick-up ETA",
  doEta: "Drop-off ETA",
  registNumber: "Driving truck",
};

export const MEMBER_DETAIL_TRUCK_ALLOCATION_TABLE_HEADER = {
  status: "Status",
  orderNumber: "Order number",
  client: "Company",
  puAddr: "Pick-up",
  doAddr: "Drop-off",
  puEta: "Pick-up ETA",
  doEta: "Drop-off ETA",
  driverName: "Driving driver",
};
