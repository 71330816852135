import React, { useRef } from "react";
import { useRecoilValue } from "recoil";
import { useSearchParams } from "react-router-dom";

import { NoResult, QueryPagination } from "components";
import { useAlignCenter, useMonitoringTruckMarker } from "hooks";
import { clientLanguage } from "stores";
import { foldCardAnimate } from "utils";
import type { GetMonitoringTrucksClientModel } from "types";
import { ButtonFoldTruck, TruckStatusInfo } from "../card";
import { useTruckMarkerEventListener, useTruckSelect } from "../hooks";
import * as S from "./Monitorings.styled";

interface WaitingMonitoringsProps {
  data: GetMonitoringTrucksClientModel;
}

const WaitingMonitorings = ({ data }: WaitingMonitoringsProps) => {
  const [searchParams] = useSearchParams();

  const languages = useRecoilValue(clientLanguage);
  const truckRef = useRef<{ [key: string]: HTMLLIElement | null }>({});

  const truckId = searchParams.get("truckId");

  const { truckMarkers } = useMonitoringTruckMarker(data.trucks);

  const monitoringTrucks = data.trucks.map((truck, i) => ({
    ...truck,
    ...truckMarkers[i],
  }));

  const { handleTruckCardClick, handleTruckMarkerClick } = useTruckSelect(
    monitoringTrucks,
    truckRef,
  );

  useTruckMarkerEventListener(handleTruckMarkerClick, monitoringTrucks);
  useAlignCenter(data.coords);

  return (
    <>
      <S.MonitoringListWrapper>
        {data.trucks.length ? (
          data.trucks.map((truck) => {
            const isFold = truck.truckId !== truckId;

            return (
              <li
                key={truck.truckId}
                ref={(el) => (truckRef.current[truck.truckId] = el)}
              >
                <ButtonFoldTruck
                  isFold={isFold}
                  status="WAITING"
                  truck={{
                    registNumber: truck.registNumber,
                    truckType:
                      languages === "en"
                        ? truck.truckTypeEn
                        : truck.truckTypeVi,
                  }}
                  handleFoldBtnClick={handleTruckCardClick(truck.truckId)}
                />
                {!isFold && (
                  <S.TruckDetailInfoWrapper
                    layout
                    initial="closed"
                    animate={isFold ? "closed" : "open"}
                    exit="closed"
                    variants={foldCardAnimate(
                      searchParams.get("type") === "card",
                    )}
                    transition={{ duration: 0.3 }}
                  >
                    <TruckStatusInfo
                      truckStatus="WAITING"
                      truckInfo={{
                        lat: truck.lat,
                        lng: truck.lng,
                        addr: truck.addr,
                        speed: truck.speed,
                        temperature: truck.temperature,
                      }}
                    />
                  </S.TruckDetailInfoWrapper>
                )}
              </li>
            );
          })
        ) : (
          <NoResult />
        )}
      </S.MonitoringListWrapper>
      <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
    </>
  );
};

export default WaitingMonitorings;
