import { css, Theme } from "@emotion/react";

import { button } from "style";

export const internalLink = (theme: Theme, variant: keyof typeof button) => css`
  ${variant === "primarySmall" && theme.button.primarySmall};
  ${variant === "primaryMedium" && theme.button.primaryMedium};
  ${variant === "primaryLarge" && theme.button.primaryLarge};
  ${variant === "outlineXSmall" && theme.button.outlineXSmall};
  ${variant === "outlineMedium" && theme.button.outlineMedium};
  ${variant === "greySmall" && theme.button.greySmall};
  ${variant === "errorMedium" && theme.button.errorMedium};
  ${variant === "ghostPrimary" && theme.button.ghostPrimary};
  ${variant === "ghostGray" && theme.button.ghostGray};
  ${variant === "ghostRed" && theme.button.ghostRed};
  ${variant === "ghostNoUnderlineGray" && theme.button.ghostNoUnderlineGray};
  ${variant === "ghostNoUnderlinePrimary" &&
  theme.button.ghostNoUnderlinePrimary};
  ${variant === "icon" && theme.button.icon};
`;
