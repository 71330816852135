import { PATH } from "constants/index";
import type { Languages } from "types";

export const CARRIER_MANAGEMENT_CREATE_PAGE_LAYOUT = {
  heading: "Add Carrier",
} as const;

export const CARRIER_MANAGEMENT_DETAIL_PAGE_LAYOUT = {
  heading: "Carrier Details",
} as const;

export const CARRIER_MANAGEMENT_EDIT_PAGE_LAYOUT = {
  heading: "Edit Details",
} as const;

export const CARRIER_SUPPORT_DETAILPAGE_LAYOUT = {
  heading: "Inquiry Details",
} as const;

export const MEMBER_DETAIL_LAYOUT = {
  heading: "Member details",
} as const;

export const CREATE_MEMBER_LAYOUT = {
  heading: "Add member",
} as const;

export const EDIT_MEMBER_LAYOUT = {
  heading: "Edit member",
} as const;

export const OREDER_MANAGEMENT_DETAIL_LAYOUT = {
  heading: "Order management",
  histories: [
    { content: "Order management", path: PATH.ORDER_MANAGEMENT },
    { content: "Order management details" },
  ],
} as const;

export const INQUIRY_DETAIL_LAYOUT = { heading: "Inquiry details" } as const;

export const NOTICE_DETAIL_LAYOUT = { heading: "Notice details" } as const;

export const SETTLENEMT_DETAIL_LAYOUT = {
  heading: "Settlement details",
} as const;

export const SUPPORT_NOTICE_CREAET_LAYOUT = { heading: "Add notice" } as const;

export const SUPPORT_NOTICE_EDIT_LAYOUT = { heading: "Edit notice" } as const;

export const QUOTATION_DETAIL_LAYOUT = {
  heading: "Quotation management details",
  histories: [
    { content: "Quotation management", path: PATH.QUOTATION },
    { content: "Quotation management details" },
  ],
} as const;

export const SETTING_REGISTERED_MEMBER_DETAIL_LAYOUT = {
  heading: "Registered member details",
} as const;

export const SETTING_REGISTERED_MEMBER_CREATE_LAYOUT = {
  heading: "Add registered member",
} as const;

export const SETTING_REGISTERED_MEMBER_EDIT_LAYOUT = {
  heading: "Edit registered member",
} as const;
