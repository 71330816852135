import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const TabList = styled.ul`
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
`;

export const Tab = styled.li<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    display: flex;
    align-items: center;

    & > a {
      span {
        ${theme.font.bold_18};
        color: ${isSelected ? theme.color.black : theme.color.gray_40};
      }

      span:nth-of-type(2) {
        ${theme.font.bold_16};
        margin-left: 4px;
      }

      &:hover span {
        color: ${theme.color.black};
      }
    }
  `}
`;
