import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { upperCase } from "lodash-es";
import { useParams } from "react-router-dom";

import {
  AddrAutoComplete,
  FormRadioBtn,
  FormInput,
  LabelContentTable,
} from "components";
import { useCurrentQueryParams } from "hooks";
import { useGetMemberDetail } from "services";
import { checkEmailValidation, onlyNumber } from "utils";
import {
  HOOKFORM_ERR,
  MEMBER_COMPANY_TYPE_RADIOS,
  MEMBER_MEMBERSHIP_PLANS_RADIOS,
  MEMBER_REGISTERED_COMPANY_RADIOS,
} from "assets";
import type { MemberForm } from "types";
import * as S from "./MemberBusinessInfoForm.styled";

const MemberBusinessInfoForm = () => {
  const { memberId } = useParams();
  const {
    watch,
    control,
    formState: { errors },
    clearErrors,
    register,
  } = useFormContext<MemberForm>();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];
  const isRegisteredCompany = watch("registered") === "YES";

  const { data: memberDetail } = useGetMemberDetail(
    {
      companyId: memberId!,
      query: {
        companyType: upperCase(
          memberType!,
        ) as (typeof MEMBER_COMPANY_TYPE_RADIOS)[number]["key"],
      },
    },
    !!memberId,
  );

  const handleCheckFormRadioDisabled = (key: string): boolean =>
    !!memberDetail?.type
      ? true
      : memberType !== "forwarder" ||
        (key !== "FORWARDER" && key !== "FORWARDERCARRIER");

  return (
    <LabelContentTable
      css={S.labelContentTable}
      variant="empty"
      subject="Business information"
    >
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Company type" isRequired>
          <FormRadioBtn
            css={S.radioBtn}
            radioList={MEMBER_COMPANY_TYPE_RADIOS}
            currentValue={watch("companyType")}
            register={register("companyType")}
            handleDisabled={handleCheckFormRadioDisabled}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Registered company" isRequired>
          <FormRadioBtn
            css={S.radioBtn}
            radioList={MEMBER_REGISTERED_COMPANY_RADIOS}
            currentValue={watch("registered")}
            register={register("registered", {
              required: true,
              onChange: () => clearErrors(),
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      {memberType !== "shipper" && (
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Membership plans" isRequired>
            <FormRadioBtn
              css={S.radioBtn}
              isDisabled
              radioList={MEMBER_MEMBERSHIP_PLANS_RADIOS}
              currentValue={watch("plan")}
              register={register("plan")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      )}
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Company name" isRequired>
          <FormInput
            css={S.input}
            maxLength={100}
            placeholder={"Enter company name"}
            hasError={!!errors.companyName?.message}
            errorMsg={errors.companyName?.message}
            register={register("companyName", {
              required: HOOKFORM_ERR.REQUIRE,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Address"
          isRequired={isRegisteredCompany}
        >
          <S.AddressWrapper>
            <Controller
              name="addr"
              control={control}
              rules={{
                validate: (addr) =>
                  !isRegisteredCompany
                    ? true
                    : addr.address.length > 0 || HOOKFORM_ERR.REQUIRE,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <AddrAutoComplete
                  placeholder="Please enter your address or choose from map"
                  value={value}
                  err={error}
                  handleSelect={(addr) => onChange(addr)}
                />
              )}
            />
            <FormInput
              maxLength={100}
              placeholder="Detail address (Optional)"
              value={watch("addr.addressDetail")}
              register={register("addr.addressDetail")}
            />
          </S.AddressWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Tax code"
          isRequired={isRegisteredCompany}
        >
          <FormInput
            css={S.input}
            maxLength={10}
            placeholder="10 letters, only numbers"
            hasError={!!errors.taxcode?.message}
            errorMsg={errors.taxcode?.message}
            value={watch("taxcode")}
            register={register("taxcode", {
              required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
              setValueAs: onlyNumber,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Business email"
          isRequired={isRegisteredCompany}
        >
          <FormInput
            css={S.input}
            maxLength={200}
            placeholder="Enter business email address"
            hasError={!!errors.businessEmail?.message}
            errorMsg={errors.businessEmail?.message}
            register={register("businessEmail", {
              required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
              validate: (value) =>
                !isRegisteredCompany && !value
                  ? true
                  : checkEmailValidation(value) || HOOKFORM_ERR.VALID_EMAIL,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Business tel."
          isRequired={isRegisteredCompany}
        >
          <FormInput
            css={S.input}
            maxLength={15}
            placeholder="Enter business telephone number"
            value={watch("businessTel")}
            hasError={!!errors.businessTel?.message}
            errorMsg={errors.businessTel?.message}
            register={register("businessTel", {
              required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
              setValueAs: onlyNumber,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          label="Representative"
          isRequired={isRegisteredCompany}
        >
          <FormInput
            css={S.input}
            maxLength={150}
            placeholder="Enter name of company representative"
            hasError={!!errors.representative?.message}
            errorMsg={errors.representative?.message}
            register={register("representative", {
              required: isRegisteredCompany ? HOOKFORM_ERR.REQUIRE : false,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default MemberBusinessInfoForm;
