import type { Languages } from "types";

export const COCOTRUCK_LANGUAGE = {
  en: "English",
  vi: "Tiếng Việt",
} as const;

export const LANG_DROPDOWN_OPTIONS = [
  { key: "en", label: "English" as Languages }, //NOTE: dropdown languages 타입으로 인해 부득이한 타입단언
  { key: "vi", label: "Tiếng Việt" as Languages }, //NOTE: dropdown languages 타입으로 인해 부득이한 타입단언
] as const;
