import React, { useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { AlertModal } from "components";
import { useModal, useToast } from "hooks";
import { usePostMemberDetailConnectLink } from "services";
import { TOAST_MSG } from "constants/index";
import type { TruckingCompanyType } from "types";

const useLinkDriver = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const { isLoading, mutate: connectLinkMutate } =
    usePostMemberDetailConnectLink();

  const { addToast } = useToast();
  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const memberType = searchParams.get("memberType");
  const companyType = (
    memberType === "forwarder" ? "forwardercarrier" : memberType
  ) as TruckingCompanyType;

  const handleConnectLinkDriver = useCallback(
    (truckId: string, driverIds: string[]) => () => {
      const req = {
        truckId,
        query: { companyId: memberId!, companyType },
        body: { driverIds },
      };

      connectLinkMutate(req, {
        onSuccess: () => addToast(TOAST_MSG.SUCCESS.LINK_CONNECT_DONE),
      });
    },
    [],
  );

  const handleDisconnectLinkDriver = useCallback(
    (truckId: string) => (): void => {
      const handleDisconnect = (): void => {
        const req = {
          truckId,
          query: { companyId: memberId!, companyType },
          body: { driverIds: [] },
        };

        connectLinkMutate(req, {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.LINK_DISCONNECT_DONE);
            handleModalClose();
          },
        });
      };

      handleModalOpen(
        <AlertModal
          ref={modalRef}
          isLoading={isLoading}
          title="Unlink all drivers"
          desc="Are you sure you want to unlink all drivers?"
          negBtnLabel="Confirm"
          closeBtnLabel="Cancel"
          callbackFn={handleDisconnect}
        />,
      )();
    },
    [],
  );

  return { handleConnectLinkDriver, handleDisconnectLinkDriver };
};

export default useLinkDriver;
