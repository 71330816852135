import { useEffect } from "react";
import { isEmpty } from "lodash-es";

import { useGoogleMap } from "contexts";
import { convertToNumberTypeLatLng } from "utils";
import { theme } from "style";
import type { LatLng } from "types";

const useAlignCenter = (originLatLngs: LatLng[] | undefined) => {
  const { googleMap } = useGoogleMap();
  const alignMapCenter = (latLngs: LatLng[]) => {
    if (!latLngs.length) return;

    const latlngbounds = new window.google.maps.LatLngBounds();

    const convertedLatLngs = latLngs.map(convertToNumberTypeLatLng);

    convertedLatLngs.forEach((latLng) => latlngbounds.extend(latLng));
    googleMap?.fitBounds(latlngbounds, {
      left: theme.size.GOOGLE_MAP_LEFT_PADDING,
      top: 50,
      bottom: 50,
      right: 50,
    });
  };

  useEffect(() => {
    if (!googleMap || !originLatLngs || isEmpty(originLatLngs)) return;

    alignMapCenter(originLatLngs);
  }, [originLatLngs, googleMap]);

  return { alignMapCenter };
};

export default useAlignCenter;
