import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AddrAutoComplete,
  Button,
  Card,
  ErrorMsg,
  FormInput,
  InternalLink,
  LabelContent,
} from "components";
import { useCurrentQueryParams } from "hooks";
import { numericOnly } from "utils";
import { ArrowBackIcon } from "assets";
import { PATH, TAB } from "constants/index";
import type {
  AddressSearchType,
  MemberDetailGarageForm,
  TruckingCompanyType,
} from "types";
import * as S from "./MemberDetailRegisteredGarageForm.styled";

interface Create {
  formType: "create";
  handleSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
}

interface Edit {
  formType: "edit";
  handleSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
}

type MemberDetailRegisteredGarageFormProps = Create | Edit;

const MemberDetailRegisteredGarageForm = ({
  formType,
  handleSubmit,
}: MemberDetailRegisteredGarageFormProps) => {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const { pathname } = useLocation();

  const {
    watch,
    register,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<MemberDetailGarageForm>();

  const memberType = useCurrentQueryParams("memberType")
    ?.memberType[0] as TruckingCompanyType;
  const beforeUrl = `${PATH.MEMBER}/${memberId}?memberType=${memberType}&tab=${TAB.MEMBER.REGISTERED_GARAGE}`;

  const handleAddClick = (): void => {
    handleSubmit();
  };

  return (
    <>
      <S.Header>
        {/* TODO:   PageBackHeader로 변경  */}
        <InternalLink variant="ghostNoUnderlineGray" to={beforeUrl}>
          <ArrowBackIcon />
          <S.Title>
            {t(`${formType === "create" ? "Add" : "Edit"} the garage`)}
          </S.Title>
        </InternalLink>
      </S.Header>
      <Card css={S.card}>
        <Card.Content css={S.cardContent} heading="Default information">
          <LabelContent
            css={S.labelContent}
            label="Garage name"
            direction="vertical"
            isRequired
          >
            <FormInput
              placeholder="Garage name"
              maxLength={50}
              hasError={!!errors.name}
              errorMsg={errors.name?.message}
              register={register("name")}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Garage address"
            direction="vertical"
            isRequired
          >
            <Controller
              control={control}
              name="garageAddress"
              render={({ field: { value } }) => {
                const handleSelect = (address: AddressSearchType): void => {
                  setValue("garageAddress", {
                    ...watch("garageAddress"),
                    ...address,
                  });
                  clearErrors("garageAddress");
                };

                return (
                  <AddrAutoComplete
                    placeholder="Please enter your address or choose from map"
                    value={{
                      ...value,
                      city: value.city!,
                      buildingName: value.buildingName!,
                    }}
                    err={errors.garageAddress?.address?.message}
                    handleSelect={handleSelect}
                  />
                );
              }}
            />
            <FormInput
              placeholder="Detail address (Optional)"
              maxLength={100}
              register={register("garageAddress.addressDetail")}
            />
            {errors.garageAddress?.address && (
              <ErrorMsg errorMsg={errors.garageAddress.address.message!} />
            )}
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Capacity"
            direction="vertical"
            isRequired
          >
            <FormInput
              placeholder="Only numbers"
              maxLength={3}
              hasError={!!errors.capacity}
              errorMsg={errors.capacity?.message}
              value={numericOnly(watch("capacity"))}
              register={register("capacity")}
            />
          </LabelContent>
        </Card.Content>
      </Card>
      <S.ButtonWrapper>
        <InternalLink variant="outlineMedium" to={beforeUrl}>
          {t("Cancel")}
        </InternalLink>
        <Button
          variant="primaryMedium"
          label={pathname.includes("create") ? "Add" : "Update"}
          isDisabled={Object.keys(errors).length > 0}
          handleClickBtn={handleAddClick}
        />
      </S.ButtonWrapper>
    </>
  );
};

export default MemberDetailRegisteredGarageForm;
