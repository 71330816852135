import React from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import * as S from "./MyAccountButtons.styled";

interface MyAccountButtonsProps {
  isEdit: boolean;
  isLoadingChangeMyAccount: boolean;
  handleChangeEdit: () => void;
  handleSubmit: () => void;
}

const MyAccountButtons = ({
  isEdit,
  isLoadingChangeMyAccount,
  handleChangeEdit,
  handleSubmit,
}: MyAccountButtonsProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <S.ButtonWrapper>
      {isEdit ? (
        <S.UpdateButtonsWrapper>
          <Button
            variant="primaryMedium"
            isLoading={isLoadingChangeMyAccount}
            isDisabled={Object.keys(errors).length > 0}
            label="Update"
            handleClickBtn={handleSubmit}
          />
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleChangeEdit}
          />
        </S.UpdateButtonsWrapper>
      ) : (
        <Button
          css={S.button}
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleChangeEdit}
        />
      )}
    </S.ButtonWrapper>
  );
};

export default MyAccountButtons;
