import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { GoogleMapProvider } from "contexts";
import { FoldBtn, GoogleMap } from "components";
import * as S from "./GoogleMapLayout.styled";

const GoogleMapLayout = () => {
  const location = useLocation();

  const [isFold, setIsFold] = useState(false);

  const handleClickFold = (): void => {
    setIsFold(!isFold);
  };

  useEffect(() => {
    setIsFold(false);
  }, [location.pathname]);

  return (
    <GoogleMapProvider>
      <S.Root>
        <S.FoldableLayout isFold={isFold}>
          <Outlet />
          <FoldBtn handleClickFold={handleClickFold} isFold={isFold} />
        </S.FoldableLayout>
        <GoogleMap />
      </S.Root>
    </GoogleMapProvider>
  );
};

export default GoogleMapLayout;
