import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { QUOTATION_DETAIL_TABLE_HEADER_COLUMNS } from "assets";
import type { QuotationDetailHistoryStatus } from "types";

export const table = (theme: Theme) => css`
  height: 330px;
  margin: 16px 0 0;
  border-bottom: 1px solid ${theme.color.gray_30};

  thead {
    position: relative;
    top: 0;
  }

  tbody {
    display: block;
    height: 300px;
    overflow-y: scroll;
  }

  tr {
    grid-template-columns: ${QUOTATION_DETAIL_TABLE_HEADER_COLUMNS};
    padding: 0;

    & > td,
    th {
      border-right: 1px solid ${theme.color.gray_30};
    }
  }
`;

export const row = css`
  position: relative;
`;

export const TruckingCompanyWrapper = styled.div`
  display: flex;
  column-gap: 10px;

  & > span {
    width: 206px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const PriceWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 10px;

    & > button {
      ${theme.font.medium_13};
    }
  `}
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    height: 100%;

    & > button {
      ${theme.font.medium_13};
      height: 28px;
      padding: 5px 32px;
    }
  `}
`;

export const tooltipBtn = css`
  position: unset;

  & > dl {
    top: 32px;
    left: initial;
  }
`;

export const status =
  (status: QuotationDetailHistoryStatus) => (theme: Theme) => {
    if (
      status === "CANCELED" ||
      status === "REJECTED_BY_RECEIVER" ||
      status === "REJECTED_BY_SENDER"
    )
      return css`
        color: ${theme.color.gray_50};

        span {
          color: ${theme.color.gray_50};
        }
      `;
  };

export const detailsBtn = (theme: Theme) => css`
  color: ${theme.color.gray_70};
`;
