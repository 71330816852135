import React from "react";
import { isEmpty, isEqual, keys, upperFirst } from "lodash-es";
import { useTranslation } from "react-i18next";

import { SearchInput, Table, DetailModal } from "components";
import { useModal, useSearchInputWithoutQuery } from "hooks";
import type {
  ColumnTable,
  CompanyInfo,
  GetCommonPlayerServerModel,
  Languages,
} from "types";
import { useSelectCompany } from "./hooks";
import * as S from "./CompanySearchModal.styled";

interface CompanySearchModalProps {
  className?: string;
  columns: ColumnTable;
  selectedCompany: CompanyInfo[];
  tableDatas: GetCommonPlayerServerModel[];
  handleCompanySelect: (companyInfo: CompanyInfo[]) => void;
}

const CompanySearchModal = React.forwardRef(
  (
    {
      className,
      columns,
      selectedCompany,
      tableDatas,
      handleCompanySelect,
    }: CompanySearchModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    const { handleModalClose } = useModal();
    const {
      searchValue,
      filteredDatas,
      handleSearch,
      handleChangeInput,
      handleResetInput,
    } = useSearchInputWithoutQuery<GetCommonPlayerServerModel>(
      tableDatas,
      "name",
    );
    const { companyInfo, clickTableRow } = useSelectCompany(selectedCompany);

    const formatTableData = (
      data: GetCommonPlayerServerModel,
      key: keyof GetCommonPlayerServerModel,
    ): string => {
      switch (key) {
        case "type":
          return data[key] ? t(upperFirst(data.type) as Languages) : "-"; //NOTE: lodash 사용으로 인해 단언 처리(반환값 string)
        default:
          return data[key] ?? "-";
      }
    };

    const handleCloseSelectCompany = (): void => {
      handleModalClose();
      handleCompanySelect(companyInfo);
    };

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        className={className}
        type="submit"
        isDisabled={
          isEmpty(companyInfo) || isEqual(companyInfo, selectedCompany)
        }
        title="Select a company"
        posBtnLabel="Select"
        closeBtnLabel="Close"
        callbackFn={handleCloseSelectCompany}
      >
        <SearchInput
          css={S.searchInput}
          placeholder="Search by company name"
          value={searchValue}
          handleChange={handleChangeInput}
          handleSearch={handleSearch}
          handleReset={handleResetInput}
        />
        <Table
          css={S.table(tableDatas.length === filteredDatas.length)}
          title="Company"
          columnTable={columns}
        >
          {filteredDatas?.map((tableData, i) => (
            <Table.SelectRow
              key={i}
              isSelected={companyInfo[0]?.id === tableData.id}
              handleRowClick={clickTableRow(tableData)}
            >
              {keys(columns).map((key) => (
                <Table.Td key={key}>
                  {formatTableData(tableData, key as keyof typeof tableData)}
                </Table.Td>
              ))}
            </Table.SelectRow>
          ))}
        </Table>
      </DetailModal>
    );
  },
);

CompanySearchModal.displayName = "CompanySearchModal";
export default CompanySearchModal;
