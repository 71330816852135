import { useSearchParams } from "react-router-dom";

import type { TabType } from "types";

type Tabs<T> = T extends readonly TabType[]
  ? { key: T[number]["key"]; label: T[number]["label"] }
  : never;

const useTab = <T extends readonly Tabs<T>[]>(
  tabs: T,
  keepQueries?: string[],
) => {
  const [searchParams] = useSearchParams();

  const defaultTab = tabs[0].key;
  const selectedTab =
    tabs.find((tab) => tab.key === searchParams.get("tab"))?.key || defaultTab;

  const queryParams = (key: string): string => {
    if (keepQueries?.length) {
      const queries = keepQueries.map((query) => {
        const value = searchParams.get(query);

        return `&${query}=${value}`;
      });

      return new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(queries.join(""))),
        tab: key,
      }).toString();
    }

    return new URLSearchParams({ tab: key }).toString();
  };

  return { queryParams, selectedTab };
};

export default useTab;
