import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const labelContentTable = css`
  margin-bottom: 32px;
`;

export const contentWrapper = css`
  grid-template-columns: 210px 1fr;
`;

export const radioBtn = css`
  column-gap: 40px;
`;

export const radioPlanBtn = css`
  column-gap: 80px;
`;

export const MembershipPlans = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DownloadBtnWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
