import { css, Theme } from "@emotion/react";

export const simpleStatistics = css`
  margin-bottom: 40px;
`;

export const layout = (theme: Theme) => css`
  & > h2 {
    ${theme.font.bold_18};
    margin-bottom: 20px;
  }
`;
