import { lowerCase, upperFirst } from "lodash-es";

export const makeNameString = (...name: any) => {
  return name.filter(Boolean).join(" ");
};

export const upperCaseToUpperFirst = (word: string): string =>
  upperFirst(lowerCase(word));

export const addrFormatter = (
  addr: string,
  addrDetail?: string | null,
): string =>
  addrDetail ? `${addrDetail} ${addr ? "," : ""} ${addr ?? ""}` : addr;
