import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { mixin_hr } from "style";
import { QUOTATION_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS } from "assets";

export const detailModal = css`
  width: 700px;

  & > div:last-of-type > button:last-of-type {
    display: none;
  }
`;

export const labelContent = (theme: Theme) => css`
  grid-template-columns: 200px 1fr;
  margin-bottom: 12px;
  padding: 12px 0;

  & > div > label {
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  }
`;

export const truckListLabelContent = (theme: Theme) => css`
  margin-bottom: 12px;

  & > div :first-of-type {
    padding: 12px 0;
  }

  & > div > label {
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  }
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px 0;
`;

export const TotalPriceText = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 0;
  `}
`;

export const PriceText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const table = (hasTruck: boolean) => (theme: Theme) =>
  css`
    height: fit-content;
    border-bottom: ${!hasTruck && `1px solid ${theme.color.gray_20}`};

    & > thead {
      position: relative;
      top: 0;

      & > tr {
        & > th:not(:last-of-type) {
          border-right: 1px solid ${theme.color.gray_20};
        }
      }
    }

    tr {
      grid-template-columns: ${QUOTATION_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS};
      padding: 0;

      & > td:not(:last-of-type) {
        border-right: 1px solid ${theme.color.gray_20};
      }
    }

    tbody {
      display: ${!hasTruck && "block"};
      padding: ${!hasTruck && "50px"};
    }
  `;

export const multiLabelContent = (theme: Theme) => css`
  ${labelContent(theme)};

  padding: 0;
`;

export const subtotalLabelContent = (theme: Theme) => css`
  grid-template-columns: 128px 1fr;
  margin-bottom: 8px;

  & > div > label {
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  }
`;

export const Hr = styled.hr`
  ${mixin_hr};
  height: 1px;
  margin: 12px 0;
`;
