import React from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import type { Languages } from "types";
import * as S from "./LoginInput.styled";

export interface AccountInputProps {
  className?: string;
  label: Languages;
  register: UseFormRegisterReturn;
  id: string;
  value: string;
  disabled?: boolean;
}

const AccountInput = ({
  className,
  label,
  register,
  id,
  value,
  disabled,
}: AccountInputProps) => {
  const { t } = useTranslation();

  return (
    <S.AccountInput className={className} hasValue={!!value}>
      <S.Label htmlFor={id}>{t(label)}</S.Label>
      <S.Input id={id} disabled={disabled} {...register} />
    </S.AccountInput>
  );
};

export default AccountInput;
