import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import type { SettlementDetailStatus } from "types";

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const Total = styled.div<{ status: SettlementDetailStatus }>`
  ${({ theme, status }) => css`
    ${theme.font.medium_14};
    padding-bottom: 12px;
    color: ${status === "ONGOING" ? theme.color.gray_70 : theme.color.black};
    text-decoration: ${status === "ONGOING" && "line-through"};
  `}
`;

export const SubtotalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;

export const SubtotalItem = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
`;

export const SubtotalLabel = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    color: ${theme.color.gray_70};
  `}
`;

export const SubtotalContent = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;

export const memo = css`
  & > span {
    align-items: flex-start;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 24px 0 0 auto;
`;

export const FileWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const previousPayment = (isPrevious: boolean) => (theme: Theme) =>
  css`
    & > div:last-of-type {
      color: ${isPrevious && theme.color.gray_70};
    }
  `;
