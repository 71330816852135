import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const OrderNumber = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: absolute;
    top: 32px;
    right: 32px;
    color: ${theme.color.gray_70};
  `}
`;
