import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable, StatusLabel } from "components";
import { useDownloadFile } from "services";
import { findLookupTableLabel, formatICTDateTime } from "utils";
import {
  SUPPORT_COMPANY_TYPE_LOOKUP_TABLE,
  SUPPORT_INQUIRY_CATEGORY_LOOKUP_TABLE,
} from "assets";
import type { GetInquiryDetailServerModel } from "types";
import * as S from "./InquiryInfo.styled";

interface InquiryInfoProps {
  data?: GetInquiryDetailServerModel;
}

const InquiryInfo = ({ data }: InquiryInfoProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate } = useDownloadFile();

  const categoryLabel =
    data &&
    findLookupTableLabel(SUPPORT_INQUIRY_CATEGORY_LOOKUP_TABLE, data.category);
  const companyTypeLabel =
    data &&
    findLookupTableLabel(SUPPORT_COMPANY_TYPE_LOOKUP_TABLE, data.companyType);

  return (
    <LabelContentTable
      css={S.labelContentTable}
      variant="bg"
      subject="Inquiry information"
    >
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Name">
          {data?.name}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Status">
          {data && <StatusLabel status={data.status} />}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2}>
        <LabelContentTable.Content label="Company type">
          {data && companyTypeLabel ? t(companyTypeLabel) : "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Device / OS/ Ver.">
          {`${data?.device ?? "-"} / ${data?.os ? `${data?.os}` : "-"} / ${
            data?.version ? `${data?.version}` : "-"
          }`}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row partition={2} marginTop={8}>
        <LabelContentTable.Content label="Category">
          {data && categoryLabel ? t(categoryLabel) : "-"}
        </LabelContentTable.Content>
        <LabelContentTable.Content label="Order number">
          {data?.orderNumber ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Created date">
          {data?.created
            ? formatICTDateTime(data.created, "DD/MM/YY, HH:mm")
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Subject">
          {data?.subject ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Description">
          {data?.description ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          css={S.downloadBtnWrapper}
          label="Attached file"
        >
          {[data?.file1, data?.file2, data?.file3].filter(Boolean).map(
            (file, i) =>
              file && (
                <DownloadBtn
                  downloadFileNamePrefix={`File 0${i}`}
                  fileName={`File 0${i + 1}`}
                  fileKey={file} //TODO: prefix 포한된 데이터로 수정 요청드린 상태(확인필요)
                  downloadFileMutate={downloadFileMutate}
                />
              ),
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default InquiryInfo;
