import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import type { ColumnTable } from "types";
import * as S from "./Table.styled";

interface TableSkeletonProps {
  className?: string;
  columnTable: ColumnTable;
  gridTemplateColumns: string;
  rowCount?: number;
}

const TableSkeleton = ({
  className,
  columnTable,
  gridTemplateColumns,
  rowCount = 10,
}: TableSkeletonProps) => {
  return (
    <S.TableSkeleton
      className={className}
      gridTemplateColumns={gridTemplateColumns}
    >
      <thead>
        <S.HeadRow>
          {Object.values(columnTable).map((value, i) => (
            <th key={i} title={value}>
              {value}
            </th>
          ))}
        </S.HeadRow>
      </thead>
      <tbody>
        {[...Array(rowCount)].map((_, i) => (
          <S.Row key={i}>
            {Object.keys(columnTable).map((item) => (
              <S.Td key={item}>
                <Skeleton />
              </S.Td>
            ))}
          </S.Row>
        ))}
      </tbody>
    </S.TableSkeleton>
  );
};

export default TableSkeleton;
