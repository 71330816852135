import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";

import { InternalLink, LabelContentTable, PageLayout } from "components";
import { useGetNoticeDetail } from "services";
import { formatICTDateTime } from "utils";
import { NOTICE_DETAIL_LAYOUT } from "assets";
import { PATH } from "constants/index";
import * as S from "./NoticeDetail.styled";

const NoticeDetail = () => {
  const { t } = useTranslation();

  const { supportId } = useParams();

  const { data } = useGetNoticeDetail(supportId!);

  const sanitizer = dompurify.sanitize;

  if (!data) return null;

  const companyTypes: string[] = [];

  data.isShipper && companyTypes.push(t("Shipper"));
  data.isForwarder && companyTypes.push(t("Forwarder"));
  data.isCarrier && companyTypes.push(t("Carrier"));
  data.isDriver && companyTypes.push(t("Driver"));

  return (
    <PageLayout css={S.heading} layoutStaticData={NOTICE_DETAIL_LAYOUT}>
      <LabelContentTable variant="bg">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Company type">
            {companyTypes.join(", ")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Subject">
            {data.subject}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Description">
            <div
              css={S.editorImg}
              dangerouslySetInnerHTML={{
                __html: sanitizer(data.description),
              }}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(data.created)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Modified date">
            {data.updated ? formatICTDateTime(data.updated) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnsWrapper>
        <InternalLink variant="outlineMedium" to={PATH.SUPPORT_NOTICE_TAB}>
          {t("Back to list")}
        </InternalLink>
        <InternalLink
          variant="primaryMedium"
          to={`${PATH.SUPPORT}/notice/edit/${supportId}`}
        >
          {t("Edit notice")}
        </InternalLink>
      </S.BtnsWrapper>
    </PageLayout>
  );
};

export default NoticeDetail;
