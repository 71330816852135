import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import SunEditorCore from "suneditor/src/lib/core";
import type { Control } from "react-hook-form";
import Editor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { HOOKFORM_ERR } from "assets";
import type { Languages } from "types";
import * as S from "./SunEditor.styled";

interface SunEditorProps {
  name: string;
  placeholder: Languages;
  defaultValue: string;
  control: Control<any>;
  hasError?: boolean;
  handleIsEmpty: (count: number, content?: string) => void;
  handleClearError: (data: string) => void;
}

const SunEditor = ({
  name,
  placeholder,
  defaultValue,
  control,
  hasError,
  handleIsEmpty,
  handleClearError,
}: SunEditorProps) => {
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: "required",
        validate: (value) =>
          value.replace(/<\/?p>|&nbsp;|<br\/?>/gi, "").trim().length > 0 ||
          HOOKFORM_ERR.REQUIRE,
      }}
      render={({ field: { value, name, onChange } }) => (
        <S.EditorWrapper hasError={hasError}>
          <Editor
            lang="en"
            name={name}
            width="100%"
            height="312"
            placeholder={placeholder}
            defaultValue={defaultValue}
            getSunEditorInstance={getSunEditorInstance}
            setDefaultStyle={"font-size: 14px;"}
            setOptions={{
              historyStackDelayTime: 0,
              charCounter: true,
              charCounterType: "char",
              imageUploadSizeLimit: 1 * 1024 * 1024,
              imageResizing: false,
              buttonList: [
                [
                  "fontSize",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "fontColor",
                  "hiliteColor",
                  "image",
                  "link",
                  "preview",
                ],
              ],
            }}
            setContents={value}
            onChange={(data) => {
              onChange(data);
              handleClearError(data);
            }}
            onBlur={() => {
              const content = editor.current?.core.getContents(false);
              const htmlTagRegex = /(<([^>]+)>|&nbsp;)/gi;
              const contentCount =
                content?.replace(htmlTagRegex, "")?.trim().length ?? 0;

              handleIsEmpty(contentCount, content);
            }}
          />
        </S.EditorWrapper>
      )}
    />
  );
};

export default SunEditor;
