import React from "react";
import { useTranslation } from "react-i18next";

import { PersonIcon, PhoneIcon, TimeIcon } from "assets";
import type { Languages } from "types";
import * as S from "./DriverInfo.styled";

interface DriverInfoProps {
  driverInfo: {
    title: Languages;
    name: string;
    phone: string;
    addr: string;
  };
}

const DriverInfo = ({ driverInfo }: DriverInfoProps) => {
  const { t } = useTranslation();

  return (
    <S.DriverInfoWrapper>
      <h3>{t(driverInfo.title)}</h3>
      <S.DriverInfoContentWrapper>
        <S.DriverInfoContentBox>
          <PersonIcon />
          <span>{driverInfo.name}</span>
        </S.DriverInfoContentBox>
        <S.DriverInfoContentBox>
          <PhoneIcon />
          <span>{driverInfo.phone}</span>
        </S.DriverInfoContentBox>
        <S.DriverInfoContentBox>
          <TimeIcon />
          <time>{driverInfo.addr}</time>
        </S.DriverInfoContentBox>
      </S.DriverInfoContentWrapper>
    </S.DriverInfoWrapper>
  );
};

export default DriverInfo;
