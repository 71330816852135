import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { InvisibleIcon, VisibleIcon } from "assets";
import type { Languages } from "types";
import { useShowPassword } from "./hooks";
import FormInput from "../FormInput/FormInput";
import * as S from "./FormPasswordInput.styled";

interface FormInputProps {
  className?: string;
  placeholder: Languages;
  maxLength?: number;
  disabled?: boolean;
  register: UseFormRegisterReturn<string>;
  hasError?: boolean;
  errorMsg?: string;
}
//TODO: 임시로 추가한 password 컴포넌트로 추후 password 컴포넌트 하나로 통일 필요
const FormPasswordInput2 = ({
  className,
  placeholder,
  maxLength,
  disabled,
  register,
  hasError,
  errorMsg,
}: FormInputProps) => {
  const { isShowPassword, handleShowPassword } = useShowPassword();

  return (
    <S.InputWrapper className={className}>
      <FormInput
        type={isShowPassword ? "text" : "password"}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        hasError={hasError}
        errorMsg={errorMsg}
        register={register}
      />
      <S.IconBtn type="button" onClick={handleShowPassword}>
        {isShowPassword ? <VisibleIcon /> : <InvisibleIcon />}
      </S.IconBtn>
    </S.InputWrapper>
  );
};

export default FormPasswordInput2;
