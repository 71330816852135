import SelectedTruckMarkerSvg from "assets/icon/ic_selected_truck.svg";
import DefaultTruckMarkerSvg from "assets/icon/ic_default_truck.svg";

export const MONITORING_QUERY_PARAMS = {
  ORDER_ID: "orderId",
  PAGE: "page",
  SEARCH: "search",
  TAB: "tab",
  TRUCK_ID: "truckId",
  TYPE: "type",
  COMPANY_TYPE: "companyType",
} as const;

export const MONITORING_TABS = [
  { key: "shipping", label: "Shipping" },
  { key: "waiting", label: "Waiting" },
] as const;

export const TRUCK_ICON = {
  default: DefaultTruckMarkerSvg,
  selected: SelectedTruckMarkerSvg,
} as const;
