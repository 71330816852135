import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DetailModal, ErrorMsg, Textarea } from "components";
import { useModal, useToast } from "hooks";
import { useCancelOrder } from "services";
import { HOOKFORM_ERR } from "assets";
import { TOAST_MSG } from "constants/index";
import * as S from "./CancelOrderModal.styled";

interface memoForm {
  memo: string;
}

const schema = yup.object({
  memo: yup
    .string()
    .required()
    .max(200, HOOKFORM_ERR.INVALID_MEMO_200)
    .test("length", HOOKFORM_ERR.REQUIRE, (value) => !!value.trim().length),
});

const CancelOrderModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const { id } = useParams();

    const { mutate: cancelOrderMutate } = useCancelOrder();
    const { handleModalClose } = useModal();
    const { addToast } = useToast();

    const formMethod = useForm<memoForm>({
      mode: "onTouched",
      defaultValues: {
        memo: "",
      },
      resolver: yupResolver(schema),
    });

    const handleCancelOrder = (data: memoForm): void =>
      cancelOrderMutate(
        { orderId: id!, body: { cancelReason: data.memo.trim() } },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.ORDER_CANCELD_SUCCESS);
            handleModalClose();
          },
        },
      );

    return (
      <DetailModal
        ref={ref}
        css={S.modal}
        title="Cancel order"
        desc="Are you sure you want to cancel this order?"
        negBtnLabel="Cancel order"
        isDisabled={!!formMethod.formState.errors.memo?.message}
        callbackFn={formMethod.handleSubmit(handleCancelOrder)}
        closeBtnLabel="Close"
      >
        <>
          <Textarea
            css={S.textarea}
            hasError={!!formMethod.formState.errors.memo?.message}
            id="memo"
            placeholder="Please enter a reason for cancellation"
            value={formMethod.watch("memo")}
            maxLength={200}
            register={formMethod.register("memo")}
          />
          {formMethod.formState.errors.memo?.message && (
            <ErrorMsg
              css={S.errMsg}
              errorMsg={formMethod.formState.errors.memo?.message}
            />
          )}
        </>
      </DetailModal>
    );
  },
);

CancelOrderModal.displayName = "CancelOrderModal";

export default CancelOrderModal;
