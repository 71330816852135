// NOTE: 임의 주소 디폴트값: 호치민 시청
export const DEFAULT_MAP_COORD = {
  lng: 106.700972,
  lat: 10.776561,
} as const;

export const PATH = {
  CARRIER_MANAGEMENT: "/carrierManagement",
  CARRIER_MANAGEMENT_DETAIL: "/carrierManagement/:id",
  CARRIER_MANAGEMENT_CREATE: "/carrierManagement/create",
  CREATE_NEWPASSWORD: "/createNewPassword",
  EXPIRED: "/expired",
  LINKING: "/linking",
  MEMBER: "/member",
  MODERATOR: "/moderator",
  MODERATOR_CREATE: "/moderatorCreate",
  MONITORING: "/monitoring",
  NOT_FOUND: "/notFound",
  MY_ACCOUNT: "/myAccount",
  ORDER_MANAGEMENT: "/orderManagement",
  ORDER_MANAGEMENT_MAP: "/orderManagement/map",
  RESET_PASSWORD: "/resetPassword",
  ROOT: "/",
  SHIPPER_MANAGEMENT: "/shipperManagement",
  SHIPPER_MANAGEMENT_DETAIL: "/shipperManagement/:id",
  STATICS: "/statics",
  SUPPORT: "/support",
  SUPPORT_INQUIRY_TAB: "/support?tab=inquiry",
  SUPPORT_NOTICE_TAB: "/support?tab=notice",
  SUPPORT_NOTICE_CREATE: "/support/notice/create",
  SUPPORT_NOTICE_EDIT: "/support/notice/edit/:id",
  TRANSIT_HISTORY: "/transitHistory",
  QUOTATION: "/quotation",
  SETTLEMENT: "/settlement",
  SETTLEMENT_DETAIL: "/settlement/:id",
  SETTING: "/setting",
  SETTING_MYACCOUNT: "/setting/myAccount",
  SETTING_REGISTERED_MEMBER: "/setting/registeredMember",
};

export const TAB = {
  CARRIER: "carrier",
  FORWARDER: "forwarder",
  SHIPPER: "shipper",
  SUPPORT: {
    INQUIRY: "inquiry",
    NOTICE: "notice",
  },
  SEETTING: {
    MY_ACCOUNT: "myAccount",
    REGISTERED_MEMBER: "registeredMember",
  },
  MEMBER: {
    DEFAULT_INFO: "defaultInfomation",
    REGISTERED_TRUCK: "registeredTruck",
    REGISTERED_GARAGE: "registeredGarage",
    REGISTERED_DRIVER: "registeredDriver",
    REGISTERED_MANAGELINK: "registeredManageLink",
    REGISTERED_MEMBER: "registeredMember",
  },
};

export const WEEKS = ["SUN", "MON", "TUE", "WES", "THU", "FRI", "SAT"];
