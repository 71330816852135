import React from "react";

import { Filter } from "components";

const LinkingFilter = () => {
  const searchLabels = [
    { queryKey: "company", label: "Company name" },
  ] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Company name">
          <Filter.Input
            queryKey="company"
            placeholder="Search by company name"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default LinkingFilter;
