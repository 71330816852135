import React from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "hooks";
import { LocationIcon, SearchIcon } from "assets";
import type { AddressSearchType, Languages } from "types";
import LocSearchModal from "./locSearchModal/LocSearchModal";
import useAddrAutoComplete from "./hooks/useAddrAutoComplete";
import * as S from "./AddrAutoComplete.styled";

interface AddrAutoCompleteProps {
  className?: string;
  err: any; // TODO: hook-form 붙일 때 수정 예정
  value: AddressSearchType;
  placeholder: Languages;
  handleSelect: (value: AddressSearchType) => void;
}

const AddrAutoComplete = ({
  className,
  err,
  value,
  placeholder,
  handleSelect,
}: AddrAutoCompleteProps) => {
  const { t } = useTranslation();

  const {
    inputRef,
    addrSuggestions,
    inputValue,
    getAutocompleteAddrInfo,
    handleAddrChange,
    handleAddrKeyPress,
    handleAddrSelect,
    handleModalAddrSelect,
  } = useAddrAutoComplete(value, handleSelect);

  const { modalRef, handleModalOpen } = useModal();

  return (
    <S.AddrAutoComplete>
      <S.AddressWrapper>
        <S.AutocompleteInput
          className={className}
          ref={inputRef}
          autoComplete="off"
          hasError={!!err}
          value={inputValue}
          placeholder={t(placeholder)}
          onChange={handleAddrChange}
          onKeyDown={handleAddrKeyPress}
        />
        <S.SearchButton type="button" onClick={getAutocompleteAddrInfo}>
          <SearchIcon />
        </S.SearchButton>
        <S.OpenMapButton
          type="button"
          onClick={handleModalOpen(
            <LocSearchModal
              ref={modalRef}
              value={value}
              handleAddrSelect={handleModalAddrSelect}
            />,
          )}
        >
          <LocationIcon />
        </S.OpenMapButton>
      </S.AddressWrapper>
      <S.DropDownWrapper isActive={addrSuggestions.length !== 0}>
        {addrSuggestions.map((suggestion) => (
          <S.DropDownItem
            key={suggestion.place_id}
            onClick={handleAddrSelect(suggestion)}
          >
            {suggestion.description}
          </S.DropDownItem>
        ))}
      </S.DropDownWrapper>
    </S.AddrAutoComplete>
  );
};

export default AddrAutoComplete;
