import React from "react";
import { useFormContext } from "react-hook-form";

import {
  FormInput,
  FormPasswordInput,
  FormRadioBtn,
  HideEmailPassword,
  LabelContentTable,
} from "components";
import { checkEmailValidation, onlyNumber } from "utils";
import { GRADE_TYPE_RADIOS, HOOKFORM_ERR } from "assets";
import type { RegisteredMemberForm } from "types";
import * as S from "./MemberInfoForm.styled";

interface MemberInfoFormProps {
  type: "add" | "edit";
}

const MemberInfoForm = ({ type }: MemberInfoFormProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<RegisteredMemberForm>();

  return (
    <LabelContentTable variant="empty">
      <input className="a11y" type="email" />
      <input className="a11y" type="password" />
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Grade" isRequired>
          <FormRadioBtn
            css={S.radio}
            radioList={GRADE_TYPE_RADIOS}
            currentValue={watch("grade")}
            register={register("grade")}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          css={S.multiLineContent}
          label="Name"
          isRequired
        >
          <FormInput
            css={S.formInput}
            maxLength={50}
            placeholder="First name"
            hasError={!!errors.firstName?.message}
            errorMsg={errors.firstName?.message}
            register={register("firstName", {
              required: HOOKFORM_ERR.REQUIRE,
            })}
          />
          <FormInput
            css={S.formInput}
            maxLength={50}
            placeholder="Middle name (Optional)"
            register={register("middleName")}
          />
          <FormInput
            css={S.formInput}
            maxLength={50}
            placeholder="Last name"
            hasError={!!errors.lastName?.message}
            errorMsg={errors.lastName?.message}
            register={register("lastName", {
              required: HOOKFORM_ERR.REQUIRE,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Phone number" isRequired>
          <FormInput
            css={S.formInput}
            maxLength={15}
            placeholder="Only numbers"
            value={watch("phone")}
            hasError={!!errors.phone?.message}
            errorMsg={errors.phone?.message}
            register={register("phone", {
              required: HOOKFORM_ERR.REQUIRE,
              setValueAs: onlyNumber,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Login ID" isRequired>
          <FormInput
            css={S.formInput}
            maxLength={200}
            placeholder="Login ID"
            hasError={!!errors.email?.message}
            disabled={type === "edit"}
            errorMsg={errors.email?.message}
            register={register("email", {
              required: HOOKFORM_ERR.REQUIRE,
              validate: (value: string) =>
                checkEmailValidation(value) || HOOKFORM_ERR.VALID_EMAIL,
            })}
          />
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      {type === "add" && (
        <>
          <LabelContentTable.Row>
            <HideEmailPassword />
            <LabelContentTable.Content label="Password" isRequired>
              <FormPasswordInput
                css={S.formInput}
                confirmType="password"
                placeholder="Enter password"
                hasError={!!errors.password?.message}
                password={watch("password") ?? ""}
                register={register("password", {
                  required: HOOKFORM_ERR.REQUIRE,
                })}
              />
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Password confirm" isRequired>
              <FormPasswordInput
                css={S.formInput}
                confirmType="passwordConfirm"
                placeholder={"Enter confirm password"}
                hasError={!!errors.confirmPassword?.message}
                password={{
                  password: watch("password") ?? "",
                  passwordConfirm: watch("confirmPassword") ?? "",
                }}
                register={register("confirmPassword", {
                  required: HOOKFORM_ERR.REQUIRE,
                })}
              />
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </>
      )}
      <S.Division />
    </LabelContentTable>
  );
};

export default MemberInfoForm;
