import React from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon, InvisibleIcon, VisibleIcon } from "assets";
import { useCheckPassword, useShowPassword } from "./hooks";
import FormInput, { FormInputProps } from "../FormInput/FormInput";
import * as S from "./FormPasswordInput.styled";

interface Password extends Omit<FormInputProps, "errorMsg"> {
  confirmType: "password";
  password: string;
}

interface PasswordConfirm extends Omit<FormInputProps, "errorMsg"> {
  confirmType: "passwordConfirm";
  password: {
    password: string;
    passwordConfirm: string;
  };
}

interface CurrentPassword extends Omit<FormInputProps, "errorMsg"> {
  confirmType: "currentPassword";
  password: string;
}

type FormPasswordInputProps = Password | PasswordConfirm | CurrentPassword;

const FormPasswordInput = ({
  className,
  placeholder,
  maxLength,
  disabled,
  register,
  hasError,
  confirmType,
  password,
}: FormPasswordInputProps) => {
  const { t } = useTranslation();

  const { isShowPassword, handleShowPassword } = useShowPassword();
  const { isLength, isType, isMatch } = useCheckPassword(password);

  return (
    <div className={className}>
      <S.InputWrapper>
        <FormInput
          type={isShowPassword ? "text" : "password"}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          hasError={hasError}
          register={register}
        />
        <S.IconBtn type="button" onClick={handleShowPassword}>
          {isShowPassword ? <VisibleIcon /> : <InvisibleIcon />}
        </S.IconBtn>
      </S.InputWrapper>
      {confirmType === "password" && (
        <>
          <S.CheckWrapper isCheck={isLength} hasError={hasError}>
            <CheckIcon /> {t("8-16 characters.")}
          </S.CheckWrapper>
          <S.CheckWrapper isCheck={isType} hasError={hasError}>
            <CheckIcon />
            {t("At least 2 types (A-Z, a-z, numbers, symbols).")}
          </S.CheckWrapper>
        </>
      )}
      {confirmType === "passwordConfirm" && (
        <S.CheckWrapper isCheck={isMatch} hasError={hasError}>
          <CheckIcon />
          {t("Password matches.")}
        </S.CheckWrapper>
      )}
    </div>
  );
};

export default FormPasswordInput;
