import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Input } from "components";
import { useCustomParams } from "hooks";
import { DeleteCircleIcon, SearchIcon } from "assets";
import type { Languages } from "types";
import * as S from "./QuerySearchInput.styled";

interface QuerySearchInputProps {
  className?: string;
  placeholder: Languages;
  searchQuery: string;
  deleteQueries: string[];
}

const QuerySearchInput = ({
  className,
  placeholder,
  searchQuery,
  deleteQueries,
}: QuerySearchInputProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleUpdateQuery } = useCustomParams();

  const [queryInput, setQueryInput] = useState("");

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQueryInput(e.target.value);
  };

  const handleSearch = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!queryInput) {
      searchParams.delete(searchQuery);
      setSearchParams(searchParams);

      return;
    }

    handleUpdateQuery({ search: queryInput }, deleteQueries);
  };

  const handleReset = (): void => {
    setQueryInput("");
    searchParams.delete(searchQuery);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setQueryInput(searchParams.get(searchQuery) || "");
  }, [searchParams.get(searchQuery)]);

  return (
    <S.Form className={className} onSubmit={handleSearch}>
      <S.SearchBtn type="submit">
        <SearchIcon />
      </S.SearchBtn>
      <Input
        css={S.input}
        placeholder={placeholder}
        maxLength={100}
        value={queryInput}
        handleChangeValue={handleChangeInput}
      />
      {queryInput && (
        <S.CloseBtn type="button" onClick={handleReset}>
          <DeleteCircleIcon />
        </S.CloseBtn>
      )}
    </S.Form>
  );
};

export default QuerySearchInput;
