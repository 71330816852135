import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const labelcontent = css`
  padding: 12px 0;
`;

export const calendarInput = (theme: Theme, hasErr: boolean) => css`
  width: 444px;
  height: fit-content;
  padding-bottom: ${hasErr && "0"};

  & > input {
    padding: 0 32px 0 12px;
    border: 1px solid ${hasErr ? theme.color.red_30 : theme.color.gray_30};
  }
`;

export const radio = css`
  column-gap: 40px;
  padding: 10px 0;
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 213px;

    & > div {
      width: 100%;
    }

    & > span {
      ${theme.font.regular_14};
      position: absolute;
      top: 9px;
      right: 12px;
    }
  `}
`;

export const modal = css`
  & > div:last-of-type {
    margin-top: 16px;
  }
`;
