import React from "react";

import { useModal, useToast } from "hooks";
import { useDeleteNotice, usePatchPin, usePatchUnpin } from "services";
import { TOAST_MSG } from "constants/index";

const usePinNotice = (checkedIds: string[], handleAllUnCheck: () => void) => {
  const { mutate: pinNoticeMutate } = usePatchPin();
  const { mutate: unPinNoticeMutate } = usePatchUnpin();
  const { mutate: deleteNoticeMutate } = useDeleteNotice();

  const { addToast } = useToast();
  const { handleModalClose } = useModal();

  const handlePinNotice = () => {
    const req = { body: { notices: checkedIds } };
    pinNoticeMutate(req, {
      onSuccess: () => {
        handleModalClose();
        handleAllUnCheck();
        addToast(TOAST_MSG.SUCCESS.SUPPORT_NOTICE_PINNED_SUCCESS);
      },
    });
  };

  const handleUnPinNotice = () => {
    const req = { body: { notices: checkedIds } };
    unPinNoticeMutate(req, {
      onSuccess: () => {
        handleModalClose();
        handleAllUnCheck();
        addToast(TOAST_MSG.SUCCESS.SUPPORT_NOTICE_UNPINNED_SUCCESS);
      },
    });
  };

  const handleDeleteNotice = () => {
    const req = { body: { noticeIds: checkedIds } };
    deleteNoticeMutate(req, {
      onSuccess: () => {
        handleModalClose();
        handleAllUnCheck();
        addToast(TOAST_MSG.SUCCESS.SUPPORT_NOTICE_DELETE_SUCCESS);
      },
    });
  };

  return { handlePinNotice, handleUnPinNotice, handleDeleteNotice };
};

export default usePinNotice;
