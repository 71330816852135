import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { mixin_scrollbar } from "style";

export const modal = css`
  height: 612px;

  h3 + button {
    display: none;
  }
`;

export const ListTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    height: 32px;
    padding: 7px 12px;
    border: 1px solid ${theme.color.gray_35};
    border-bottom: 0;
    color: ${theme.color.gray_70};
    background-color: ${theme.color.gray_10};
  `}
`;

export const PlateNumberWrapper = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    width: 420px;
    height: 442px;
    border: 1px solid ${theme.color.gray_35};
    overflow-y: auto;
  `}
`;

const commonPlateNumber = (theme: Theme) => css`
  ${theme.font.medium_14};
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border-bottom: 1px solid ${theme.color.gray_35};
  text-align: left;
  color: ${theme.color.gray_70};

  :hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const PlateNumberButton = styled.button`
  ${({ theme }) => css`
    ${commonPlateNumber(theme)};
  `}
`;

export const PlateNumberCopyButtonWrapper = styled.div`
  ${({ theme }) => css`
    ${commonPlateNumber(theme)};
    display: flex;
    justify-content: space-between;
  `}
`;

export const button = (theme: Theme) => css`
  :hover {
    background-color: inherit !important; // NOTE: important 없으면 적용안됨
  }

  svg {
    margin: 0;

    & > g > path {
      fill: ${theme.color.primary_40};
    }
  }
`;
