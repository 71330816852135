import { css } from "@emotion/react";

export const textarea = css`
  height: 144px;
  padding: 12px 20px 30px;
  resize: none;
`;

export const modal = css`
  width: 510px;

  & > div {
    &:last-of-type {
      padding-top: 24px;
    }
  }
`;

export const errMsg = css`
  padding: 4px 0 0;
`;
