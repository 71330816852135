import styled from "@emotion/styled";
import { css, keyframes, Theme } from "@emotion/react";

const scaleUp = keyframes`
  from { max-height: 0;}
  to {max-height: 100px;}
`;

const scaleDown = keyframes`
  from { max-height: 100px;}
  to { max-height: 0;}
 `;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-50%);}
  to { opacity: 1; transform: translateY(0)}
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0)}
  to { opacity: 0; transform: translateY(50%)}
 `;

export const ToastItem = styled.div<{ isClosing: boolean }>`
  ${({ isClosing }) => css`
    max-height: 0;
    overflow: visible;
    animation: 0.6s forwards ${isClosing ? scaleDown : scaleUp};

    & > div {
      animation: 0.3s forwards ${isClosing ? fadeOut : fadeIn};
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    max-width: 348px;
    border-radius: 10px;
    padding: 18px 20px 18px 18px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.toast};

    & > pre {
      ${theme.font.medium_14};
      color: ${theme.color.gray_80};
      white-space: pre-wrap;
    }
  `}
`;

export const successIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.green_20};
  }
`;

export const warningIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.orange_20};
  }
`;
