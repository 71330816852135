import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./ListPageLayout.styled";

interface ListPageLayoutProps {
  className?: string;
  heading?: Languages;
  children: React.ReactNode;
}

const ListPageLayout = ({
  className,
  heading,
  children,
}: ListPageLayoutProps) => {
  const { t } = useTranslation();

  return (
    <S.Section className={className}>
      {heading && <S.Heading>{t(heading)}</S.Heading>}
      {children}
    </S.Section>
  );
};

export default ListPageLayout;
