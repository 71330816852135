import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Carrier,
  // Driver, TODO: 10월 10일 업데이트에서 사용 안함으로 주석 처리
  Forwarder,
  ListPageLayout,
  Shipper,
  Tab,
} from "components";
import { MEMBER_TABS, PlusCircleIcon } from "assets";
import { TAB } from "constants/index";
import * as S from "./Member.styled";

const Member = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const memberType = [TAB.CARRIER, TAB.FORWARDER, TAB.SHIPPER] as const;

  return (
    <ListPageLayout css={S.listPageLayout}>
      <Link
        css={S.link}
        to={`create?memberType=${
          memberType.find((type) => type === searchParams.get("tab")) ||
          "shipper"
        }`}
      >
        <PlusCircleIcon css={S.icon} /> {t("Add member")}
      </Link>
      <Tab tabs={MEMBER_TABS}>
        <Tab.TabList />
        <Tab.TabPanel
          render={{
            [TAB.SHIPPER]: <Shipper />,
            [TAB.FORWARDER]: <Forwarder />,
            [TAB.CARRIER]: <Carrier />,
            // driver: <Driver /> TODO: 10월 10일 업데이트에서 사용 안함으로 주석 처리
          }}
        />
      </Tab>
    </ListPageLayout>
  );
};

export default Member;
