import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { mixinBtn_ghost_b, mixinBtn_ghost_g } from "style";

export const DriverListWrapper = styled.ul`
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  width: 100%;
`;

export const AddBtn = styled.button`
  ${mixinBtn_ghost_b}
`;

export const ProfileList = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const profile = css`
  margin-right: 8px;
`;

export const DriverName = styled.span`
  height: fit-content;
`;

export const AutoCompletesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AutocompletesButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  padding-top: 10px;
`;

export const CancelBtn = styled.button`
  ${mixinBtn_ghost_g}
`;

export const UpdateBtn = styled.button`
  ${mixinBtn_ghost_b}
`;
