import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  width: 764px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 12px 0 0 auto;
`;

export const labelContent = css`
  & > div {
    column-gap: 10px;
  }
`;
