import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getLinkCompanysAPI, getLinkingsAPI, patchLinkCompanysAPI } from "api";
import type {
  APIError,
  GetLinkingCompanysQueryModel,
  GetLinkingsQueryModel,
  PatchLinkingCompanysQueryModel,
} from "types";

const linkingCompanysKeys = {
  all: ["linkingCompanys"],
  lists: () => [...linkingCompanysKeys.all, "list"],
  list: (filters: GetLinkingsQueryModel) => [
    ...linkingCompanysKeys.lists(),
    { filters },
  ],
  linkCompanys: () => [...linkingCompanysKeys.all, "linkCompany"],
  linkCompany: (filters: GetLinkingCompanysQueryModel) => [
    ...linkingCompanysKeys.linkCompanys(),
    { filters },
  ],
};

export const useGetLinkings = (req: GetLinkingsQueryModel) => {
  return useQuery({
    queryKey: linkingCompanysKeys.list(req),
    queryFn: () => getLinkingsAPI(req),
  });
};

export const useGetLinkingCompanys = (req: GetLinkingCompanysQueryModel) => {
  return useQuery({
    queryKey: linkingCompanysKeys.linkCompany(req),
    queryFn: () => getLinkCompanysAPI(req),
  });
};

export const usePatchLinkingCompanys = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PatchLinkingCompanysQueryModel>({
    mutationFn: (req) => patchLinkCompanysAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: linkingCompanysKeys.lists() });
    },
  });
};
