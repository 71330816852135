import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface MembershipPlanPaginationProps {
  pageInfo?: PageInfo;
}

const MembershipPlanPagination = ({
  pageInfo,
}: MembershipPlanPaginationProps) => {
  if (!pageInfo) return null;
  return <QueryPagination pageInfo={pageInfo} />;
};

export default MembershipPlanPagination;
