import truckSvg from "assets/icon/ic_truckMap.svg";

export const createMarker = (
  map: google.maps.Map,
  lat: number,
  lng: number,
  icon?: string | google.maps.Icon,
  zIndex?: number,
) => {
  return new google.maps.Marker({
    map,
    position: { lat: +lat, lng: +lng },
    shape: { coords: [40, 40, 40], type: "circle" },
    optimized: false,
    icon,
    zIndex,
  });
};

export const cleanUpMarkers = (markers: google.maps.Marker[]) => {
  markers.forEach((marker) => marker.setMap(null));
};

export const driverIcon = (imgUrl: string | undefined): google.maps.Icon => ({
  url: imgUrl || truckSvg,
  scaledSize: new google.maps.Size(40, 40),
});

// NOTE: marker 에 className 적용
export const makeMarkerLayer = (map: google.maps.Map) => {
  const markerLayer = new google.maps.OverlayView();
  markerLayer.draw = function () {
    this.getPanes()!.markerLayer.className = "profile-marker";
  };
  markerLayer.setMap(map);
};
