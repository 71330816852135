import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useCurrentQueryParams, useToast } from "hooks";
import { usePostMember, usePostMemberDummy } from "services";
import { makeCryptoFunction, makeMemberS3PresignedUrlFileUuids } from "utils";
import { COMPANY_LOOKUP_TABLE } from "assets";
import { TOAST_MSG, PATH } from "constants/index";
import type {
  APIError,
  MemberForm,
  PostMemberDummyQueryModel,
  PostMemberQueryModel,
} from "types";

const useCreateMember = () => {
  const navigate = useNavigate();

  const { mutate: createMemberMutate, isLoading: isLoadingCreateMember } =
    usePostMember();
  const {
    mutate: createDummyMemberMutate,
    isLoading: isLoadingCreateDummyMember,
  } = usePostMemberDummy();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];
  const { addToast } = useToast();

  const companyType = memberType
    ? COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE]
    : "SHIPPER";

  const formMethod = useForm<MemberForm>({
    mode: "onTouched",
    defaultValues: {
      companyType,
      registered: "YES",
      plan: "STANDARD",
      companyName: "",
      addr: {
        address: "",
        addressDetail: "",
        buildingName: "",
        city: "",
        coord: {
          lng: 0,
          lat: 0,
        },
      },
      taxcode: "",
      businessEmail: "",
      businessTel: "",
      representative: "",
      license1: null,
      license2: null,
      transportLicense1: null,
      transportLicense2: null,
      managerFirstName: "",
      managerMiddleName: "",
      managerLastName: "",
      managerPhone: "",
      managerEmail: "",
      managerPassword: "",
      managerPasswordConfirm: "",
      gpsType: { key: "", label: "" },
      gpsCustomerCode: "",
      gpsApiKey: "",
    },
  });

  const createMember = async (data: MemberForm): Promise<void> => {
    const gpsApiCheck =
      data.companyType === "CARRIER" || data.companyType === "FORWARDERCARRIER";

    const { license1, license2, transportLicense1, transportLicense2 } =
      await makeMemberS3PresignedUrlFileUuids(data.companyType, {
        license1: data.license1,
        license2: data.license2,
        transportLicense1: data.transportLicense1,
        transportLicense2: data.transportLicense2,
      });

    const req: PostMemberQueryModel = {
      query: { companyType: data.companyType },
      body: {
        plan: data.plan,
        name: data.companyName,
        addr: data.addr.address,
        ...(data.addr.addressDetail && { addrDetail: data.addr.addressDetail }),
        taxcode: data.taxcode,
        email: data.businessEmail,
        phone: data.businessTel,
        representative: data.representative,
        license1,
        license2,
        managerFirstName: data.managerFirstName,
        ...(data.managerMiddleName && {
          managerMiddleName: data.managerMiddleName,
        }),
        managerLastName: data.managerLastName,
        managerPhone: data.managerPhone,
        managerEmail: data.managerEmail,
        managerPassword: makeCryptoFunction(data.managerPassword),
        ...(gpsApiCheck && data.gpsType.key && { gpsType: data.gpsType.key }),
        ...(gpsApiCheck &&
          data.gpsCustomerCode && { gpsCustomerCode: data.gpsCustomerCode }),
        ...(gpsApiCheck && data.gpsApiKey && { gpsApiKey: data.gpsApiKey }),
      },
    };

    const dummyReq: PostMemberDummyQueryModel = {
      query: { companyType: data.companyType },
      body: {
        plan: data.plan,
        name: data.companyName,
        managerEmail: data.managerEmail,
        managerPassword: makeCryptoFunction(data.managerPassword),
        ...(data.addr.address && { addr: data.addr.address }),
        ...(data.addr.addressDetail && { addrDetail: data.addr.addressDetail }),
        ...(data.taxcode && { taxcode: data.taxcode }),
        ...(data.businessEmail && { email: data.businessEmail }),
        ...(data.businessTel && { phone: data.businessTel }),
        ...(data.representative && { representative: data.representative }),
        ...(data.license1 && { license1 }),
        ...(data.license2 && { license2 }),
        ...(data.managerFirstName && {
          managerFirstName: data.managerFirstName,
        }),
        ...(data.managerMiddleName && {
          managerMiddleName: data.managerMiddleName,
        }),
        ...(data.managerLastName && { managerLastName: data.managerLastName }),
        ...(data.managerPhone && { managerPhone: data.managerPhone }),
        ...(gpsApiCheck && data.gpsType.key && { gpsType: data.gpsType.key }),
        ...(gpsApiCheck &&
          data.gpsCustomerCode && {
            gpsCustomerCode: data.gpsCustomerCode,
          }),
        ...(gpsApiCheck && data.gpsApiKey && { gpsApiKey: data.gpsApiKey }),
      },
    };

    if (
      data.companyType === "CARRIER" ||
      data.companyType === "FORWARDERCARRIER"
    ) {
      const tempReq = data.registered === "YES" ? req : dummyReq;

      tempReq.body.lng = data.addr.coord.lng;
      tempReq.body.lat = data.addr.coord.lat;
      tempReq.body.transportLicense1 = transportLicense1;
      tempReq.body.transportLicense2 = transportLicense2;
    }

    const onSuccess = (): void => {
      addToast(TOAST_MSG.SUCCESS.MEMBER_CREATE_DONE);
      navigate(`${PATH.MEMBER}?tab=${memberType || "shipper"}`);
    };

    const onError = (err: APIError): void => {
      switch (err.response?.data.response) {
        case "TAXCODE_EXISTS":
          addToast(TOAST_MSG.WARNING.TAXCODE_EXISTS);
          return;

        case "STAFF_EMAIL_EXISTS":
          addToast(TOAST_MSG.WARNING.STAFF_EMAIL_EXISTS);
          return;

        case "COMPANY_EMAIL_EXISTS":
          addToast(TOAST_MSG.WARNING.COMPANY_EMAIL_EXISTS);
          return;

        case "COMPANY_PHONE_EXISTS":
          addToast(TOAST_MSG.WARNING.COMPANY_PHONE_EXISTS);
          return;

        case "STAFF_PHONE_EXISTS":
          addToast(TOAST_MSG.WARNING.STAFF_PHONE_EXISTS);
          return;

        default:
          alert(err.response?.data.response);
          return;
      }
    };

    data.registered === "YES"
      ? createMemberMutate(req, { onSuccess, onError })
      : createDummyMemberMutate(dummyReq, { onSuccess, onError });
  };

  return {
    isLoadingCreateMember,
    isLoadingCreateDummyMember,
    createMember,
    formMethod,
  };
};

export default useCreateMember;
