import React from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { comma } from "utils";
import type { Languages } from "types";
import * as S from "./Textarea.styled";

interface TextareaProps {
  className?: string;
  id: string;
  disabled?: boolean;
  hasError?: boolean;
  placeholder: Languages;
  value: string;
  maxLength: number;
  onFocus?: () => void;
  register?: UseFormRegisterReturn<string>;
}

const Textarea = ({
  className,
  id,
  onFocus,
  placeholder,
  disabled,
  hasError,
  value,
  maxLength = 200,
  register,
}: TextareaProps) => {
  const { t } = useTranslation();
  const currentLength = comma(value.length);
  const totalLength = comma(maxLength);

  return (
    <S.Root className={className} hasError={hasError}>
      <S.TextareaContent
        id={id}
        autoComplete="off"
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        onFocus={onFocus}
        placeholder={t(placeholder)}
        {...register}
      />
      <S.TextareaLength>{`${currentLength}/${totalLength}`}</S.TextareaLength>
    </S.Root>
  );
};

export default Textarea;
