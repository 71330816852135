import { css } from "@emotion/react";

export const downloadBtnWrapper = css`
  & > div {
    display: flex;
    column-gap: 20px;
  }
`;

export const labelContentTable = css`
  margin-bottom: 32px;
`;
