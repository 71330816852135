import { useEffect, useState } from "react";

import { checkPasswordValidation } from "utils";

const useCheckPassword = (
  password: string | { password: string; passwordConfirm: string },
) => {
  const [isLength, setIsLength] = useState(false);
  const [isType, setIsType] = useState(false);
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    if (password === undefined || typeof password == "object") return;

    setIsLength(password.length > 7);
    setIsType(checkPasswordValidation(password));
  }, [password]);

  useEffect(() => {
    if (
      password === undefined ||
      typeof password === "string" ||
      password.password.length === 0
    )
      return;

    setIsMatch(password.password === password.passwordConfirm);
  }, [password]);

  return {
    isLength,
    isType,
    isMatch,
  };
};

export default useCheckPassword;
