import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable, StatusLabel } from "components";
import { useDownloadFile } from "services";
import {
  calculateVatAmount,
  calculateTotalAmount,
  findLookupTableLabel,
  formatPrice,
  makeOrderFileName,
} from "utils";
import { PAYMENT_METHOD_RADIOS } from "assets";
import type { GetSettlementDetailServerModel, SettlementStatus } from "types";
import * as S from "./SettlementPayment.styled";

interface SettlementPaymentProps {
  payment?: GetSettlementDetailServerModel["payment"];
  memo?: string;
  status?: SettlementStatus;
}

const SettlementPayment = ({
  payment,
  memo,
  status,
}: SettlementPaymentProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  if (!payment) return null;

  const paymentMethodLabel = findLookupTableLabel(
    PAYMENT_METHOD_RADIOS,
    payment.paymentMethod,
  );

  const vatPrice = formatPrice(
    calculateVatAmount(`${payment.price}`, `${payment.vat}`),
  );
  const vat = `(${payment.vat}%) ${vatPrice}`;
  const totalPrice = formatPrice(
    calculateTotalAmount(`${payment.price}`, `${payment.vat}`),
  );
  const hasUploadFile = Object.values(payment.attachment ?? {}).some(Boolean);

  return (
    <LabelContentTable variant="underline">
      {status !== "ONGOING" && status && (
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status" labelWidth={200}>
            <StatusLabel status={status} />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      )}
      <LabelContentTable.Row>
        <LabelContentTable.Content
          css={S.previousPayment(status === "ONGOING")}
          label="Payment method"
          labelWidth={200}
        >
          {paymentMethodLabel ? t(paymentMethodLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total" labelWidth={200}>
          <S.PriceWrapper>
            {status && <S.Total status={status}>{totalPrice}</S.Total>}
            <S.SubtotalWrapper>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                <S.SubtotalContent>
                  {formatPrice(payment.price)}
                </S.SubtotalContent>
              </S.SubtotalItem>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                <S.SubtotalContent>{vat}</S.SubtotalContent>
              </S.SubtotalItem>
            </S.SubtotalWrapper>
          </S.PriceWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      {status !== "ONGOING" && (
        <>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Attached file" labelWidth={200}>
              {hasUploadFile && payment.attachment ? (
                <S.FileWrapper>
                  {[
                    payment.attachment.attachment1,
                    payment.attachment.attachment2,
                  ].map(
                    (fileKey, idx) =>
                      fileKey && (
                        <DownloadBtn
                          key={fileKey}
                          downloadFileNamePrefix="SettlementAttachedFile"
                          fileName={makeOrderFileName(fileKey, idx)}
                          fileKey={fileKey}
                          downloadFileMutate={downloadFileMutate}
                        />
                      ),
                  )}
                </S.FileWrapper>
              ) : (
                "-"
              )}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row css={S.memo}>
            <LabelContentTable.Content label="Memo" labelWidth={200}>
              {memo || "-"}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </>
      )}
    </LabelContentTable>
  );
};

export default SettlementPayment;
