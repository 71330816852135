export const HOOKFORM_ERR = {
  REQUIRE: "You can’t leave this empty.",
  EMAIL: "Please enter email address.",
  PASSWORD: "Please enter password.",
  MATCH_PASSWORD: "Password matches.",
  VALID_EMAIL: "Please enter a valid email address.",
  PASSWORD_LENGTH: "Incorrect password.",
  VALID_PASSWORD: "Incorrect password.",
  VALID_AUTHCODE: "The verification code is incorrect.",
  INCORRECT_LOGIN: "Incorrect email or password.",
  SELECT_DATE: "Please select the date",
  NOT_EMPTY: "You can't leave this empty.", // TODO: REQUIRE과 문구가 통일되어야 하는지 확인 필요
  VAILD_EMAIL: "Please enter a valid e-mail address.",
  EMAIL_EXISTS: "This Login ID is already taken.",
  PHONE_EXISTS: "This phone number is already registered.",
  PASSWORD_CONFIRM: "Password does not match.",
  PASSWORD_STEP_ONE: "Password length is 8-16 characters.",
  PASSWORD_STEP_TWO: "At least 2 types (A-Z, a-z, number and symbol)",
  INVALID_MEMO_200: "Memo cannot be longer than 200 characters.", //TODO: 임의로 추가한 에러 메세지로 확인 필요함
  PLATE_NUMBER_EXISTS: "Plate number already exists",
  VIN_EXISTS:
    "This truck is already registered, please check number of trucks.",
} as const;
