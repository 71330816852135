import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Navbar = styled.nav`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    width: 64px;
    height: 100%;
    border-right: 1px solid ${theme.color.gray_20};
    padding-top: ${theme.size.HEADER_HEIGHT};
    background-color: ${theme.color.secondary};
    z-index: ${theme.zIndex.NAV};
  `}
`;

export const link = (theme: Theme, content: string) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: "transparent";

  :hover {
    background-color: ${theme.color.white_00_04};
    :after {
      ${theme.font.medium_13};
      content: "${content}";
      position: fixed;
      left: ${`calc(${theme.size.NAV_WIDTH} + 6px)`};
      border-radius: 2px;
      padding: 4px;
      color: ${theme.color.white};
      background-color: ${theme.color.secondary};
    }
  }

  & > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${theme.color.gray_50};
    }
  }

  &.active {
    background-color: ${theme.color.white_10_10};

    & > svg > path {
      fill: ${theme.color.white};
    }
  }
`;
