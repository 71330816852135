import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  width: 764px;
`;

export const labelContentTableContent = css`
  & :last-of-type > div {
    width: 100%;
  }
`;

export const PlateNumberWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const textarea = css`
  height: 146px;
  padding: 12px 12px 38px 12px;
`;

export const AttachedFileWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  row-gap: 24px;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    color: ${theme.color.gray_70};
  `}
`;

export const viewDetailsButton = (isEdit: boolean) => (theme: Theme) =>
  css`
    ${theme.font.regular_13};
    display: flex;
    align-items: center;
    margin-left: ${isEdit ? "4px" : "auto"};
    color: ${theme.color.primary_40};

    & > svg {
      width: 12px;
      height: 12px;
      margin-right: 4px;

      & > path {
        fill: ${theme.color.primary_40};
      }
    }
  `;

export const GPSIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `;

export const FileWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 12px 0 0 auto;
`;
