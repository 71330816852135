import { useState } from "react";

const useShowPassword = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleShowPassword = () => setIsShowPassword(!isShowPassword);

  return {
    isShowPassword,
    handleShowPassword,
  };
};

export default useShowPassword;
