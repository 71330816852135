import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  InternalLink,
  MemberDetailRegisteredDriverAccountInfo,
  MemberDetailRegisteredDriverDefaultInfo,
  MemberDetailRegisteredDriverDrivingRecords,
  MemberDetailRegisteredDriverIDPassword,
  TableOfContent,
} from "components";
import { DeletePersonIcon } from "assets";
import { useMemberDetailRegisteredDriverDetail } from "./hooks";
import * as S from "./MemberDetailRegisteredDriverDetail.styled";

const MemberDetailRegisteredDriverDetail = () => {
  const { t } = useTranslation();

  const { beforeUrl, handleDriverDelete } =
    useMemberDetailRegisteredDriverDetail();

  return (
    // TODO: DetailLayout 사용하기
    <S.Layout>
      <TableOfContent
        pageName="Driver details"
        stickyBottom={
          <Button
            css={S.button}
            type="button"
            variant="text"
            icon={<DeletePersonIcon />}
            label="Delete the driver"
            handleClickBtn={handleDriverDelete}
          />
        }
        contents={[
          {
            heading: { headingLevel: "h2", text: "Default information" },
            content: <MemberDetailRegisteredDriverDefaultInfo />,
          },
          {
            heading: { headingLevel: "h2", text: "Account information" },
            content: <MemberDetailRegisteredDriverAccountInfo />,
          },
          {
            heading: { headingLevel: "h2", text: "ID and password" },
            content: <MemberDetailRegisteredDriverIDPassword />,
          },
          {
            heading: { headingLevel: "h2", text: "Driving records" },
            content: <MemberDetailRegisteredDriverDrivingRecords />,
          },
        ]}
      />
      <InternalLink
        css={S.previousPageLink}
        variant="outlineMedium"
        to={beforeUrl}
      >
        {t("Back to list")}
      </InternalLink>
    </S.Layout>
  );
};

export default MemberDetailRegisteredDriverDetail;
