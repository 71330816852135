/* eslint-disable no-useless-escape */
import dayjs, { Dayjs } from "dayjs";

type DateArgs = Dayjs | string | number | Date;

export const calcHourDiffICTWithUTC0 = (): number =>
  dayjs.utc().tz().utcOffset() / 60;

export const formatDate = (
  date: DateArgs,
  template: string = "DD/MM/YY, HH:mm",
) => dayjs(date).format(template);

// NOTE: ICT 시간으로 포매팅 된 string 필요 시 사용
export const formatICTDateTime = (
  date: DateArgs,
  template: string = "DD/MM/YYYY, HH:mm",
) => dayjs.utc(date).tz().format(template);

export const formatPeriodICTToUtc = (localDate: string) => {
  const [startDate, endDate] = localDate.split("~").map((date) => date.trim());

  const makeDate = (date: string, type: "startDate" | "endDate") => {
    const ICTDate = dayjs
      .utc(date, "DD/MM/YYYY")
      .subtract(calcHourDiffICTWithUTC0(), "h");

    return type === "startDate"
      ? ICTDate.toISOString()
      : ICTDate.add(1, "day").toISOString();
  };
  return [makeDate(startDate, "startDate"), makeDate(endDate, "endDate")];
};

export const addZeroFirstString = (num: number) => {
  if (num === undefined) return;

  return (num + "").length === 1 ? `0${num}` : num;
};
