import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const AllocationListWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > span:first-of-type {
      ${theme.font.bold_16};
      padding: 12px 20px;
    }
  `}
`;

export const AllocationContents = styled.li<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: 1px solid ${theme.color.primary_40};
    border-radius: 5px;
    padding: 16px 20px;
    background-color: ${isSelected && theme.color.primary_10};
  `}
`;

export const DriverProfileInfo = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_12};
    display: grid;
    grid-template-columns: 28px 1fr 58px;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 2px;
      padding: 3px 8px;
    }

    & > span {
      ${theme.font.medium_15};
    }
  `}
`;

export const DriverInfoWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    margin-bottom: 12px;
  `}
`;

export const DriverInfo = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};

    display: flex;
    align-items: center;
    column-gap: 8px;

    svg path {
      fill: ${theme.color.gray_40};
    }
  `}
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const AddressBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 12px 1fr 102px;
    column-gap: 8px;
    align-items: center;

    span {
      ${theme.font.regular_14};
    }
  `}
`;

export const checkIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    width: 16px;

    path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_20};
    }
  `;
