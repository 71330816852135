import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NAV } from "assets";
import * as S from "./Navbar.styled";

const Navbar = () => {
  const { t } = useTranslation();

  const handleNavLinkClick = (): void => {
    window.scrollTo({ top: 0 });
  };

  return (
    <S.Navbar>
      {NAV.map(({ path, Icon, content }, i) => (
        <NavLink
          css={(theme) => S.link(theme, t(content))}
          key={content}
          to={path}
          onClick={handleNavLinkClick}
        >
          <Icon />
        </NavLink>
      ))}
    </S.Navbar>
  );
};

export default Navbar;
