import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { QUOTATION_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS } from "assets";
import { mixin_scrollbar } from "style";

export const detailModal = css`
  width: 700px;
  padding: 32px 0;

  & > :nth-of-type(1) {
    padding: 0 32px;
  }

  & > :nth-of-type(2) {
    padding-left: 32px;
  }

  & > :nth-of-type(3) {
    padding-right: 32px;
  }
`;

export const ModalBodyWrapper = styled.div`
  ${mixin_scrollbar}
  height: 560px;
  overflow: auto;
`;

export const ModalBody = styled.div`
  width: 636px;
`;

export const labelContent = (theme: Theme) => css`
  grid-template-columns: 200px 1fr;
  margin-bottom: 12px;

  & > div > label {
    ${theme.font.regular_14};
    padding: 12px 0;
    color: ${theme.color.gray_70};
  }
`;

export const truckList = (theme: Theme) => css`
  margin-bottom: 12px;

  & > div > label {
    padding: 12px 0;
    color: ${theme.color.gray_70};
  }
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 12px 0;

    & > input {
      margin-bottom: 4px;
    }

    & > span:first-of-type {
      position: absolute;
      right: 12px;
      top: 21px;
      color: ${theme.color.gray_50};
    }
  `}
`;

export const multiInfoLabelContent = (theme: Theme) => css`
  ${labelContent(theme)};

  & > div:first-of-type {
    align-self: center;
  }
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px 0;
`;

export const subLabelContent = (theme: Theme) => css`
  grid-template-columns: 120px 1fr;

  & > div > label {
    color: ${theme.color.gray_70};
  }
`;

export const PaymentMethodText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
  `}
`;

export const table = (theme: Theme) => css`
  height: fit-content;

  & > thead {
    position: relative;
    top: 0;

    & > tr {
      & > th:not(:last-of-type) {
        border-right: 1px solid ${theme.color.gray_20};
      }
    }
  }

  tr {
    grid-template-columns: ${QUOTATION_DETAIL_TRUCK_LIST_TABLE_HEADER_COLUMNS};
    padding: 0;

    & > td:not(:last-of-type) {
      border-right: 1px solid ${theme.color.gray_20};
    }
  }
`;

export const TotalPriceText = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 0;
  `}
`;

export const PriceText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const memo = css`
  height: 144px;
  padding: 12px 20px 32px 20px;
  margin-bottom: 4px;
`;
export const radio = css`
  column-gap: 40px;
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    height: 1px;
    margin-bottom: 12px;
    border: 0;
    background-color: ${theme.color.gray_30};
  `}
`;
