import { css, Theme } from "@emotion/react";

export const link = (theme: Theme) => css`
  ${theme.font.medium_14};
  display: flex;
  width: fit-content;
  margin: 32px 0 0 auto;
  padding: 9px 19px;
  text-decoration: none;
  background-color: ${theme.color.white};
`;
