import React from "react";
import { useTranslation } from "react-i18next";
import { upperFirst } from "lodash-es";

import { DataSection } from "components";
import { addrFormatter } from "utils";
import { MONITORING_COMPANYS_TABLE_HEADER } from "assets";
import type { GetMonitoringCompanysServerModel, Languages } from "types";
import * as S from "./MonitoringTable.styled";

interface MonitoringTableProps {
  companys: GetMonitoringCompanysServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const MonitoringTable = ({
  companys,
  dataUpdatedAt,
  refetch,
}: MonitoringTableProps) => {
  const { t } = useTranslation();

  const formatTableData = (
    data: GetMonitoringCompanysServerModel["companyInfo"][number],
    key: keyof typeof MONITORING_COMPANYS_TABLE_HEADER,
  ) => {
    switch (key) {
      case "companyType": {
        const companyType = upperFirst(
          data[key] === "forwardercarrier" ? "forwarder" : data[key] ?? "-",
        );

        return t(companyType as Languages);
      }

      case "addr":
        return addrFormatter(data[key], data.addrDetail);

      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={companys.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        columnTable={MONITORING_COMPANYS_TABLE_HEADER}
      >
        {!!companys.companyInfo.length ? (
          companys.companyInfo.map((company) => (
            <DataSection.Table.SelectRowMovePage
              id={company.companyId}
              key={company.companyId}
              path={`${company.companyId}?companyType=${company.companyType}`}
            >
              {Object.keys(MONITORING_COMPANYS_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    company,
                    key as keyof typeof MONITORING_COMPANYS_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))
        ) : (
          <DataSection.Table.NoData />
        )}
      </DataSection.Table>
    </DataSection>
  );
};

export default MonitoringTable;
