import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useGetLinkManageDriverSearch } from "services";
import type {
  GetMemberDetailLinkedDriverSearchClientItem,
  TruckingCompanyType,
} from "types";

const useSearchDriver = (selectedTruckId: string | null) => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const [searchText, setSearchText] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState<
    GetMemberDetailLinkedDriverSearchClientItem[] | undefined
  >(undefined);

  const memberType = searchParams.get("memberType");
  const req = {
    query: {
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  };

  const { isLoading, data } = useGetLinkManageDriverSearch(
    req,
    !!selectedTruckId,
  );

  const handleSearch = (): void => {
    setFilteredDrivers(
      data?.filter((driver) =>
        driver.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()),
      ),
    );
  };

  const handleChangeSearchText = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = e.target.value;

    if (value.length > 100) return;
    setSearchText(value);
  };

  const handleResetSearch = (): void => {
    setSearchText("");
    setFilteredDrivers(data);
  };

  useEffect(() => {
    setFilteredDrivers(data);
  }, [data]);

  return {
    drivers: filteredDrivers,
    isLoading,
    searchText,
    handleSearch,
    handleChangeSearchText,
    handleResetSearch,
  };
};

export default useSearchDriver;
