import { ax } from "api/axios";
import { uploadBase64ImageToS3API } from "api";
import { findNonCommonStrings, findUrl } from "utils";
import type {
  GetInquiriesQueryModel,
  GetInquiriesServerModel,
  GetInquiryDetailServerModel,
  GetNoticeDetailServerModel,
  GetNoticesQueryModel,
  GetNoticesServerModel,
  PatchNoticePinnedQueryModel,
  PostInquiryReplyQueryModel,
  PostNoticeQueryModel,
  PutNoticeQueryModel,
  PostNoticeDeleteQueryModel,
} from "types";

export const getInquiriesAPI = async (req: GetInquiriesQueryModel) => {
  const { data } = await ax.get<GetInquiriesServerModel>("/support/inquiry", {
    params: req.query,
  });

  return data;
};

export const getInquiryDetailAPI = async (inquiryId: string) => {
  const { data } = await ax.get<GetInquiryDetailServerModel>(
    `/support/inquiry/${inquiryId}`,
  );

  return data;
};

export const postInquiryReplyAPI = async (req: PostInquiryReplyQueryModel) => {
  const { data } = await ax.post(
    `/support/inquiry/${req.inquiryId}/reply`,
    req.body,
  );

  return data;
};

export const getNoticesAPI = async (req: GetNoticesQueryModel) => {
  const { data } = await ax.get<GetNoticesServerModel>("/support/notice", {
    params: req.query,
  });

  return data;
};

export const getNoticeDetailAPI = async (noticeId: string) => {
  const { data } = await ax.get<GetNoticeDetailServerModel>(
    `/support/notice/${noticeId}`,
  );

  return data;
};

export const postNoticeAPI = async (req: PostNoticeQueryModel) => {
  const res = await uploadBase64ImageToS3API(req.body.description);

  const { data } = await ax.post(`/support/notice`, {
    ...req.body,
    description: res?.content,
    images: res?.fileNames,
  });

  return data;
};

export const putNoticeAPI = async (req: PutNoticeQueryModel) => {
  const res = await uploadBase64ImageToS3API(req.body.description);

  const nonCommonFileNames = findNonCommonStrings(
    findUrl(req.body.originalDescription as string) as string[],
    findUrl(res?.content as string) as string[],
  );

  const oldImages: string[] = [];

  if (!!nonCommonFileNames.length) {
    nonCommonFileNames.forEach((fileName) => {
      if (!res?.fileNames.includes(fileName)) {
        oldImages.push(fileName);
      }
    });
  }

  const { data } = await ax.put(`/support/notice/${req.noticeId}`, {
    shipper: req.body.shipper,
    forwarder: req.body.forwarder,
    carrier: req.body.carrier,
    driver: req.body.driver,
    subject: req.body.subject,
    description: res?.content,
    ...(!!res?.fileNames.length && { newImages: res?.fileNames }),
    ...(!!oldImages.length && { oldImages }),
  });

  return data;
};

export const postNoticeDeleteAPI = async (req: PostNoticeDeleteQueryModel) => {
  const { data } = await ax.post(`/support/notice/delete`, req.body);

  return data;
};

export const patchNoticePinAPI = async (req: PatchNoticePinnedQueryModel) => {
  const { data } = await ax.patch("/support/notice/pin", req.body);

  return data;
};

export const patchNoticeUnpinAPI = async (req: PatchNoticePinnedQueryModel) => {
  const { data } = await ax.patch("/support/notice/unpin", req.body);

  return data;
};
