import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { DetailModal, LabelContent, Table } from "components";
import { clientLanguage, truckTypeOptions } from "stores";
import {
  calculateTotalAmount,
  calculateVatAmount,
  deleteComma,
  findLookupTableLabel,
  formatPrice,
} from "utils";
import { PAYMENT_METHOD_RADIOS, TRUCK_LIST_TABLE_HEADER } from "assets";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationdetailsModal.styled";

interface QuotationDetailsModalProps {
  modalRef: React.RefObject<HTMLDialogElement>;
  quotationHistory: GetQuotationDetailServerModel["history"][number];
  companyInfo: Record<string, string | number>;
  paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"];
  trucks: { [key: string]: number };
}

const QuotationDetailsModal = ({
  modalRef,
  quotationHistory,
  companyInfo,
  paymentMethod,
  trucks,
}: QuotationDetailsModalProps) => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const truckOptions = useRecoilValue(truckTypeOptions);

  const payMethodLabel = findLookupTableLabel(
    PAYMENT_METHOD_RADIOS,
    paymentMethod,
  );

  const totalPrice = calculateTotalAmount(
    `${quotationHistory.price ?? 0}`,
    `${quotationHistory.vat}`,
  );

  const vatAmount = calculateVatAmount(
    `${quotationHistory.price ?? 0}`,
    `${quotationHistory.vat}`,
  );

  return (
    <DetailModal
      css={S.detailModal}
      ref={modalRef}
      title="Quotation details"
      posBtnLabel="Close"
    >
      <LabelContent
        css={S.labelContent}
        label="Trucking company"
        direction="horizontal"
      >
        <div> {companyInfo ? companyInfo.truckingCompany : "-"}</div>
      </LabelContent>
      <LabelContent
        css={S.labelContent}
        label="Payment method"
        direction="horizontal"
      >
        <div>{payMethodLabel ? t(payMethodLabel) : "-"}</div>
      </LabelContent>
      <LabelContent
        css={S.truckListLabelContent}
        label="Truck list"
        direction="vertical"
      >
        <Table css={S.table(!!trucks)} columnTable={TRUCK_LIST_TABLE_HEADER}>
          {trucks
            ? Object.entries(trucks).map(([key, truckCount]) => {
                const truckOption = truckOptions?.find(
                  ({ truckOptionId }) => truckOptionId === +key,
                );

                return (
                  <Table.Row key={key}>
                    <Table.Td>
                      {truckOption?.[language === "en" ? "typeEn" : "typeVi"]}
                    </Table.Td>
                    <Table.Td>
                      {
                        truckOption?.[
                          language === "en" ? "optionEn" : "optionVi"
                        ]
                      }
                    </Table.Td>
                    <Table.Td>{truckCount}</Table.Td>
                  </Table.Row>
                );
              })
            : null}
        </Table>
      </LabelContent>
      <LabelContent
        css={S.multiLabelContent}
        label="Total"
        direction="horizontal"
      >
        <div>
          <S.TotalPriceText>{formatPrice(totalPrice)}</S.TotalPriceText>
          <S.PriceInfo>
            <LabelContent
              css={S.subtotalLabelContent}
              label="Subtotal"
              direction="horizontal"
            >
              <S.PriceText>
                {formatPrice(+deleteComma(`${quotationHistory.price ?? 0}`))}
              </S.PriceText>
            </LabelContent>
            <LabelContent
              css={S.subtotalLabelContent}
              label="Tax"
              direction="horizontal"
            >
              <S.PriceText>{`(${quotationHistory.vat}%) ${formatPrice(
                vatAmount,
              )}`}</S.PriceText>
            </LabelContent>
          </S.PriceInfo>
        </div>
      </LabelContent>
      <S.Hr />
    </DetailModal>
  );
};

export default QuotationDetailsModal;
