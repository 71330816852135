import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Login = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
  padding-top: 100px;

  & > svg {
    margin-bottom: 40px;
  }
`;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 528px;
    border: 1px solid ${theme.color.gray_30};
    padding: 64px;
    background-color: ${theme.color.white};
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & > div:first-of-type > input {
    margin-bottom: 24px;
  }
`;

export const button = (theme: Theme) => css`
  ${theme.font.medium_16};
  height: 52px;
  margin: 20px 0;

  :disabled {
    cursor: not-allowed;
  }
`;

export const linkResetPassword = (theme: Theme) => css`
  ${theme.font.regular_14};
  width: fit-content;
  color: ${theme.color.gray_20};
`;
