import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { SCHEMA } from "utils";
import { GRADE_TYPE_RADIOS } from "assets";
import type { CreateMemberDetailRegisteredMemberForm } from "types";

const initForm = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  grade: GRADE_TYPE_RADIOS[0],
  email: "",
  password: "",
  confirmPassword: "",
};

const schema = yup.object({
  lastName: SCHEMA.REQUIRED_STRING,
  middleName: SCHEMA.OPTIONAL_STRING,
  firstName: SCHEMA.REQUIRED_STRING,
  phone: SCHEMA.REQUIRED_STRING,
  grade: SCHEMA.DROPDOWN_GRADE,
  email: SCHEMA.EMAIL,
  password: SCHEMA.PASSWORD,
  confirmPassword: SCHEMA.CONFIRM_PASSWORD,
});

const useMemberDetailRegisteredMemberCreate = () => {
  const formMethod = useForm<CreateMemberDetailRegisteredMemberForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  return { formMethod };
};

export default useMemberDetailRegisteredMemberCreate;
