import React from "react";
import { FormProvider } from "react-hook-form";

import {
  MemberBusinessInfoForm,
  MemberBusinessLicenseForm,
  MemberFormBtns,
  MemberGpsApi,
  MemberUserInfoForm,
  PageLayout,
} from "components";
import { EDIT_MEMBER_LAYOUT } from "assets";
import { useEditMember } from "./hooks";
import * as S from "./MemberEdit.styled";

const MemberEdit = () => {
  const { editMember, formMethod } = useEditMember();

  return (
    <PageLayout css={S.layout} layoutStaticData={EDIT_MEMBER_LAYOUT}>
      <FormProvider {...formMethod}>
        <form onSubmit={formMethod.handleSubmit(editMember)}>
          <MemberBusinessInfoForm />
          <MemberBusinessLicenseForm />
          <MemberUserInfoForm type="edit" />
          {(formMethod.watch("companyType") === "CARRIER" ||
            formMethod.watch("companyType") === "FORWARDERCARRIER") && (
            <MemberGpsApi />
          )}
          <MemberFormBtns isLoading={false} />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default MemberEdit;
