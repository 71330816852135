import React from "react";

import { useFilterParams } from "hooks";
import { useGetLinkings } from "services";
import { GetLinkingsQueryModel } from "types";
import { ShipperPagination, ShipperTable } from "./containers";
import { LinkingFilter } from "../container";

const ShipperLinking = () => {
  const { filters } = useFilterParams<GetLinkingsQueryModel["query"]>([
    "page",
    "type",
    "company",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetLinkings({
    query: { ...filters, type: "shipper" },
  });

  return (
    <>
      <LinkingFilter />
      <ShipperTable
        linkings={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <ShipperPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default ShipperLinking;
