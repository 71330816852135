import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  DownloadBtn,
  FormInput,
  FormFileInput,
  FormRadioBtn,
  LabelContent,
  LabelContentTable,
  Textarea,
  LinkingDriver,
  ErrorMsg,
} from "components";
import { useMemberType, useModal, useToast, useToggle } from "hooks";
import {
  useDownloadFile,
  useGetTruckGarage,
  useGetTruckGpss,
  usePutTruckDefaultInfomation,
} from "services";
import { makeS3PresignedUrlFileUuids } from "api";
import { HOOKFORM_ERR, SearchListIcon, SuccessIcon } from "assets";
import { TOAST_MSG, VALID_MSG } from "constants/index";
import {
  formatICTDateTime,
  formatPlateNumber,
  getTruckGarageData,
  selectDropdownForm,
  upperCaseToUpperFirst,
} from "utils";
import type {
  AutoCompleteDropdown,
  GetMemberDetailTruckDetailClientModel,
  Languages,
  PropertyStatus,
  PutTruckDefaultInformationQueryModel,
  S3FileUploadType,
} from "types";
import PlateNumberModal from "../plateNumberModal/PlateNumberModal";
import * as S from "./MemberDetailTruckDetailDefautInfo.styled";

export interface TruckDefaultInformationForm {
  plateNumber: string;
  status: PropertyStatus;
  garage: { key: string; label: Languages };
  linked: AutoCompleteDropdown[];
  memo: string | null;
  registration: FileList | string | null;
  truckPhoto: FileList | string | null;
}

interface MemberDetailTruckDetailDefautInfoProps {
  truck: GetMemberDetailTruckDetailClientModel;
}

const TRUCK_DEFAULT_INFORMATION_FORM_INIT_STATE = {
  plateNumber: "",
  status: "UNAVAILABLE" as const,
  garage: { key: "", label: "" as Languages },
  linked: [],
  memo: "",
  registration: null,
  truckPhoto: null,
};

const MemberDetailTruckDetailDefautInfo = ({
  truck,
}: MemberDetailTruckDetailDefautInfoProps) => {
  const { t } = useTranslation();
  const { memberId, truckId } = useParams();

  const [isEdit, handleIsEdit] = useToggle();
  const { addToast } = useToast();
  const { memberType } = useMemberType();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const formMethod = useForm<TruckDefaultInformationForm>({
    mode: "onTouched",
    defaultValues: TRUCK_DEFAULT_INFORMATION_FORM_INIT_STATE,
  });

  const query = { companyId: memberId!, companyType: memberType };

  const { data: truckGpss } = useGetTruckGpss({ query });
  const { data: truckGarages } = useGetTruckGarage({ query });
  const { mutate: downloadTruckPhotoMutate } = useDownloadFile();
  const { mutate: downloadVehicleRegistrationCertificateMutate } =
    useDownloadFile();
  const {
    mutate: updateTruckDefaultInformationMutate,
    isLoading: isUpdateTruckDefaultInformation,
  } = usePutTruckDefaultInfomation();

  const garage = getTruckGarageData(truckGarages || []).find(
    ({ label }) => label === truck.parkedGarage,
  ) || { key: "", label: "" as Languages };

  const handleEditStatePlateListOpen = (): void => {
    !!truckGpss
      ? handleModalOpen(
          <Controller
            name="plateNumber"
            control={formMethod.control}
            render={({ field: { onChange } }) => {
              const handleRowClick = (plate: string) => (): void => {
                onChange(plate);
                handleModalClose();
              };

              const handleCancelClick = (): void => {
                handleModalClose();
              };

              return (
                <PlateNumberModal
                  ref={modalRef}
                  type="select"
                  plateNumbers={truckGpss}
                  handleRowClick={handleRowClick}
                  handleCancelClick={handleCancelClick}
                />
              );
            }}
          />,
        )()
      : addToast(TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  const handleNonEditStatePlateListOpen = (): void => {
    !!truckGpss
      ? handleModalOpen(
          <PlateNumberModal
            ref={modalRef}
            type="copy"
            plateNumbers={truckGpss || []}
            handleCancelClick={handleModalClose}
          />,
        )()
      : addToast(TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  const handleFileDelete =
    (name: "registration" | "truckPhoto") => (): void => {
      formMethod.resetField(name);

      if (name === "registration") {
        formMethod.setError(name, { message: VALID_MSG.REQUIRED });
      }
    };

  const handleCancelClick = (): void => {
    formMethod.reset(
      {
        plateNumber: truck.plateNumber,
        status: truck.category,
        garage,
        linked: truck.linked,
        memo: truck.memo,
        truckPhoto: truck.truckPhotoS3Key,
        registration: truck.registrationS3Key,
      },
      { keepDefaultValues: true },
    );
    handleIsEdit();
  };

  const handleEditClick = formMethod.handleSubmit(
    async (data): Promise<void> => {
      const registration = data.registration;
      const truckPhoto = data.truckPhoto;

      const files: S3FileUploadType = {
        ...(registration !== null &&
          typeof registration !== "string" && {
            registration: { file: registration[0], path: "license" },
          }),
        ...(truckPhoto !== null &&
          typeof truckPhoto !== "string" && {
            truckPhoto: { file: truckPhoto[0], path: "profile" },
          }),
      };

      const fileUuids = await makeS3PresignedUrlFileUuids(files);

      const changeCheckKeys = [
        "garage",
        "memo",
        "registration",
        "truckPhoto",
      ] as const;

      const body = changeCheckKeys.reduce((acc, key) => {
        switch (key) {
          case "registration":
          case "truckPhoto":
            if (data[key] === null) {
              acc[key] = null;
            } else if (fileUuids[key]) {
              acc[key] = fileUuids[key];
            }
            break;

          case "memo":
            if (data.memo !== truck.memo) {
              if (!data.memo) {
                acc.memo = null;
              } else {
                acc.memo = data.memo;
              }
            }
            break;

          case "garage":
            if (data.garage.key !== garage.key) {
              acc.garageId = data.garage.key;
            }
            break;

          default:
            break;
        }

        return acc;
      }, {} as PutTruckDefaultInformationQueryModel["body"]);

      if (!Object.keys(body).length) {
        handleIsEdit();
        return;
      } else {
        updateTruckDefaultInformationMutate(
          {
            truckId: truckId!,
            query,
            body: {
              ...body,
              registNumber: data.plateNumber,
              status: data.status,
            },
          },
          {
            onSuccess: () => {
              addToast(TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
              handleIsEdit();
            },
            onError: (error) => {
              if (error.response?.data.response === "PLATE_NUMBER_EXISTS") {
                formMethod.setError("plateNumber", {
                  type: "validate",
                  message: HOOKFORM_ERR.PLATE_NUMBER_EXISTS,
                });
              } else if (error.response?.data.response === "VIN_EXISTS") {
                formMethod.setError("plateNumber", {
                  type: "validate",
                  message: HOOKFORM_ERR.VIN_EXISTS,
                });
              } else if (error.response?.data.response === "GARAGE_FULL") {
                addToast(TOAST_MSG.WARNING.GARAGE_FULL);
              }
            },
          },
        );
      }
    },
  );

  useEffect(() => {
    formMethod.reset(
      {
        plateNumber: truck.plateNumber,
        status: truck.category,
        garage,
        linked: truck.linked,
        memo: truck.memo,
        truckPhoto: truck.truckPhotoS3Key,
        registration: truck.registrationS3Key,
      },
      { keepDefaultValues: true },
    );
  }, [truck, truckGarages]);

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Plate number"
            isRequired={isEdit}
          >
            {isEdit ? (
              <S.PlateNumberWrapper>
                <FormInput
                  placeholder="Search by plate number"
                  hasError={!!formMethod.formState.errors.plateNumber?.message}
                  value={formMethod.watch("plateNumber")}
                  register={formMethod.register("plateNumber", {
                    required: VALID_MSG.REQUIRED,
                    setValueAs: formatPlateNumber,
                  })}
                />
                <S.Desc>
                  {`- ${t("Do you want to check plate number list?")}`}
                  <Button
                    css={S.viewDetailsButton(isEdit)}
                    variant="text"
                    label="View details"
                    handleClickBtn={handleEditStatePlateListOpen}
                  />
                </S.Desc>
                {formMethod.formState.errors.plateNumber?.message && (
                  <ErrorMsg
                    errorMsg={
                      formMethod.formState.errors.plateNumber
                        ?.message as Languages
                    }
                  />
                )}
              </S.PlateNumberWrapper>
            ) : (
              <>
                {truck.plateNumber}
                <Button
                  css={S.viewDetailsButton(isEdit)}
                  variant="text"
                  label="GPS company registered vehicles"
                  icon={<SearchListIcon />}
                  handleClickBtn={handleNonEditStatePlateListOpen}
                />
              </>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label={"GPS" as Languages}>
            <SuccessIcon css={S.GPSIcon(!!truck.gpsAvailable)} />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            {isEdit ? (
              <FormRadioBtn
                radioList={[
                  { key: "AVAILABLE", label: "Available" },
                  { key: "UNAVAILABLE", label: "Unavailable" },
                ]}
                currentValue={formMethod.watch("status")}
                register={formMethod.register("status")}
              />
            ) : (
              t(upperCaseToUpperFirst(truck.category) as Languages)
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(truck.created)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Parked garage"
          >
            {isEdit ? (
              <Controller
                control={formMethod.control}
                name="garage"
                render={({ field: { value, onChange } }) => {
                  const formatGarages = getTruckGarageData(truckGarages || []);

                  return (
                    <Dropdown
                      options={formatGarages}
                      selectedOption={value}
                      handleSelect={selectDropdownForm(formatGarages, onChange)}
                    />
                  );
                }}
              />
            ) : (
              truck.parkedGarage
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Linking">
            <LinkingDriver
              isEditDisabled={isEdit}
              linkedDrivers={truck.linked ?? []}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Memo">
            {isEdit ? (
              <Textarea
                css={S.textarea}
                id="memo"
                placeholder="Write anything about the truck."
                value={formMethod.watch("memo") || ""}
                maxLength={200}
                register={formMethod.register("memo")}
              />
            ) : (
              truck.memo || "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Attached file">
            {isEdit ? (
              <S.AttachedFileWrapper>
                <LabelContent
                  label="Vehicle registration certificate"
                  direction="vertical"
                  isRequired
                >
                  <FormFileInput
                    placeholder={"Upload vehicle registration certificate"}
                    file={formMethod.watch("registration")}
                    hasErr={
                      !!formMethod.formState.errors?.registration?.message
                    }
                    register={formMethod.register("registration", {
                      required: VALID_MSG.REQUIRED,
                      onChange: () => formMethod.clearErrors("registration"),
                    })}
                    handleDeleteFile={handleFileDelete("registration")}
                  />
                  <S.Desc>
                    {`- ${t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}`}
                  </S.Desc>
                </LabelContent>
                <LabelContent label="Truck photo" direction="vertical">
                  <FormFileInput
                    placeholder="Upload truck photo"
                    file={formMethod.watch("truckPhoto")}
                    hasErr={false}
                    register={formMethod.register("truckPhoto")}
                    handleDeleteFile={handleFileDelete("truckPhoto")}
                  />
                  <S.Desc>
                    {`- ${t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}`}
                  </S.Desc>
                </LabelContent>
              </S.AttachedFileWrapper>
            ) : (
              <S.FileWrapper>
                {truck.truckPhotoS3Key || truck.registrationS3Key ? (
                  <>
                    {truck.truckPhotoS3Key && (
                      <DownloadBtn
                        downloadFileNamePrefix="truck"
                        fileName="Truck photo"
                        fileKey={truck.truckPhotoS3Key}
                        key="truckPhoto"
                        downloadFileMutate={downloadTruckPhotoMutate}
                      />
                    )}
                    {truck.registrationS3Key && (
                      <DownloadBtn
                        downloadFileNamePrefix="registration"
                        fileName="Vehicle registration certificate"
                        // TODO: s3 prefix로 license 잘 오는지 확인 필요
                        fileKey={truck.registrationS3Key}
                        key="registration"
                        downloadFileMutate={
                          downloadVehicleRegistrationCertificateMutate
                        }
                      />
                    )}
                  </>
                ) : (
                  "-"
                )}
              </S.FileWrapper>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.ButtonWrapper>
        {isEdit ? (
          <>
            <Button
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleCancelClick}
            />
            <Button
              variant="primaryMedium"
              label="Update"
              isLoading={isUpdateTruckDefaultInformation}
              handleClickBtn={handleEditClick}
            />
          </>
        ) : (
          <Button
            variant="outlineMedium"
            label="Edit"
            handleClickBtn={handleIsEdit}
          />
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default MemberDetailTruckDetailDefautInfo;
