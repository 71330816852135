import React, { useRef, useCallback } from "react";
import { useRecoilState } from "recoil";

import { modalCompoState } from "stores";
import { useKeyTrap } from "hooks";

const useModal = () => {
  const modalRef = useRef<HTMLDialogElement>(null);

  const [modalCompo, setModalCompo] = useRecoilState(modalCompoState);

  const openModalComponent = useCallback((compo: React.ReactNode) => {
    document.body.style.cssText = "overflow: hidden";
    const newCompo = [...modalCompo, compo];
    setModalCompo(newCompo);
  }, []);

  const handleModalOpen = useCallback(
    (compo: React.ReactNode, cbFunction?: () => void) =>
      (e?: React.MouseEvent) => {
        e?.stopPropagation();

        openModalComponent(compo);
        cbFunction && cbFunction();
      },
    [],
  );

  const handleModalClose = () => {
    const newModalList = [...modalCompo];
    newModalList.pop();
    setModalCompo(newModalList);

    if (newModalList.length === 0) {
      document.body.style.cssText = "overflow: overlay";
    }
  };

  const handleModalAllClose = useCallback(() => {
    setModalCompo([]);

    document.body.style.cssText = "overflow: overlay";
  }, []);

  useKeyTrap(modalRef, handleModalClose);

  return {
    modalCompo,
    modalRef,
    openModalComponent,
    handleModalOpen,
    handleModalClose,
    handleModalAllClose,
  };
};

export default useModal;
