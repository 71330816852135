import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";

import { useGoogleMap } from "contexts";
import {
  useAlignCenter,
  useLocationMarker,
  useMonitoringTruckMarker,
} from "hooks";
import type { GetShipperMonitoringOrderDetailClientModel } from "types";
import {
  useAllocationMarkerEventListener,
  useOrderSelect,
} from "../../../hooks";

const useOrderAllocationInfo = (
  orderDetail: GetShipperMonitoringOrderDetailClientModel,
  truckRef: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>,
  orderId: string,
) => {
  const [selectedOrderCard, setSelectedOrderCard] = useState(-1);

  const { googleMap } = useGoogleMap();
  const { truckMarkers } = useMonitoringTruckMarker(orderDetail.allocationList);

  const monitoringAllocations = orderDetail.allocationList.map(
    (allocation, i) => ({ ...allocation, ...truckMarkers[i] }),
  );

  const { focusTruckMarker } = useOrderSelect(monitoringAllocations, truckRef);

  const { displayLocationMarker, resetLocationMarker } =
    useLocationMarker("Allocation");

  const handleOrderCardClick = (index: number): void => {
    setSelectedOrderCard(index);
  };

  const handleAllocationCardClick = (index: number) => (): void => {
    setSelectedOrderCard(index);

    if (selectedOrderCard === index) setSelectedOrderCard(-1);

    focusTruckMarker(
      orderDetail.allocationList[index]?.plateNumber,
      selectedOrderCard === index,
    );
  };

  useAllocationMarkerEventListener(
    monitoringAllocations,
    selectedOrderCard,
    focusTruckMarker,
    handleOrderCardClick,
  );

  useAlignCenter(
    !orderDetail.allocationList[selectedOrderCard]?.gpsAvailable
      ? orderDetail.locations
      : orderDetail.coords,
  );

  useEffect(() => {
    if (!orderId) {
      resetLocationMarker();
    }

    if (!orderDetail.locations || !googleMap || isEmpty(orderDetail.locations))
      return;

    displayLocationMarker(orderDetail.locations);
  }, [orderDetail.locations, googleMap]);

  return { selectedOrderCard, handleAllocationCardClick };
};

export default useOrderAllocationInfo;
