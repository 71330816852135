import { includes } from "lodash-es";

import type {
  CompanyInfo,
  GetCommonPlayerServerModel,
  StringKeyOf,
} from "types";
import { formatPeriodICTToUtc } from "./formatter/time";

export const getFilterParams = <T>(
  searchParams: URL["searchParams"],
  params: StringKeyOf<T>[],
  init?: Partial<T>,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    if (!includes(params, query)) return acc;

    const queryValue = searchParams.getAll(query);
    const value = queryValue.join(",");

    if (query === "date") {
      const [startDate, endDate] = formatPeriodICTToUtc(value);

      return { ...acc, startDate, endDate };
    }

    return {
      ...acc,
      [query]: value,
    };
  }, getDefaultQuery(init) as unknown as T);
};

const getDefaultQuery = <T>(init?: T) => ({
  page: "1",
  pageSize: "20",
  ...(init ?? {}),
});

export const getAllQuery = (searchParams: URL["searchParams"]) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce(
    (acc, query) => ({
      ...acc,
      [query]: searchParams.getAll(query),
    }),
    {} as { [key: string]: string[] },
  );
};

export const selectCompanyInfo = (
  companies: GetCommonPlayerServerModel[],
  companyId: string,
): CompanyInfo[] => {
  const company = companies.filter(({ id }) => companyId === id)[0];

  return company ? [{ id: company.id, name: company.name }] : [];
};
