export const zIndex = {
  TABLE_SELECT_ROW: 5,
  CHECKBOX: 10,
  MAIN: 10,
  SIDE_PANEL: 52,
  ROW_DETAIL_BUTTON: 12,
  STICKY: 50,
  CALENDAR: 51,
  DROPDOWN: 51,
  NAV: 100,
  HEADER: 1000,
  MODAL: 10000,
  TOAST: 10000,
  FOLDBUTTON: 100000,
  DIALOG: 100001,
  POLYLINE: 10000,
  GOOGLE_MAP_DEFAULT_TRUCK_MARKER: 10002,
  GOOGLE_MAP_SELECTED_TRUCK_MARKER: 10003,
  GOOGLE_MAP_GARAGE_ICON_MARKER: 10009, // NOTE: 임시값입니다. 알맞게 추후 수정이 필요합니다.
  GOOGLE_MAP_DROPOFF_ICON_MARKER: 10010,
  GOOGLE_MAP_PICKUP_ICON_MARKER: 10011,
} as const;

export type ZIndexType = typeof zIndex;
