import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface ForwarderPaginationProps {
  pageInfo?: PageInfo;
}

const ForwarderPagination = ({ pageInfo }: ForwarderPaginationProps) => {
  if (!pageInfo) return null;

  return <QueryPagination pageInfo={pageInfo} />;
};

export default ForwarderPagination;
