import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const AllocationInfoWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 20px;
    background-color: ${theme.color.gray_00};

    & > h3 {
      ${theme.font.semibold_16};
      margin-bottom: 12px;
    }
  `}
`;

export const OrderCardWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-left: 12px;
`;

export const AllocationCard = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_35};
    border-radius: 5px;
    padding: 16px 20px;
  `}
`;

export const OrderCardTitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding-bottom: 12px;
  `}
`;

export const OrderCardTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    margin: 0;

    :hover {
      text-decoration: underline;
    }
  `}
`;

export const moreBtn = (theme: Theme) => css`
  position: static;

  & > button {
    ${theme.font.medium_12};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 1px 5px;
    color: ${theme.color.gray_70};
  }

  & > dialog {
    left: 84px;

    & > div {
      box-shadow: ${theme.boxShadow.shadow_dropdown};
    }
  }

  :hover {
    background-color: ${theme.color.gray_10};
  }
`;
