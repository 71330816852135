import styled from "@emotion/styled";
import { css } from "@emotion/react";

// import { mixin_scrollbar } from "styles"; TODO: style 정의 필요

export const AddrAutoComplete = styled.div`
  position: relative;
  width: 100%;
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const AutocompleteInput = styled.input<{ hasError: boolean }>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_14};
    flex: 1;
    height: 40px;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
    border-radius: 2px;
    padding: 0 39px 0 11px;
    color: ${theme.color.black};

    ::placeholder {
      color: ${theme.color.gray_50};
    }

    &:focus {
      border: 1px solid ${theme.color.primary_40};
    }

    &:focus-within + button svg path {
      fill: ${theme.color.primary_40};
    }
  `}
`;

export const SearchButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);

    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;

export const OpenMapButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    background-color: ${theme.color.gray_00};

    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;

export const location = css`
  position: absolute;
  right: 12px;
`;

export const DropDownWrapper = styled.ul<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    position: absolute;
    top: 100%;
    display: ${isActive ? "block" : "none"};
    width: 100%;
    max-height: 200px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_30};
    border-bottom: 0;
    background-color: ${theme.color.white};
    z-index: 10;
  `}
`;

export const DropDownItem = styled.li`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    padding: 8px 12px;
    background-color: inherit;
    cursor: pointer;
    color: ${theme.color.gray_80};

    :hover {
      color: ${theme.color.primary_40};
    }

    &:last-of-type {
      border-bottom: 1px solid ${theme.color.gray_30};
    }
  `}
`;
