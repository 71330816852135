import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { LabelContentTable, FormInput, Dropdown } from "components";
import { GPS_API_DROPDOWN_OPTIONS } from "assets";
import type { MemberForm } from "types";
import * as S from "./MemberGpsApi.styled";

const MemberGpsApi = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<MemberForm>();

  return (
    <>
      <LabelContentTable variant="empty" subject="GPS API">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Company">
            <Controller
              name="gpsType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  css={S.dropdown}
                  options={GPS_API_DROPDOWN_OPTIONS}
                  selectedOption={value}
                  placeholder="Select company"
                  hasError={!!errors.gpsType?.message}
                  handleSelect={(gpsType) => {
                    const selectIndex = GPS_API_DROPDOWN_OPTIONS.findIndex(
                      (item) => item.key === gpsType,
                    );

                    onChange(GPS_API_DROPDOWN_OPTIONS[selectIndex]);
                  }}
                />
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="API ID">
            <FormInput
              css={S.input}
              placeholder="Enter GPS API ID"
              register={register("gpsCustomerCode")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="API KEY">
            <FormInput
              css={S.input}
              placeholder="Enter GPS API KEY"
              register={register("gpsApiKey")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.Division />
    </>
  );
};

export default MemberGpsApi;
