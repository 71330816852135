import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  width: 764px;
`;

export const labelContentTableContent = css`
  & :last-of-type > div {
    width: 100%;
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const UnitSuffixWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 4px;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;

export const year = css`
  & :last-of-type > div {
    width: 250px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 12px 0 0 auto;
`;
