import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const EditorWrapper = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    width: 100%;
    border: ${hasError && `1px solid ${theme.color.red_30}`};

    .se-dialog-tabs {
      & > button:nth-of-type(2) {
        display: none;
      }
    }

    .se-btn-group {
      & > button:nth-of-type(1),
      & > button:nth-of-type(2),
      & > button:nth-of-type(3) {
        display: none;
      }
    }

    .se-dialog-footer {
      & > div:first-of-type {
        display: none;
      }
    }
  `}
`;
