import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const detailModal = css`
  padding: 32px;

  & > div:first-of-type {
    margin-bottom: 18px;
  }

  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 28px;
  }

  & > div:last-of-type {
    padding: 0;
  }
`;

export const Form = styled.form`
  width: 414px;
  height: fit-content;
`;

export const ImgWrapper = styled.div`
  width: 344px;
  height: 100%;

  & > div {
    width: 100%;
  }
`;

export const labelContent = css`
  padding: 12px 0;
`;

export const radio = css`
  column-gap: 40px;
  padding: 10px 0;
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin-bottom: 32px;
    border: 0;
    border-top: 1px solid ${theme.color.gray_20};
  `}
`;
