import React from "react";

import { CarrierPagination, CarrierTable } from "./containers";
import { MemberFilter } from "../container";

const Carrier = () => {
  return (
    <>
      <MemberFilter />
      <CarrierTable />
      <CarrierPagination />
    </>
  );
};

export default Carrier;
