import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useCurrentQueryParams, useToast } from "hooks";
import { useGetMemberDetail, usePutMember, usePutMemberDummy } from "services";
import { detectValueChange, makeMemberS3PresignedUrlFileUuids } from "utils";
import { COMPANY_LOOKUP_TABLE, GPS_API_DROPDOWN_OPTIONS } from "assets";
import { TOAST_MSG, PATH } from "constants/index";
import type {
  APIError,
  MemberForm,
  PutMemberDummyQueryModel,
  PutMemberQueryModel,
} from "types";

const useEditMember = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();

  const { mutate: editMemberMutate, isLoading: isLoadingEditMember } =
    usePutMember();
  const { mutate: editDummyMemberMutate, isLoading: isLoadingEditDummyMember } =
    usePutMemberDummy();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];
  const { addToast } = useToast();

  const companyType = memberType
    ? COMPANY_LOOKUP_TABLE[memberType as keyof typeof COMPANY_LOOKUP_TABLE]
    : "SHIPPER";

  const { data: memberDetail } = useGetMemberDetail({
    companyId: memberId!,
    query: { companyType },
  });

  const formMethod = useForm<MemberForm>({
    mode: "onTouched",
    defaultValues: {
      companyType,
      registered: "YES",
      plan: "STANDARD",
      companyName: "",
      addr: {
        address: "",
        addressDetail: "",
        buildingName: "",
        city: "",
        coord: {
          lng: 0,
          lat: 0,
        },
      },
      taxcode: "",
      businessEmail: "",
      businessTel: "",
      representative: "",
      license1: null,
      license2: null,
      transportLicense1: null,
      transportLicense2: null,
      managerFirstName: "",
      managerMiddleName: "",
      managerLastName: "",
      managerPhone: "",
      managerEmail: "",
      managerPassword: "",
      managerPasswordConfirm: "",
      gpsType: { key: "", label: "" },
      gpsCustomerCode: "",
      gpsApiKey: "",
    },
  });

  const editMember = async (data: MemberForm): Promise<void> => {
    const gpsApiCheck =
      data.companyType === "CARRIER" || data.companyType === "FORWARDERCARRIER";

    const originMemberDetail = {
      ...memberDetail,
      lat: memberDetail?.lat && +memberDetail.lat.replace(/\.?0+$/, ""),
      lng: memberDetail?.lng && +memberDetail.lng.replace(/\.?0+$/, ""),
    };

    const { license1, license2, transportLicense1, transportLicense2 } =
      await makeMemberS3PresignedUrlFileUuids(data.companyType, {
        license1: data.license1,
        license2: data.license2,
        transportLicense1: data.transportLicense1,
        transportLicense2: data.transportLicense2,
      });

    const req: PutMemberQueryModel = {
      companyId: memberId!,
      query: { companyType },
      body: {
        name: data.companyName,
        addr: data.addr.address,
        addrDetail: data.addr.addressDetail ?? "",
        taxcode: data.taxcode,
        email: data.businessEmail,
        phone: data.businessTel,
        representative: data.representative,
        license1,
        license2,
        managerFirstName: data.managerFirstName,
        managerMiddleName: data.managerMiddleName ?? "",
        managerLastName: data.managerLastName,
        managerPhone: data.managerPhone,
        managerEmail: data.managerEmail,
        ...(gpsApiCheck && { gpsType: data.gpsType.key ?? "" }),
        ...(gpsApiCheck && { gpsCustomerCode: data.gpsCustomerCode ?? "" }),
        ...(gpsApiCheck && { gpsApiKey: data.gpsApiKey ?? "" }),
      },
    };

    const dummyReq: PutMemberDummyQueryModel = {
      companyId: memberId!,
      query: { companyType },
      body: {
        name: data.companyName,
        managerEmail: data.managerEmail,
        addr: data.addr.address ?? "",
        addrDetail: data.addr.addressDetail ?? "",
        taxcode: data.taxcode ?? "",
        email: data.businessEmail ?? "",
        phone: data.businessTel ?? "",
        representative: data.representative ?? "",
        // NOTE: 키 안보내면 -> 유지, 빈 문자열 -> 삭제, 수정은 생성과 동일
        ...(data.license1 === null && { license1: "" }), // 삭제
        ...(data.license1 instanceof FileList && { license1 }), // 수정
        ...(data.license2 === null && { license2: "" }), // 삭제
        ...(data.license2 instanceof FileList && { license2 }), // 수정
        managerFirstName: data.managerFirstName,
        managerMiddleName: data.managerMiddleName ?? "",
        managerLastName: data.managerLastName,
        managerPhone: data.managerPhone,
        ...(gpsApiCheck && { gpsType: data.gpsType.key ?? "" }),
        ...(gpsApiCheck && { gpsCustomerCode: data.gpsCustomerCode ?? "" }),
        ...(gpsApiCheck && { gpsApiKey: data.gpsApiKey ?? "" }),
      },
    };

    if (
      data.companyType === "CARRIER" ||
      data.companyType === "FORWARDERCARRIER"
    ) {
      const tempReq = data.registered === "YES" ? req : dummyReq;

      tempReq.body.lng = data.addr.coord.lng;
      tempReq.body.lat = data.addr.coord.lat;

      if (data.transportLicense1 === null) {
        tempReq.body.transportLicense1 = "";
      }
      if (data.transportLicense1 instanceof FileList) {
        tempReq.body.transportLicense1 = transportLicense1;
      }

      if (data.transportLicense2 === null) {
        tempReq.body.transportLicense2 = "";
      }
      if (data.transportLicense2 instanceof FileList) {
        tempReq.body.transportLicense2 = transportLicense2;
      }
    }

    const onSuccess = (): void => {
      addToast(TOAST_MSG.SUCCESS.DEFAULT_INFORMATION_EDIT_DONE);
      navigate(
        `${PATH.MEMBER}/${memberId}/?memberType=${memberType || "shipper"}`,
      );
    };

    const onError = (err: APIError): void => {
      switch (err.response?.data.response) {
        case "TAXCODE_EXISTS":
          addToast(TOAST_MSG.WARNING.TAXCODE_EXISTS);
          return;

        case "STAFF_EMAIL_EXISTS":
          addToast(TOAST_MSG.WARNING.STAFF_EMAIL_EXISTS);
          return;

        case "COMPANY_EMAIL_EXISTS":
          addToast(TOAST_MSG.WARNING.COMPANY_EMAIL_EXISTS);
          return;

        case "COMPANY_PHONE_EXISTS":
          addToast(TOAST_MSG.WARNING.COMPANY_PHONE_EXISTS);
          return;

        case "STAFF_PHONE_EXISTS":
          addToast(TOAST_MSG.WARNING.STAFF_PHONE_EXISTS);
          return;

        default:
          alert(err.response?.data.response);
          return;
      }
    };

    data.registered === "YES"
      ? editMemberMutate(
          {
            ...req,
            body: {
              ...detectValueChange(originMemberDetail ?? {}, req.body),
              name: data.companyName, // NOTE: 서버에서 name을 제외한 값이 선택값으로 변경
            },
          },
          { onSuccess, onError },
        )
      : editDummyMemberMutate(
          {
            ...dummyReq,
            body: {
              ...detectValueChange(originMemberDetail ?? {}, dummyReq.body),
              name: data.companyName, // NOTE: 서버에서 name을 제외한 값이 선택값으로 변경
            },
          },
          { onSuccess, onError },
        );
  };

  useEffect(() => {
    if (!memberDetail) return;

    formMethod.reset(
      {
        companyType: memberDetail.type,
        registered: memberDetail.registered,
        plan: memberDetail.plan,
        companyName: memberDetail.name,
        addr: {
          address: memberDetail.addr,
          addressDetail: memberDetail.addrDetail,
          buildingName: "",
          city: "",
          coord: {
            lng: memberDetail.lng ? +memberDetail.lng : 0,
            lat: memberDetail.lat ? +memberDetail.lat : 0,
          },
        },
        taxcode: memberDetail.taxcode,
        businessEmail: memberDetail.email,
        businessTel: memberDetail.phone,
        representative: memberDetail.representative,
        position: memberDetail.position,
        license1: memberDetail.license1,
        license2: memberDetail.license2,
        transportLicense1: memberDetail.transportLicense1 ?? null,
        transportLicense2: memberDetail.transportLicense2 ?? null,
        managerFirstName: memberDetail.managerFirstName,
        managerMiddleName: memberDetail.managerMiddleName,
        managerLastName: memberDetail.managerLastName,
        managerPhone: memberDetail.managerPhone,
        managerEmail: memberDetail.managerEmail,
        gpsType: GPS_API_DROPDOWN_OPTIONS.find(
          (option) => option.key === memberDetail.gpsType,
        ) ?? { key: "", label: "" },
        gpsCustomerCode: memberDetail.gpsCustomerCode ?? "",
        gpsApiKey: memberDetail.gpsApiKey ?? "",
      },
      { keepDefaultValues: true },
    );
  }, [memberDetail, companyType]);

  return {
    isLoadingEditMember,
    isLoadingEditDummyMember,
    editMember,
    formMethod,
  };
};

export default useEditMember;
