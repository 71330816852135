import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const SummaryStatistics = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.color.gray_35};
    padding: 13px 20px;
    background-color: ${theme.color.white};
  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    width: 42px;
    height: 42px;
    margin-right: 16px;
    border-radius: 2px;
    padding: 13px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const icon = (theme: Theme) => css`
  width: 16px;
  height: 16px;

  & > path,
  & > g > path {
    fill: ${theme.color.gray_60};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.black};
  `}
`;

export const Content = styled.span`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    color: ${theme.color.primary_40};
  `}
`;
