import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PasswordHint = styled.div<{
  isValidCheck: boolean;
}>`
  ${({ theme, isValidCheck }) => css`
    ${theme.font.regular_13};
    display: flex;
    align-items: center;
    column-gap: 4px;
    heigth: 18px;
    color: ${!isValidCheck ? theme.color.gray_60 : theme.color.primary_20};

    & > svg {
      width: 16px;
      height: 16px;
      fill: ${!isValidCheck ? theme.color.gray_40 : theme.color.primary_20};
    }
  `}
`;
