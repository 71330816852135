import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./Input.styled";

interface InputProps {
  id?: string;
  className?: string;
  placeholder: Languages;
  maxLength?: number;
  disabled?: boolean;
  value?: string;
  errorId?: string;
  hasError?: boolean;
  register?: UseFormRegisterReturn<string>;
  type?: "text" | "password";
  autoComplete?: "on" | "off";
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  handleChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef(
  (
    {
      id,
      className,
      placeholder,
      maxLength,
      disabled,
      value,
      errorId,
      hasError = false,
      register,
      type = "text",
      autoComplete,
      handleKeyDown,
      handleFocus,
      handleBlur,
      handleChangeValue,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement | null>,
  ) => {
    const { t } = useTranslation();

    return (
      <S.Input
        className={className}
        ref={ref}
        id={id}
        type={type}
        autoComplete={autoComplete}
        placeholder={t(placeholder)} //TODO: 오류로 { nsSeparator: false } 삭제 -> 번역 줄바꿈 오류 발생시 확인 후 추가 필요
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        aria-invalid={hasError}
        aria-errormessage={errorId}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChangeValue}
        {...register}
      />
    );
  },
);

Input.displayName = "Input";

export default Input;
