import React from "react";

import { useCurrentQueryParams } from "hooks";
import InquiryDetail from "./inquiry/InquiryDetail";
import NoticeDetail from "./notice/NoticeDetail";

const SupportDetail = () => {
  const query = useCurrentQueryParams("tab") ?? { tab: "inquiry" };

  const detailRender = () => {
    switch (query.tab[0]) {
      case "notice":
        return <NoticeDetail />;

      case "inquiry":
      default:
        return <InquiryDetail />;
    }
  };

  return <div>{detailRender()}</div>;
};

export default SupportDetail;
