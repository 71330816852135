import React, { useId } from "react";
import { useTranslation } from "react-i18next";

import { DropdownArrow, ErrorIcon } from "assets";
import type { DropdownOptionType, Languages } from "types";
import useDropdown from "./hooks/useDropdown";
import * as S from "./Dropdown.styled";

interface DropdownProps {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  options: readonly DropdownOptionType[];
  placeholder?: Languages;
  selectedOption: DropdownOptionType | null;
  type?: "default" | "address";
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  handleSelect: (value: string) => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Dropdown = ({
  className,
  isLoading = false,
  disabled,
  hasError,
  options,
  placeholder = "",
  selectedOption,
  type = "default",
  Icon = DropdownArrow,
  handleSelect,
  handleFocusCondition,
  handleBlurCondition,
}: DropdownProps) => {
  const { dropdownRef, optionsRef, isOpen, handleOpener, handleClickOption } =
    useDropdown(
      selectedOption ? selectedOption.key : null,
      handleSelect,
      handleFocusCondition,
      handleBlurCondition,
    );
  const id = useId();

  const { t } = useTranslation();

  return (
    <S.Dropdown ref={dropdownRef} className={className}>
      <S.DropdownBtn
        aria-controls={id}
        aria-expanded={isOpen}
        hasError={hasError}
        disabled={disabled}
        type="button"
        onClick={handleOpener}
      >
        {selectedOption?.label ? (
          <S.SelectedValue>{t(selectedOption.label)}</S.SelectedValue>
        ) : (
          <S.Placeholder>{t(placeholder)}</S.Placeholder>
        )}
        <Icon />
      </S.DropdownBtn>
      <S.OptionWrapper
        ref={optionsRef}
        aria-hidden={!isOpen}
        id={id}
        isOpen={isOpen}
      >
        {options.length === 0 ? (
          <S.NoOptionData>
            <ErrorIcon css={S.noOptionIcon} />
            {/* <S.noOptionDataMsg>{t("No history")}</S.noOptionDataMsg> // NOTE: 드롭다운 데이터가 없는 경우가 있는지 확인 후 문구 요청 필요 */}
          </S.NoOptionData>
        ) : (
          options.map(({ key, label }, i) => (
            <S.Option key={i}>
              <S.OptionBtn
                data-selected={selectedOption?.key === key}
                status={type}
                type="button"
                onClick={handleClickOption(key)}
              >
                {t(label)}
                <S.CustomCheckboxIcon />
              </S.OptionBtn>
            </S.Option>
          ))
        )}
      </S.OptionWrapper>
    </S.Dropdown>
  );
};

export default Dropdown;
