import React, { useEffect } from "react";

import { ArrowIcon, ZoomInIcon, ZoomOutIcon } from "assets";
import NoneSearchImg from "assets/image/none_searchImg.png";
import { useImgZoom, useImgPen, useImgBtn } from "./hooks";
import * as S from "./ImgZoom.styled";

interface ImgZoomProps {
  imgArr: string[];
}

const ImgZoom = ({ imgArr }: ImgZoomProps) => {
  const { imgIdx, handlePrevImg, handleNextImg } = useImgBtn();
  const {
    minZoomLevel,
    maxZoomLevel,
    zoomLevel,
    zoomValue,
    handleChangeZoomLevel,
    handleZoomIn,
    handleZoomOut,
  } = useImgZoom(1, 5, imgIdx);
  const { position, handleMouseDown } = useImgPen(zoomLevel);

  const handleErrorImg = (e: any) => {
    e.target.src = NoneSearchImg;
  };

  useEffect(() => {
    if (!imgArr) return;

    const images = [];

    imgArr.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
      images.push(img);
    });
  }, []);

  return (
    <S.Root>
      {imgArr && (
        <>
          <S.ImgWrapper onMouseDown={handleMouseDown}>
            <S.Img
              draggable="true"
              src={imgArr[imgIdx]}
              alt="이미지"
              rangeValue={zoomLevel}
              position={position}
              onError={handleErrorImg}
            />
          </S.ImgWrapper>
          <S.BottomWrapper>
            <S.BtnWrapper>
              <S.Btn onClick={handlePrevImg} disabled={imgIdx === 0}>
                <ArrowIcon css={S.arrowLeftIcon} />
              </S.Btn>
              <span>{`${imgIdx + 1} / ${imgArr.length}`}</span>
              <S.Btn
                onClick={handleNextImg}
                disabled={imgIdx === imgArr.length - 1}
              >
                <ArrowIcon />
              </S.Btn>
            </S.BtnWrapper>
            <S.ZoomBtnWrapper>
              <S.Btn type="button" onClick={handleZoomOut}>
                <ZoomOutIcon width={20} height={20} />
              </S.Btn>
              <S.RangeBar
                type="range"
                value={zoomLevel}
                zoomValue={zoomValue}
                min={minZoomLevel.current}
                max={maxZoomLevel.current}
                onChange={handleChangeZoomLevel}
              />
              <S.Btn onClick={handleZoomIn}>
                <ZoomInIcon width={20} height={20} />
              </S.Btn>
            </S.ZoomBtnWrapper>
          </S.BottomWrapper>
        </>
      )}
    </S.Root>
  );
};

export default ImgZoom;
