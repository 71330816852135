import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Input = styled.input`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.color.gray_30};
    /* TODO: color 전체적으로 확인필요 */
    border-radius: 2px;
    padding: 9px 11px;

    &::placeholder {
      color: ${theme.color.gray_50};
    }

    &:not(:disabled):not([aria-invalid="true"]):hover {
      border-color: ${theme.color.primary_30};
    }

    &:focus {
      border: 1px solid ${theme.color.primary_40};
    }

    &[aria-invalid="true"] {
      border-color: ${theme.color.red_20};
    }
  `}
`;
