import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { MEMBER_DETAIL_REGISTERED_MEMBER } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${MEMBER_DETAIL_REGISTERED_MEMBER};

    & > :last-of-type {
      padding: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & > button,
  a {
    width: 32px;

    :hover {
      background-color: transparent !important;
    }

    svg {
      width: 12px;
      height: 12px;
      margin: 0;
    }
  }
`;

export const alertModal = css`
  width: 392px;
`;
