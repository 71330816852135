import React from "react";

import { Filter } from "components";
import { SUPPORT_COMPANY_TYPE_RADIOS } from "assets";

const NoticeFilter = () => {
  const searchLabels = [
    {
      queryKey: "companyType",
      label: "Company type",
      options: SUPPORT_COMPANY_TYPE_RADIOS,
    },
    { queryKey: "subject", label: "Subject" },
  ] as const;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Company type">
          <Filter.Radio
            queryKey="companyType"
            radios={SUPPORT_COMPANY_TYPE_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row>
        <Filter.Condtion conditionName="Subject">
          <Filter.Input queryKey="subject" placeholder="Search by subject" />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default NoticeFilter;
