import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const AccountInput = styled.div<{ hasValue: boolean }>`
  ${({ theme, hasValue }) => css`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 400px;

    label {
      ${hasValue ? theme.font.regular_12 : theme.font.regular_15};
      top: ${hasValue ? "0px" : "16px"};
      transition-duration: 0.2s;
    }

    &:focus-within {
      label {
        ${theme.font.regular_12};
        top: 0px;
        transition-duration: 0.2s;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.color.gray_50};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    ${theme.font.regular_15};
    border: 0;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 16px 0 12px;
    margin-bottom: 20px;
    background-color: inherit;

    :focus {
      border-bottom: 1px solid ${theme.color.primary_10};
      outline: none;
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`;

export const PasswordShowButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: inherit;
`;

export const passwordShowIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;
