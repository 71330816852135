import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Filter, CompanySearchModal } from "components";
import { useModal } from "hooks";
import { useGetCommonPlayer } from "services";
import { selectCompanyInfo, getAllQuery } from "utils";
import { COMPANY_COLUMNS, SETTLEMENT_FILTER_STATUS_RADIOS } from "assets";
import type { CompanyInfo, Languages } from "types";
import * as S from "./SettlementFilter.styled";

const SettlementFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSender, setSelectedSender] = useState<CompanyInfo[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<CompanyInfo[]>([]);

  const { data: companies } = useGetCommonPlayer();

  const { modalRef } = useModal();

  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: SETTLEMENT_FILTER_STATUS_RADIOS,
    },
    { queryKey: "orderNumber", label: "Order number" },
    { queryKey: "date", label: "Date" },
    {
      queryKey: "sender",
      label: "Sender",
      options: companies?.map((company) => ({
        key: company.id,
        label: company.name as Languages,
      })),
    },
    {
      queryKey: "recipient",
      label: "Recipient",
      options: companies?.map((company) => ({
        key: company.id,
        label: company.name as Languages,
      })),
    },
  ] as const;

  const handleCompanySelect =
    (type: "sender" | "recipient") =>
    (companyInfo: CompanyInfo[]): void => {
      setSearchParams({
        ...getAllQuery(searchParams),
        ...(companyInfo[0] &&
          (type === "recipient"
            ? { recipient: companyInfo[0].id }
            : { sender: companyInfo[0].id })),
        page: "1",
      });
    };

  useEffect(() => {
    if (!companies) return;

    setSelectedSender(
      selectCompanyInfo(companies, searchParams.get("sender") ?? ""),
    );
    setSelectedRecipient(
      selectCompanyInfo(companies, searchParams.get("recipient") ?? ""),
    );
  }, [companies, searchParams.get("sender"), searchParams.get("recipient")]);

  return (
    <Filter css={S.filter} searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status" isRequired>
          <Filter.Radio
            queryKey="status"
            radios={SETTLEMENT_FILTER_STATUS_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Order number">
          <Filter.Input
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Date" isRequired>
          <Filter.Calendar queryKey="date" />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Sender">
          <Filter.Modal
            selectModalData={selectedSender[0]?.name}
            placeholder="Search by sender name"
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedSender}
                tableDatas={companies ?? []}
                handleCompanySelect={handleCompanySelect("sender")}
              />
            }
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Recipient">
          <Filter.Modal
            selectModalData={selectedRecipient[0]?.name}
            placeholder="Search by recipient name"
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedRecipient}
                tableDatas={companies ?? []}
                handleCompanySelect={handleCompanySelect("recipient")}
              />
            }
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default SettlementFilter;
