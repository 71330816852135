import React from "react";

import { LabelContentTable } from "components";
import { formatICTDateTime, formatPhoneNum } from "utils";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationDropOffInfo.styled";

interface QuotationDropOffInfoProps {
  dropoffInfo?: GetQuotationDetailServerModel["dropOffInfo"];
}

const QuotationDropOffInfo = ({ dropoffInfo }: QuotationDropOffInfoProps) => {
  if (!dropoffInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address" labelWidth={200}>
          <S.AddrWrapper>
            <span>{dropoffInfo.doAddr}</span>
            {dropoffInfo.doAddrDetail && (
              <S.AddrDetail>{dropoffInfo.doAddrDetail}</S.AddrDetail>
            )}
          </S.AddrWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge" labelWidth={200}>
          {dropoffInfo.doPic} {`(${formatPhoneNum(dropoffInfo.doPhone)})`}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA" labelWidth={200}>
          {formatICTDateTime(dropoffInfo.doEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD" labelWidth={200}>
          {dropoffInfo.doEtd ? formatICTDateTime(dropoffInfo.doEtd) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default QuotationDropOffInfo;
