/* eslint-disable react-hooks/rules-of-hooks */ //NOTE: 함수 오버로딩 사용 시 함수 내에서 useFilterParams 사용이 불가능하여 추가(rules에서 작성 시 작동 안함)
import React from "react";
import { lowerCase, upperCase } from "lodash-es";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import {
  COMPANY_LOOKUP_TABLE,
  MONITORING_QUERY_PARAMS,
  MONITORING_TABS,
} from "assets";
import type {
  GetMonitoringOrdersQueryModel,
  GetMonitoringTrucksQueryModel,
  TruckStatus,
} from "types";

const useMonitoringMap = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  const companyType = searchParams.get(
    "companyType",
  ) as keyof typeof COMPANY_LOOKUP_TABLE;

  const status = upperCase(
    searchParams.get(MONITORING_QUERY_PARAMS.TAB) ===
      lowerCase(MONITORING_TABS[1].key)
      ? MONITORING_TABS[1].key
      : MONITORING_TABS[0].key,
  ) as TruckStatus;

  const heading =
    MONITORING_TABS.find(
      ({ key }) => key === searchParams.get(MONITORING_QUERY_PARAMS.TAB),
    )?.label ?? MONITORING_TABS[0].label;

  function makeMonitoringQuery(
    monitoringData: "orders",
  ): GetMonitoringOrdersQueryModel["query"];
  function makeMonitoringQuery(
    monitoringData: "trucks",
  ): GetMonitoringTrucksQueryModel["query"];
  function makeMonitoringQuery(
    monitoringData: "orders" | "trucks",
  ):
    | GetMonitoringOrdersQueryModel["query"]
    | GetMonitoringTrucksQueryModel["query"] {
    switch (monitoringData) {
      case "orders": {
        const { filters } = useFilterParams<
          GetMonitoringOrdersQueryModel["query"] & { search?: string }
        >(["orderNumber", "search", "companyId", "companyType", "page"]);

        const { search, ...restFilters } = filters;

        const query = {
          ...restFilters,
          orderNumber: search,
          companyId: id!,
          companyType,
        };

        return query;
      }

      case "trucks": {
        const { filters } = useFilterParams<
          GetMonitoringTrucksQueryModel["query"] & { search?: string }
        >([
          "status",
          "registNumber",
          "search",
          "companyId",
          "companyType",
          "page",
        ]);

        const { search, ...restFilters } = filters;

        const query = {
          ...restFilters,
          status,
          registNumber: search,
          companyId: id!,
          companyType,
        };

        return query;
      }
    }
  }

  const deleteSearchParams = (): void => {
    searchParams.delete(
      companyType === "shipper"
        ? MONITORING_QUERY_PARAMS.ORDER_ID
        : MONITORING_QUERY_PARAMS.TRUCK_ID,
    );
    searchParams.delete(MONITORING_QUERY_PARAMS.TYPE);
    searchParams.delete(MONITORING_QUERY_PARAMS.SEARCH);
    setSearchParams(searchParams);
  };

  return {
    heading,
    companyType,
    makeMonitoringQuery,
    deleteSearchParams,
  };
};

export default useMonitoringMap;
