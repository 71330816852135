import { useSearchParams } from "react-router-dom";

const useMemberType = () => {
  const [searchParams] = useSearchParams();

  const memberType =
    searchParams.get("memberType") === "forwarder"
      ? ("forwardercarrier" as const)
      : ("carrier" as const);

  return { memberType };
};

export default useMemberType;
