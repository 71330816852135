import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import {
  DetailModal,
  FormInput,
  FormRadioBtn,
  LabelContent,
  Table,
  Textarea,
} from "components";
import { clientLanguage, truckTypeOptions } from "stores";
import { useModal, useToast } from "hooks";
import { usePatchQuotationReject } from "services";
import {
  calculateTotalAmount,
  calculateVatAmount,
  comma,
  deleteComma,
  findLookupTableLabel,
  formatPrice,
  numericOnly,
} from "utils";
import {
  HOOKFORM_ERR,
  PAYMENT_METHOD_RADIOS,
  TRUCK_LIST_TABLE_HEADER,
  TAX_RADIOS,
} from "assets";
import { TOAST_MSG } from "constants/index";
import type { GetQuotationDetailServerModel } from "types";
import { useSendtoShipperForm } from "./hooks";
import RejectModal from "../rejectModal/RejectModal";
import * as S from "./SendToShipperModal.styled";

interface SendToShipperModalProps {
  modalRef: React.RefObject<HTMLDialogElement>;
  history: GetQuotationDetailServerModel["history"][number];
}

const SendToShipperModal = ({ modalRef, history }: SendToShipperModalProps) => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const truckOptions = useRecoilValue(truckTypeOptions);

  const { handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const {
    isLoading: useSendtoShipperFormLoading,
    formMethod,
    handleSubmit,
  } = useSendtoShipperForm(history.offerId);

  const {
    mutate: rejectQuotationMutate,
    isLoading: usePatchQuotationRejectLaoding,
  } = usePatchQuotationReject();

  const payMethodLabel = findLookupTableLabel(
    PAYMENT_METHOD_RADIOS,
    history.pay,
  );

  const totalPrice = calculateTotalAmount(
    `${formMethod.watch("subtotal")}`,
    `${formMethod.watch("tax")}`,
  );

  const vatAmount = calculateVatAmount(
    `${formMethod.watch("subtotal")}`,
    `${formMethod.watch("tax")}`,
  );

  const handleCarrierOfferReject = (offerId: string) => () => {
    rejectQuotationMutate(offerId, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.QUOTATION_REJECT_SUCCESS);
        handleModalClose();
      },
    });
  };

  return (
    <DetailModal
      css={S.detailModal}
      ref={modalRef}
      type="submit"
      isLoading={useSendtoShipperFormLoading}
      isDisabled={Object.keys(formMethod.formState.errors).length > 0}
      title={"Send to shipper"}
      closeBtnLabel="Reject"
      posBtnLabel="Send"
      callbackFn={handleSubmit}
      closeBtnFn={handleModalOpen(
        <RejectModal
          modalRef={modalRef}
          isLoading={usePatchQuotationRejectLaoding}
          handleOfferReject={handleCarrierOfferReject(history.offerId)}
        />,
      )}
    >
      <S.ModalBodyWrapper>
        <S.ModalBody>
          <LabelContent
            css={S.labelContent}
            direction="horizontal"
            label="Trucking company"
          >
            <S.PaymentMethodText>{history.name}</S.PaymentMethodText>
          </LabelContent>
          <LabelContent
            css={S.truckList}
            direction="vertical"
            label="Truck list"
          >
            <Table css={S.table} columnTable={TRUCK_LIST_TABLE_HEADER}>
              {Object.entries(history.trucks).map(([key, truckCount]) => {
                const truckOption = truckOptions?.find(
                  ({ truckOptionId }) => truckOptionId === +key,
                );
                return (
                  <Table.Row key={key}>
                    <Table.Td>
                      {truckOption?.[language === "en" ? "typeEn" : "typeVi"]}
                    </Table.Td>
                    <Table.Td>
                      {
                        truckOption?.[
                          language === "en" ? "optionEn" : "optionVi"
                        ]
                      }
                    </Table.Td>
                    <Table.Td>{truckCount}</Table.Td>
                  </Table.Row>
                );
              })}
            </Table>
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            direction="horizontal"
            label="Payment method"
          >
            <S.PaymentMethodText>
              {payMethodLabel ? t(payMethodLabel) : "-"}
            </S.PaymentMethodText>
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            direction="horizontal"
            label="Received price"
          >
            <S.PaymentMethodText>
              {comma(history.price)} ₫ ({t("VAT included")})
            </S.PaymentMethodText>
          </LabelContent>
          <S.Division />
          <LabelContent
            css={S.labelContent}
            direction="horizontal"
            label="Subtotal"
            isRequired
          >
            <S.InputWrapper>
              <FormInput
                maxLength={13}
                placeholder={"Enter a price (only numbers)"}
                value={formMethod.watch("subtotal")}
                hasError={!!formMethod.formState.errors.subtotal?.message}
                errorMsg={formMethod.formState.errors.subtotal?.message}
                register={formMethod.register("subtotal", {
                  required: HOOKFORM_ERR.REQUIRE,
                  setValueAs: (value: string) =>
                    comma(deleteComma(numericOnly(value))),
                })}
              />
              <span>₫</span>
            </S.InputWrapper>
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            direction="horizontal"
            label="Tax"
            isRequired
          >
            <FormRadioBtn
              css={S.radio}
              radioList={TAX_RADIOS}
              currentValue={formMethod.watch("tax")}
              register={formMethod.register("tax")}
            />
          </LabelContent>
          <LabelContent
            css={S.multiInfoLabelContent}
            direction="horizontal"
            label="Total"
          >
            <div>
              <S.TotalPriceText>{formatPrice(totalPrice)}</S.TotalPriceText>
              <S.PriceInfo>
                <LabelContent
                  css={S.subLabelContent}
                  direction="horizontal"
                  label="Subtotal"
                >
                  <S.PriceText>
                    {formatPrice(+deleteComma(formMethod.watch("subtotal")))}
                  </S.PriceText>
                </LabelContent>
                <LabelContent
                  css={S.subLabelContent}
                  direction="horizontal"
                  label="Tax"
                >
                  <S.PriceText>{`(${formMethod.watch("tax")}%) ${formatPrice(
                    vatAmount,
                  )}`}</S.PriceText>
                </LabelContent>
              </S.PriceInfo>
            </div>
          </LabelContent>
          <LabelContent
            css={S.multiInfoLabelContent}
            direction="horizontal"
            label="Memo"
          >
            <Textarea
              css={S.memo}
              id="memo"
              placeholder="Please enter information about the amount"
              hasError={!!formMethod.formState.errors.memo?.message}
              maxLength={150}
              value={formMethod.watch("memo")}
              register={formMethod.register("memo")}
            />
          </LabelContent>
        </S.ModalBody>
      </S.ModalBodyWrapper>
    </DetailModal>
  );
};

export default SendToShipperModal;
