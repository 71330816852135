import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { map } from "lodash-es";

import { getAllQuery, formatDate } from "utils";

const useFilterCalendar = (queryKey: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const date = searchParams.get(queryKey);

  const [selectedDates, setSelectedDates] = useState<string[]>(
    date?.split("~").map((item) => item.trim())! ?? [],
  );

  const selectedDate = selectedDates[0]
    ? `${selectedDates[0]} ~ ${selectedDates[selectedDates.length - 1]}`
    : "";

  const handleChangeFilterDate = (date: dayjs.Dayjs[] | []) => {
    if (date.length === 0) {
      setSelectedDates([]);
    } else {
      const [startDate, endDate] = map(date, (d) =>
        formatDate(d, "DD/MM/YYYY"),
      );
      setSelectedDates([startDate, endDate ?? startDate]);

      setSearchParams({
        ...getAllQuery(searchParams),
        page: "1",
        [queryKey]: `${startDate} ~ ${endDate ?? startDate}`,
      });
    }
  };

  useEffect(() => {
    // NOTE: 뒤로가기
    if (!date) {
      setSelectedDates([]);
      return;
    }

    // NOTE: 초기, 앞으로가기
    if (!selectedDates.length) {
      setSelectedDates(date.split("~").map((item) => item.trim()));
    }
  }, [date]);

  return {
    selectedDates,
    selectedDate,
    handleChangeFilterDate,
  };
};

export default useFilterCalendar;
