import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const layout = (theme: Theme) => css`
  & > h1 {
    ${theme.font.bold_18};
    margin-bottom: 32px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-bottom: 16px;
`;

export const checkbox = css`
  column-gap: 8px;

  & > label {
    width: fit-content;
  }
`;

export const CompanyTypeDesc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    margin: 32px 0;
    border: 0;
    border-bottom: 1px solid ${theme.color.gray_20};
  `}
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 13px;
`;

export const content = css`
  & > div:last-of-type {
    position: relative;
  }
`;

export const subject = css`
  width: 500px;

  & > input {
    padding: 10px 60px 10px 12px;
  }
`;

export const SubjectLength = styled.span`
  ${({ theme }) => css`
    ${theme.font.semibold_14};
    position: absolute;
    top: 20px;
    left: 445px;
    color: ${theme.color.gray_50};
  `}
`;

export const contentSunEditor = css`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
