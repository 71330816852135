import React from "react";
import { useTranslation } from "react-i18next";

import { ExternalLink, LabelContentTable } from "components";
import { formatOrderNum } from "utils";
import { ChevronRightIcon, SENDER_TOOLTIP, RECIPIENT_TOOLTIP } from "assets";
import { PATH } from "constants/index";
import type { GetSettlementDetailServerModel } from "types";
import * as S from "./SettlementDefaultInfo.styled";

interface SettlementDefaultInfoProps {
  defaultInfo?: GetSettlementDetailServerModel["defaultInfo"];
}

const SettlementDefaultInfo = ({ defaultInfo }: SettlementDefaultInfoProps) => {
  const { t } = useTranslation();

  if (!defaultInfo) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Order number" labelWidth={200}>
          <S.ContentWrapper>
            <span>{formatOrderNum(defaultInfo.orderNumber)}</span>
            <ExternalLink
              variant="ghostPrimary"
              href={`${PATH.ORDER_MANAGEMENT}/${defaultInfo.orderId}`}
            >
              <>
                {t("Order management")}
                <ChevronRightIcon css={S.icon} />
              </>
            </ExternalLink>
          </S.ContentWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          css={S.content}
          label="Sender"
          labelWidth={200}
          tooltip={SENDER_TOOLTIP}
        >
          <S.ContentWrapper>
            <span>{defaultInfo.sender}</span>
            <ExternalLink
              variant="ghostPrimary"
              href={`${PATH.QUOTATION}/${defaultInfo.orderId}`}
            >
              {t("Quotation management")}
              <ChevronRightIcon css={S.icon} />
            </ExternalLink>
          </S.ContentWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content
          css={S.content}
          label="Recipient"
          labelWidth={200}
          tooltip={RECIPIENT_TOOLTIP}
        >
          <S.ContentWrapper>
            <span>{defaultInfo.recipient}</span>
          </S.ContentWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default SettlementDefaultInfo;
