import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 20px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const icon = css`
  width: 12px;
  height: 12px;
`;
