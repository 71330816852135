export const COMPANY_STATUS_LOOKUP_TABLE = {
  APPROVED: "Apporved",
  REJECTED: "Rejected",
  PENDING: "Pending",
} as const;

export const COMPANY_LOOKUP_TABLE = {
  shipper: "SHIPPER",
  forwarder: "FORWARDER",
  carrier: "CARRIER",
  forwardercarrier: "FORWARDERCARRIER",
} as const;

export const DRIVER_DELIVERY_STATUS = {
  COMPLETED: "Completed",
  REQUESTED: "Requested",
  ALLOCATED: "Allocated",
  TO_PICKUP: "Shipping",
  TO_DROPOFF: "Shipping",
  REJECTED: "Rejected",
  EMPTY_TRUCK: "Empty truck",
} as const;

export const DELIVERY_LOG_LABELS = {
  doAtd: "Drop-off ATD",
  doAta: "Drop-off ATA",
  puAtd: "Pick-up ATD",
  puAta: "Pick-up ATA",
  shipmentsStart: "Shipments start",
} as const;

export const SUPPORT_INQUIRY_CATEGORY_LOOKUP_TABLE = [
  { key: "ORDER", label: "Order" },
  { key: "SERVICE", label: "Service" },
  { key: "ERROR", label: "Error" },
  { key: "DELETE_ACCOUNT", label: "Delete account" },
  { key: "OTHER", label: "Other" },
] as const;

export const SUPPORT_COMPANY_TYPE_LOOKUP_TABLE = [
  { key: "SHIPPER", label: "Shipper" },
  { key: "FORWARDER", label: "Forwarder" },
  { key: "CARRIER", label: "Carrier" },
  { key: "DRIVER", label: "Driver" },
] as const;
