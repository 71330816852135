import React from "react";

import {
  ListPageLayout,
  MonitoringFilter,
  MonitoringPagination,
  MonitoringTable,
} from "components";
import { useFilterParams } from "hooks";
import { useGetMonitoringCompanys } from "services";
import type { GetMonitoringCompanysQueryModel } from "types";
import * as S from "./Monitoring.styled";

const Monitoring = () => {
  const { filters } = useFilterParams<GetMonitoringCompanysQueryModel["query"]>(
    ["page", "companyName"],
  );

  const { data, refetch, dataUpdatedAt } = useGetMonitoringCompanys({
    query: filters,
  });

  if (!data) return null;

  return (
    <ListPageLayout css={S.layout} heading="Monitoring">
      <MonitoringFilter />
      <MonitoringTable
        companys={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <MonitoringPagination pageInfo={data.pageInfo} />
    </ListPageLayout>
  );
};

export default Monitoring;
