import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable } from "components";
import { useDownloadFile } from "services";
import { findLookupTableLabel, makeOrderFileName } from "utils";
import { LOADING_METHOD_TYPE, LOADING_STATUS_RADIOS } from "assets";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementTruckingMethods.styled";

interface OrderManagementTruckingMethodsProps {
  truckingMethods?: GetOrderManagementDetailServerModel["truckingMethods"];
}

const OrderManagementTruckingMethods = ({
  truckingMethods,
}: OrderManagementTruckingMethodsProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate } = useDownloadFile();

  if (!truckingMethods) return null;

  const loadingStatusLabel = findLookupTableLabel(
    LOADING_STATUS_RADIOS,
    truckingMethods.loadingStatus,
  );

  const loadingMethodLabel = findLookupTableLabel(
    LOADING_METHOD_TYPE,
    truckingMethods.loadingMethod,
  );

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading status">
          {loadingStatusLabel ? t(loadingStatusLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading/unloading method">
          {loadingMethodLabel
            ? t(loadingMethodLabel)
            : truckingMethods.loadingMethod}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Attached file">
          <S.DownloadBtnWrapper>
            {!truckingMethods.file1 && !truckingMethods.file2 ? (
              "-"
            ) : (
              <>
                {[truckingMethods.file1, truckingMethods.file2].map(
                  (fileKey, idx) => {
                    if (!fileKey) return null;

                    return (
                      <DownloadBtn
                        key={fileKey}
                        downloadFileNamePrefix="OrderAttachedFile"
                        fileName={makeOrderFileName(fileKey, idx)}
                        fileKey={fileKey}
                        downloadFileMutate={downloadFileMutate}
                      />
                    );
                  },
                )}
              </>
            )}
          </S.DownloadBtnWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Memo">
          {truckingMethods.orderMemo ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementTruckingMethods;
