import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface NoticePaginationProps {
  pageInfo: PageInfo;
}

const NoticePagination = ({ pageInfo }: NoticePaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default NoticePagination;
