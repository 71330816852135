import axios from "axios";

import { ax } from "./axios";
import { getS3FileFormData, makeBase64ImageToFile } from "utils";
import type {
  GetCommonPlayerServerModel,
  S3PresignedServerModel,
  TruckTypeOption,
} from "types";

export const getCommonPlayerAPI = async () => {
  const { data } = await ax.get<GetCommonPlayerServerModel[]>("/common/player");

  return data;
};

const sendPublicS3PresignedAPI = async (
  key: string,
  file: File,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/public/${key}`);

  const formData = getS3FileFormData(res, file);

  return await axios.post(res.data.url, formData);
};

export const uploadBase64ImageToS3API = async (htmlCode: string) => {
  const isDevelopment = process.env.REACT_APP_TITLE_PREFIX === "dev-";
  const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;

  const fileNames: string[] = [];

  let updatedContent = htmlCode;

  let match;
  while ((match = base64ImageRegex.exec(htmlCode)) !== null) {
    const base64Image = match[1];

    const { file, fileName } = makeBase64ImageToFile(base64Image);

    fileNames.push(fileName);

    const noticeImagePath = `notice/${fileName}`;

    const { status } = await sendPublicS3PresignedAPI(
      isDevelopment ? `${noticeImagePath}` : `cocotruck/${noticeImagePath}`,
      file,
    );

    if (status !== 204) return null;

    updatedContent = updatedContent.replace(
      base64Image,
      isDevelopment
        ? `${process.env.REACT_APP_AWS_URL}/${noticeImagePath}`
        : `${process.env.REACT_APP_AWS_URL}/cocotruck/${noticeImagePath}`,
    );
  }

  return { content: updatedContent, fileNames };
};

export const getTruckTypeOptionsAPI = async () => {
  const { data } = await ax.get<TruckTypeOption[]>("/property/truck/option");

  return data;
};
