import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFilterParams } from "hooks";
import { useGetTrucks } from "services";
import type {
  GetMemberDetailTrucksQueryModel,
  TruckingCompanyType,
} from "types";
import {
  RegisteredTruckFilter,
  RegisteredTruckPagination,
  RegisteredTruckTable,
} from "./container";

const MemberDetailRegisteredTruck = () => {
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const { filters } = useFilterParams<GetMemberDetailTrucksQueryModel["query"]>(
    [
      "status",
      "plateNumber",
      "linked",
      "truckTypeId",
      "truckOptionId",
      "page",
      "plateNumber",
    ],
  );

  const memberType = searchParams.get("memberType");

  const { data, dataUpdatedAt, refetch } = useGetTrucks({
    query: {
      ...filters,
      status: filters.status || "ALL",
      companyId: memberId!,
      companyType: (memberType === "forwarder"
        ? "forwardercarrier"
        : memberType) as TruckingCompanyType,
    },
  });

  return (
    <>
      <RegisteredTruckFilter />
      <RegisteredTruckTable
        trucks={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <RegisteredTruckPagination pageInfo={data?.pageInfo} />
    </>
  );
};

export default MemberDetailRegisteredTruck;
