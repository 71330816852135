import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components";
import { useModal } from "hooks";
import { CloseIcon } from "assets";
import type { Languages, ModalCloseLabel } from "types";
import BaseModal from "../baseModal/BaseModal";
import * as S from "./DetailModal.styled";

interface Common {
  className?: string;
  children?: React.ReactNode;
  type?: "submit" | "button";
  isLoading?: boolean;
  isDisabled?: boolean;
  title: Languages;
  desc?: Languages;
  closeBtnLabel?: ModalCloseLabel;
  closeBtnFn?: () => void;
  callbackFn?: () => void;
}
interface Pos extends Common {
  posBtnLabel: Languages;
  negBtnLabel?: never;
}

interface Neg extends Common {
  posBtnLabel?: never;
  negBtnLabel: Languages;
}

type DetailModalProps = Pos | Neg;

const DetailModal = React.forwardRef(
  (
    {
      className,
      children,
      type = "button",
      isLoading,
      isDisabled,
      title,
      desc,
      posBtnLabel,
      negBtnLabel,
      closeBtnLabel = "Close",
      closeBtnFn,
      callbackFn,
    }: DetailModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.Header>
          <S.Title>{t(title)}</S.Title>
          {desc && <S.Desc>{t(desc)}</S.Desc>}
          {closeBtnFn && (
            <Button
              css={S.closeBtn}
              variant="icon"
              icon={<CloseIcon />}
              label=""
              handleClickBtn={handleModalClose}
            />
          )}
        </S.Header>
        <S.Body>{children}</S.Body>
        <S.Footer>
          <Button
            variant="outlineMedium"
            label={closeBtnLabel}
            handleClickBtn={closeBtnFn ?? handleModalClose}
          />
          {(posBtnLabel || negBtnLabel) && (
            <Button
              isLoading={isLoading}
              isDisabled={isDisabled}
              type={type}
              variant={posBtnLabel ? "primaryMedium" : "errorMedium"}
              label={(posBtnLabel ? posBtnLabel : negBtnLabel)!}
              handleClickBtn={callbackFn}
            />
          )}
        </S.Footer>
      </BaseModal>
    );
  },
);

DetailModal.displayName = "DetailModal";

export default DetailModal;
