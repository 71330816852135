import { ax } from "api/axios";
import type {
  GetRegisteredMemberDetailServerModel,
  GetRegisteredMemberQueryModel,
  GetRegisteredMemberServerModel,
  GetMyAccountServerModel,
  PutAccountQueryModel,
  PutChangePasswordQueryModel,
  PostRegisteredMemberQueryModel,
  PutRegisteredMemberQueryModel,
} from "types";

export const getMyAccountAPI = async () => {
  const { data } = await ax.get<GetMyAccountServerModel>("/setting/account");

  return data;
};

export const putMyAccountAPI = async (req: PutAccountQueryModel) => {
  const { data } = await ax.put("/setting/account", req.body);

  return data;
};

export const getRegisteredMembersAPI = async (
  req: GetRegisteredMemberQueryModel,
) => {
  const { data } = await ax.get<GetRegisteredMemberServerModel>(
    "/setting/admin",
    { params: req.query },
  );

  return data;
};

export const getRegisteredMemberDetailAPI = async (adminId: string) => {
  const { data } = await ax.get<GetRegisteredMemberDetailServerModel>(
    `/setting/admin/${adminId}`,
  );

  return data;
};

export const putChangePasswordAPI = async (
  req: PutChangePasswordQueryModel,
) => {
  const { data } = await ax.put("/setting/password", req.body);

  return data;
};

export const postRegisteredMemberAPI = async (
  req: PostRegisteredMemberQueryModel,
) => {
  const { data } = await ax.post("/setting/admin", req.body);

  return data;
};

export const putRegisteredMemberAPI = async (
  req: PutRegisteredMemberQueryModel,
) => {
  const { data } = await ax.put(`/setting/admin/${req.adminId}`, req.body);

  return data;
};
