import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./Card.styled";

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

const Card = ({ className, children }: CardProps) => {
  return <S.Card className={className}>{children}</S.Card>;
};

interface ContentProps {
  className?: string;
  id?: string;
  heading: Languages;
  headingLevel?: "h2" | "h3" | "h4";
  addHeadingElement?: React.ReactNode;
  children: React.ReactNode;
}

Card.Content = function Content({
  className,
  id,
  heading,
  headingLevel,
  addHeadingElement,
  children,
}: ContentProps) {
  const { t } = useTranslation();

  return (
    <div className={className} id={id}>
      <S.HeadingWrapper>
        <S.Heading id={heading} as={headingLevel}>
          {t(heading)}
        </S.Heading>
        {addHeadingElement && addHeadingElement}
      </S.HeadingWrapper>
      {children}
    </div>
  );
};

export default Card;
