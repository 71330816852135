export const color = {
  white: "#FFFFFF",
  white_00_04: "rgba(255, 255, 255, 0.04)",
  white_10_10: "rgba(255, 255, 255, 0.10)", //TODO: 삭제
  gray_00: "#FAFBFB",
  gray_10: "#F5F7F8",
  gray_20: "#EFF1F3",
  gray_30: "#E9EBEC",
  gray_35: "#E1E3E7",
  gray_40: "#D3D6D9",
  gray_50: "#AFB5BB",
  gray_50_50: "rgba(175, 181, 187, 0.5)", //TODO: 삭제
  gray_60: "#9098A0",
  gray_70: "#7A848D",
  gray_80: "#4E5B67",
  black: "#191F28",
  primary_10: "#F6F9FF",
  primary_10_10: "rgba(51, 137, 255, 0.1)", //TODO: 삭제
  primary_disabled: "rgba(51, 137, 255, 0.1)",
  primary_20: "#EEF4FF",
  primary_30: "#73A4FF",
  primary_40: "#508DFF",
  primary_40_20: "rgba(80, 141, 255, 0.2)", //TODO: 삭제
  primary_50: "#3569CC",
  secondary: "#182736",
  red_10: "#FFEEEE",
  red_20: "#FF6060",
  red_30: "#E42E2E",
  red_disabled: "rgba(255, 96, 96, 0.2)",
  orange_10: "#FCF6E8",
  orange_20: "#E5A01A",
  green_10: "#EBF7F2",
  green_20: "#6FC86D",
  green_30: "#006644",
} as const;

export type ColorType = typeof color;
