import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Button,
  PreviousPageLink,
  SettlementDefaultInfo,
  SettlementPayment,
  SettlementRequestPayment,
  SettlementSelectModal,
  TableOfContent,
  DetailLayout,
} from "components";
import { useModal } from "hooks";
import { useGetSettlementDetail } from "services";
import { PATH } from "constants/index";
import * as S from "./SettlementDetail.styled";

const SettlementDetail = () => {
  const { t } = useTranslation();
  const { settlementId } = useParams();

  const { data } = useGetSettlementDetail(settlementId!);

  const { handleModalOpen, modalRef } = useModal();

  if (!data) return null;

  const contents = {
    ONGOING: [
      {
        text: "Requested payment",
        content: (
          <SettlementRequestPayment
            requestPayment={data.requestPayment}
            memo={data.memo}
            status={data.status}
          />
        ),
      },
      {
        text: "Payment",
        content: (
          <SettlementPayment payment={data.payment} status={data.status} />
        ),
      },
      {
        text: "Default information",
        content: <SettlementDefaultInfo defaultInfo={data.defaultInfo} />,
      },
    ],
    WAITING: [
      {
        text: "Payment",
        content: (
          <SettlementPayment
            payment={data.payment}
            memo={data.memo}
            status={data.status}
          />
        ),
      },
      {
        text: "Default information",
        content: <SettlementDefaultInfo defaultInfo={data.defaultInfo} />,
      },
    ],
    COMPLETED: [
      {
        text: "Payment",
        content: (
          <SettlementPayment
            payment={data.payment}
            memo={data.memo}
            status={data.status}
          />
        ),
      },
      {
        text: "Default information",
        content: <SettlementDefaultInfo defaultInfo={data.defaultInfo} />,
      },
    ],
  };

  return (
    <DetailLayout css={S.layout(data.status === "ONGOING")}>
      <TableOfContent
        pageName="Settlement details"
        css={S.tableOfContent}
        contents={contents[data.status].map(({ text, content }) => ({
          heading: { headingLevel: "h2" as const, text },
          content: content,
        }))}
      />
      <S.ButtonWrapper>
        <PreviousPageLink
          css={S.link}
          variant="outlineMedium"
          to={PATH.SETTLEMENT}
        >
          {t("Back to list")}
        </PreviousPageLink>
        {data.status === "ONGOING" && (
          <Button
            label="Change status"
            variant="primaryMedium"
            handleClickBtn={handleModalOpen(
              <SettlementSelectModal ref={modalRef} />,
            )}
          />
        )}
      </S.ButtonWrapper>
    </DetailLayout>
  );
};

export default SettlementDetail;
