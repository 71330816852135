import type { Languages } from "types";

export const LOADING_METHOD_TYPE = [
  { key: "forklift", label: "Forklift" },
  { key: "manual", label: "Manual" },
  { key: "crane", label: "Crane" },
  { key: "hoist", label: "Hoist" },
  { key: "conveyor", label: "Conveyor" },
  { key: "others", label: "Others" },
] as const;

export const CARGO_TYPE = [
  { key: "medicine", label: "Medicine" },
  { key: "textile/clothing", label: "Textile/Clothing" },
  { key: "food/beverage", label: "Food and beverage" },
  { key: "constructedMaterials", label: "Constructed materials" },
  { key: "chemicals", label: "Chemicals" },
  { key: "moving/furniture", label: "House moving/Furniture" },
  {
    key: "refrigeratedContainer",
    label: "Refrigerated container",
  },
  { key: "others", label: "Others" },
] as const;

export const PACKAGING_TYPE = [
  { key: "pallets", label: "Pallets" },
  { key: "boxes", label: "Boxes" },
  { key: "cartons", label: "Cartons" },
  { key: "crates", label: "Crates" },
  { key: "drums", label: "Drums" },
] as const;

export const SETTLEMENT_STATUS_DROPDOWN_OPTIONS = [
  { key: "REJECT", label: "Reject" },
  { key: "CONFIRM", label: "Confirm" },
] as const;

export const REASON_FOR_REJECT_DROPDOWN_OPTIONS = [
  {
    key: "Company information doesn’t exist.",
    label: "Company information doesn’t exist.",
  },
  {
    key: "Difficult to identify due to low image quality.",
    label: "Difficult to identify due to low image quality.",
  },
  {
    key: "The business license and information doesn’t match.",
    label: "The business license and information doesn’t match.",
  },
] as const;

export const GPS_API_DROPDOWN_OPTIONS = [
  {
    key: "EUPFIN",
    label: "EUPFIN" as Languages,
  },
  {
    key: "BA_GPS",
    label: "BA GPS" as Languages,
  },
] as const;
