import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const OrderInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 20px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const OrderInfoRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    & > h3 {
      ${theme.font.medium_16};
    }
  `}
`;

export const OrderInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-left: 12px;
`;

export const OrderInfoContentBox = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    column-gap: 8px;

    & > svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme.color.gray_40};
      }
    }

    & > span,
    time {
      color: ${theme.color.gray_80};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;
