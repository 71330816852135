import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  MemberDetailBusinessInfo,
  MemberDetailUserInfo,
  InternalLink,
  MemberDetailGpsApi,
} from "components";
import { useCurrentQueryParams } from "hooks";
import { MEMBER_COMPANY_TYPE_RADIOS } from "assets";
import { PATH } from "constants/index";
import * as S from "./MemberDetailDefaultInfo.styled";

interface MemberDetailDefaultInfoProps {
  memeberDetailType?: (typeof MEMBER_COMPANY_TYPE_RADIOS)[number]["key"];
}

const MemberDetailDefaultInfo = ({
  memeberDetailType,
}: MemberDetailDefaultInfoProps) => {
  const { t } = useTranslation();
  const { memberId } = useParams();

  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  return (
    <>
      <MemberDetailBusinessInfo />
      <MemberDetailUserInfo />
      {(memeberDetailType === "FORWARDERCARRIER" ||
        memeberDetailType === "CARRIER") && <MemberDetailGpsApi />}
      <S.LinkWrapper>
        <InternalLink
          css={S.link}
          variant="outlineMedium"
          to={`${PATH.MEMBER}?tab=${memberType}`}
        >
          {t("Back to list")}
        </InternalLink>
        <InternalLink
          css={S.link}
          variant="primaryMedium"
          to={`${PATH.MEMBER}/edit/${memberId}?memberType=${memberType}`}
        >
          {t("Edit information")}
        </InternalLink>
      </S.LinkWrapper>
    </>
  );
};

export default MemberDetailDefaultInfo;
