import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const profile = css`
  width: 40px;
  height: 40px;
`;

export const content = (theme: Theme) => css`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray_30};
  }

  & > div > div {
    padding: 12px 0;
  }
`;

export const ProfileWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;

      & > span {
        ${theme.font.regular_14};
      }
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    flex-direction: column;
  `}
`;

export const labelcontent = css`
  grid-template-columns: 100px 1fr;
  column-gap: 8px;
`;

export const labelcontentShipment = (theme: Theme) => css`
  grid-template-columns: 1px 128px 1fr;
  column-gap: 10px;

  &:before {
    content: "";
    flex-grow: 1;
    height: 20px;
    width: 2px;
    border-radius: 100px;
    background-color: ${theme.color.gray_30};
  }
`;
