import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface RegisteredTruckPaginationProps {
  pageInfo?: PageInfo;
}

const RegisteredTruckPagination = ({
  pageInfo,
}: RegisteredTruckPaginationProps) => {
  return <QueryPagination pageInfo={pageInfo} />;
};

export default RegisteredTruckPagination;
