import React from "react";
import { useRecoilValue } from "recoil";

import { Filter } from "components";
import { clientLanguage, truckTypeOptions } from "stores";
import { formatTruckTypes } from "utils";

const RegisteredManageLinkFilter = () => {
  const language = useRecoilValue(clientLanguage);
  const recoilTruckTypeOptions = useRecoilValue(truckTypeOptions) || [];
  const truckTypes = formatTruckTypes(language, recoilTruckTypeOptions);

  const searchLabels = [
    {
      queryKey: "driverName",
      label: "Linking",
    },
    {
      queryKey: "truckTypeId",
      label: "Truck type",
      options: truckTypes,
    },
    {
      queryKey: "registNumber",
      label: "Plate number",
    },
  ] as const;

  const excludeResetQueryKeys = ["memberType"];

  return (
    <Filter
      searchLabels={searchLabels}
      excludeResetQueryKeys={excludeResetQueryKeys}
    >
      <Filter.Row>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input
            queryKey="driverName"
            placeholder="Search by driver name"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Truck type">
          <Filter.Dropdown
            queryKey="truckTypeId"
            placeholder="Select the truck type"
            dropdowns={truckTypes}
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Plate number">
          <Filter.Input
            queryKey="registNumber"
            placeholder="Search by plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default RegisteredManageLinkFilter;
