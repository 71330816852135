import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { AlertModal, Button, DataSection, InternalLink } from "components";
import { useModal, useToast } from "hooks";
import { useDeleteMemberDetailRegisteredMember } from "services";
import { findLookupTableLabel, formatICTDateTime } from "utils";
import {
  GRADE_TYPE_RADIOS,
  MEMBER_REGISTERED_MEMBER_TABLE_HEADER,
  EditIcon,
  TrashIcon,
} from "assets";
import { TOAST_MSG } from "constants/index";
import type {
  TruckingCompanyType,
  GetMemberDetailRegisteredMemberServerModel,
  Languages,
} from "types";
import * as S from "./RegisteredMember.styled";

interface RegisteredMemberTableProps {
  staffs?: GetMemberDetailRegisteredMemberServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const RegisteredMemberTable = ({
  staffs,
  dataUpdatedAt,
  refetch,
}: RegisteredMemberTableProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { isLoading, mutate: deleteMemberDetailRegisteredMemberMutate } =
    useDeleteMemberDetailRegisteredMember();

  const memberType = searchParams.get("memberType");
  const handleDeleteButtonClick = (staffId: string) => (): void => {
    const req = {
      staffId,
      query: {
        companyType: (memberType === "forwarder"
          ? "forwardercarrier"
          : memberType) as TruckingCompanyType,
      },
    };

    deleteMemberDetailRegisteredMemberMutate(req, {
      onSuccess: () => addToast(TOAST_MSG.SUCCESS.MEMBER_DELETE_DONE),
      onSettled: handleModalClose,
    });
  };

  const formatTableData = (
    data: GetMemberDetailRegisteredMemberServerModel["staffs"][number],
    key: keyof typeof MEMBER_REGISTERED_MEMBER_TABLE_HEADER,
  ): string | React.ReactNode => {
    switch (key) {
      case "grade":
        const gradeLabel = findLookupTableLabel(GRADE_TYPE_RADIOS, data[key]);

        return gradeLabel ? t(gradeLabel) : "-";

      case "lastAccess":
      case "created":
        return data[key] ? formatICTDateTime(data[key]) : "-";

      case "":
        return (
          <S.ButtonWrapper>
            <Button
              variant="icon"
              label=""
              icon={<TrashIcon />}
              handleClickBtn={handleModalOpen(
                <AlertModal
                  css={S.alertModal}
                  ref={modalRef}
                  isLoading={isLoading}
                  title="Delete member"
                  desc="Are you sure you want to delete the member?"
                  negBtnLabel="Delete"
                  closeBtnLabel="Cancel"
                  callbackFn={handleDeleteButtonClick(data.staffId)}
                />,
              )}
            />
            <InternalLink
              variant="icon"
              to={`registeredMember/edit/${data.staffId}?memberType=${memberType}`}
            >
              <EditIcon />
            </InternalLink>
          </S.ButtonWrapper>
        );

      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={staffs ? staffs.pageInfo.totalData : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="driver list"
        columnTable={MEMBER_REGISTERED_MEMBER_TABLE_HEADER}
      >
        {staffs?.staffs.length &&
          staffs?.staffs.map((staff) => (
            <DataSection.Table.Row key={staff.staffId}>
              {keys(MEMBER_REGISTERED_MEMBER_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    staff,
                    key as keyof typeof MEMBER_REGISTERED_MEMBER_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.Row>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default RegisteredMemberTable;
