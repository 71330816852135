import { useState, useCallback, useRef, useEffect } from "react";

const useImgZoom = (
  minLevel: number,
  maxLevel: number,
  imageUrlIndex: number,
) => {
  const minZoomLevel = useRef(minLevel);
  const maxZoomLevel = useRef(maxLevel);
  const [zoomLevel, setZoomLevel] = useState(1);

  const zoomValue =
    ((zoomLevel - 1) / (maxZoomLevel.current - minZoomLevel.current)) * 100 +
    "%";

  const handleChangeZoomLevel = useCallback((e: any) => {
    setZoomLevel(+e.target.value);
  }, []);

  const handleZoomIn = useCallback(() => {
    if (maxZoomLevel.current === zoomLevel) return;

    setZoomLevel(zoomLevel + 1);
  }, [zoomLevel]);

  const handleZoomOut = useCallback(() => {
    if (minZoomLevel.current === zoomLevel) return;

    setZoomLevel(zoomLevel - 1);
  }, [zoomLevel]);

  useEffect(() => {
    setZoomLevel(1);
  }, [imageUrlIndex]);

  return {
    minZoomLevel,
    maxZoomLevel,
    zoomLevel,
    zoomValue,
    handleChangeZoomLevel,
    handleZoomIn,
    handleZoomOut,
  };
};

export default useImgZoom;
