import type { Languages } from "types";

export const MEMBER_FILTER_STATUS_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "APPROVED", label: "Approved" },
  { key: "REJECTED", label: "Rejected" },
  { key: "PENDING", label: "Pending" },
] as const;

export const LINKING_FILTER_STATUS_RADIOS = [
  { key: "shipper", label: "Shipper" },
  { key: "forwarder", label: "Forwarder" },
  { key: "carrier", label: "Carrier" },
] as const;

export const MEMBER_COMPANY_TYPE_RADIOS = [
  { key: "SHIPPER", label: "Shipper" },
  { key: "FORWARDER", label: "Forwarder" },
  { key: "CARRIER", label: "Carrier" },
  { key: "FORWARDERCARRIER", label: "Forwarder + Carrier" },
  // { key: "DRIVER", label: "Driver (Individual)" }, TODO: 10월 10일 업데이트에서 사용 안함으로 주석처리
] as const;

export const MEMBER_REGISTERED_COMPANY_RADIOS = [
  { key: "YES", label: "Yes" },
  { key: "NO", label: "No" },
] as const;

export const MEMBER_MEMBERSHIP_PLANS_RADIOS = [
  { key: "STANDARD", label: "Standard" },
  { key: "BUSINESS", label: "Business" },
  { key: "PREMIUM", label: "Premium" },
] as const;

export const ORDER_MANAGEMENT_FILTER_STATUS_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "ALLOCATION", label: "Allocation" },
  { key: "SHIPPING", label: "Shipping" },
  { key: "COMPLETED", label: "Completed" },
  { key: "CANCELLED", label: "Canceled" },
] as const;

export const QUOTATION_FILTER_STATUS_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "ONGOING", label: "Ongoing" },
  { key: "COMPLETED", label: "Completed" },
  { key: "CANCELLED", label: "Canceled" },
] as const;

export const LOADING_STATUS_RADIOS = [
  { key: "LTL", label: "LTL" },
  { key: "FTL", label: "FTL" },
] as const;

export const PAYMENT_METHOD_RADIOS = [
  { key: "ACCOUNT_TRANSFER", label: "Account transfer" },
  { key: "COD", label: "CoD" },
] as const;

export const PAYMENT_METHOD_PLAN_RADIOS = [
  { key: "ACCOUNT_TRANSFER", label: "Account transfer" },
  { key: "CREDIT_CARD", label: "Credit card" },
] as const;

export const MEMBERSHIP_PLANS_STATUS_RADIOS = [
  { key: "COMPLETED", label: "Completed" },
  { key: "CANCELED", label: "Canceled" },
] as const;

export const HAZARD_GOODS_RADIOS = [
  { key: "yes", label: "Yes" },
  { key: "no", label: "No" },
] as const;

export const STACKABLE_GOODS_RADIOS = [
  { key: "yes", label: "Yes" },
  { key: "no", label: "No" },
] as const;

export const SETTLEMENT_FILTER_STATUS_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "WAITING", label: "Waiting" },
  { key: "ONGOING", label: "Ongoing" },
  { key: "COMPLETED", label: "Completed" },
] as const;

export const SUPPORT_INQUIRY_CATEGORY_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "ORDER", label: "Order" },
  { key: "SERVICE", label: "Service" },
  { key: "ERROR", label: "Error" },
  { key: "DELETE_ACCOUNT", label: "Delete account" },
  { key: "OTHER", label: "Other" },
] as const;

export const SUPPORT_COMPANY_TYPE_RADIOS = [
  { key: "ALL", label: "All" },
  { key: "SHIPPER", label: "Shipper" }, //TODO: (Shipper)수정필요 -> 번역이 없어서 괄호 삭제
  { key: "FORWARDER", label: "Forwarder" },
  { key: "CARRIER", label: "Carrier" },
  { key: "DRIVER", label: "Driver" },
] as const;

export const TAX_RADIOS = [
  { key: "8", label: "8%" as Languages }, //NOTE: radio languages 타입으로 인해 부득이한 타입단언
  { key: "10", label: "10%" as Languages }, //NOTE: radio languages 타입으로 인해 부득이한 타입단언
] as const;

export const GRADE_TYPE_RADIOS = [
  { key: "MODERATOR", label: "Moderator" },
  { key: "MANAGER", label: "Manager" },
] as const;

export const APPROVE_STATUS_RADIOS = [
  { key: "APPROVE", label: "Approve" },
  { key: "REJECT", label: "Reject" },
] as const;
