import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const tab = (theme: Theme) => css`
  height: ${`calc(100% - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT})`};
`;

export const querySearchInput = (theme: Theme) => css`
  & > input {
    ${theme.font.regular_15};

    ::placeholder {
      ${theme.font.regular_15};
    }
  }
`;

export const TabListWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${theme.size.LIST_LAYOUT_HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 14px 20px 8px 20px;
  `}
`;

export const resetButton = (theme: Theme) => css`
  color: ${theme.color.gray_70};

  & > svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;

    path {
      fill: ${theme.color.gray_70};
    }
  }
`;

export const tabPanel = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const tabList = css`
  /* NOTE: 기존 tab의 마진값으로 인해 임시로 추가함 -> 전체적으로 확인 필요 */
  margin-bottom: 0;
`;
