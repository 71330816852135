import React from "react";
import { useRecoilValue } from "recoil";

import { toastAtom } from "stores";

import ToastItem from "./item/ToastItem";
import * as S from "./Toast.styled";

const Toast = () => {
  const toasts = useRecoilValue(toastAtom);

  return (
    <S.Root>
      {toasts.map((toast) => (
        <ToastItem key={toast.id} {...toast} />
      ))}
    </S.Root>
  );
};

export default Toast;
