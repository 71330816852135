import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 8px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 20px;

    svg {
      width: 20px;
    }
  `}
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    height: 12px;
    border: 0;
    background-color: ${theme.color.gray_20};
  `}
`;

export const AllocationListWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > span:first-of-type {
      ${theme.font.bold_16};
      border-bottom: 1px solid ${theme.color.gray_30};
      padding: 12px 20px;
    }
  `}
`;
