import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "hooks";
import { useGetRegisteredMemberDetail, usePutRegisteredMember } from "services";
import { PATH, TOAST_MSG } from "constants/index";
import type { RegisteredMemberForm } from "types";

const useEditRegisteredMember = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();

  const { data } = useGetRegisteredMemberDetail(memberId!);
  const { mutate: putRegisteredMemberMutate } = usePutRegisteredMember();

  const formMethod = useForm<RegisteredMemberForm>({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      grade: "MODERATOR",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { addToast } = useToast();

  const handleEditRegisteredMember = formMethod.handleSubmit((data) => {
    const req = {
      firstName: data.firstName.trim(),
      middleName: data.middleName.trim() ?? "",
      lastName: data.lastName.trim(),
      phone: data.phone.trim(),
      grade: data.grade,
    };

    putRegisteredMemberMutate(
      { adminId: memberId!, body: req },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.REGISTERED_MEMBER_EDIT_SUCCESS);
          navigate(PATH.SETTING_REGISTERED_MEMBER);
        },
        onError: (err) => {
          if (err.response?.data.response === "EMAIL_EXISTS") {
            addToast(TOAST_MSG.WARNING.SETTING_REGISTERED_MEMBER_EMAIL_EXISTS);
          }
          if (err.response?.data.response === "PHONE_EXISTS") {
            addToast(TOAST_MSG.WARNING.SETTING_REGISTERED_MEMBER_PHONE_EXISTS);
          }
        },
      },
    );
  });

  useEffect(() => {
    if (!data) return;

    formMethod.reset({
      firstName: data.firstName ?? "",
      middleName: data.middleName ?? "",
      lastName: data.lastName ?? "",
      phone: data.phone ?? "",
      grade: data.grade ?? "MODERATOR",
      email: data.email,
      password: "",
      confirmPassword: "",
    });
  }, [data]);

  return {
    formMethod,
    handleSubmit: handleEditRegisteredMember,
  };
};

export default useEditRegisteredMember;
