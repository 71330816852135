import { useState, useEffect } from "react";
import { isEmpty } from "lodash-es";

import { PickUpDropOffInfoWindow } from "components";
import { cleanUpMarkers, createMarker } from "utils";
import pickUpMarkerSvg from "assets/icon/ic_pickUpPin.svg";
import pointMarkerSvg from "assets/icon/ic_pointPin.svg";
import type { AddressSearchType } from "types";

const useAddrSearchLocationMarker = (
  googleMap: google.maps.Map,
  locationInfo: AddressSearchType,
  isRenderReady: boolean,
) => {
  const [locationLatLngs, setLocationLatLngs] = useState<google.maps.LatLng>();

  const [markers, setMarkers] = useState<google.maps.Marker>();

  const addLocationInfoWindow = (
    map: google.maps.Map,
    marker: google.maps.Marker,
    infoWindowContent: { label: string; icon: string },
  ) => {
    const infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, 6),
      content: PickUpDropOffInfoWindow(infoWindowContent),
    });

    infoWindow.open({ anchor: marker, map });

    return infoWindow;
  };

  const addLocationMarker = () => {
    if (!googleMap || isEmpty(locationInfo)) return;

    const { lat, lng } = locationInfo.coord;

    if (markers) {
      markers.setMap(null);
    }

    const marker = createMarker(
      googleMap!,
      locationInfo.coord.lat,
      locationInfo.coord.lng,
      pointMarkerSvg,
    );

    addLocationInfoWindow(googleMap, marker, {
      label: "Company",
      icon: pickUpMarkerSvg,
    });
    setMarkers(marker);

    setLocationLatLngs(new google.maps.LatLng(lat, lng));
  };

  useEffect(() => {
    isRenderReady && addLocationMarker();

    return () => {
      cleanUpMarkers([]);
    };
  }, [googleMap, locationInfo]);

  return { locationLatLngs };
};

export default useAddrSearchLocationMarker;
