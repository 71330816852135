import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  getSettlementDetailAPI,
  getSettlementsAPI,
  putSettlementStatusChangeAPI,
} from "api";
import type {
  APIError,
  GetSettlementQueryModel,
  GetSettlementServerModel,
  putSettlementStatusChangeQueryModel,
} from "types";

const settlementKeys = {
  all: ["settlement"],
  lists: () => [...settlementKeys.all, "list"],
  list: (filters: GetSettlementQueryModel) => [
    ...settlementKeys.lists(),
    { filters },
  ],
  details: () => [...settlementKeys.all, "detail"],
  detail: (id: string) => [...settlementKeys.details(), id],
};

export const useGetSettlements = (
  req: GetSettlementQueryModel,
  options?: UseQueryOptions<
    GetSettlementServerModel,
    APIError,
    GetSettlementServerModel,
    ReturnType<typeof settlementKeys.list>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: settlementKeys.list(req),
    queryFn: () => getSettlementsAPI(req),
  });
};

export const useGetSettlementDetail = (offerId: string) => {
  return useQuery({
    queryKey: settlementKeys.detail(offerId),
    queryFn: () => getSettlementDetailAPI(offerId),
  });
};

export const usePutSettlementStatusChange = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: putSettlementStatusChangeQueryModel) =>
      putSettlementStatusChangeAPI(req),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: settlementKeys.details() }),
  });
};
