import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  PageLayout,
  PreviousPageLink,
  QuotationCargoDetails,
  QuotationDefaultInfo,
  QuotationDropOffInfo,
  QuotationPickUpInfo,
  QuotationSection,
  QuotationTruckingMethods,
  TableOfContent,
} from "components";
import { useGetQuotationDetail } from "services";
import { QUOTATION_DETAIL_LAYOUT } from "assets";
import { PATH } from "constants/index";
import * as S from "./QuotationDetail.styled";

const QuotationDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: quotationDetail } = useGetQuotationDetail(id!);

  const contents = [
    {
      text: "Default information",
      content: (
        <QuotationDefaultInfo defaultInfo={quotationDetail?.defaultInfo} />
      ),
    },
    {
      text: `${t("Quotation")} (${quotationDetail?.history.length ?? 0})`,
      content: (
        <QuotationSection
          defaultInfo={quotationDetail?.defaultInfo}
          histories={quotationDetail?.history}
        />
      ),
    },
    {
      text: "Pick-up information",
      content: <QuotationPickUpInfo pickupInfo={quotationDetail?.pickUpInfo} />,
    },
    {
      text: "Drop-off information",
      content: (
        <QuotationDropOffInfo dropoffInfo={quotationDetail?.dropOffInfo} />
      ),
    },
    {
      text: `${t("Cargo details")} (${quotationDetail?.cargoes.length ?? 0})`,
      content: <QuotationCargoDetails cargoes={quotationDetail?.cargoes} />,
    },
    {
      text: "Trucking methods",
      content: (
        <QuotationTruckingMethods
          truckingMethods={quotationDetail?.truckingMethods}
        />
      ),
    },
  ];

  return (
    <PageLayout css={S.layout} layoutStaticData={QUOTATION_DETAIL_LAYOUT}>
      <TableOfContent
        css={S.tableOfContent}
        contents={contents.map(({ text, content }) => ({
          heading: { headingLevel: "h2", text },
          content,
        }))}
      />
      <PreviousPageLink
        css={S.link}
        variant="outlineMedium"
        to={PATH.QUOTATION}
      >
        {t("Back to list")}
      </PreviousPageLink>
    </PageLayout>
  );
};

export default QuotationDetail;
