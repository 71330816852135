import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const layout = css`
  padding-bottom: 30px;
`;

export const pageBackHeader = (theme: Theme) => css`
  padding-left: 0;

  & > div > span {
    ${theme.font.bold_18};
  }
`;

export const card = css`
  width: 564px;
  margin-bottom: 12px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 24px;
  margin-top: 16px;
`;

export const NameWrapper = styled.div`
  & > div {
    display: flex;
    column-gap: 10px;
  }
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin-left: auto;
  width: fit-content;
`;

export const AgreementProperty = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 16px 20px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const checkbtn = (theme: Theme) => css`
  ${theme.font.regular_14};
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  color: ${theme.color.gray_70};

  & > label {
    width: fit-content;

    &:last-of-type {
      &::after {
        content: "*";
        margin-left: 2px;
        color: ${theme.color.red_20};
      }
    }
  }
`;
