import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AddrWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const AddrDetail = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_70};
  `}
`;
