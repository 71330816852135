import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const layout = (theme: Theme) => css`
  & > h1 {
    ${theme.font.bold_18};
    margin-bottom: 32px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
  margin-top: 40px;

  & > a {
    width: fit-content;
  }
`;
