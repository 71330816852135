import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { badge } from "style";

interface BadgeProps {
  variant: keyof typeof badge;
}

export const Badge = styled.span<BadgeProps>`
  ${({ theme, variant }) => css`
    ${variant === "primary" && theme.badge.primary};
    ${variant === "red" && theme.badge.red};
    ${variant === "gray" && theme.badge.gray};
  `}
`;
