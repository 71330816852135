import React from "react";
import { FormProvider } from "react-hook-form";

import {
  SettingMemberInfoButtons,
  MemberInfoForm,
  PageLayout,
} from "components";
import { SETTING_REGISTERED_MEMBER_CREATE_LAYOUT } from "assets";
import { useCreateRegisteredMember } from "./hooks";
import * as S from "./RegisteredMemberCreate.styled";

const RegisteredMemberCreate = () => {
  const { formMethod, handleSubmit } = useCreateRegisteredMember();

  return (
    <PageLayout
      css={S.layout}
      layoutStaticData={SETTING_REGISTERED_MEMBER_CREATE_LAYOUT}
    >
      <FormProvider {...formMethod}>
        <form onSubmit={handleSubmit}>
          <MemberInfoForm type="add" />
          <SettingMemberInfoButtons isLoading={false} />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default RegisteredMemberCreate;
