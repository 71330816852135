import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { SCHEMA } from "utils";
import {
  GetMemberDetailGarageServerModel,
  MemberDetailGarageForm,
} from "types";

const initForm = {
  name: "",
  garageAddress: {
    address: "",
    addressDetail: "",
    coord: { lng: 0, lat: 0 },
    city: "",
    buildingName: "",
  },
  capacity: "",
};

const schema = yup.object({
  name: SCHEMA.REQUIRED_STRING,
  garageAddress: SCHEMA.ADDRESS,
  capacity: SCHEMA.REQUIRED_STRING,
});

interface UseMemeberDetailRegisteredGarageFormProps {
  data?: GetMemberDetailGarageServerModel;
}

const useMemeberDetailRegisteredGarageForm = ({
  data,
}: UseMemeberDetailRegisteredGarageFormProps) => {
  const formMethod = useForm<MemberDetailGarageForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!data) return;

    formMethod.reset({
      name: data.name,
      garageAddress: {
        address: data.address,
        addressDetail: data.addressDetail ?? "",
        coord: { lat: data.lat, lng: data.lng },
      },
      capacity: `${data.capacity}`,
    });
  }, [data]);

  return { formMethod };
};

export default useMemeberDetailRegisteredGarageForm;
