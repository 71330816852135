import React from "react";
import { keys } from "lodash-es";

import { DataSection, StatusLabel, Profile } from "components";
import { useCurrentQueryParams } from "hooks";
import { formatICTDateTime, formatPhoneNum, makeNameString } from "utils";
import { MEMBER_REGISTERED_DRIVER_TABLE_HEADER } from "assets";
import type { GetMemberDetailDriversClientModel } from "types";
import * as S from "./RegisteredDriver.styled";

interface RegisteredDriverTableProps {
  drivers?: GetMemberDetailDriversClientModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const RegisteredDriverTable = ({
  drivers,
  dataUpdatedAt,
  refetch,
}: RegisteredDriverTableProps) => {
  const memberType = useCurrentQueryParams("memberType")?.memberType[0];

  const formatTableData = (
    data: GetMemberDetailDriversClientModel["drivers"][number],
    key: keyof typeof MEMBER_REGISTERED_DRIVER_TABLE_HEADER,
  ): string | React.ReactNode => {
    switch (key) {
      case "status":
        return data[key] ? <StatusLabel status={data[key]} /> : "-";

      case "phone":
        return data[key] ? formatPhoneNum(data[key]) : "-";

      case "lastShippingOn":
      case "created":
        return data[key]
          ? formatICTDateTime(
              data[key],
              key === "created" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm",
            )
          : "-";

      case "name":
        return (
          <S.Driver>
            <Profile src={data?.profileUrl ?? ""} />
            <span>
              {makeNameString(data.lastName, data.middleName, data.firstName)}
            </span>
          </S.Driver>
        );

      default:
        return data[key] ?? "-";
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={drivers ? drivers.pageInfo.totalData : null}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="driver list"
        columnTable={MEMBER_REGISTERED_DRIVER_TABLE_HEADER}
      >
        {drivers?.drivers.length &&
          drivers?.drivers.map((driver) => (
            <DataSection.Table.SelectRowMovePage
              id={driver.driverId}
              key={driver.driverId}
              path={`registeredDriver/${driver.driverId}?memberType=${memberType}`}
            >
              {keys(MEMBER_REGISTERED_DRIVER_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    driver,
                    key as keyof typeof MEMBER_REGISTERED_DRIVER_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.SelectRowMovePage>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default RegisteredDriverTable;
