import React from "react";
import { useRecoilValue } from "recoil";
import { keys } from "lodash";

import { Button, Profile, Table } from "components";
import { clientLanguage } from "stores";
import {
  LinkIcon,
  MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER,
  UnlinkIcon,
} from "assets";
import type { GetMemberLinksClientModel } from "types";
import { useLinkDriver } from "../driverList/hooks";
import * as S from "./RegisteredManageLinkTable.styled";

interface RegisteredManageLinkTableProps {
  links?: GetMemberLinksClientModel["links"];
  selectedTruckId: string | null;
  handleRowClick: (truckId: string) => () => void;
}

const RegisteredManageLinkTable = ({
  links,
  selectedTruckId,
  handleRowClick,
}: RegisteredManageLinkTableProps) => {
  const language = useRecoilValue(clientLanguage);

  const { handleDisconnectLinkDriver } = useLinkDriver();

  const formatTableData = (
    data: GetMemberLinksClientModel["links"][number],
    key: keyof typeof MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER,
  ): string | React.ReactNode => {
    const hasLinkedDriver = data.drivers.length > 0;

    const getTruck = (
      key: keyof GetMemberLinksClientModel["links"][number],
    ): string => {
      const suffix = language === "en" ? "En" : "Vi";
      const newKey = (key.replace("Id", "") + suffix) as keyof Omit<
        GetMemberLinksClientModel["links"][number],
        "drivers" | "truckOptionId" | "truckTypeId"
      >;

      return data[key] ? data[newKey] : "-";
    };

    switch (key) {
      case "registNumber":
        return data[key] ?? "-";

      case "truckTypeId":
      case "truckOptionId":
        return getTruck(key);

      case "drivers":
        return (
          <S.Linked>
            {hasLinkedDriver ? (
              <S.Driver>
                <Profile src={data.drivers[0].profileUrl} />
                <span>{data.drivers[0].driverName}</span>
              </S.Driver>
            ) : (
              "-"
            )}
            <S.LinkingDriverNumber>{`(${data.drivers.length})`}</S.LinkingDriverNumber>
          </S.Linked>
        );

      case "":
        return (
          <Button
            css={S.linkButton(hasLinkedDriver)}
            variant="icon"
            label=""
            isDisabled={!hasLinkedDriver}
            icon={
              hasLinkedDriver ? (
                <LinkIcon css={S.linkIcon} />
              ) : (
                <UnlinkIcon css={S.linkIcon} />
              )
            }
            handleClickBtn={handleDisconnectLinkDriver(data.truckId)}
          />
        );
    }
  };

  return (
    <Table
      css={S.table}
      title="manage link list"
      columnTable={MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER}
    >
      {links?.length ? (
        links.map((link) => (
          <Table.SelectRow
            key={link.registNumber}
            css={S.selectRow(selectedTruckId === link.truckId)}
            handleRowClick={handleRowClick(link.truckId)}
          >
            {keys(MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER).map((key, i) => (
              <Table.Td css={S.td} key={i}>
                {formatTableData(
                  link,
                  key as keyof typeof MEMBER_REGISTERED_MANAGE_LINK_TABLE_HEADER,
                )}
              </Table.Td>
            ))}
          </Table.SelectRow>
        ))
      ) : (
        <Table.NoData />
      )}
    </Table>
  );
};

export default RegisteredManageLinkTable;
