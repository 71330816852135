import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_ul } from "style";
import type { PickDropType } from "types";

export const Root = styled.ul`
  ${mixin_ul};
  margin: 0;
`;

export const PointDetail = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 10px auto auto;
    max-width: calc(10px + 9px + 168px);
  }
`;

export const pointIcon = (type: PickDropType) => (theme: Theme) =>
  css`
    path {
      fill: ${type === "PICK" ? theme.color.primary_40 : theme.color.red_20};
    }
  `;

export const PointDestination = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin: 0 2px 0 9px;
    color: ${theme.color.gray_80};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  `}
`;

const mixin_content = (theme: Theme) => css`
  ${theme.font.regular_13};
`;

export const NotYet = styled.span`
  ${({ theme }) => css`
    ${mixin_content(theme)};
    color: ${theme.color.gray_60};
  `}
`;

export const Time = styled.time`
  ${({ theme }) => css`
    ${mixin_content(theme)};
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const Ongoing = styled.span`
  ${({ theme }) => css`
    ${mixin_content(theme)};
    color: ${theme.color.primary_40};
  `}
`;
