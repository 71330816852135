import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "components";
import { QuestionIcon } from "assets";
import type { Languages, TooltipPropsType } from "types";
import * as S from "./LabelContent.styled";

interface LabelContentProps {
  className?: string;
  label: Languages;
  icon?: React.ReactNode;
  children: React.ReactNode;
  tooltip?: TooltipPropsType;
  isRequired?: boolean;
  direction: "horizontal" | "vertical";
}

const LabelContent = ({
  className,
  label,
  icon,
  tooltip,
  isRequired,
  direction,
  children,
}: LabelContentProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper className={className} direction={direction}>
      <S.LabelWrapper>
        <S.Label isRequired={isRequired}>
          {icon && icon}
          {t(label)}
        </S.Label>
        {tooltip && (
          <Tooltip
            title={tooltip.title}
            contents={tooltip.contents}
            tooltipBtnContent={QuestionIcon}
          />
        )}
      </S.LabelWrapper>
      {children}
    </S.Wrapper>
  );
};

export default LabelContent;
