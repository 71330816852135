import React from "react";

import { Button, FormInput, LabelContentTable } from "components";
import { makeNameString, numericOnly } from "utils";
import { useMemberDetailRegisteredDriverAccountInfo } from "./hooks";
import * as S from "./MemberDetailRegisteredDriverAccountInfo.styled";

const MemberDetailRegisteredDriverAccountInfo = () => {
  const { isEdit, data, formMethod, handleIsEdit, handleSubmit } =
    useMemberDetailRegisteredDriverAccountInfo();

  if (!data) return null;

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Name of bank"
          >
            {isEdit ? (
              <FormInput
                placeholder="Name of bank"
                maxLength={50}
                register={formMethod.register("bankName")}
              />
            ) : (
              <>{data.bankName || "-"}</>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Branch"
          >
            {isEdit ? (
              <FormInput
                placeholder="Branch"
                maxLength={50}
                register={formMethod.register("bankBranch")}
              />
            ) : (
              <>{data.bankBranch || "-"}</>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Account number"
          >
            {isEdit ? (
              <FormInput
                placeholder="Only numbers"
                maxLength={50}
                value={numericOnly(formMethod.watch("bankAccountNo"))}
                register={formMethod.register("bankAccountNo")}
              />
            ) : (
              <>{data.bankAccountNo || "-"}</>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Account holder">
            {makeNameString(data.lastName, data.middleName, data.firstName)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.ButtonWrapper>
        {isEdit ? (
          <>
            <Button
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleIsEdit}
            />
            <Button
              variant="primaryMedium"
              label="Update"
              handleClickBtn={handleSubmit}
            />
          </>
        ) : (
          <Button
            variant="outlineMedium"
            label="Edit"
            handleClickBtn={handleIsEdit}
          />
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default MemberDetailRegisteredDriverAccountInfo;
