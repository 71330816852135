import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const FormInput = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 40px;
    margin-bottom: ${hasError && "4px"};
    border: 1px solid ${hasError ? theme.color.red_30 : theme.color.gray_30};
    padding: 10px 13px;

    &::placeholder {
      color: ${theme.color.gray_50};
    }

    &:disabled {
      border: 1px solid ${theme.color.gray_35};
      color: ${theme.color.black};
      background-color: ${theme.color.gray_10};
    }

    &:not(:disabled):hover {
      border: 1px solid ${theme.color.primary_40};
    }
  `}
`;

export const errorMsg = (theme: Theme) => css`
  color: ${theme.color.red_20};
`;

export const Length = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: absolute;
    bottom: 0;
    left: 0;
    color: ${theme.color.gray_50};
  `};
`;
