import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const OrderInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px 20px;
    background-color: ${theme.color.gray_00};

    & > div > div > label {
      ${theme.font.medium_14};
    }

    & > div > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_80};
    }
  `}
`;

export const Division = styled.hr`
  ${({ theme }) => css`
    width: 350px;
    margin: 0 auto;
    border: 1px solid ${theme.color.gray_20};
  `}
`;

export const labelcontent = (theme: Theme) =>
  css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: flex-start;

    label {
      display: grid;
      grid-template-columns: 20px 1fr;

      & > svg {
        margin-top: 4px;
      }
    }
  `;

export const CargoBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;

    & > div:nth-of-type(1) {
      ${theme.font.medium_12};
      position: relative;
      left: 0;
    }
  `}
`;

export const badge = css`
  margin-right: 8px;
`;

export const tooltip = (theme: Theme) => css`
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  & > button {
    height: fit-content;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 2px 5px;
    color: ${theme.color.gray_70};
    background-color: ${theme.color.white};

    :hover {
      background-color: ${theme.color.gray_10};
    }
  }

  /* NOTE: overflow 이슈로 인하여 고정 */
  > dl {
    top: calc(100% + 2px);
    left: inherit;
    right: 0;
  }
`;

export const tooltipInner = styled.span`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.color.white};
  `}
`;
