import { atom } from "recoil";

interface VerifyAccountInfo {
  id: string;
  authCode: string;
  isEmailVerifiDone: boolean;
}

export const initVerifyAccountInfo = {
  id: "",
  authCode: "",
  isEmailVerifiDone: false,
};

const verifyAccountInfoAtom = atom<VerifyAccountInfo>({
  key: "verifyAccountInfoAtom",
  default: initVerifyAccountInfo,
});

export { verifyAccountInfoAtom };
