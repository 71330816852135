import React from "react";
import { useTranslation } from "react-i18next";
import { keys } from "lodash-es";

import { DataSection, StatusLabel } from "components";
import { useModal } from "hooks";
import { formatICTDateTime, formatPrice, findLookupTableLabel } from "utils";
import {
  EditIcon,
  MEMBERSHIP_PLAN_TABLE_HEADER,
  PAYMENT_METHOD_PLAN_RADIOS,
  MEMBER_MEMBERSHIP_PLANS_RADIOS,
} from "assets";
import type { GetMemberCompanyPlanServerModel } from "types";
import MembershipPlanModal from "../modal/MembershipPlanModal";
import * as S from "./MembershipPlanTable.styled";

interface MembershipPlanTableProps {
  plans?: GetMemberCompanyPlanServerModel;
  refetch: () => void;
  dataUpdatedAt: number;
}

const MembershipPlanTable = ({
  plans,
  refetch,
  dataUpdatedAt,
}: MembershipPlanTableProps) => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();

  const formatTableData = (
    data: GetMemberCompanyPlanServerModel["plans"][number],
    key: keyof typeof MEMBERSHIP_PLAN_TABLE_HEADER,
  ) => {
    switch (key) {
      case "startDate":
      case "endDate":
        return data[key] ? formatICTDateTime(data[key], "DD/MM/YYYY") : "-";

      case "total":
        return formatPrice(+data[key]);

      case "planName":
        const planLabel =
          findLookupTableLabel(MEMBER_MEMBERSHIP_PLANS_RADIOS, data[key]) ??
          "Standard";

        return t(planLabel);

      case "status":
        return <StatusLabel status={data[key]} />;

      case "pay":
        const payMethodLabel =
          findLookupTableLabel(PAYMENT_METHOD_PLAN_RADIOS, data[key]) ??
          "Account transfer";
        return t(payMethodLabel);

      case "":
        return (
          <S.Button
            onClick={handleModalOpen(
              <MembershipPlanModal
                ref={modalRef}
                title="Edit"
                data={plans?.plans}
                id={data.companyPlanId}
              />,
            )}
          >
            <EditIcon />
          </S.Button>
        );

      default:
        return data[key];
    }
  };

  if (!plans) return null;

  const planData = plans.plans?.map((item) => ({
    ...item,
    "": null,
  }));

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={plans?.pageInfo.totalData}
      refetch={refetch}
      activeBtns={
        <DataSection.ActiveBtn
          label="Add"
          variant="primaryMedium"
          handleClickActiveBtn={handleModalOpen(
            <MembershipPlanModal ref={modalRef} title="Add" />,
          )}
        />
      }
    >
      <DataSection.Table
        css={S.table}
        title="Membership plans details"
        columnTable={MEMBERSHIP_PLAN_TABLE_HEADER}
      >
        {plans?.plans.length &&
          planData.map((plan) => (
            <DataSection.Table.Row css={S.row} key={plan.companyPlanId}>
              {keys(MEMBERSHIP_PLAN_TABLE_HEADER).map((key) => (
                <DataSection.Table.Td key={key}>
                  {formatTableData(
                    plan,
                    key as keyof typeof MEMBERSHIP_PLAN_TABLE_HEADER,
                  )}
                </DataSection.Table.Td>
              ))}
            </DataSection.Table.Row>
          ))}
      </DataSection.Table>
    </DataSection>
  );
};

export default MembershipPlanTable;
