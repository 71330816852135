import React from "react";

import { formatOrderNum } from "utils";
import { DropdownIcon } from "assets";
import * as S from "./ButtonFoldOrderNumber.styled";

interface ButtonFoldOrderNumberProps {
  isFold: boolean;
  orderNumber: string;
  handleFoldBtnClick: () => void;
}

const ButtonFoldOrderNumber = ({
  isFold,
  orderNumber,
  handleFoldBtnClick,
}: ButtonFoldOrderNumberProps) => {
  return (
    <S.ButtonFoldOrderNumber
      type="button"
      isFold={isFold}
      onClick={handleFoldBtnClick}
    >
      <S.OrderInfoFoldBtnContentWrapper>
        {formatOrderNum(orderNumber)}
      </S.OrderInfoFoldBtnContentWrapper>
      <DropdownIcon css={S.foldButton(isFold)} />
    </S.ButtonFoldOrderNumber>
  );
};

export default ButtonFoldOrderNumber;
