import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { upperCase } from "lodash-es";

import {
  InternalLink,
  MembershipPlanPagination,
  MembershipPlanTable,
  PageLayout,
} from "components";
import { useGetPlans } from "services";
import { MEMBER_COMPANY_TYPE_RADIOS } from "assets";
import { PATH } from "constants/index";
import * as S from "./MembershipPlanDetail.styled";

const MembershipPlanDetail = () => {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();

  const memberTypeParams = searchParams.get("memberType")!;

  const MEMBERSHIP_PLAN_DETAIL_LAYOUT = {
    heading: "Membership plans details",
    histories: [
      { content: "Member", path: PATH.MEMBER },
      {
        content: "Member details",
        path: `${PATH.MEMBER}/${memberId}?memberType=${memberTypeParams}`,
      },
      { content: "Membership plans" },
    ],
  } as const;

  const {
    data: plans,
    refetch,
    dataUpdatedAt,
  } = useGetPlans({
    companyId: memberId!,
    query: {
      companyType: upperCase(
        memberTypeParams,
      ) as (typeof MEMBER_COMPANY_TYPE_RADIOS)[number]["key"],
    },
  });

  return (
    <PageLayout layoutStaticData={MEMBERSHIP_PLAN_DETAIL_LAYOUT}>
      <MembershipPlanTable
        plans={plans}
        refetch={refetch}
        dataUpdatedAt={dataUpdatedAt}
      />
      <MembershipPlanPagination pageInfo={plans?.pageInfo} />
      <InternalLink
        css={S.link}
        variant="outlineMedium"
        to={`${PATH.MEMBER}/${memberId}?memberType=${memberTypeParams}`}
      >
        {t("Back to list")}
      </InternalLink>
    </PageLayout>
  );
};

export default MembershipPlanDetail;
