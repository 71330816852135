import React from "react";

import { QueryPagination } from "components";
import type { PageInfo } from "types";

interface CarrierPaginationProps {
  pageInfo?: PageInfo;
}

const CarrierPagination = ({ pageInfo }: CarrierPaginationProps) => {
  if (!pageInfo) return null;

  return <QueryPagination pageInfo={pageInfo} />;
};

export default CarrierPagination;
