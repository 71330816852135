import React from "react";
import { pick } from "lodash-es";

import {
  ListPageLayout,
  QuotationFilter,
  QuotationPagination,
  QuotationTable,
} from "components";
import { useFilterParams } from "hooks";
import { useGetQuotations } from "services";
import type { GetQuotationQueryModel } from "types";
import * as S from "./Quotation.styled";

interface Filter
  extends Omit<GetQuotationQueryModel["query"], "startDate" | "endDate"> {
  date?: string;
}

const Quotation = () => {
  const requiredFilterKeys = ["startDate", "endDate"];

  const { filters } = useFilterParams<Filter>([
    "page",
    "status",
    "shipperId",
    "senderId",
    "date",
    "orderNumber",
  ]);

  const hasRequiredFilter =
    Object.keys(pick(filters, requiredFilterKeys)).length ===
    requiredFilterKeys.length;

  const {
    data: quotations,
    refetch,
    dataUpdatedAt,
  } = useGetQuotations(
    {
      query: { ...filters, status: filters.status ?? "ALL" },
    },
    { enabled: hasRequiredFilter },
  );

  return (
    <ListPageLayout css={S.layout} heading="Quotation management">
      <QuotationFilter />
      <QuotationTable
        quotations={quotations}
        hasRequiredFilter={hasRequiredFilter}
        refetch={refetch}
        dataUpdatedAt={dataUpdatedAt}
      />
      <QuotationPagination pageInfo={quotations?.pageInfo} />
    </ListPageLayout>
  );
};

export default Quotation;
